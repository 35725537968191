import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { EditButton, modalActions } from '../../../../packages';
import { accountModalName } from '../../consts';

export const EditAccountButton = memo(({ id }) => {
  const dispatch = useDispatch();

  const onModalOpen = useCallback(() => {
    dispatch(
      modalActions.setModalData({
        name: accountModalName,
        data: id,
      })
    );
    dispatch(
      modalActions.setModalIsOpen({
        name: accountModalName,
        isOpen: true,
      })
    );
  }, [dispatch]);

  return <EditButton onClick={onModalOpen} />;
});
