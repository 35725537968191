import styled from 'styled-components';

import { scrollable } from '../../../theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: column;

  ${scrollable}
`;

export const ListHeader = styled.div`
  display: flex;
  min-height: 40px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.activeBackground};
`;

export const HeaderCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
`;

export const HeaderLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondaryBackground};
`;

export const ItemWrapper = styled.div`
  display: flex;
  min-height: 40px;
  cursor: pointer;
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.background : theme.colors.secondaryBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBorderColor};
`;

export const ItemCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 56px);
`;

export const ItemLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  max-width: calc(100% - 20px);
  vertical-align: top;
`;
