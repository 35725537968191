import React from 'react';
import branding from '../../branding';

const IconInproggres = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_3135_507)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9 0.75C9.41421 0.75 9.75 1.08579 9.75 1.5V4.5C9.75 4.91421 9.41421 5.25 9 5.25C8.58579 5.25 8.25 4.91421 8.25 4.5V1.5C8.25 1.08579 8.58579 0.75 9 0.75ZM3.16717 3.16717C3.46006 2.87428 3.93494 2.87428 4.22783 3.16717L6.35033 5.28967C6.64322 5.58256 6.64322 6.05744 6.35033 6.35033C6.05744 6.64322 5.58256 6.64322 5.28967 6.35033L3.16717 4.22783C2.87428 3.93494 2.87428 3.46006 3.16717 3.16717ZM14.8328 3.16717C15.1257 3.46006 15.1257 3.93494 14.8328 4.22783L12.7103 6.35033C12.4174 6.64322 11.9426 6.64322 11.6497 6.35033C11.3568 6.05744 11.3568 5.58256 11.6497 5.28967L13.7722 3.16717C14.0651 2.87428 14.5399 2.87428 14.8328 3.16717ZM0.75 9C0.75 8.58579 1.08579 8.25 1.5 8.25H4.5C4.91421 8.25 5.25 8.58579 5.25 9C5.25 9.41421 4.91421 9.75 4.5 9.75H1.5C1.08579 9.75 0.75 9.41421 0.75 9ZM12.75 9C12.75 8.58579 13.0858 8.25 13.5 8.25H16.5C16.9142 8.25 17.25 8.58579 17.25 9C17.25 9.41421 16.9142 9.75 16.5 9.75H13.5C13.0858 9.75 12.75 9.41421 12.75 9ZM6.35033 11.6497C6.64322 11.9426 6.64322 12.4174 6.35033 12.7103L4.22783 14.8328C3.93494 15.1257 3.46006 15.1257 3.16717 14.8328C2.87428 14.5399 2.87428 14.0651 3.16717 13.7722L5.28967 11.6497C5.58256 11.3568 6.05744 11.3568 6.35033 11.6497ZM11.6497 11.6497C11.9426 11.3568 12.4174 11.3568 12.7103 11.6497L14.8328 13.7722C15.1257 14.0651 15.1257 14.5399 14.8328 14.8328C14.5399 15.1257 14.0651 15.1257 13.7722 14.8328L11.6497 12.7103C11.3568 12.4174 11.3568 11.9426 11.6497 11.6497ZM9 12.75C9.41421 12.75 9.75 13.0858 9.75 13.5V16.5C9.75 16.9142 9.41421 17.25 9 17.25C8.58579 17.25 8.25 16.9142 8.25 16.5V13.5C8.25 13.0858 8.58579 12.75 9 12.75Z'
          fill={branding.colors.primary}
        />
      </g>
      <defs>
        <clipPath id='clip0_3135_507'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconInproggres;
