import React, { memo, useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";

import { Table } from "../../../../packages";

import {
  platformSecuritySelectors,
  platformSecurityService,
  standardSelectors,
  standardService,
} from "../../../../entities";

import { NoScanHistory, ScanResultsTableWrapper, ScanResultsWrapper } from "./styled";

import { scanHistoryHeader } from "./consts";
import { formatData } from "./utils";

export const PolicyGroups = memo(({ searchValue }) => {
  const dispatch = useDispatch();
  const { accountId, resource, scanId, ppToolToken, ppFeature } = useParams();

  const { data, isLoading } = useSelector((state) => platformSecuritySelectors.getResourceControls(state, resource));

  const tableData = useMemo(() => formatData(data), [data]);

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        platformSecurityService.getResourceControls({
          scanId,
          accountId,
          ppFeature,
          resourceId: resource,
          ppToolToken,
          search: searchValue,
          ...query,
        })
      );
    },
    [accountId, resource, scanId, searchValue, ppFeature, ppToolToken]
  );

  if (!data.length) return null;

  return (
    <ScanResultsWrapper>
      <ScanResultsTableWrapper>
        <Table
          name="ps-resource-controls"
          isLoading={isLoading}
          onPageChange={onChangeHandler}
          onSort={onChangeHandler}
          rows={tableData}
          headCells={scanHistoryHeader}
          withoutCheck
        />
      </ScanResultsTableWrapper>
      {!isLoading && !data.length && <NoScanHistory>Resource controls is empty</NoScanHistory>}
    </ScanResultsWrapper>
  );
});
