import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import { PageLayout, getAccountTabs } from "../../layouts";

import {
  customerDetailsSelectors,
  platformSecuritySelectors,
  platformSecurityService,
  policyGroupSelectors,
  policyGroupService,
  standardSelectors,
} from "../../entities";

import { InfoCard } from "../../packages";

import { OverviewContent } from "./styled";
import { PoliciesCard } from "./components/policies-card";
import TemperatureGraph from "../../packages/components/temperature-graph";
import { colors } from "../../theme";
import Box from "./components/box";

export const PoliciesPage = () => {
  const dispatch = useDispatch();
  const { id, accountId, resource, control, scanId, ppToolToken, ppFeature } = useParams();
  const { data, isLoading } = useSelector((state) =>
    platformSecuritySelectors.getResourceControlPolicies(state, resource, control)
  );
  // const currentPolicyGroup = useSelector((state) => standardSelectors.getPolicyGroupById(state, policyGroupId));
  const selectedAccount = useSelector((state) => customerDetailsSelectors.getAccountById(state, accountId));
  const currentResource = useSelector((state) => platformSecuritySelectors.getResource(state, resource));

  useEffect(() => {
    dispatch(
      platformSecurityService.getResourceControlPolicies({
        id,
        accountId,
        resourceId: resource,
        controlId: control,
        scanId,
        ppToolToken,
        ppFeature,
      })
    );
  }, [scanId, accountId, resource, control, ppToolToken, ppFeature]);


  return (
    <PageLayout
    // tabs={getAccountTabs(selectedAccount)}
    >
      <Box>Control: {control}</Box>
      <OverviewContent>
        <PoliciesCard />
      </OverviewContent>
    </PageLayout>
  );
};
