import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import branding from '../../../../branding';

export const LinkWrapper = styled.div`
  margin-right: 32px;
  margin-bottom: 32px;

  &:last-child {
    margin-right: 0;
  }
`;

export const LinkWrapperNav = styled(NavLink)`
  margin-right: 32px;
  margin-bottom: 32px;

  &:last-child {
    margin-right: 0;
  }

  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 40px;
  padding: 0;
  background: ${({ background }) => background ?? (branding.isBank ? '' : branding.colors.deepBlue)};
`;

export const ShowMore = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: white;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 16px;

  ${({ shouldTruncate }) =>
    shouldTruncate &&
    `
       -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
      `}
`;

export const Content = styled.div`
  padding: 24px 32px 56px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContactToAdminWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const InfoImage = styled.img`
  display: flex;
  margin-right: 8px;
`;

export const InfoMessage = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #ffffff;
`;
