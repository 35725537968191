import React from 'react';
import { RegistrationLayout } from '../../layouts';

import { WelcomeLabel } from './styled';
import { FormInviteToTeam } from '../forms/invite-to-team-form';
import branding from '../../branding';

export const PageInviteToTeam = () => {
  return (
    <RegistrationLayout
      styleContent={{ backgroundColor: branding.colors.lightBlue, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div
        style={{
          backgroundColor: 'white',
          maxWidth: 700,
          width: '100%',
          minHeight: 500,
          color: '#535353',
          padding: '64px 100px 80px',
        }}
      >
        <WelcomeLabel>Welcome to {branding.name}!</WelcomeLabel>
        <FormInviteToTeam />
      </div>
    </RegistrationLayout>
  );
};
