import React, { memo, useCallback } from 'react';

import { colors } from '../../../theme';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LabelWrapper,
  ServiceAccountCount,
} from './styled';
import { ServiceAccounts } from './ServiceAccounts';

export const AzureAccounts = memo(
  ({
    onAccountSelect,
    azureAccounts,
    azureJenkinsAccounts,
    pipelineAccounts,
    accountsCount,
    expandedService,
    onServiceSelect,
    ...rest
  }) => {
    const onAzureServiceSelectHandler = useCallback(() => {
      onServiceSelect(expandedService?.includes('Azure') ? null : 'Azure');
    }, [expandedService]);

    return (
      <Accordion key="Azure" expanded={expandedService?.includes('Azure')}>
        <AccordionSummary
          isSelected={expandedService?.includes('Azure')}
          sx={{ background: colors.background }}
          onClick={onAzureServiceSelectHandler}
        >
          <LabelWrapper isActive={expandedService?.includes('Azure')}>
            Azure
            <ServiceAccountCount>
              {azureAccounts.length + azureJenkinsAccounts.length + pipelineAccounts.length}
            </ServiceAccountCount>
          </LabelWrapper>
        </AccordionSummary>
        <AccordionDetails onScroll={rest.onScroll}>
          <ServiceAccounts
            key="Azure-accounts"
            title="Accounts"
            serviceName="Azure-accounts"
            parentService="Azure"
            isServiceSelected={expandedService?.includes('Azure-accounts')}
            accounts={azureAccounts}
            count={accountsCount.azureCount ?? 0}
            onAccountSelect={onAccountSelect}
            onServiceSelect={onServiceSelect}
            {...rest}
          />
          <ServiceAccounts
            key="Azure-jenkins-accounts"
            title="Jenkins"
            serviceName="Azure-jenkins-accounts"
            parentService="Azure"
            isServiceSelected={expandedService?.includes('Azure-accounts')}
            accounts={azureJenkinsAccounts}
            count={azureJenkinsAccounts.length ?? 0}
            onAccountSelect={onAccountSelect}
            onServiceSelect={onServiceSelect}
            {...rest}
          />
          <ServiceAccounts
            key="Azure-pipelines"
            title="Pipelines"
            serviceName="Azure-pipelines"
            parentService="Azure"
            isServiceSelected={expandedService?.includes('Azure-pipelines')}
            accounts={pipelineAccounts}
            count={accountsCount.pipelineCount ?? 0}
            onAccountSelect={onAccountSelect}
            onServiceSelect={onServiceSelect}
            {...rest}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
);
