import React, { useCallback, useMemo, useState } from 'react';

import { customerDetailsApi } from '../../../../entities';

import headers from './header';

import { CustomerAccountsWrapper, TableWrapper } from '../../../accounts/components/customer-accounts/styled';
import TableNew from '../../../../packages/components/table-new';
import formatData from './formatData';
import { ModalInline, useModalInline } from '../../../../packages/components/modal/ModalInline';
import useApiData from '../../../../hooks/useApiData';
import DeleteTeamForm from '../delete-team-form';

export const TableTeams = (props) => {
  const { keyMutate, onOpenEdit } = props;
  const [apiQuery, setApiQuery] = useState({});

  const { isOpen, modalProps, onClose, onOpen } = useModalInline();

  const { data, dirty, loading, mutate } = useApiData(
    customerDetailsApi.getTeams,
    [apiQuery],
    {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    keyMutate
  );

  const onChangeHandler = useCallback((query) => {
    setApiQuery(query);
  }, []);

  const preparedData = useMemo(
    () => ({
      ...data,
      results: formatData(data.results, { onDeleteTeam: onOpen, onOpenEdit }),
    }),
    [data, onOpenEdit]
  );

  return (
    <CustomerAccountsWrapper>
      <TableWrapper>
        <TableNew
          key={`${keyMutate}`}
          isLoading={loading}
          onPageChange={onChangeHandler}
          onSort={onChangeHandler}
          count={preparedData.count}
          rows={preparedData.results}
          headCells={headers}
          withoutCheck
        />
      </TableWrapper>
      <ModalInline isOpen={isOpen} header='Delete team' onClose={onClose}>
        <DeleteTeamForm
          onCancel={onClose}
          onSuccess={() => {
            setApiQuery({});
            onClose(true);
            mutate();
          }}
          id={modalProps.id}
          name={modalProps.name}
        />
      </ModalInline>
    </CustomerAccountsWrapper>
  );
};
