import React, { memo, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Wrapper } from '../styled';
import { AccountCreatingMode } from '../account';

import { customerDetailsSelectors } from '../../../../entities';

import { ReadyToScanning } from './ReadyToScanning';

const ReadyToScanningModes = {
  ready: 'ready',
  creating: 'creating',
};

export const ReadyToScanningStep = memo(() => {
  const [readyToScanningModes, setReadyToScanningModes] = useState(
    ReadyToScanningModes.ready
  );
  const [serviceType, setServiceType] = useState();
  const { accounts } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );

  const showCreatingForm = useCallback(
    (service) => {
      setServiceType(service);
      setReadyToScanningModes(ReadyToScanningModes.creating);
    },
    [setServiceType]
  );

  const onBackClick = useCallback(() => {
    setReadyToScanningModes(ReadyToScanningModes.ready);
  }, []);

  useEffect(() => {
    if (accounts.length >= 2) {
      setReadyToScanningModes(ReadyToScanningModes.ready);
    }
  }, [accounts]);

  return (
    <Wrapper>
      {readyToScanningModes === ReadyToScanningModes.ready && (
        <ReadyToScanning onServiceCreate={showCreatingForm} />
      )}
      {readyToScanningModes === ReadyToScanningModes.creating && (
        <AccountCreatingMode
          serviceType={serviceType}
          onBackClick={onBackClick}
        />
      )}
    </Wrapper>
  );
});
