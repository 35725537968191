import React, { memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  EmbeddedTabs,
  getCurrentFeaturePrefix,
  modalActions,
  modalSelectors,
} from '../../../../packages';

import {
  customerDetailsActions,
  customerDetailsSelectors,
} from '../../../../entities';

import { AccountForm } from '../../../../pages/accounts/components/account-form';

import { FeaturePrefix } from '../../../../consts';

import { AccountTabs, accountModalName, editAccountModalTabs } from './consts';
import { TabsWrapper } from './styled';
import { AssignStandardsForm } from './AssignStandardsForm';

export const AddAccountModalContent = memo(() => {
  const dispatch = useDispatch();
  const [activeTabName, setActiveTabName] = useState(AccountTabs.credentials);
  const accountId = useSelector((state) =>
    modalSelectors.getModalData(state, accountModalName)
  );
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );
  const currentFeaturePrefix = getCurrentFeaturePrefix();
  const onTabClick = useCallback(
    (tab) => {
      setActiveTabName(tab);
    },
    [setActiveTabName]
  );

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: accountModalName,
          data: null,
        })
      );
      dispatch(customerDetailsActions.setCurrentService(null));
    },
    []
  );

  return (
    <>
      {!!selectedAccount &&
        currentFeaturePrefix !== FeaturePrefix.PipelineSecurity && (
          <TabsWrapper>
            <EmbeddedTabs
              tabs={editAccountModalTabs}
              activeTabName={activeTabName}
              onClick={onTabClick}
            />
          </TabsWrapper>
        )}
      {activeTabName === AccountTabs.credentials && <AccountForm />}
      {!!selectedAccount && activeTabName === AccountTabs.standards && (
        <AssignStandardsForm accountId={accountId} />
      )}
    </>
  );
});
