import { wretch, wretchppl } from '../app';

const getResources = ({
  accountId,
  standardId,
  policyGroupId,
  policyId,
  scanId = 'current',
  query,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/pols/${policyId}/resources/`
  )
    .query(query)
    .get()
    .json();

const getResourcesPP = ({
  ppToolToken,
  standardId,
  policyGroupId,
  policyId,
  query,
}) =>
  wretchppl(
    `results/${ppToolToken}/regs/${standardId}/grps/${policyGroupId}/pols/${policyId}/resources/`
  )
    .query(query)
    .get()
    .json();

export const policyApi = {
  getResources,
  getResourcesPP,
};
