import React, { memo, useCallback } from 'react';

import { Link } from '@mui/material';

import { useDispatch } from 'react-redux';

import {
  Image,
  ImageBox,
  InfoBox,
  Label,
  WarningLabel,
  WelcomeLabel,
} from '../styled';

import serverSecureUrl from '../../images/server-secure.svg';

import { colors } from '../../../../theme';

import { accountServiceModalName } from '../../../../layouts/onboarding-layouts/modals';
import { modalActions } from '../../../../packages';

import { AccountForm } from './account-form';
import branding from '../../../../branding';

export const AccountCreatingMode = memo(({ serviceType, onBackClick }) => {
  const dispatch = useDispatch();

  const showAccountModal = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalData({
          name: accountServiceModalName,
          data: serviceType,
        })
      );
      dispatch(
        modalActions.setModalIsOpen({
          name: accountServiceModalName,
          isOpen: true,
        })
      );
    },
    [serviceType]
  );

  const serviceName = serviceType === 'azure' ? 'Azure' : 'AWS';

  return (
    <>
      <InfoBox>
        <WelcomeLabel>{`Add ${serviceName} account`}</WelcomeLabel>
        <Label>
          <WarningLabel>Attention!</WarningLabel>
          {`By adding your account credentials, you are not providing any access
          to your account to ${branding.platform}. We keep your data securely encrypted. To
          add your ${serviceName} account, please, give it a name and fill the
          form below. Don’t know where to get credentials to fill the form? `}
          <Link
            sx={{
              color: colors.activeTextColor,
              textDecorationColor: colors.activeTextColor,
              cursor: 'pointer',
            }}
            onClick={showAccountModal}
            underline="always"
          >
            Steps to create secure account.
          </Link>
          {` By saving this data, ${branding.platform} will connect to the account
          to check if credentials are valid. If a connection is successful, you
          will be forwarded to the next step to launch the scan. Otherwise, you
          will get an error message.`}
        </Label>
        <AccountForm serviceType={serviceType} onBackClick={onBackClick} />
      </InfoBox>
      <ImageBox>
        <Image src={serverSecureUrl} />
      </ImageBox>
    </>
  );
});
