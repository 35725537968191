import styled from 'styled-components';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

export const DeleteIcon = styled(TrashCanOutlineIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.warningIcon};
  margin-right: 5px;
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;