import React, { memo } from 'react';

import { IconButton } from '@mui/material';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';

import { EditIcon } from './styled';

export const EditButton = memo(
  ({ onClick, title = 'Edit', color = colors.darkBorderColor, showTitle }) => (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        <EditIcon color={color} />
        {!!showTitle && title}
      </IconButton>
    </Tooltip>
  )
);
