import styled from 'styled-components';

import ArrowRightIcon from 'mdi-react/ArrowRightIcon';

import { scrollable, xsm } from '../../../../theme';

import copy from './icons/copy.png';

export const Wrapper = styled.div`
  padding: 0 30px 6px 30px;
  background: ${({ theme }) => theme.colors.secondaryBackground};

  @media screen and (max-width: ${xsm}) {
    padding: 0 10px 10px 10px;
  }
`;

export const Content = styled.div`
  padding: 16px;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors.borderColor};
`;

export const Label = styled.div`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  font-size: 16px;
  line-height: 24px;
`;

export const Title = styled(Label)`
  font-size: 32px;
  line-height: 48px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    min-width: 100px;
  }
`;

export const StepWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
`;

export const StepHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textColor};
  width: 100%;
`;

export const StepContent = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  flex-direction: column;
`;

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const ListTopic = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const LinkLabel = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin: 16px 5px 0 5px;
  color: ${({ theme }) => theme.colors.activeTextColor};
  text-decoration: underline;
`;

export const PermissionLabel = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.secondaryActiveTextColor};
  word-break: break-word;
`;

export const Comment = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.darkBorderColor};
`;

export const HighlightedText = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 5px;
  color: ${({ theme, color }) => color ?? theme.colors.textColor};
`;

export const AwsButton = styled.span`
  width: fit-content;
  background: ${({ theme }) => theme.colors.secondaryActiveTextColor};
  margin: 0 5px;
  padding: 6px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  white-space: pre;
`;

export const DisabledAwsButton = styled.span`
  width: fit-content;
  background: ${({ theme }) => theme.colors.secondaryBorderColor};
  margin: 0 5px;
  padding: 6px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBorderColor};
  white-space: pre;
`;

export const ArrowRight = styled(ArrowRightIcon)`
  fill: ${({ theme }) => theme.colors.darkBorderColor};
  margin: 0 5px;
`;

export const JSONContent = styled.textarea`
  display: flex;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.secondaryBorderColor};
  color: ${({ theme }) => theme.colors.darkBorderColor};
  border-radius: 4px;
  outline: none;
  resize: none;
  border: none;
  padding: 8px;
  height: 250px;
  ${scrollable};
`;

export const JSONContentWrapper = styled.div`
  position: relative;
`;

export const JSONCopyIcon = styled.img.attrs({
  src: copy,
})`
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 24px;
  cursor: pointer;
`;

export const Image = styled.img`
  margin: 0 5px;
  width: ${({ width }) => width};
`;
