import { createSlice } from '@reduxjs/toolkit';

export const stndartInitialState = {
  isLoading: false,
  isPolicyGroupsLoading: false,
  isAssignStandardsLoading: false,
  standards: [],
  customerStandards: [],
  standardChartData: [],
  policyGroups: [],
  standardStats: {},
  policyGroupsScan: [],
  assignStandards: [],
  presets: [],
};

const STORE_NAME = '@standard';

export const standardReducerNamespace = 'standard';

export const standardStore = createSlice({
  name: STORE_NAME,
  initialState: stndartInitialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsCustomerStandardsLoading: (state, action) => {
      state.isCustomerStandardsLoading = action.payload;
    },
    setStandards: (state, action) => {
      state.standards = action.payload;
    },
    setStandardChartData: (state, action) => {
      state.standardChartData = action.payload;
    },
    setPolicyGroups: (state, action) => {
      state.policyGroups = action.payload;
    },
    setIsPolicyGroupsLoading: (state, action) => {
      state.isPolicyGroupsLoading = action.payload;
    },
    setCustomerStandards: (state, action) => {
      state.customerStandards = action.payload;
    },
    setStandardStats: (state, action) => {
      state.standardStats = action.payload;
    },
    setPresets: (state, action) => {
      state.presets = action.payload;
    },
    setIsAssignStandardsLoading: (state, action) => {
      state.isAssignStandardsLoading = action.payload;
    },
    setAssignStandards: (state, action) => {
      state.assignStandards = action.payload;
    },
    resetState: () => stndartInitialState,
  },
});

export const standardReducer = standardStore.reducer;
export const standardActions = standardStore.actions;
