import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import IconTrash from '../../../../packages/icons/IconTrash';
import { PathNames } from '../../../../consts';
import IconEdit from '../../../../packages/icons/IconEdit';
import branding from '../../../../branding';
import {Button} from '../../../../packages';
import Permission from '../../../../packages/components/permissions';
import React from 'react';

const formatData = (data, { onDeleteTeam, onOpenEdit }) =>
  data.map((item) => {
    const { id, name, members_count, users } = item;

    return {
      name: {
        align: 'left',
        component: () => (
          <NavLink to={PathNames.team.replace(':teamId', id)} style={{ color: branding.colors.primary, textDecoration: 'none' }}>
            {name}
          </NavLink>
        ),
      },
      members_count: { value: members_count, align: 'left' },
      _delete: {
        align: 'right',
        component: () => (
          <div>
            <Permission resource="company::teams">
              {(actions) => ((actions.company['update']?.allowed) ?
                  <IconEdit onClick={() => onOpenEdit({ id, name })} style={{ cursor: 'pointer', marginRight: 16 }} />
                  : null
              )}
            </Permission>

            <Permission resource="company::teams">
              {(actions) => ((actions.company['delete']?.allowed) ?
                  <IconTrash onClick={() => onDeleteTeam({ id, name })} style={{ cursor: 'pointer' }} />
                  : null
              )}
            </Permission>
          </div>
        ),
      },
      data: { id, name, members_count, enable: true },
      id,
    };
  });

export default formatData;
