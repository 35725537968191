import React, { useCallback } from 'react';

import ProfileForm from './components/profile-form';
import { ModalInline, useModalInline } from '../../packages/components/modal/ModalInline';
import ChangePasswordForm from './components/change-password-form';
import { PageLayout } from '../../layouts';
import ProfileContentLayout from '../../layouts/page-layout/ProfileContentLayout';
import { SecondaryButton } from '../../packages';
import { useDispatch } from 'react-redux';
import { authService } from '../../entities';

export const PageProfile = () => {
  const { isOpen, onClose, onOpen } = useModalInline();

  const dispatch = useDispatch();
  const onLogoutHandler = useCallback(() => {
    dispatch(authService.logout());
  }, []);

  return (
    <PageLayout>
      <ProfileContentLayout
        title='Profile'
        headerComponent={
          <SecondaryButton size='medium' type='button' style={{ width: 140 }} onClick={onLogoutHandler}>
            Log Out
          </SecondaryButton>
        }
      >
        <ProfileForm onChangePassword={onOpen} />
        <ModalInline isOpen={isOpen} header='Change password' onClose={onClose}>
          <ChangePasswordForm onCancel={onClose} onSuccess={onClose} />
        </ModalInline>
      </ProfileContentLayout>
    </PageLayout>
  );
};
