import React from 'react';
import { useState } from 'react';

const Accordion = ({ label, children, initOpen = false }) => {
  const [open, setOpen] = useState(initOpen);

  return (
    <div className='panel-accordion'>
      <div className='panel-accordion-label' onClick={() => setOpen(!open)}>
        {label}
      </div>
      <div className={`panel-accordion-content ${open ? 'open' : ''}`}>{children}</div>
    </div>
  );
};

export default Accordion;
