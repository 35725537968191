import { ComplianceStatus } from '../../../../packages';
import { AccountName } from '../account-name';

export const formatScanHistoryData = (scanHistory) =>
  scanHistory.map((scan, index) => {
    const {
      name,
      results_from,
      compliance,
      policies_bad,
      service,
      scan_id,
      policies,
    } = scan;

    const lastScan = new Date(results_from * 1000);

    return {
      indicator: { align: 'center', component: ComplianceStatus },
      service: { value: service, align: 'left' },
      name: { value: name, align: 'left', component: AccountName },
      lastScan: {
        value: `${lastScan.toLocaleDateString()} ${lastScan.toLocaleTimeString()}`,
        align: 'left',
      },
      compliance: {
        value: String(policies ? compliance : '-'),
        align: 'center',
      },
      policiesViolated: {
        value: String(policies ? policies_bad : '-'),
        align: 'center',
      },
      data: { ...scan, compliancePercent: compliance, scanId: scan_id },
      id: index,
    };
  });
