export const usePagination = (name) => {
  const page = Number(sessionStorage.getItem(`${name}-page`) ?? 0);
  const count = Number(sessionStorage.getItem(`${name}-count`));

  return {
    page,
    count,
  };
};

export const setPagination = (name, pagination) => {
  if (pagination) {
    sessionStorage.setItem(`${name}-page`, pagination.page || 0);
    if (pagination.count) {
      sessionStorage.setItem(`${name}-count`, pagination.count);
    }
  }
};
