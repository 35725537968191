import styled from 'styled-components';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';

export const EditIcon = styled(SquareEditOutlineIcon)`
  cursor: pointer;
  margin-right: 5px;
  fill: ${({ theme }) => theme.colors.darkBorderColor};
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;
