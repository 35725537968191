import styled from 'styled-components';
import MenuIcon from 'mdi-react/MenuIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import branding from '../../branding';

import { md, sm, xsm } from '../../theme';

export const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 88px;
  justify-content: space-between;
  padding: 0 48px;
  align-items: center;
`;

export const HeaderWithProfileWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 88px;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondaryBackground};

  [data-logo-wrapper] {
    min-width: 360px;
  }

  [data-profile-wrapper] {
    padding-right: 24px;
  }

  @media screen and (max-width: ${md}) {
    [data-features-tabs] {
      display: none;
    }
  }

  @media screen and (max-width: ${sm}) {
    [data-username] {
      display: none;
    }

    [data-link-home] {
      display: none;
    }

    [data-right-wrapper] {
      justify-content: flex-end;
    }

    [data-profile-wrapper] {
      padding-right: 12px;
    }
  }

  @media screen and (max-width: 1080px) {
    [data-features-tabs] {
      &>* {
        text-wrap: wrap;
      }
    }
  }
`;

export const HeaderWithoutProfileWrapper = styled(HeaderWithProfileWrapper)`
  padding: 0;
  min-height: 88px;
`;

export const HeaderRightWrapper = styled.div`
flex: 1;
justify-content: space-between;
display: flex;
align-items: center;
gap: 24px;
`;

export const HeaderTabsWrapper = styled.div`
display: flex;
align-items: center;
`;

export const MobileHomeButtonWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${md}) {
    margin-right: auto;
  }

  @media screen and (max-width: ${xsm}) {
    display: block;
    margin-right: 0px;
    margin-left: auto;
  }
`;

export const HomeButtonWrapper = styled.div`
  display: flex;
`;

export const HomeButton = styled.div`
  display: flex;
  cursor: pointer;
  margin: auto 20px;
`;

export const AdditionalLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${({ theme }) => branding.isBank ? '' : theme.colors.activeBackground};
  
  @media screen and (max-width: ${md}) {
    display: none;
  }
`;

export const FullWidthLogoWrapper = styled(AdditionalLogoWrapper)`
  width: 100%;

  @media screen and (max-width: ${md}) {
    display: flex;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  height: 100%;
  transition: 0.2s;
  background: ${({ isSidebarOpen, theme }) =>
    branding.isBank ? '' : (isSidebarOpen
      ? theme.colors.activeBackground
      : theme.colors.secondaryBackground)
  };


  @media screen and (max-width: ${md}) {
    display: flex;
  }
`;

export const MenuButton = styled(MenuIcon)`
  margin: 0 16px;
  fill: ${({ isSidebarOpen, theme }) =>
    isSidebarOpen
      ? theme.colors.secondaryBackground
      : theme.colors.activeBackground};
  cursor: pointer;
  display: none;

  @media screen and (max-width: ${md}) {
    display: block;
  }
`;

export const CloseButton = styled(CloseIcon)`
  margin: 0 16px;
  fill: ${({ isSidebarOpen, theme }) =>
    branding.isBank ? theme.colors.activeTextColor : isSidebarOpen
      ? theme.colors.secondaryBackground
      : theme.colors.activeBackground};
  cursor: pointer;
  display: none;

  @media screen and (max-width: ${md}) {
    display: block;
  }
`;

export const MobileLogoTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;
  color: ${({ isSidebarOpen, theme }) =>
    isSidebarOpen
      ? theme.colors.secondaryBackground
      : theme.colors.activeBackground};
  margin-left: 0;
  display: flex;
  align-items: center;
`;

export const AdditionalLogoTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  margin-left: 12px;
`;

export const LogoWrapperSvg = styled.svg`
  fill: none;
  width: 88px;
  height: 88px;
`;

export const AdditionalLogoSvgAirbot = styled.svg`
  fill: none;
  width: 88px;
  height: 56px;
`;

export const AdditionalLogoSvgBank = styled.svg`
  fill: none;
  width: 203px;
  height: 50px;
`;

export const StepStatus = styled.div`
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
