import styled from 'styled-components';

import { icons } from '../icons';
import { md, sm } from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;

  @media screen and (max-width: ${md}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const InfoBox = styled.div`
  display: flex;

  @media screen and (max-width: ${md}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ScanBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  max-width: 250px;
  width: 100%;
`;

export const ServiceLogo = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 12px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PipelineInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media screen and (max-width: ${sm}) {
    margin-top: 10px;
  }
`;

export const PipelineDataLabel = styled.div`
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor};
  margin-bottom: 8px;
  text-align: left;
`;

export const AvailableCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor};
  margin-bottom: 8px;
  text-align: right;
`;

export const AccountName = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 8px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ServiceName = styled.div`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
`;

export const ArrowRight = styled.img.attrs({
  src: icons.arrowIcon,
})`
  margin-right: 16px;
  width: 20px;
  height: 20px;
`;
