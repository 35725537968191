import React, { useCallback, useMemo, useState } from 'react';
import { SelectLazyField } from '../../pages/diagram-result/components/add-component-modal/components-form/styled';
import { useLazyList } from './list';

const useSelectLazyField = (params, props = {}) => {
  const {
    keyUpdate,
    name,
    label,
    request,
    query = {},
    mapFunc = (i) => ({ id: i.id, label: i.name }),
    onChange: onChangeOut = () => {},
    value,
  } = params;
  const [search, setSearch] = useState('');
  const isActiveSearch = search.length >= 3;
  const list = useLazyList(request, [], isActiveSearch ? { search, ...query } : query);
  const options = useMemo(() => list.data?.map(mapFunc), [list.data?.length]);

  const onChange = useCallback(
    (e) => {
      // TODO not work
      onChangeOut(e);
    },
    [onChangeOut]
  );

  return (
    <SelectLazyField
      key={keyUpdate}
      name={name}
      label={label}
      placeholder='Start typing (3 characters minimum)'
      options={options}
      component='field'
      value={typeof value === 'object' ? value : value ? options.find((i) => i.id == value) : undefined}
      onChange={onChange}
      intersectionRef={list.intersectionRef}
      search={search}
      onSearch={setSearch}
      {...props}
    />
  );
};

export default useSelectLazyField;
