import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

import {
  CUSTOMER_STEPS,
  appSelectors,
  customerDetailsActions,
} from '../../../../entities';
import { FeaturePrefix, PathNames } from '../../../../consts';
import {getCurrentFeaturePrefix, history} from '../../../../packages';

import {
  AccountName,
  EmptyCustomerWrapper,
  LinkToCustomerDetails,
  MessageWrapper,
  NotFoundIcon,
} from './styled';

export const EmptyCustomer = memo(() => {
  const dispatch = useDispatch();
  const { selectedCustomerId } = useSelector(appSelectors.getAppData);
  const selectedCustomer = useSelector((state) =>
    appSelectors.getMenuItemById(state, selectedCustomerId)
  );

  const onHereLabelClick = useCallback(() => {
    dispatch(
      customerDetailsActions.setCurrentStep(CUSTOMER_STEPS.customerAccount)
    );
    history.push({
      pathname: PathNames.customerAccounts
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', selectedCustomerId),
    });
  }, [selectedCustomerId]);

  return (
    <EmptyCustomerWrapper>
      <MessageWrapper>
        <NotFoundIcon size={100} />
        {'Here you will be able to review completed scans results for '}
        <AccountName>{selectedCustomer?.name}</AccountName>
        {' accounts. You can add/edit accounts and launch scans '}
        <LinkToCustomerDetails component={NavLink} onClick={onHereLabelClick}>
          here
        </LinkToCustomerDetails>
      </MessageWrapper>
    </EmptyCustomerWrapper>
  );
});
