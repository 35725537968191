import React, { memo, useMemo } from 'react';

import { Card } from '../card';
import { ResultLines } from '../result-lines';

import { FeaturePrefix } from '../../../consts';

import { OverallComplianceWrapper } from './styled';

export const OverallCompliance = memo(
  ({
    awsCompliancePercent,
    azureCompliancePercent,
    azureDevCompliancePercent,
    githubDevCompliancePercent,
    overallCompliancePercent,
    featureName,
    styles = {},
    cardStyles = {},
  }) => {
    const data = useMemo(
      () =>
        featureName === FeaturePrefix.PlatformSecurity
          ? [
              {
                percent: azureCompliancePercent,
                title: `Azure ${
                  azureCompliancePercent === null
                    ? '-'
                    : `${azureCompliancePercent}%`
                }`,
                radius: 70,
              },
              {
                percent: awsCompliancePercent,
                title: `AWS ${
                  awsCompliancePercent === null
                    ? '-'
                    : `${awsCompliancePercent}%`
                }`,
                radius: 100,
              },
            ]
          : [
              {
                percent: azureDevCompliancePercent,
                title: `AzureDev ${
                  azureDevCompliancePercent === null
                    ? '-'
                    : `${azureDevCompliancePercent}%`
                }`,
                radius: 70,
              },
              {
                percent: githubDevCompliancePercent,
                title: `GithubDev ${
                  githubDevCompliancePercent === null
                    ? '-'
                    : `${githubDevCompliancePercent}%`
                }`,
                radius: 100,
              },
            ],
      [
        awsCompliancePercent,
        azureCompliancePercent,
        githubDevCompliancePercent,
        azureDevCompliancePercent,
        featureName,
      ]
    );

    return (
      <Card
        title="Overall Compliance"
        helpMessage="Overall compliance % of all the customer's accounts and compliance % per each service (if exists) - Azure, AWS and GitHub accounts."
        style={cardStyles}
      >
        <OverallComplianceWrapper style={styles}>
          <ResultLines data={data} overallPercent={overallCompliancePercent} />
        </OverallComplianceWrapper>
      </Card>
    );
  }
);
