import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from '@mui/material';

import {
  Button,
  Form,
  SecondaryButton,
  modalActions,
  modalSelectors,
} from '../../../../packages';

import {
  customerDetailsActions,
  customerDetailsSelectors,
  customerDetailsService,
  customerSelectors,
} from '../../../../entities';

import { accountModalName } from '../../consts';

import { colors } from '../../../../theme';

import { accountServiceModalName } from '../../../../layouts/onboarding-layouts/modals';

import {
  AccountFormWrapper,
  ButtonsWrapper,
  InputField,
  SelectField,
  ServiceInfo,
} from './styled';
import {
  defaultValidationSchema,
  generateAccountValidationSchema,
} from './validate';
import { AuthButton } from './AuthButton';
import { generateAccountInitModel } from './utils';

export const AccountForm = memo(() => {
  const dispatch = useDispatch();
  const { isAccountCreating, currentService } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const fields = useSelector(customerSelectors.getAccountFormFields);

  const data = useSelector((state) =>
    modalSelectors.getModalData(state, accountModalName)
  );
  const serviceOptions = useSelector(customerSelectors.getServiceOptions);
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, data)
  );

  const { service, id, enable } = selectedAccount ?? {};

  const initialValues = currentService
    ? generateAccountInitModel(fields[currentService], selectedAccount)
    : {};

  const validationSchema = currentService
    ? generateAccountValidationSchema(fields[currentService])
    : defaultValidationSchema;

  const onSubmit = useCallback(
    (values) => {
      dispatch(customerDetailsService.createOrUpdateAccount(values, id));
    },
    [dispatch, id]
  );

  const onDisableAccount = useCallback(() => {
    dispatch(customerDetailsService.disableAccount(id));
    dispatch(
      modalActions.setModalIsOpen({
        name: accountModalName,
        isOpen: false,
      })
    );
  }, [id, dispatch]);

  const onChangeHandler = useCallback(
    (fieldName, value) => {
      if (fieldName === 'service') {
        dispatch(customerDetailsActions.setCurrentService(value));
      }
    },
    [id, dispatch]
  );

  const onEnableAccount = useCallback(() => {
    dispatch(customerDetailsService.enableAccount(id));
    dispatch(
      modalActions.setModalIsOpen({
        name: accountModalName,
        isOpen: false,
      })
    );
  }, [id, dispatch]);

  useEffect(() => {
    if (service) {
      dispatch(customerDetailsActions.setCurrentService(service));
    }
  }, [service]);

  const showAccountModal = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalData({
          name: accountServiceModalName,
          data: currentService,
        })
      );
      dispatch(
        modalActions.setModalIsOpen({
          name: accountServiceModalName,
          isOpen: true,
        })
      );
    },
    [currentService]
  );

  return (
    <AccountFormWrapper>
      {currentService && (
        <ServiceInfo>
          <Link
            sx={{
              color: colors.activeTextColor,
              textDecorationColor: colors.activeTextColor,
              cursor: 'pointer',
            }}
            onClick={showAccountModal}
            underline="always"
          >
            Step by step guide to create a secure account
          </Link>
        </ServiceInfo>
      )}
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        isLoading={isAccountCreating}
        onChange={onChangeHandler}
      >
        <SelectField
          name="service"
          label="Service"
          options={serviceOptions}
          styles={{ marginBottom: '12px' }}
        />
        <InputField name="name" label="Account’s name" />
        {currentService
          ? fields[currentService].map((field) => {
              if (field.name === 'code') {
                return (
                  <AuthButton
                    name={field.name}
                    label={field.label}
                    url={field.url}
                  />
                );
              }

              return (
                <InputField
                  name={field.name}
                  label={field.label}
                  type={field.masked && 'password'}
                  autoComplete="new-password"
                />
              );
            })
          : null}
        <ButtonsWrapper component="button">
          {!!id && (
            <SecondaryButton
              variant="outlined"
              size="medium"
              color={enable ? 'error' : 'warning'}
              component="button"
              onClick={enable ? onDisableAccount : onEnableAccount}
            >
              {enable ? 'Disable' : 'Enable'}
            </SecondaryButton>
          )}
          <Button
            variant="contained"
            size="medium"
            type="submit"
            fullWidth={!id}
          >
            {selectedAccount ? 'Save' : 'Create'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </AccountFormWrapper>
  );
});
