import React from 'react';

import DropdownMenu from '../packages/components/dropdown-menu';
import Link from './sidebar-profile/components/Link';
import {PathNames} from '../consts';
import Permission from '../packages/components/permissions';

export const UserMenu = (props) => {
  const {
    children,
    onOpenAddToTeam,
    onOpenDelete,
    onOpenActivate,
    onOpenBlock,
    onOpenResend,
    hideResend = false,
    hideActivate = false,
    hideBlock = false,
  } = props;

  return (
    <Permission resource="company::users">
      {(actions) => (
        <DropdownMenu
          data={[
            {
              label: 'Add to teams',
              onClick: onOpenAddToTeam,
              hidden: (
                !actions.company['manage-teams']?.allowed
              ),
            },
            {
              label: 'Re-send invite',
              onClick: onOpenResend,
              hidden: (
                hideResend || !actions.company['invite']?.allowed
              ),
            },
            {
              label: 'Activate user',
              onClick: onOpenActivate,
              hidden: (
                hideActivate || !(actions.company['block']?.allowed || actions.team['block']?.allowed)
              ),
            },
            {
              label: 'Block user',
              onClick: onOpenBlock,
              hidden: (
                hideBlock || !(actions.company['block']?.allowed || actions.team['block']?.allowed)
              ),
            },
            // { label: 'Delete user', onClick: onOpenDelete, disabled: true },
          ]}
        >
          {children}
        </DropdownMenu>
      )}
    </Permission>
  );
};
