import { wretch, wretchppl } from '../app';

const getPresets = () => wretch('customers/presets/').get().json();

const getAssignStandards = ({ id, accountId }) =>
  wretch(`customers/${id}/accounts/${accountId}/active_regs/`).get().json();

const saveAssignStandards = ({ id, accountId, requestModel }) =>
  wretch(`customers/${id}/accounts/${accountId}/active_regs/`)
    .post(requestModel)
    .json();

const getStandards = ({ accountId, scanId = 'current', query }) =>
  wretch(`results/account/${accountId}/${scanId}/regs/?sort=name`)
    .query(query)
    .get()
    .json();

const getStandardsPP = ({ ppToolToken, query }) =>
  wretchppl(`results/${ppToolToken}/regs/`).query(query).get().json();

const getStandardById = ({ accountId, scanId = 'current', standardId }) =>
  wretch(`results/account/${accountId}/${scanId}/regs/${standardId}/`)
    .get()
    .json();

const getPolicyGroups = ({
  accountId,
  scanId = 'current',
  standardId,
  query,
}) =>
  wretch(`results/account/${accountId}/${scanId}/regs/${standardId}/grps/`)
    .query(query)
    .get()
    .json();

const getPolicyGroupsPP = ({ ppToolToken, standardId, query }) =>
  wretchppl(`results/${ppToolToken}/regs/${standardId}/grps/`)
    .query(query)
    .get()
    .json();

const getPolicyGroupById = ({
  accountId,
  scanId = 'current',
  standardId,
  policyGroupId,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/`
  )
    .get()
    .json();

const getCustomerStandards = ({ customerId, query }) =>
  wretch(`results/customer/${customerId}/`).query(query).get().json();

const getCustomerStandardsPipeline = ({ customerId, query }) =>
  wretch(`results/customer/${customerId}/pipeline/`).query(query).get().json();

const getStandardChartModel = ({
  accountId,
  scanId = 'current',
  standardId,
  query,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/chart/`
  )
    .query(query)
    .get()
    .json();

const getStandardChartModelPP = ({ standardId, ppToolToken, query }) =>
  wretchppl(`results/${ppToolToken}/regs/${standardId}/grps/chart/`)
    .query(query)
    .get()
    .json();

const setActive = ({ accountId, scanId = 'current', standardId }) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/set_active/`
  )
    .post()
    .res();

const setInactive = ({ accountId, scanId = 'current', standardId }) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/set_inactive/`
  )
    .post()
    .res();

const getStandardStats = ({ accountId, scanId = 'current', standardId }) =>
  wretch(`results/account/${accountId}/${scanId}/regs/${standardId}/stat/`)
    .get()
    .json();

const getStandardStatsPP = ({ ppToolToken, standardId }) =>
  wretchppl(`results/${ppToolToken}/regs/${standardId}/stat/`).get().json();

const joinAccountByPatPP = ({ ppToolToken, org, requestBody }) =>
  wretchppl(`results/${ppToolToken}/oauth-account/${org}/`).post(requestBody);

export const standardApi = {
  setActive,
  getPresets,
  setInactive,
  getStandards,
  getStandardsPP,
  joinAccountByPatPP,
  getStandardById,
  getPolicyGroups,
  getPolicyGroupsPP,
  getStandardStats,
  getStandardStatsPP,
  getAssignStandards,
  getPolicyGroupById,
  saveAssignStandards,
  getCustomerStandards,
  getStandardChartModel,
  getStandardChartModelPP,
  getCustomerStandardsPipeline,
};
