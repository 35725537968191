import React, { useCallback, useMemo, useState } from 'react';
import { Shapes } from '../library/basic';
import InputText from '../controls/InputText';
import InputNumber from '../controls/InputNumber';
import Select from '../controls/Select';
import Checkbox from '../controls/Checkbox';
import Color from '../controls/Color';
import { LABEL_DEFAULT_FONT_SIZE, LabelVerticalAligns, VerticalAlignValues } from './node-details/constants';
import {
  prepareFloat,
  prepareFontSize,
  prepareInt,
  prepareLabelVerticalAlign,
  prepareSelectValue,
  prepareShape,
} from './node-details/utils';
import Accordion from './Accordion';
import ControlBorders from '../controls/ControlBorders';
import { RowWrapper } from '../../editor-modal/styled';

/*
{
    "width": 150,
    "height": 37,
    "id": "3",
    "type": "output",
    "data": {
        "label": "Output Node"
    },
    "position": {
        "x": 250,
        "y": 250
    },
    "style": {
        "backgroundColor": "#6865A5",
        "color": "white"
    },
    "selected": true,
    "dragging": false,
    "positionAbsolute": {
        "x": 250,
        "y": 250
    }
}
*/

const FormNodeDetails = (props) => {
  const { id, type, width, height, data = {}, onChange: onChangeOut, controls } = props;
  const { label, labelStyle, shape, isUnder } = data;

  const borderRadius = useMemo(
    () => `${data.style?.borderRadius || ''}`.replaceAll(/[^\d]/g, ''),
    [data.style?.borderRadius]
  );
  const [borderRadiusUnit, setBorderRadiusUnit] = useState(
    `${data.style?.borderRadius}`.indexOf('%') !== -1 ? '%' : 'px'
  );

  const labelOffset = useMemo(() => {
    if (!labelStyle?.transform) {
      return {};
    }

    const cont = {};
    const matches = `${labelStyle?.transform}`.matchAll(new RegExp('translate(X|Y)\\((-?\\d+)px\\)', 'g'));

    for (const match of matches) {
      const [, name, offset] = match;
      cont[name] = parseInt(offset, 10);
    }

    return cont;
  }, [labelStyle?.transform]);

  const onFieldChange = useCallback(
    (field, value) => {
      const changes = {
        id: `${id}`,
        data: {},
      };

      if (field === 'label') {
        changes.data = { label: value };
      } else if (field === 'labelNoWrap') {
        changes.data.labelStyle = { textWrap: value ? 'nowrap' : undefined };
      } else if (field === 'labelPadding') {
        changes.data.labelStyle = { padding: value };
      } else if (field === 'labelColor') {
        changes.data.labelStyle = { color: value };
      } else if (field === 'labelFontSize') {
        changes.data.labelStyle = value;
      } else if (field.startsWith('labelOffset')) {
        const name = field.replace('labelOffset', '');

        const offset = { ...labelOffset };
        offset[name] = value;

        Object.keys(offset).forEach((n) => {
          if (typeof offset[n] === 'undefined') {
            delete offset[name];
          }
        });

        const transform = Object.keys(offset)
          .map((n) => `translate${n}(${offset[n]}px)`)
          .join(' ');

        changes.data.labelStyle = { transform };
      } else if (field === 'labelVerticalAlign') {
        changes.data.labelStyle = value;
      } else if (field === 'shape') {
        changes.data.shape = value.shape;
        changes.data.style = value.style;
      } else if (field === 'isUnder') {
        changes.data.isUnder = value;
      } else {
        changes.data.style = { [field]: value };
      }

      onChangeOut(changes);
    },
    [id, onChangeOut, labelOffset]
  );
  console.log('labelOffset', labelOffset);
  return (
    <div className='panel-node-details'>
      <Accordion label='Label' initOpen>
        <InputText
          label='Text'
          placeholder='label'
          onChange={(e) => onFieldChange('label', e.target.value)}
          value={label}
          custom={controls?.text}
        />
        <Checkbox
          label='Not wrap'
          value={labelStyle?.textWrap === 'nowrap'}
          onChange={(e) => onFieldChange('labelNoWrap', e.target.checked)}
          custom={controls?.checkbox}
        />
        <Color
          label='Color'
          value={labelStyle?.color || '#0000ff'}
          onChange={(e) => onFieldChange('labelColor', e.target.value)}
          custom={controls?.color}
        />
        <InputNumber
          label='Font size'
          placeholder={`${LABEL_DEFAULT_FONT_SIZE}`}
          value={labelStyle?.fontSize || LABEL_DEFAULT_FONT_SIZE}
          min={5}
          max={40}
          step={1}
          onChange={(e) => onFieldChange('labelFontSize', prepareFontSize(e, labelStyle?.alignSelf))}
          custom={controls?.number}
        />
        <Select
          label='Vertical align'
          value={
            labelStyle?.alignSelf === VerticalAlignValues.FLEX_START
              ? LabelVerticalAligns.TOP
              : labelStyle?.alignSelf === VerticalAlignValues.FLEX_END
              ? LabelVerticalAligns.BOTTOM
              : LabelVerticalAligns.CENTER
          }
          options={[
            { value: LabelVerticalAligns.TOP, label: 'Top' },
            { value: LabelVerticalAligns.CENTER, label: 'Center' },
            { value: LabelVerticalAligns.BOTTOM, label: 'Bottom' },
          ]}
          onChange={(e) => onFieldChange('labelVerticalAlign', prepareLabelVerticalAlign(e, labelStyle?.fontSize))}
          custom={controls?.select}
        />
        <InputNumber
          label='Offset X, px'
          placeholder='0px'
          value={labelOffset.X || 0}
          step={10}
          onChange={(e) => onFieldChange('labelOffsetX', prepareInt(e.target.value))}
          custom={controls?.number}
        />
        <InputNumber
          label='Offset Y, px'
          placeholder='0px'
          value={labelOffset.Y || 0}
          step={10}
          onChange={(e) => onFieldChange('labelOffsetY', prepareInt(e.target.value))}
          custom={controls?.number}
        />
        {/* <InputNumber
          label='Margin'
          min={0}
          step={1}
          placeholder='0'
          value={labelStyle?.padding || '0'}
          onChange={(e) => onFieldChange('labelPadding', prepareInt(e.target.value))}
          custom={controls?.number}
        /> */}
      </Accordion>
      <Accordion label='Container'>
        <Checkbox
          label='Lower than others'
          value={isUnder}
          onChange={(e) => onFieldChange('isUnder', e.target.checked)}
          custom={controls?.checkbox}
        />
        <Select
          label='Shape'
          value={shape}
          options={[
            { value: undefined, label: '-' },
            { value: Shapes.RECT, label: 'Rect' },
            { value: Shapes.ELLIPSE, label: 'Ellipse' },
            { value: Shapes.CYLINDER, label: 'Cylinder' },
            { value: Shapes.PENTAGON, label: 'Pentagon' },
            { value: Shapes.HEXAGON, label: 'Hexagon' },
            { value: Shapes.TRIANGLE, label: 'Triangle' },
          ]}
          onChange={(e) => onFieldChange('shape', prepareShape(e))}
          custom={controls?.select}
        />
        <InputNumber
          label='Width'
          min={0}
          step={10}
          placeholder={width}
          value={data.style?.width}
          onChange={(e) => onFieldChange('width', prepareInt(e.target.value))}
          custom={controls?.number}
        />
        <InputNumber
          label='Height'
          min={0}
          step={10}
          placeholder={height}
          value={data.style?.height}
          onChange={(e) => onFieldChange('height', prepareInt(e.target.value))}
          custom={controls?.number}
        />
        <Color
          label='Background color'
          value={data.style?.backgroundColor || '#ffffff'}
          onChange={(e) => onFieldChange('backgroundColor', e.target.value)}
          custom={controls?.color}
        />
      </Accordion>
      <Accordion label='Border'>
        <InputNumber
          label='Width'
          min={0}
          step={1}
          placeholder='2'
          value={typeof data.style?.borderWidth !== 'undefined' ? data.style?.borderWidth : '2'}
          onChange={(e) => onFieldChange('borderWidth', prepareFloat(e.target.value))}
          custom={controls?.number}
        />
        <RowWrapper>
          <InputNumber
            label='Radius'
            placeholder='0'
            value={borderRadius || '0'}
            onChange={(e) => onFieldChange('borderRadius', prepareInt(e.target.value) + borderRadiusUnit)}
            custom={controls?.number}
          />
          <Select
            label='Unit'
            value={borderRadiusUnit}
            options={[
              { value: 'px', label: 'px' },
              { value: '%', label: '%' },
            ]}
            onChange={(e) => {
              const val = prepareSelectValue(e);
              setBorderRadiusUnit(val);
              onFieldChange('borderRadius', borderRadius + val);
            }}
            custom={controls?.select}
          />
        </RowWrapper>
        <Select
          label='Style'
          value={data.style?.borderStyle || 'solid'}
          options={[
            { value: 'dashed', label: 'Dashed' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'double', label: 'Double' },
            { value: 'groove', label: 'Groove' },
            { value: 'outset', label: 'Outset' },
            { value: 'ridge', label: 'Ridge' },
            { value: 'solid', label: 'Solid' },
          ]}
          onChange={(e) => {
            const val = prepareSelectValue(e);
            onFieldChange('borderStyle', val !== 'solid' ? val : undefined);
          }}
          custom={controls?.select}
        />
        <Color
          label='Color'
          value={data.style?.borderColor || '#000'}
          onChange={(e) => onFieldChange('borderColor', e.target.value)}
          custom={controls?.color}
        />
        <ControlBorders label='Borders' value={data.style || {}} onChange={onFieldChange} />
      </Accordion>
    </div>
  );
};

export default FormNodeDetails;
