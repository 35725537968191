import { createSelector } from "reselect";

import { platformSecurityinitialState, platformSecurityReducerNamespace } from "./store";

const getResourcesData = (state) => state[platformSecurityReducerNamespace] || platformSecurityinitialState;

const getResourceControls = (state, resourceId) => {
  const data = getResourcesData(state).resourceControls[resourceId] || [];
  const isLoading = getResourcesData(state).resourceControlsIsLoading;
  return {
    data,
    isLoading,
  };
};

const getResource = (state, resourceId) => {
  const data = getResourcesData(state).resource[resourceId] || null;
  return data;
};

const getResourceControlPolicies = (state, resourceId, controlId) => {
  const data = getResourcesData(state).resourceControlPolicies[`${resourceId}-${controlId}`] || [];
  const isLoading = getResourcesData(state).resourceControlPoliciesIsLoading;
  return {
    data,
    isLoading,
  };
};

export const platformSecuritySelectors = {
  getResourcesData,
  getResource,
  getResourceControls,
  getResourceControlPolicies,
};
