import { createSlice } from "@reduxjs/toolkit";

export const platformSecurityinitialState = {
  isLoading: false,
  resources: [],
  resource: {},
  resourcesStats: {
    policies_compliant: 0,
    policies_total: 0,
    policies_violated: 0,
  },
  resourceControlsIsLoading: false,
  resourceControls: {},
  resourceControlPoliciesIsLoading: false,
  resourceControlPolicies: {},
};

const STORE_NAME = "@platformSecurity";

export const platformSecurityReducerNamespace = "platformSecurity";

export const platformSecurityStore = createSlice({
  name: STORE_NAME,
  initialState: platformSecurityinitialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setResources: (state, action) => {
      state.resources = action.payload;
    },
    setResource: (state, action) => {
      const { resourceId, data } = action.payload;
      state.resource[resourceId] = data;
    },
    setResourcesStats: (state, action) => {
      state.resourcesStats = action.payload;
    },
    setResourceControlsIsLoading: (state, action) => {
      state.resourceControlsIsLoading = action.payload;
    },
    setResourceControls: (state, action) => {
      const { resourceId, data } = action.payload;
      state.resourceControls[resourceId] = data;
    },
    setResourceControlPoliciesIsLoading: (state, action) => {
      state.resourceControlPoliciesIsLoading = action.payload;
    },
    setResourceControlPolicies: (state, action) => {
      const { resourceId, controlId, data } = action.payload;
      state.resourceControlPolicies[`${resourceId}-${controlId}`] = data;
    },
    resetState: () => platformSecurityinitialState,
  },
});

export const platformSecurityReducer = platformSecurityStore.reducer;
export const platformSecurityActions = platformSecurityStore.actions;
