import styled from 'styled-components';

import { lg, md, scrollable, xsm } from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  background: ${({ theme, background }) =>
    background || theme.colors.secondaryBackground};

  ${scrollable}
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 80px 120px;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${lg}) {
    padding: 80px;
  }

  @media screen and (max-width: ${xsm}) {
    padding: 40px;
  }

  @media screen and (max-width: ${md}) {
    justify-content: center;
  }
`;
