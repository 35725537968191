import { useParams } from "react-router-dom";
import { PathNames } from "../../../../consts";
import { ComplianceStatus, getCurrentFeaturePrefix } from "../../../../packages";
import { PolicyGroupName } from "../policy-group-name";
import React from "react";

export const formatData = (data) => {
  const { id: customerId, scanId, accountId, resource, ppToolToken, ppFeature } = useParams();

  return data.map((scan, index) => {
    const {
      id,
      name,
      descr,
      is_custom,
      active,
      last_scan,
      policies_compliant_percent,
      policies_total,
      policies_compliant,
      policies_violated,
    } = scan;
    // /:feature/:scanId/:id/account/:accountId/resources/:resource/
    const to =
      PathNames.psResource
        .replace(":feature", getCurrentFeaturePrefix())
        .replace(":id", customerId)
        .replace(":scanId", scanId ?? "current")
        .replace(":accountId", accountId)
        .replace(":resource", resource) + `${id}/`;

    return {
      indicator: { align: "center", component: ComplianceStatus },
      name: {
        value: name,
        align: "left",
        component: () => <PolicyGroupName to={to} is_custom={is_custom} value={name} />,
      },
      policies_compliant_percent: { value: String(policies_compliant_percent.toFixed(2) + " %" ?? "-"), align: "center" },
      policies_total: { value: String(policies_total ?? "-"), align: "center" },
      policies_violated: { value: String(policies_violated ?? "-"), align: "center" },
      last_scan: {
        value: last_scan
          ? `${new Date(last_scan * 1000).toLocaleDateString()} ${new Date(last_scan * 1000).toLocaleTimeString()}`
          : "-",
        align: "center",
      },
      data: { ...scan, compliancePercent: policies_compliant_percent },
      id: index,
    };
  });
};
