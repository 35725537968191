import React, { useCallback, useState } from 'react';

import { Button, SecondaryButton } from '../../../../packages';
import { ButtonsWrapper, ProfileFormWrapper } from './styled';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../../packages/utils/parse';

const DeleteTeamForm = (props) => {
  const { id, name, onCancel = () => {}, onSuccess = () => {} } = props;

  const [loading, setLoading] = useState(false);

  const onDelete = useCallback(() => {
    setLoading(true);

    customerDetailsApi
      .deleteTeam(id)
      .then(() => {
        NotificationManager.success(`Team "${name}" has been deleted.`);
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        // TODO Empty response
        if (err) {
          const error = parseError(err);
          if (error?.detail) {
            NotificationManager.error(error?.detail);
            return;
          }
        }

        NotificationManager.error(`Server error`);
        onCancel();
      });
  }, [id, name, onSuccess, onCancel]);

  return (
    <ProfileFormWrapper>
      <div style={{ marginBottom: 24, fontSize: 14, fontFamily: 'Open Sans' }}>
        Do you really want to delete <span style={{ fontWeight: 900, color: '#000000' }}>{name}</span> team?
      </div>
      <ButtonsWrapper>
        <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
          {'Cancel'}
        </SecondaryButton>
        <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }} onClick={onDelete}>
          {loading ? 'Deleting...' : 'Yes'}
        </Button>
      </ButtonsWrapper>
    </ProfileFormWrapper>
  );
};

export default DeleteTeamForm;
