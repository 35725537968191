import React, { useCallback, useMemo, useState } from 'react';

import { customerDetailsApi, threatModelingService } from '../../../entities';
import { Button, TextField } from '../../../packages';

import { List, listFilter, Scrollable } from './modalAssignedUtils';
import useDataFullList from '../../../hooks/useDataFullList';
import {useDispatch} from 'react-redux';

const ModalAssignedTeam = (props) => {
  const { componentId, team = null, onSuccess } = props;
  const [ selectedTeam, setSelectedTeam ] = useState(team?.id);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const onSearch = useCallback((e) => setSearch(e.target.value), []);

  const teams = useDataTeams();

  const listTeams = useMemo(
    () =>
      teams
        .map((i) => ({
          value: i.id,
          title: i.name,
        }))
        .filter((i) => listFilter(i.title, search)),
    [teams, search]
  );

  const onSelect = useCallback((value) => {
    if (value === selectedTeam) {
      setSelectedTeam(null);
    } else {
      setSelectedTeam(value);
    }
  }, []);

  const updateAssigned = useCallback(async () => {
    if (!selectedTeam) {
      setSelectedTeam(null);
    }

    setLoading(true);

    await dispatch(
      threatModelingService.addOrUpdateComponents({
        id: componentId,
        requestModel: {
          team_id: selectedTeam,
        },
      })
    );

    onSuccess();
  }, [componentId, selectedTeam, onSuccess]);

  return (
    <div style={{ width: 416, margin: '0 26px' }}>
      <TextField
        label="Teams"
        placeholder='Start typing'
        style={{ width: '100%', marginTop: 5 }}
        autoFocus
        onChange={onSearch}
      />
      <div style={{ position: 'relative', height: 290, margin: '16px 0' }}>
        <Scrollable key="teams">
          <List data={listTeams} active={selectedTeam} onSelect={(val) => onSelect(val)} />
        </Scrollable>
      </div>
      <div>
        <Button
          variant='contained'
          size='medium'
          type='submit'
          style={{ width: '100%', height: 40 }}
          onClick={updateAssigned}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default ModalAssignedTeam;

const useDataTeams = () => {
  const { data } = useDataFullList(customerDetailsApi.getTeams);
  return data;
};
