import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Button, SecondaryButton } from '../../../../packages';

import { ButtonsWrapper, ProfileFormWrapper } from './styled';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';

const DeleteUserForm = (props) => {
  const { userId, onCancel = () => {}, onSuccess = () => {} } = props;
  const dispatch = useDispatch();

  const onDelete = useCallback(
    (values) => {
      // customerDetailsApi
      //   .deleteUser(userId)
      //   .then(() => {
      //     NotificationManager.success('Invite sent successfully.');
      //     onSuccess(true);
      //   })
      //   .catch(() => {
      //     NotificationManager.error('Server error.');
      //     onCancel();
      //   });
    },
    [dispatch, onSuccess]
  );

  return (
    <ProfileFormWrapper>
      <div style={{ marginBottom: 24, fontSize: 14, fontFamily: 'Open Sans' }}>
        Do you really want to delete <span style={{ fontWeight: 900, color: '#000000' }}>“User name”</span> ?
      </div>
      <ButtonsWrapper>
        <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
          {'Cancel'}
        </SecondaryButton>
        <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }} onClick={onDelete}>
          {'Yes'}
        </Button>
      </ButtonsWrapper>
    </ProfileFormWrapper>
  );
};

export default DeleteUserForm;
