import styled from 'styled-components';

import { xsm } from '../../../theme';

export const StatWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 32px;
  color: ${({ theme }) => theme.colors.textColor};
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 8px;

  @media screen and (max-width: ${xsm}) {
    flex-wrap: wrap;
  }
`;

export const StatCell = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.mediumBorderColor};
  padding: 0 10px;
  text-align: center;

  &:last-child {
    border-right: none;
  }

  @media screen and (max-width: ${xsm}) {
    padding-top: 20px;
    padding-bottom: 20px;
    border-right: none;

    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

export const CellCounter = styled.div`
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  color: ${({ color, theme }) => color ?? theme.colors.secondaryTextColor}};
`;

export const CellLabel = styled.div`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor}};
`;
