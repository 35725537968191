import React, { memo, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { policySelectors } from '../../../../entities';
import { StatTable } from '../../../../packages';

export const PolicyStat = memo(() => {
  const { currentPolicy } = useSelector(policySelectors.getPolicyData);
  const stats = useMemo(() => {
    if (!currentPolicy) return {};

    const passedCount = currentPolicy.records - currentPolicy.records_bad;
    const compliancePercent = Math.round(
      (passedCount * 100) / currentPolicy.records
    );

    return {
      compliant: compliancePercent,
      passed: passedCount,
      scanned: currentPolicy.records,
      failed: currentPolicy.records_bad,
    };
  }, [currentPolicy]);

  return <StatTable {...stats} />;
});
