import React, { memo, useEffect, useRef, useState } from 'react';

import { Button, ThirdButton } from '../../../../packages';
import infoImage from '../images/info.svg';

import {
  ContactToAdminWrapper,
  Content,
  Description,
  Image,
  InfoImage,
  InfoMessage,
  LinkWrapper,
  LinkWrapperNav,
  Name,
  ShowMore,
  Wrapper,
} from './styled';

export const FeatureCard = memo(
  ({
    image,
    name,
    description,
    disabled,
    requested,
    background,
    isComingSoon,
    to,
    onClick,
    toggleExpand,
    isExpand,
  }) => {
    const ref = useRef(null);
    const [shouldTruncate, setShouldTruncate] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);
    const LinkWrapperComponent =
      disabled || isComingSoon ? LinkWrapper : LinkWrapperNav;

    useEffect(() => {
      setIsShowMore(ref.current?.clientHeight > 154);

      if (!isExpand && ref.current?.clientHeight > 154) {
        setShouldTruncate(true);

        return;
      }

      setShouldTruncate(false);
    }, [description]);

    return (
      <LinkWrapperComponent to={to} onClick={onClick || (() => {})}>
        <Wrapper background={background}>
          <Image src={image} />
          <Content>
            <Name>{name}</Name>
            <Description ref={ref} shouldTruncate={!isExpand && shouldTruncate}>
              {description}
            </Description>
            {isShowMore && (
              <ShowMore onClick={toggleExpand}>
                {isExpand ? ' Show less' : 'Show more'}
              </ShowMore>
            )}
            {disabled && !isComingSoon && (
              <ContactToAdminWrapper>
                <InfoImage src={infoImage} />
                <InfoMessage>
                  {requested
                    ? 'Feature request has been sent to administrator'
                    : 'To enable please contact to administrator'}
                </InfoMessage>
              </ContactToAdminWrapper>
            )}
            {isComingSoon ? (
              <ThirdButton
                variant="outlined"
                size="medium"
                sx={{ width: '100%', display: 'block', marginTop: 'auto' }}
                component="button"
                onClick={() => {}}
                disabled
              >
                Coming soon
              </ThirdButton>
            ) : !disabled ? (
              <Button
                onClick={() => {}}
                sx={{ display: 'block', marginTop: 'auto', width: '100%' }}
              >
                Go
              </Button>
            ) : null}
          </Content>
        </Wrapper>
      </LinkWrapperComponent>
    );
  }
);
