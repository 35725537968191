import React from "react";

const IconHexagon = (props) => {
  const { backgroundColor } = props;

  return (
    <svg
      width="116"
      height="109"
      viewBox="0 0 116 109"
      strokeWidth="1"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path d="M58 0L115.063 41.459L93.2671 108.541H22.7329L0.936607 41.459L58 0Z" fill={backgroundColor} />
    </svg>
  );
};

export default IconHexagon;
