import { createSlice } from '@reduxjs/toolkit';

export const policyInitialState = {
  isLoading: false,
  isResourcesLoading: false,
  isResourcesScanLoading: false,
  selectedPolicyId: null,
  currentPolicy: null,
  policyTrends: [],
  resources: [],
  resourcesScan: [],
  accounts: [],
};

const STORE_NAME = '@policy';

export const policyReducerNamespace = 'policy';

export const policyStore = createSlice({
  name: STORE_NAME,
  initialState: policyInitialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedPolicyId: (state, action) => {
      state.selectedPolicyId = action.payload;
    },
    setCurrentPolicy: (state, action) => {
      state.currentPolicy = action.payload;
    },
    setResources: (state, action) => {
      state.resources = action.payload;
    },
    setResourcesScan: (state, action) => {
      state.resourcesScan = action.payload;
    },
    setIsResourcesScanLoading: (state, action) => {
      state.isResourcesScanLoading = action.payload;
    },
    setIsResourcesLoading: (state, action) => {
      state.isResourcesLoading = action.payload;
    },
    setPolicyTrends: (state, action) => {
      state.policyTrends = action.payload;
    },
    resetState: () => policyInitialState,
  },
});

export const policyReducer = policyStore.reducer;
export const policyActions = policyStore.actions;
