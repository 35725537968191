import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { SecondaryTextField } from '../../../packages';

export const ConfirmEmailWrapper = styled.div`
  margin-top: 40px;
`;

export const ResendCode = styled.div`
  text-decoration: underline;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.greyText : theme.colors.activeTextColor};
`;

export const InputField = muiStyled(SecondaryTextField)(() => ({
  marginBottom: 10,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
