import { ComplianceStatus } from '../../../../packages';
import { PolicyName } from '../policy-name';

export const formatPolicesData = (polices) =>
  polices.map((scan, index) => {
    const { name, last_scan, compliance, severity, records, records_bad } =
      scan;

    return {
      indicator: { align: 'center', component: ComplianceStatus },
      name: { value: name, align: 'left', component: PolicyName },
      policySeverity: {
        value: severity === 'unset' ? 'Not defined' : severity,
        align: 'center',
      },
      resourcesScanned: { value: String(records ?? '-'), align: 'center' },
      resourcesViolated: { value: String(records_bad ?? '-'), align: 'center' },
      lastScan: {
        value: last_scan
          ? `${new Date(last_scan * 1000).toLocaleDateString()} ${new Date(
              last_scan * 1000
            ).toLocaleTimeString()}`
          : '-',
        align: 'left',
      },
      data: { ...scan, compliancePercent: compliance ? 100 : 0 },
      id: index,
    };
  });
