import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Form } from '../../packages';

import { authSelectors, authService } from '../../entities';

import {
  AuthFormWrapper,
  AuthPageWrapper,
  LoginField,
  PasswordField,
} from './styled';
import { authValidationSchema } from './validate';
import { AddPipelineModal } from './add-pipeline-modal';

export const AuthPage = memo(() => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(authSelectors.getAuthData);
  const onSubmit = useCallback(
    (values) => {
      dispatch(authService.signIn(values, false));
    },
    [dispatch]
  );

  const query = new URLSearchParams(window.location.search);
  const email = query.get('email');

  const initialValues = {
    username: decodeURIComponent(email || ''),
    password: '',
  };

  const onAad = () => {
    location.href = '/aribot-api/v1/auth/oauth_redirect/';
  };

  useEffect(() => {
    dispatch(authService.getRegistrationData());
  }, []);

  return (
    <AuthPageWrapper>
      <AuthFormWrapper>
        <Form
          validationSchema={authValidationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          isLoading={isLoading}
        >
          <LoginField name="username" label="Username" />
          <PasswordField name="password" label="Password" type="password" />
          <Button variant="contained" size="medium" type="submit">
            Sign in
          </Button>
        </Form>
      </AuthFormWrapper>
      <AuthFormWrapper>
        <Button onClick={onAad} variant="contained" size="medium" type="button">
          Login through Active Directory
        </Button>
      </AuthFormWrapper>
      <AddPipelineModal />
    </AuthPageWrapper>
  );
});
