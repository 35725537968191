import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FileDrop } from 'react-file-drop';

import Select from 'react-select';

import { Button, Table } from '../../packages';

import { threatModelingActions, threatModelingSelectors, threatModelingService } from '../../entities';

import { PageLayout } from '../../layouts';
import useTimeout from '../../packages/utils/timeout';
import useInterval from '../../packages/utils/interval';
import { usePagination } from '../../packages/utils';

import { ButtonsWrapper, DiagramsCard, DiagramsHeader, DiagramsPageWrapper, FileInput, NoDiagrams } from './styled';
import { DeleteDiagramModal, DeleteSelectedDiagramsButton, ImportDiagramButton, CreateDiagramButton } from './components';
import { formatDiagramsData, getDiagramsHeaders, getDiagramsHeadersPlus } from './utils';
import Header from './components/header';
import Permission, {getActions} from '../../packages/components/permissions';
import { useModalInline } from '../../packages/components/modal/ModalInline';
import { EditorModal } from '../../packages/components/editor-modal';

export const TabDiagram = () => {
  const dispatch = useDispatch();
  const { diagrams, companies, isLoading, companyValue } = useSelector(threatModelingSelectors.getThreatModelingData);
  const fileInputRef = useRef(null);
  const diagramsActions = getActions("threat-modeling::diagrams");

  const [keyUpdate, setKeyUpdate] = useState(0);

  const onTargetClick = useCallback(() => {
    if (diagramsActions.company?.["create"]?.allowed) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);


  const startDiagramParse = useCallback(
    (value) => {
      dispatch(threatModelingService.startDiagramParse({ id: value }));
      dispatch(
        threatModelingService.getDiagrams(companyValue && companyValue.value ? { company: companyValue.value } : {})
      );
    },
    [companyValue]
  );

  const needRecall = useMemo(() => {
    let ret = false;
    if (diagrams && diagrams.length) {
      diagrams.map((obj) => {
        if (obj.to_delete !== null && obj.to_delete !== undefined ){
          ret = true;
        }
      });
    }    
    return ret;
  }, [diagrams]);

  const getDiagramsForRecall = useCallback(() => {
    if (needRecall) {
      const pagination = usePagination('diagrams');
      dispatch(
        threatModelingService.getDiagrams({...pagination})
      );
    }
  }, [needRecall])

  useInterval(getDiagramsForRecall, 15000);
  
  const tableData = useMemo(() => formatDiagramsData(diagrams, diagramsActions, startDiagramParse), [diagrams, startDiagramParse]);

  const onChangeHandler = useCallback(
    (_query) => {
      const query = { ..._query };
      if (companyValue && companyValue.value) {
        query.company = companyValue.value;
      }
      dispatch(
        threatModelingService.getDiagrams({
          ...query,
        })
      );
    },
    [companyValue]
  );

  const onFileDrop = useCallback(
    (files) => {
      const file = files[0];

      if (!file) {
        console.warn('File was not selected');

        return;
      }
      const data = { file };
      if (companyValue && companyValue.value) {
        data.company = companyValue.value;
      }

      dispatch(threatModelingService.uploadDiagram(data));
    },
    [companyValue]
  );

  const onFileInputChange = useCallback(
    (event) => {
      const files = event.target?.files;

      if (files) {
        onFileDrop(files);
      }
    },
    [onFileDrop]
  );

  const onCompanySelect = useCallback((value) => {
    dispatch(threatModelingActions.setCompanyValue(value));
    dispatch(
      threatModelingService.getDiagrams(
        value && value.value ? { company: value.value, ordering: '-created_at' } : { ordering: '-created_at' }
      )
    );
  }, []);

  const onDiagramSelect = useCallback((value) => {
    dispatch(threatModelingActions.setSelectedDiagrams(value));
  }, []);

  useEffect(() => {
    dispatch(threatModelingService.initDiagrams({ company: companyValue?.value }));
  }, [companyValue, keyUpdate]);

  const {
    isOpen: isOpenEditor,
    onClose: onCloseEditor,
    onOpen: onOpenEditor,
    keyUpdate: keyMutateEditor,
  } = useModalInline();

  return (
    <PageLayout withoutSidebar>
      <DiagramsPageWrapper>
        <Header rightComponent={
        <DiagramsHeader>
          {companies && companies.length > 1 ? (
            <Select
              styles={{
                control: (styles) => ({ ...styles, minWidth: '200px' }),
              }}
              options={companies}
              defaultValue={companyValue}
              value={companyValue}
              onChange={onCompanySelect}
              components={{ IndicatorSeparator: null }}
            />
          ) : null}
          <div style={{ width: 22 }} />
          <ButtonsWrapper>
            <Permission resource="threat-modeling::diagrams">
              {(actions) => ((actions.company?.["delete"]?.allowed) ?
                  <DeleteSelectedDiagramsButton />
                  : null
              )}
            </Permission>
            <Permission resource="threat-modeling::diagrams">
              {(actions) => ((actions.company?.["create"]?.allowed) ?
                  <ImportDiagramButton onClickHandler={onTargetClick} />
                  : null
              )}
            </Permission>
            <Permission resource="threat-modeling::diagrams">
              {(actions) => ((actions.company?.["create"]?.allowed) ?
                  <CreateDiagramButton onClickHandler={() => onOpenEditor()} />
                  : null
              )}
            </Permission>
          </ButtonsWrapper>
        </DiagramsHeader>
        } />
        <FileInput onChange={onFileInputChange} ref={fileInputRef} />
        <FileDrop onDrop={onFileDrop} onTargetClick={tableData.length ? () => {} : onTargetClick}>
          <DiagramsCard>
            {tableData.length ? (
              <Table
                isLoading={isLoading}
                name="diagrams"
                defaultOrder="desc"
                defaultOrderingName="created_at"
                defaultOrderingValue="-created_at"
                rows={tableData}
                rowsPerPage={10}
                onPageChange={onChangeHandler}
                onSort={onChangeHandler}
                headCells={
                  companies && companies.length > 1 ?
                    getDiagramsHeadersPlus(diagramsActions)
                    : getDiagramsHeaders(diagramsActions)
                }
                onSelect={onDiagramSelect}
              />
            ) : (
              <NoDiagrams>
                <Permission resource="threat-modeling::diagrams">
                  {(actions) => ((actions.company?.["create"]?.allowed) ?
                      "Drops file here or click to upload"
                      : "No diagrams available"
                  )}
                </Permission>
              </NoDiagrams>
            )}
          </DiagramsCard>
        </FileDrop>
      </DiagramsPageWrapper>
      <DeleteDiagramModal />
      <EditorModal
        isOpen={isOpenEditor}
        onClose={onCloseEditor}
        onUpdate={() => setKeyUpdate(Date.now())}
        props={{}}
      />
    </PageLayout>
  );
};
