import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { PipelineOnboardingLayout } from '../../layouts';

import { OnboardingSteps, appSelectors } from '../../entities';

import {
  AssignStandardStep,
  ConfirmEmailStep,
  RegisterStep,
  SelectRegistrationTypeStep,
} from './steps';

export const PipelineOnboardingPage = memo(() => {
  const { onboardingStep } = useSelector(appSelectors.getAppData);

  return (
    <PipelineOnboardingLayout>
      {onboardingStep === OnboardingSteps.selectRegistrationType && (
        <SelectRegistrationTypeStep />
      )}
      {onboardingStep === OnboardingSteps.register && <RegisterStep />}
      {onboardingStep === OnboardingSteps.confirmEmail && <ConfirmEmailStep />}
      {onboardingStep === OnboardingSteps.assignStandards && (
        <AssignStandardStep />
      )}
    </PipelineOnboardingLayout>
  );
});
