import React from 'react';

const Checkbox = ({ label, value, onChange, custom: Custom }) => {
  if (Custom) {
    return <Custom label={label} value={value} onChange={onChange} />;
  }

  return (
    <div>
      <div>{label}</div>
      <input type='checkbox' checked={value} onChange={onChange} />
    </div>
  );
};

export default Checkbox;
