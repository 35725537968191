import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { SecondaryButton, modalActions } from '../../../../packages';
import { customerModalName } from '../../consts';

export const AddCustomerButton = memo(() => {
  const dispatch = useDispatch();

  const onModalOpen = useCallback(() => {
    dispatch(
      modalActions.setModalIsOpen({
        name: customerModalName,
        isOpen: true,
      })
    );
  }, [dispatch]);

  return (
    <SecondaryButton onClick={onModalOpen} sx={{ marginLeft: '16px' }}>
      Create new customer
    </SecondaryButton>
  );
});
