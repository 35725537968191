import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { ProgressIcon, ScanButton, modalActions } from '../../../../packages';
import { ScanStatuses } from '../../../../consts';
import { customerService } from '../../../../entities';
import { customerStartScanModal } from '../start-scan-modal/consts';

export const ScanCustomerButton = memo(({ id, disabled, status, ac_count }) => {
  const dispatch = useDispatch();

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();

      if (ac_count > 1) {
        dispatch(
          modalActions.setModalIsOpen({
            name: customerStartScanModal,
            isOpen: true,
          })
        );
        dispatch(
          modalActions.setModalData({
            name: customerStartScanModal,
            data: id,
          })
        );
      } else {
        dispatch(customerService.scanSelectedCustomers([id]));
      }
    },
    [id]
  );

  if (status === ScanStatuses.inprogress) {
    return <ProgressIcon disabled={disabled} />;
  }

  return (
    <ScanButton
      onClick={onClickHandler}
      title="Scan customer"
      message={!ac_count ? 'Doesn\'t have accounts' : null}
      disabled={disabled}
    />
  );
});
