import * as yup from 'yup';

const textRequired = 'This field is required.';

const defaultSchema = {
  code: yup.string().required(textRequired),
  name: yup.string().required(textRequired),
  component_type: yup.object({ id: yup.number(), label: yup.string() }).nullable().required(textRequired),
  control_codes_mappings: yup
    .array()
    .of(yup.object({ id: yup.number(), label: yup.string() }))
    .required(textRequired),
};

export const defaultValidationSchema = yup.object(defaultSchema);
