import styled from 'styled-components';
import ArrowCollapseIcon from 'mdi-react/ArrowCollapseIcon';
import ArrowExpandIcon from 'mdi-react/ArrowExpandIcon';

export const MaximizeAllWrapper = styled.button`
  min-width: 165px;
  height: 40px;
  border: 2px solid #000000;
  border-radius: 8px;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 24px;
`;

export const CollapseIcon = styled(ArrowCollapseIcon)`
  width: 20px;
  height: 20px;
  fill: #000000;
  cursor: pointer;
  margin-right: 16px;
`;

export const ExpandIcon = styled(ArrowExpandIcon)`
  width: 20px;
  height: 20px;
  fill: #000000;
  cursor: pointer;
  margin-right: 16px;
`;
