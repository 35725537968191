import { wretch } from "../app";

const getResources = (accountId, scanId, query = {}) =>
  wretch(`results/account/${accountId}/${scanId}/resources/`).query(query).get().json();

const getResource = (accountId, scanId, resourceId) =>
  wretch(`results/account/${accountId}/${scanId}/resources/${resourceId}/`).get().json();

const getResourcesStats = (accountId, scanId) =>
  wretch(`results/account/${accountId}/${scanId}/resources/stat/`).get().json();

const getResourceControls = (accountId, scanId, resourceId, query = {}) =>
  wretch(`results/account/${accountId}/${scanId}/resources/${resourceId}/grps/`).query(query).get().json();

const getResourceControlPolicies = (accountId, scanId, resourceId, controlId, query = {}) =>
  wretch(`results/account/${accountId}/${scanId}/resources/${resourceId}/grps/${controlId}/pols/`)
    .query(query)
    .get()
    .json();

export const platformSecurityApi = {
  getResources,
  getResource,
  getResourcesStats,
  getResourceControls,
  getResourceControlPolicies,
};
