import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Form, SecondaryButton } from '../../../../packages';

import { ButtonsWrapper, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { Checkmarks } from '../../../../packages/controls/checkmarks';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';
import useApiData from '../../../../hooks/useApiData';
import { parseError } from '../../../../packages/utils/parse';

const InviteUserForm = (props) => {
  const { onCancel = () => {}, onSuccess = (update = false) => {} } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      email: '',
      teams: [],
    }),
    []
  );

  const validationSchema = defaultValidationSchema;

  const { data, dirty, mutate } = useApiData(customerDetailsApi.getTeams, [], {
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const onSubmit = useCallback(
    (values, helpers) => {
      const { email, teams } = values;

      setLoading(true);

      customerDetailsApi
        .teamInviteUsers({ emails: [email], team_ids: teams })
        .then(() => {
          NotificationManager.success('Invite sent successfully.');
          onSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          const dataErr = parseError(err);

          if (dataErr?.email?.[0]) {
            helpers.setFieldError('email', dataErr?.email?.[0]);
          } else if (typeof dataErr?.[0] === 'string') {
            const text = dataErr?.[0].replaceAll(/(\{|\})/g, '');
            helpers.setFieldError('email', text);
          } else {
            NotificationManager.error('Server error.');
          }
        });
    },
    [dispatch, onSuccess, onCancel]
  );

  const options = useMemo(() => data.results.map((i) => ({ id: i.id, label: i.name })), [data]);

  return (
    <ProfileFormWrapper>
      <Form validationSchema={validationSchema} initialValues={initialValues} isLoading={loading} onSubmit={onSubmit}>
        <InputField name='email' label='User email' />
        <Checkmarks name='teams' label='Add to Teams' options={options} multiple />
        <ButtonsWrapper>
          <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
            {'Cancel'}
          </SecondaryButton>
          <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
            {loading ? 'Sending...' : 'Send invite'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </ProfileFormWrapper>
  );
};

export default InviteUserForm;
