import styled from 'styled-components';

export const CustomerAccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;

export const NoAccounts = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
`;
