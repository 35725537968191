import React from 'react';
import branding from '../../branding';

const IconCopy = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.3335 2C3.15668 2 2.98712 2.07024 2.86209 2.19526C2.73707 2.32028 2.66683 2.48985 2.66683 2.66666V8.66666C2.66683 8.84347 2.73707 9.01304 2.86209 9.13807C2.98712 9.26309 3.15669 9.33333 3.3335 9.33333H4.00016C4.36835 9.33333 4.66683 9.63181 4.66683 10C4.66683 10.3682 4.36835 10.6667 4.00016 10.6667H3.3335C2.80306 10.6667 2.29435 10.4559 1.91928 10.0809C1.54421 9.7058 1.3335 9.1971 1.3335 8.66666V2.66666C1.3335 2.13623 1.54421 1.62752 1.91928 1.25245C2.29436 0.877378 2.80306 0.666664 3.3335 0.666664H9.3335C9.86393 0.666664 10.3726 0.877378 10.7477 1.25245C11.1228 1.62752 11.3335 2.13623 11.3335 2.66666V3.33333C11.3335 3.70152 11.035 4 10.6668 4C10.2986 4 10.0002 3.70152 10.0002 3.33333V2.66666C10.0002 2.48985 9.92992 2.32028 9.8049 2.19526C9.67988 2.07024 9.51031 2 9.3335 2H3.3335ZM8.00016 6.66666C7.63197 6.66666 7.3335 6.96514 7.3335 7.33333V13.3333C7.3335 13.7015 7.63197 14 8.00016 14H14.0002C14.3684 14 14.6668 13.7015 14.6668 13.3333V7.33333C14.6668 6.96514 14.3684 6.66666 14.0002 6.66666H8.00016ZM6.00016 7.33333C6.00016 6.22876 6.89559 5.33333 8.00016 5.33333H14.0002C15.1047 5.33333 16.0002 6.22876 16.0002 7.33333V13.3333C16.0002 14.4379 15.1047 15.3333 14.0002 15.3333H8.00016C6.89559 15.3333 6.00016 14.4379 6.00016 13.3333V7.33333Z'
        fill={branding.colors.primary}
      />
    </svg>
  );
};

export default IconCopy;
