import * as yup from 'yup';

const defaultSchema = {
  old_password: yup.string('Enter your password').required('Password is required'),
  new_password: yup
    .string('Enter your new password')
    .min(6, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirm_new_password: yup
    .string('Enter confirm new password')
    .oneOf([yup.ref('new_password')], 'Confirm passwords are not matching')
    .required('Password is required'),
};

export const defaultValidationSchema = yup.object(defaultSchema);
