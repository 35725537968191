import React, { useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";

import { CircularProgress, Link } from "@mui/material";
import { NavLink } from "react-router-dom";

import { PathNames } from "../../consts";

import { PageLayout, getAccountTabs, getPlSecAccountTabs } from "../../layouts";
import { LoaderWrapper } from "../../app";
import { PolicyViolations } from "../../packages";

import { customerDetailsSelectors, standardSelectors, standardService } from "../../entities";

import { OverviewContent, PolicyViolationsWrapper } from "./styled";
import { PolicyGroupTrend, PolicyGroupsList } from "../standard-overview/components";
import { ResourcesList } from "./components/resources-list";
import { platformSecurityService } from "../../entities/platform-security/service";
import { platformSecuritySelectors } from "../../entities/platform-security/selectors";
import TemperatureGraph from "../../packages/components/temperature-graph";
import { colors } from "../../theme";

export const ResourcesPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, accountId, scanId, ppToolToken, ppFeature } = params;
  const { isLoading, resources, resourcesStats } = useSelector(platformSecuritySelectors.getResourcesData);
  // const violationStats = useSelector(standardSelectors.getViolatedPolicesStats);
  const selectedAccount = useSelector((state) => customerDetailsSelectors.getAccountById(state, accountId));

  useEffect(() => {
    dispatch(
      platformSecurityService.getResources({
        id,
        accountId,
        // standardId,
        scanId,
        ppToolToken,
        ppFeature,
      })
    );
    dispatch(platformSecurityService.getResourcesStats(scanId, accountId));
  }, [accountId, scanId, ppToolToken, ppFeature]);

  const total = resourcesStats.policies_total;
  const lines = useMemo(
    () => [
      {
        count: resourcesStats.policies_compliant,
        stat: `${resourcesStats.policies_compliant} are compliant`,
        percent: (resourcesStats.policies_compliant / total) * 100,
        color: colors.success,
        title: "compliant",
      },
      {
        count: resourcesStats.policies_violated,
        stat: `${resourcesStats.policies_violated} are non-compliant`,
        percent: (resourcesStats.policies_violated / total) * 100,
        color: colors.danger,
        title: "non-compliant",
      },
    ],
    [total, resourcesStats.policies_compliant, resourcesStats.policies_violated]
  );

  return (
    <PageLayout tabs={getPlSecAccountTabs(selectedAccount)}>
      <OverviewContent>
        <TemperatureGraph
          title={"Compliance"}
          total={total}
          lines={lines}
          helpMessage={
            <ul>
              <li>Number of non-compliant policies of customer account.</li>
              <li>All policies are compliant.</li>
              <li>Any non-compliant.</li>
            </ul>
          }
          style={{ marginBottom: "1rem" }}
        />
        <PolicyGroupTrend />
        <ResourcesList />
      </OverviewContent>
    </PageLayout>
  );
};
