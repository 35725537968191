import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { BackButton, Button, Form } from '../../../../../packages';

import {
  customerDetailsActions,
  customerDetailsSelectors,
  customerDetailsService,
  customerSelectors,
} from '../../../../../entities';

import { ContinueButtonWrapper } from '../../styled';

import { AccountFormWrapper, InputField } from './styled';
import {
  defaultValidationSchema,
  generateAccountValidationSchema,
} from './validate';
import { AuthButton } from './AuthButton';
import { generateAccountInitModel } from './utils';

export const AccountForm = memo(({ serviceType, onBackClick }) => {
  const dispatch = useDispatch();
  const { isAccountCreating } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const fields = useSelector(customerSelectors.getAccountFormFields);
  const serviceOptions = useSelector(customerSelectors.getServiceOptions);
  const currentOption = useMemo(
    () => serviceOptions.find((service) => service.value === serviceType),
    [serviceType]
  );

  const initialValues = serviceType
    ? generateAccountInitModel(fields[serviceType], {
        service: currentOption.label,
      })
    : {};

  const validationSchema = serviceType
    ? generateAccountValidationSchema(fields[serviceType])
    : defaultValidationSchema;

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        customerDetailsService.createOrUpdateAccount({
          ...values,
          service: currentOption.value,
        })
      );
    },
    [dispatch, currentOption]
  );

  const onChangeHandler = useCallback(
    (fieldName, value) => {
      if (fieldName === 'service') {
        dispatch(customerDetailsActions.setCurrentService(value));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (serviceType) {
      dispatch(customerDetailsActions.setCurrentService(serviceType));
    }
  }, [serviceType]);

  return (
    <AccountFormWrapper>
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        isLoading={isAccountCreating}
        onChange={onChangeHandler}
      >
        <InputField name="service" label="Service" disabled />
        <InputField name="name" label="Account’s name" />
        {serviceType
          ? fields[serviceType].map((field) => {
              if (field.name === 'code') {
                return (
                  <AuthButton
                    name={field.name}
                    label={field.label}
                    url={field.url}
                  />
                );
              }

              return (
                <InputField
                  name={field.name}
                  label={field.label}
                  type={field.masked && 'password'}
                  autoComplete="new-password"
                />
              );
            })
          : null}
        <ContinueButtonWrapper component="button">
          <BackButton onClick={onBackClick} component="button">
            Back
          </BackButton>
          <Button variant="contained" type="submit">
            Continue
          </Button>
        </ContinueButtonWrapper>
      </Form>
    </AccountFormWrapper>
  );
});
