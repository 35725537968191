export const generateAccountInitModel = (fields, model) => {
  const initFields = [{ name: 'service' }, { name: 'name' }, ...fields];

  return initFields.reduce((schema, field) => {
    const fieldValue = (model && model[field.name]) || '';
    const credentialsValue =
      (model?.credentials && model?.credentials[field.name]) || '';

    return {
      ...schema,
      [field.name]: fieldValue || credentialsValue,
    };
  }, {});
};
