import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { ButtonGroup, ProgressManager, SecondaryButton, StatTable, Table, modalActions } from '../../../packages';
import TableNew from '../../../packages/components/table-new';
import {
  threatModelingActions,
  threatModelingApi,
  threatModelingSelectors,
  threatModelingService,
} from '../../../entities';

import { PageLayout } from '../../../layouts';

import { PathNames } from '../../../consts';

import { diagramsHeaders, reportHeaders } from '../consts';

import {
  BackButton,
  BackButtonLink,
  ButtonsWrapper,
  CRUDButtonsWrapper,
  ComponentsCard,
  ContentWrapper,
  DiagramResultHeader,
  DiagramResultPageWrapper,
  DiagramViewCard,
  NoComponents,
  ReportCard,
  ReportCardHeaderWrapper,
  ReportHeader,
  SideWrapper,
  StatWrapper,
  Title,
  ToolbarWrapper,
  TruncateTitle,
} from '../styled';
import {
  AddComponentButton,
  AddComponentModal,
  DeleteComponentsModal,
  DeleteSelectedComponentsButton,
  DiagramView,
  MaximizeAllButton,
  ThreatAnalysisButton,
  componentsModalName,
} from '../components';
import { formatComponentsData, formatReportData } from '../utils';
import { ButtonRelatedAccounts } from '../components/button-related-accounts';
import DiagramResultContext from './DiagramResultContext';
import TableReport from './table-reports';
import useApiData from '../../../hooks/useApiData';
import Permission, { getActions } from '../../../packages/components/permissions';
import { ButtonEditDiagram } from '../components/button-edit-diagram';
import { EditorModal } from '../../../packages/components/editor-modal';
import { ModalInline, useModalInline } from '../../../packages/components/modal/ModalInline';
import { ComponentsFormInline } from '../components/add-component-modal/components-form/ComponentsFormInline';
import useDataFullList from '../../../hooks/useDataFullList';

import { parseError } from '../../../packages/utils/parse';
import { NotificationManager } from 'react-notifications';
import { createNodeByTemplate } from '../../../packages/components/diagram-editor';

function downloadCompleted(file) {
  ProgressManager.success(file, 'The file is ready. Click to download', 1000_000);
}

const REPEAT_REQUEST = 10_000;
function getReport(diagramId, reportConfigId) {
  threatModelingApi.exportReport(diagramId, reportConfigId).then((data) => {
    if (data?.status === 'completed') {
      downloadCompleted(data.file);
    } else if (['preparing', 'in-progress'].includes(data?.status)) {
      setTimeout(() => getReport(diagramId, reportConfigId), REPEAT_REQUEST);
    }
  });
}

export const DiagramResult = () => {
  const dispatch = useDispatch();
  const { diagramId, ppToolToken } = useParams();
  const [isReportExpand, setIsReportExpand] = useState(false);

  const { currentDiagram, isLoading, components, reportConfigs } = useSelector(
    threatModelingSelectors.getThreatModelingData
  );

  const componentActions = getActions('threat-modeling::diagrams::components');
  const componentResourcesActions = getActions('threat-modeling::diagrams::components::resources');

  const [keyUpdate, setKeyUpdate] = useState(0);
  const [keyUpdatePreview, setKeyUpdatePreview] = useState(0);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const isManual = currentDiagram?.is_manual;
  const [manual, setManual] = useState();
  const [loadingDiagram, setLoadingDiagram] = useState(false);

  useEffect(() => {
    if (isManual && currentDiagram) {
      setLoadingDiagram(true);
      threatModelingApi
        .getManualDiagram(currentDiagram?.id)
        .then((data) => {
          setManual(data);
          setKeyUpdatePreview(Date.now());
          setLoadingComponent(false);
          setLoadingDiagram(false);
        })
        .catch(() => setLoadingDiagram(false));
    }
  }, [currentDiagram?.id, isManual, keyUpdate, setLoadingDiagram]);

  const {
    isOpen: isOpenComponent,
    onOpen: onOpenComponent,
    onClose: onCloseComponent,
    modalProps: modalPropsComponent,
  } = useModalInline();

  const getManualDiagramComponents = useCallback(
    ({ page }) => threatModelingApi.getManualDiagramComponents(diagramId, page).catch(() => {}),
    [diagramId]
  );
  const {
    data: existingComponents,
    onUpdate,
    loading: loadingComponents,
  } = useDataFullList(getManualDiagramComponents, !diagramId || !isManual);

  useEffect(() => {
    if (!isManual) {
      return;
    }

    if (loadingComponents && !existingComponents.length && manual && !manual.manual_data) {
      ProgressManager.progress('Diagram preview processing');
    }
    if (!loadingComponents && existingComponents.length && manual && !manual.manual_data) {
      const nodes = [];
      const components = [];
      const startId = Date.now();
      const size = Math.ceil(Math.sqrt(existingComponents.length));

      const iconSize = 100;
      let rowIdx = 0;
      let idx = 0;
      while (existingComponents[idx]) {
        for (let colIdx = 0; colIdx < size; colIdx++) {
          if (idx >= existingComponents.length) {
            break;
          }
          const x = iconSize * colIdx;
          const y = iconSize * rowIdx;

          const { id, label, type, type_verbose, generic_type, generic_type_verbose, manual_data } =
            existingComponents[idx];
          const img = manual_data?.__lib__?.icon;
          const node_id = `id_${startId + idx}`;
          const node = createNodeByTemplate({
            id: node_id,
            x,
            y,
            label,
            img,
            type,
            type_verbose,
            generic_type,
            generic_type_verbose,
          });
          nodes.push(node);
          components.push({
            id,
            manual_data: {
              id: node_id,
            },
          });
          idx++;
        }

        rowIdx++;
        if (rowIdx > size) {
          rowIdx = 0;
        }
      }

      threatModelingApi
        .updateManualDiagram(diagramId, { manual_data: { nodes }, components })
        .then(() => {
          setKeyUpdate(Date.now());
          onUpdate();
          ProgressManager.ready('Diagram preview is ready!');
        })
        .catch((err) => {
          console.log('Error: updateManualDiagram()', err);
          const error = parseError(err);
          NotificationManager.error(error?.message || error?.detail || 'Server error');
        });
    }
  }, [isManual, loadingComponents, existingComponents, manual]);

  const onElementSettings = useCallback(
    (node) => {
      const exist = existingComponents.find((i) => i.manual_data?.id === node.id);
      onOpenComponent({ ...(exist || node.data?._meta || {}), label: node.data?.label, node_id: node.id });
    },
    [existingComponents, onOpenComponent]
  );

  const onSubmitComponent = useCallback(
    async (data) => {
      const { node_id, ...component } = data;
      const { label, type, generic_type, resource } = component;

      setLoadingComponent(true);
      const manual = await threatModelingApi.getManualDiagram(diagramId).catch((err) => {
        console.log('threatModelingApi.getManualDiagram', diagramId, err);
      });

      if (!manual) {
        console.error('Network error');
        setLoadingComponent(false);
        return;
      }

      manual.manual_data.nodes = manual.manual_data.nodes.map((i) => {
        if (i.id === node_id) {
          i.data.label = data.label;
          i.data._meta = { ...(i.data._meta || {}), type, generic_type, resource };
        }
        return i;
      });

      component.manual_data = { id: node_id };

      const result = await threatModelingApi
        .updateManualDiagram(diagramId, { manual_data: manual.manual_data, components: [component] })
        .catch((err) => {
          console.log('onSubmitComponentErrrrorrr22222', err);
        });

      if (result) {
        onCloseComponent();
        setKeyUpdate(Date.now());
        onUpdate();
      }
      setLoadingComponent(false);
    },
    [diagramId, onCloseComponent, setKeyUpdate, onUpdate]
  );

  let hideMenu = false;
  if (
    !(
      componentActions.company?.['update']?.allowed ||
      componentActions.company?.['manage-teams']?.allowed ||
      componentResourcesActions.company?.['view']?.allowed ||
      componentActions.company?.['delete']?.allowed
    )
  ) {
    hideMenu = true;
  }

  const onEditModalOpen = useCallback((id) => {
    dispatch(
      modalActions.setModalData({
        name: componentsModalName,
        data: id,
      })
    );
    dispatch(
      modalActions.setModalIsOpen({
        name: componentsModalName,
        isOpen: true,
      })
    );
  }, [dispatch]);

  const tableData = useMemo(
    () =>
      formatComponentsData(components ?? [], diagramId, hideMenu, () => {
        setKeyUpdate(Date.now())   ;
        onUpdate();
      }, onEditModalOpen),
    [components, setKeyUpdate, onUpdate, onEditModalOpen]
  );

  const reports = useApiData(threatModelingApi.getReport, [{ id: diagramId }], 0);
  const reportsCountt = reports.data?.count;

  const onDiagramSelect = useCallback((value) => {
    dispatch(threatModelingActions.setSelectedComponents(value));
  }, []);

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        threatModelingService.getComponents(
          {
            ...query,
          },
          diagramId
        )
      );
    },
    [diagramId]
  );

  useEffect(() => {
    dispatch(threatModelingService.initDiagramResult({ id: diagramId, ppToolToken }));
  }, [diagramId, ppToolToken, keyUpdate]);

  const stats = useMemo(() => {
    if (!currentDiagram || !currentDiagram.components_count) return {};
    const { recognized, unrecognized, manual, total } = currentDiagram.components_count;

    return {
      compliantLabel: 'Manually',
      scannedLabel: 'Total',
      passedLabel: 'Recognized',
      failedLabel: 'Unrecognized',
      compliantSymbol: '',
      compliant: manual,
      scanned: total,
      passed: recognized,
      failed: unrecognized,
    };
  }, [currentDiagram]);

  const toggleIsReportExpand = useCallback(() => {
    setIsReportExpand(!isReportExpand);
  }, [isReportExpand, setIsReportExpand]);

  const exportOptions = reportConfigs?.map((reportConfig) => ({
    ...reportConfig,
    label: reportConfig.name,
    onClick: async () => {
      await threatModelingApi.prepareReport(diagramId, reportConfig.id).catch((err) => {
        const error = parseError(err);
        NotificationManager.error(error.detail || error.error || 'Server error');
      });

      threatModelingApi
        .exportReport(diagramId, reportConfig.id)
        .then((data) => {
          if (data?.status === 'completed') {
            downloadCompleted(data.file);
          } else {
            setTimeout(() => getReport(diagramId, reportConfig.id), REPEAT_REQUEST);
          }
        })
        .catch((err) => {
          const error = parseError(err);
          NotificationManager.error(error.detail || 'Server error');
        });
    },
  }));

  const analyzedComponentsCount =
    (currentDiagram?.components_count?.recognized ?? 0) + (currentDiagram?.components_count?.manual ?? 0);

  const {
    isOpen: isOpenEditor,
    onClose: onCloseEditor,
    onOpen: onOpenEditor,
    keyUpdate: keyMutateEditor,
  } = useModalInline();

  if (ppToolToken) {

    const [ppPage, setPpPage] = useState(0);
    const onChangeHandlerPP = useCallback((query) => {
      setPpPage(query.page - 1);
    });

    const reporRet = useApiData(threatModelingApi.getTmReportDataPP, [{ ppToolToken: ppToolToken, query: {page: ppPage} }], [ppToolToken, ppPage]);
    const reportCount = reporRet.data?.count;
    const reportData = reporRet.data?.report;
  
    const reportTableData = useMemo(() => formatReportData(reportData ?? []), [reportData]);
  
    return (
      <PageLayout>
        <DiagramResultPageWrapper>
          <StatWrapper>
            <StatTable {...stats} />
          </StatWrapper>
          <DiagramViewCard id='diagram-view'>
            <DiagramView manual={manual} />
          </DiagramViewCard>
          <ReportCard id='components-report'>
            <ReportHeader>
              <div>
                {`${reportsCountt ? reportsCountt : '...'} threats detected for ${analyzedComponentsCount} components`}
              </div>
              <div>{stats.scanned - analyzedComponentsCount} components are processing by AI</div>
            </ReportHeader>
            {reportTableData && reportCount ? (
              <TableNew
                onPageChange={onChangeHandlerPP}
                onSort={onChangeHandler}
                count={reportCount}
                rows={reportTableData}
                headCells={reportHeaders}
                paginationSize={10}
                isAllExtand={false}
                withoutCheck
                withCollapse
              />
            ) : (
              <NoComponents>No reports found</NoComponents>
            )}
          </ReportCard>
        </DiagramResultPageWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout withoutSidebar>
      <DiagramResultContext>
        <DiagramResultPageWrapper>
          <ContentWrapper>
            <SideWrapper>
              <DiagramResultHeader>
                <BackButtonLink to={PathNames.diagrams}>
                  <BackButton />
                </BackButtonLink>
                <Title>
                  <span>
                    Parsing:
                    <TruncateTitle>{currentDiagram?.filename}</TruncateTitle>
                  </span>
                </Title>
              </DiagramResultHeader>
              <div style={{ display: 'flex', gap: 24, height: '60px' }}>
                <Permission resource='threat-modeling::diagrams::related-accounts'>
                  {(actions) =>
                    actions.company?.['view']?.allowed || actions.team?.['view']?.allowed ? (
                      <ButtonRelatedAccounts />
                    ) : null
                  }
                </Permission>
                {isManual && (
                  <Permission resource='threat-modeling::diagrams::related-accounts'>
                    {(actions) =>
                      actions.company?.['view']?.allowed || actions.team?.['view']?.allowed ? (
                        <ButtonEditDiagram onClick={onOpenEditor} />
                      ) : null
                    }
                  </Permission>
                )}
              </div>

              <DiagramViewCard id='diagram-view'>
                <DiagramView
                  key={keyUpdatePreview}
                  manual={manual}
                  onElementSettings={onElementSettings}
                  loading={loadingComponents}
                />
              </DiagramViewCard>
            </SideWrapper>
            <SideWrapper>
              <StatWrapper>
                <StatTable {...stats} />
              </StatWrapper>
              <ToolbarWrapper>
                {!isManual && (
                  <CRUDButtonsWrapper>
                    <Permission resource='threat-modeling::diagrams::components'>
                      {(actions) => (actions.company?.['create']?.allowed ? <AddComponentButton /> : null)}
                    </Permission>
                    <Permission resource='threat-modeling::diagrams::components'>
                      {(actions) => (actions.company?.['delete']?.allowed ? <DeleteSelectedComponentsButton /> : null)}
                    </Permission>
                  </CRUDButtonsWrapper>
                )}
                <Permission resource='threat-modeling::diagrams'>
                  {(actions) =>
                    actions.company?.['create']?.allowed || actions.company?.['updated']?.allowed ? (
                      <ThreatAnalysisButton />
                    ) : null
                  }
                </Permission>
              </ToolbarWrapper>
              <ComponentsCard>
                {tableData.length ? (
                  <Table
                    isLoading={isLoading}
                    name='components'
                    defaultOrder='asc'
                    defaultOrderingName='name'
                    defaultOrderingValue='label'
                    rows={tableData}
                    rowsPerPage={10}
                    onPageChange={onChangeHandler}
                    headCells={diagramsHeaders}
                    onSelect={onDiagramSelect}
                    onSort={onChangeHandler}
                  />
                ) : (
                  <NoComponents>No components found</NoComponents>
                )}
              </ComponentsCard>
            </SideWrapper>
          </ContentWrapper>
          <ReportCard id='components-report'>
            <ReportCardHeaderWrapper>
              <ReportHeader>
                <div>
                  {`${
                    reportsCountt ? reportsCountt : '...'
                  } threats detected for ${analyzedComponentsCount} components`}
                </div>
                <div>{stats.scanned - analyzedComponentsCount} components are processing by AI</div>
              </ReportHeader>
              <ButtonsWrapper>
                <ButtonGroup options={exportOptions} disabled={reportsCountt === 0} label='Export to...' />
                <MaximizeAllButton isExpand={isReportExpand} toggleExpand={toggleIsReportExpand} />
              </ButtonsWrapper>
            </ReportCardHeaderWrapper>
            <TableReport keyMutate={keyUpdate} diagramId={diagramId} isReportExpand={isReportExpand} />
          </ReportCard>
        </DiagramResultPageWrapper>
        <DeleteComponentsModal />
        <AddComponentModal />
      </DiagramResultContext>
      {isManual && manual && !loadingComponents && !loadingDiagram && (
        <>
          <EditorModal
            key={keyUpdate}
            editorStorageKey={`editor-diagram-${currentDiagram.id}`}
            diagramId={manual.id}
            name={manual.filename}
            isOpen={isOpenEditor}
            onClose={onCloseEditor}
            onUpdate={() => {
              onUpdate();
              setKeyUpdate(Date.now());
              setKeyUpdatePreview(Date.now());
            }}
            props={{ initial: manual.manual_data }}
            existingComponents={existingComponents}
            onUpdateExistingComponents={onUpdate}
          />
          <ModalInline header='Component' isOpen={isOpenComponent} onClose={onCloseComponent}>
            <ComponentsFormInline
              diagramId={diagramId}
              component={modalPropsComponent}
              onCancel={onCloseComponent}
              onSubmit={onSubmitComponent}
              loading={loadingComponent}
            />
          </ModalInline>
        </>
      )}
    </PageLayout>
  );
};
