import React, { useCallback, useMemo } from 'react';

export const DEFAULT_BORDER_WIDTH = 2;

function toggleValue(val) {
  return val === 0 ? undefined : 0;
}

function defaultIfNotExist(val) {
  if (typeof val === 'undefined') {
    return DEFAULT_BORDER_WIDTH;
  }

  if (Number.isNaN(val)) {
    return DEFAULT_BORDER_WIDTH;
  }

  return val;
}

const ControlBorders = ({ label, value = {}, onChange = (parameter, value) => {} }) => {
  const { borderWidth } = value;

  const borders = useMemo(() => {
    if (typeof borderWidth === 'undefined') {
      return {};
    }

    if (typeof borderWidth === 'number') {
      return {
        top: borderWidth,
        right: borderWidth,
        bottom: borderWidth,
        left: borderWidth,
      };
    }

    if (typeof borderWidth === 'string') {
      const vals = borderWidth
        .replaceAll('px', '')
        .split(' ')
        .map((i) => parseFloat(i));

      return {
        top: vals[0],
        right: vals[1],
        bottom: vals[2],
        left: vals[3],
      };
    }

    return {};
  }, [borderWidth]);

  const onToggleBorder = useCallback(
    (border) => {
      const top = border === 'top' ? toggleValue(borders.top) : borders.top;
      const right = border === 'right' ? toggleValue(borders.right) : borders.right;
      const bottom = border === 'bottom' ? toggleValue(borders.bottom) : borders.bottom;
      const left = border === 'left' ? toggleValue(borders.left) : borders.left;

      if (
        (typeof top === 'undefined' || top === DEFAULT_BORDER_WIDTH) &&
        (typeof right === 'undefined' || right === DEFAULT_BORDER_WIDTH) &&
        (typeof bottom === 'undefined' || bottom === DEFAULT_BORDER_WIDTH) &&
        (typeof left === 'undefined' || left === DEFAULT_BORDER_WIDTH)
      ) {
        onChange('borderWidth', undefined);
        return;
      }

      const val = `${defaultIfNotExist(top)}px ${defaultIfNotExist(right)}px ${defaultIfNotExist(
        bottom
      )}px ${defaultIfNotExist(left)}px`;
      onChange('borderWidth', val);
    },
    [borders, onChange]
  );

  return (
    <div className='control-borders'>
      <div className='control-borders-label'>{label}</div>
      <div className='borders-body'>
        <div className={`borders-top ${borders.top !== 0 && 'active'}`} onClick={() => onToggleBorder('top')} />
        <div className={`borders-right ${borders.right !== 0 && 'active'}`} onClick={() => onToggleBorder('right')} />
        <div
          className={`borders-bottom ${borders.bottom !== 0 && 'active'}`}
          onClick={() => onToggleBorder('bottom')}
        />
        <div className={`borders-left ${borders.left !== 0 && 'active'}`} onClick={() => onToggleBorder('left')} />
      </div>
    </div>
  );
};

export default ControlBorders;
