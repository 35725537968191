import { getCurrentFeaturePrefix } from '../../../../packages';
import { FeaturePrefix } from '../../../../consts';

export const scanHistoryHeader = [
  {
    id: 'compliancePercent',
    numeric: false,
    disablePadding: true,
    label: 'Compliance',
    align: 'center',
  },
  {
    id: 'name',
    numeric: false,
    sort: 'name',
    disablePadding: false,
    label:
      getCurrentFeaturePrefix() === FeaturePrefix.PlatformSecurity
        ? 'Policy name'
        : 'Check name',
    align: 'left',
  },
  {
    id: 'policySeverity',
    numeric: false,
    sort: 'severity',
    disablePadding: false,
    label:
      getCurrentFeaturePrefix() === FeaturePrefix.PlatformSecurity
        ? 'Policy severity'
        : 'Check severity',
    align: 'center',
  },
  {
    id: 'resourcesScanned',
    numeric: true,
    disablePadding: false,
    label: 'Resources scanned',
    align: 'center',
  },
  {
    id: 'resourcesViolated',
    numeric: true,
    disablePadding: false,
    label: 'Resources violated',
    align: 'center',
  },
  {
    id: 'lastScan',
    numeric: false,
    sort: 'last_scan',
    disablePadding: false,
    label: 'Last scan',
    align: 'center',
  },
];
