export const scanHistoryHeader = [
  {
    id: "indicator",
    numeric: false,
    disablePadding: true,
    label: "Compliance",
    align: "center",
  },
  {
    id: "name",
    numeric: false,
    sort: "name",
    disablePadding: false,
    label: "Control name",
    align: "left",
  },
  {
    id: "policies_compliant_percent",
    numeric: false,
    sort: "policies_compliant_percent",
    disablePadding: false,
    label: "Policies compliant %",
    align: "center",
  },
  {
    id: "policies_total",
    numeric: false,
    disablePadding: false,
    sort: "policies_total",
    label: "Policies total",
    align: "center",
  },
  {
    id: "policies_violated",
    numeric: false,
    disablePadding: false,
    sort: "policies_violated",
    label: "Policies violated",
    align: "center",
  },
  {
    id: "last_scan",
    numeric: false,
    sort: "last_scan",
    disablePadding: false,
    label: "Last scan",
    align: "center",
  },
];
