import React, { memo, useState } from 'react';

import TextField from '@mui/material/TextField';
import ButtonDlg from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


export const InputNamePopup = memo(({open, onClose, initialValue}) => {

    const [dialogValue, setDialogValue] = useState(initialValue)

    const handleInputClose = () => {
        onClose(false);
    };
    const handleInputOk = () => {
        onClose(dialogValue);
    };

    return (
      <Dialog open={open} onClose={handleInputClose}>
        <DialogContent>
          <DialogContentText>
            Please set new Component 
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            value={dialogValue}
            onChange={(event) => setDialogValue(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <ButtonDlg onClick={handleInputClose}>Cancel</ButtonDlg>
          <ButtonDlg onClick={handleInputOk}>Create</ButtonDlg>
        </DialogActions>
      </Dialog>
    );

});