import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Form, SecondaryButton } from '../../../../packages';
import { ButtonsWrapper, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { appApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../../packages/utils/parse';

export const ChangePasswordForm = (props) => {
  const { onCancel = () => {}, onSuccess = () => {} } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      new_password: '',
      old_password: '',
      confirm_new_password: '',
    }),
    []
  );

  const validationSchema = defaultValidationSchema;

  const onSubmit = useCallback(
    (values, helpers) => {
      setLoading(true);

      appApi
        .changePassword({ old_password: values.old_password, new_password: values.new_password })
        .then(() => {
          NotificationManager.success('Password has been changed.');
          onSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          const dataErr = parseError(err);

          if (dataErr?.old_password?.[0]) {
            helpers.setFieldError('old_password', dataErr?.old_password?.[0]);
          } else if (dataErr?.new_password?.[0]) {
            helpers.setFieldError('new_password', dataErr?.new_password?.[0]);
          } else {
            NotificationManager.error('Server error.');
          }
        });
    },
    [dispatch, onSuccess]
  );

  return (
    <ProfileFormWrapper>
      <Form validationSchema={validationSchema} initialValues={initialValues} isLoading={false} onSubmit={onSubmit}>
        <InputField name='old_password' label='Old password' type='password' />
        <InputField name='new_password' label='New password' type='password' />
        <InputField name='confirm_new_password' label='Confirm new password' type='password' />
        <ButtonsWrapper>
          <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
            {'Cancel'}
          </SecondaryButton>
          <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
            {loading ? 'Changing' : 'Change'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </ProfileFormWrapper>
  );
};

export default ChangePasswordForm;
