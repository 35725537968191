import React, { memo } from 'react';

import {
  NoViolationsDescription,
  NoViolationsIcon,
  NoViolationsTitle,
  NoViolationsWrapper,
} from './styled';

export const NoViolations = memo(() => (
  <NoViolationsWrapper>
    <NoViolationsIcon />
    <NoViolationsTitle>All policies are compliant</NoViolationsTitle>
    <NoViolationsDescription>0 violations</NoViolationsDescription>
  </NoViolationsWrapper>
));
