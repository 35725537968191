import React, { memo, useCallback, useState } from 'react';

import { Popover, Tooltip } from '@mui/material';

import { useSelector } from 'react-redux';

import { appSelectors } from '../entities';

import profileUrl from './profile.png';

import {
  AccountIcon,
  AccountIconWrapper,
  MenuButtonWrapperWrapper,
  ProfileBlockWrapper,
  UserName,
} from './styled';
import { ProfileMenu } from './ProfileMenu';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { PathNames } from '../consts';
import IconSettings from '../packages/icons/IconSettings';
import Avatar from '../packages/components/avatar';

export const ProfileBlock = memo(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector(appSelectors.getAppData);
  const profile = useSelector(appSelectors.getProfile);

  const onOpenMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const username =
    user.first_name && user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username;

  return (
    <ProfileBlockWrapper data-profile-wrapper>
      <MenuButtonWrapperWrapper onClick={onOpenMenu} data-username>
        <Tooltip title={username} arrow>
          <UserName>{username}</UserName>
        </Tooltip>
        <AccountIconWrapper>
          {profile.avatar ? (
            <Avatar src={profile.avatar} size={64} />
          ) : (
            <AccountIcon src={profileUrl} />
          )}
        </AccountIconWrapper>
      </MenuButtonWrapperWrapper>
      <NavLink to={PathNames.profile} data-link-settings style={{ display: "flex" }}>
        <IconSettings />
      </NavLink>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ProfileMenu />
      </Popover>
    </ProfileBlockWrapper>
  );
});
