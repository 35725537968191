import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, SecondaryButton } from '../../../../packages';
import { ButtonsWrapper, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { threatModelingApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../../packages/utils/parse';

const GenerateDiagramForm = (props) => {
  const { accountId, onCancel = () => {}, onSuccess = () => {} } = props;
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      threatModelingApi
        .generateDiagram(accountId, values.name)
        .then(() => {
          NotificationManager.success('The diagram generation is started');
          onSuccess();
        })
        .catch((err) => {
          console.log('Error: generateDiagram()', err);
          const error = parseError(err);
          NotificationManager.error(error?.message || error?.detail || 'Server error');
        });
    },
    [dispatch, onSuccess, accountId]
  );

  return (
    <ProfileFormWrapper>
      <Form validationSchema={defaultValidationSchema} initialValues={{ name: '' }} onSubmit={onSubmit}>
        <InputField name='name' label='Diagram name' autoFocus />
        <ButtonsWrapper>
          <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
            Generate
          </Button>
        </ButtonsWrapper>
      </Form>
    </ProfileFormWrapper>
  );
};

export default GenerateDiagramForm;
