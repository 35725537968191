import React, { useMemo } from 'react';

import { ProfileFormWrapper } from './styled';
import List from '../../../../packages/components/list';
import useApiData from '../../../../hooks/useApiData';
import { customerDetailsApi } from '../../../../entities';
import branding from '../../../../branding';

const TeamsMemberList = (props) => {
  const { data, dirty, mutate } = useApiData(customerDetailsApi.getUserTeams, [props.data.id], {
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const list = useMemo(
    () => data.results.map((i) => ({ id: i.id, title: i.name, style: { color: branding.colors.primary, fontSize: 16 } })),
    [data]
  );

  return (
    <ProfileFormWrapper>
      <List data={list} />
    </ProfileFormWrapper>
  );
};

export default TeamsMemberList;
