import React, { useCallback } from 'react';

import { Button, SecondaryButton } from '../../../../packages';

import { ButtonsWrapper, ProfileFormWrapper } from './styled';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';

const StatusUserForm = (props) => {
  const { emails = [], activateUser = false, onCancel = () => {}, onSuccess = () => {} } = props;

  const onBlock = useCallback(() => {
    const request = activateUser
      ? customerDetailsApi.companiesActivateUsers({ emails })
      : customerDetailsApi.companiesBlockUsers({ emails });

    request
      .then(() => {
        NotificationManager.success(`User has been ${activateUser ? 'activated' : 'blocked'}.`);
        onSuccess(true);
      })
      .catch(() => {
        NotificationManager.error('Server error.');
        onCancel();
      });
  }, [onSuccess, emails]);

  return (
    <ProfileFormWrapper>
      <div style={{ marginBottom: 24, fontSize: 14, fontFamily: 'Open Sans' }}>
        Do you really want to {activateUser ? 'activate' : 'block'}{' '}
        <span style={{ fontWeight: 900, color: '#000000' }}>“{emails.join(', ')}”</span>?
      </div>
      <ButtonsWrapper>
        <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
          {'Cancel'}
        </SecondaryButton>
        <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }} onClick={onBlock}>
          {'Yes'}
        </Button>
      </ButtonsWrapper>
    </ProfileFormWrapper>
  );
};

export default StatusUserForm;
