import React, { memo } from 'react';

import { ProgressIcon } from '../progress-icon';

import { SuccessIcon, WarningIcon } from './styled';

export const ComplianceStatus = memo(
  ({ compliancePercent, timestamp, results_to }) => {
    const lastScan = new Date(timestamp * 1000);
    const resultsTo = new Date(results_to * 1000);

    if (lastScan > Date.now() || resultsTo > Date.now()) {
      return <ProgressIcon />;
    }

    if (compliancePercent < 100) {
      return <WarningIcon />;
    }

    return <SuccessIcon />;
  }
);
