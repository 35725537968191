import React, { memo } from 'react';

import {
  LogoTitle,
  LogoWrapper,
  PipelineDataLabel,
  PipelineInfoWrapper,
  PipelineMenu,
  Separator,
  Wrapper,
} from './styled';
import { Link } from '@mui/material';
import { FeatureSlugs, PathNames } from '../../../consts';
import { Logo } from './Logo';
import { NavLink } from 'react-router-dom';
import { colors } from '../../../theme';
import branding from '../../../branding';

const activesx = {
  fontSize: '13px',
  padding: '20px 30px',
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#fff !important',
  background: colors.activeTextColor,
  textDecoration: 'none !important',
};

const nonactivesx = {
  fontSize: '13px',
  padding: '20px 30px',
  textAlign: 'center',
  cursor: 'pointer',
  textDecoration: 'none !important',
};

const disnonactivesx = {
  ...nonactivesx, 
  opacity: 0.6, cursor: 'not-allowed'
};

export const PipelineHeader = memo(({ pipelineData, ppToolToken, ppFeature }) => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
      <LogoTitle>{branding.platform}</LogoTitle>
    </LogoWrapper>
    <PipelineMenu>
    {pipelineData?.enabled_features && pipelineData?.enabled_features.indexOf(FeatureSlugs.PlatformSecurity) !== -1 ? <Link
          sx={ppFeature === FeatureSlugs.PlatformSecurity ? activesx : nonactivesx}
          component={NavLink}
          to={PathNames.ppOverview.replace(':ppToolToken', ppToolToken).replace(':ppFeature', FeatureSlugs.PlatformSecurity)}
      >
          Platform security
      </Link> : <a  style={disnonactivesx}>Platform security</a>}
      <Separator/>
      {pipelineData?.is_tm_data && pipelineData?.enabled_features && pipelineData?.enabled_features.indexOf(FeatureSlugs.ThreatModelling) !== -1 ? <Link 
          sx={ppFeature === FeatureSlugs.ThreatModelling ? activesx : nonactivesx}
          component={NavLink}
          to={PathNames.ppThreadModeling.replace(':ppToolToken', ppToolToken)}
      >
          Threat modelling
      </Link> : <a style={disnonactivesx}>Threat modelling</a> }
      <Separator/>
      {pipelineData?.enabled_features && pipelineData?.enabled_features.indexOf(FeatureSlugs.PipelineSecurity) !== -1 ? <Link 
          sx={ppFeature === FeatureSlugs.PipelineSecurity ? activesx : nonactivesx}
          component={NavLink}
          to={PathNames.ppOverview.replace(':ppToolToken', ppToolToken).replace(':ppFeature', FeatureSlugs.PipelineSecurity)}
      >
          Pipeline security
      </Link> : <a style={disnonactivesx}>Pipeline security</a> }
    </PipelineMenu>
    {pipelineData ? (
      <PipelineInfoWrapper>
        {ppFeature === FeatureSlugs.PlatformSecurity && pipelineData?.resource_group_name && (
          <PipelineDataLabel>
            Resource group: <strong>{pipelineData?.resource_group_name}</strong>
          </PipelineDataLabel>
        )}
        {pipelineData?.subscription_id && (
          <PipelineDataLabel>
            Subscription ID: <strong>{pipelineData?.subscription_id}</strong>
          </PipelineDataLabel>
        )}
        {pipelineData?.tenant_id && (
          <PipelineDataLabel>
            Tenant ID: <strong>{pipelineData?.tenant_id}</strong>
          </PipelineDataLabel>
        )}
      </PipelineInfoWrapper>
    ): <PipelineInfoWrapper></PipelineInfoWrapper>}
  </Wrapper>
));
