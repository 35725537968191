import React, { memo, useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  customerDetailsSelectors,
  customerSelectors,
} from '../../../../entities';
import { StatTable } from '../../../../packages';

export const CustomerStat = memo(() => {
  const customerStat = useSelector(customerSelectors.getResourcesStat);
  const { overallCompliancePercent } = useSelector(
    customerDetailsSelectors.getTotalCompliancePercent
  );
  const stats = useMemo(() => {
    if (!customerStat) return {};

    return {
      compliant: overallCompliancePercent,
      passed: customerStat.resources_passed,
      scanned: customerStat.resources,
      failed: customerStat.resources_bad,
    };
  }, [customerStat, overallCompliancePercent]);

  return <StatTable {...stats} scannedLabel="Total assets" />;
});
