import React, { useCallback, useMemo } from 'react';
import { NotificationManager } from 'react-notifications';

import IconInproggres from '../../../../../packages/icons/IconInproggres';
import IconReady from '../../../../../packages/icons/IconReady';
import IconResolved from '../../../../../packages/icons/IconResolved';
import IconUnresolved from '../../../../../packages/icons/IconUnresolved';
import { threatModelingApi } from '../../../../../entities';
import { capitalizeFirstLetter } from '../../../../../packages/utils/string';
import DropdownMenu from '../../../../../packages/components/dropdown-menu';

import { parseError } from '../../../../../packages/utils/parse';
import branding from '../../../../../branding';

const ReportStatus = {
  UNRESOLVED: 'unresolved',
  INPROGRESS: 'in_progress',
  RESOLVED: 'resolved',
  MANUAL: 'manual',
  UNDEFINED: 'undefined',
};

const StatusStyle = {
  [ReportStatus.UNRESOLVED]: {
    color: '#EA0000',
    borderColor: '#ea000019',
    icon: <IconUnresolved />,
  },
  [ReportStatus.INPROGRESS]: {
    label: 'in progress',
    color: branding.colors.primary,
    borderColor: '#2170f619',
    icon: <IconInproggres />,
  },
  [ReportStatus.RESOLVED]: {
    color: '#79CD67',
    borderColor: '#79cd6719',
    icon: <IconResolved />,
  },
  [ReportStatus.MANUAL]: {
    label: 'manual',
    color: '#79CD67',
    borderColor: '#79cd6719',
    icon: <IconReady />,
  },
  [ReportStatus.UNDEFINED]: {
    color: '#8989897d',
    icon: <IconUnresolved />,
  },
};

const Statuses = [ReportStatus.UNRESOLVED, ReportStatus.INPROGRESS, ReportStatus.MANUAL];

const MenuStatusLabel = (props) => {
  const { value } = props;

  const icon = StatusStyle[value]?.icon;
  const label = StatusStyle[value]?.label || value;

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 28, minWidth: 112 }}>
      {icon}
      <span style={{ marginLeft: 8, fontWeight: 600, color: '#000' }}>{capitalizeFirstLetter(label)}</span>
    </div>
  );
};

export const MenuReportStatus = (props) => {
  const { children, reportId, disabled, current, onSuccess } = props;

  const updateStatus = useCallback(
    (value) => {
      threatModelingApi
        .patchReport(reportId, { status: value })
        .then(() => {
          onSuccess();
        })
        .catch((err) => {
          const error = parseError(err);
          let text = 'Server error';
          if (Array.isArray(error.status)) {
            text = error.status.join(`\n`);
          }
          NotificationManager.error(text);
        });
    },
    [reportId, onSuccess]
  );

  const data = useMemo(
    () =>
      Statuses.filter((i) => i !== current).map((i) => ({
        key: i,
        label: <MenuStatusLabel value={i} />,
        onClick: () => (disabled ? () => {} : updateStatus(i)),
      })),
    [current, updateStatus]
  );

  return current !== ReportStatus.RESOLVED ? <DropdownMenu data={data} disabled={disabled}>{children}</DropdownMenu> : <>{children}</>;
};

export const ComponentStatus = (props) => {
  const { value } = props;

  const data = StatusStyle[value] || StatusStyle[ReportStatus.UNDEFINED];
  const label = StatusStyle[value]?.label || value;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        minWidth: 136,
        height: 28,
        borderRadius: 4,
        fontWeight: 600,
        border: `1px solid ${data.borderColor}`,
        color: data.color,
      }}
    >
      {data.icon}
      <span style={{ marginLeft: 4 }}>{capitalizeFirstLetter(label)}</span>
    </div>
  );
};
