import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Form,
  SecondaryButton,
  modalActions,
  modalSelectors,
} from '../../../../packages';

import { customerSelectors, customerService } from '../../../../entities';

import { customerModalName } from '../../consts';

import {
  ButtonsWrapper,
  CustomerFormWrapper,
  DescriptionField,
  NameField,
} from './styled';
import { createCustomerValidationSchema } from './validate';

export const CustomerForm = memo(() => {
  const dispatch = useDispatch();
  const { isCustomerCreating } = useSelector(customerSelectors.getCustomerData);
  const customerId = useSelector((state) =>
    modalSelectors.getModalData(state, customerModalName)
  );
  const selectedCustomer = useSelector((state) =>
    customerSelectors.getCustomerById(state, customerId)
  );

  const { descr, name, id, enable } = selectedCustomer ?? {};

  const initialValues = {
    name: customerId ? name : '',
    description: customerId ? descr : '',
  };

  const onSubmit = useCallback(
    (values) => {
      dispatch(customerService.createOrUpdateCustomer(values, id));
    },
    [id]
  );

  const onDisableCustomer = useCallback(() => {
    dispatch(customerService.disableCustomer(id));
    dispatch(
      modalActions.setModalIsOpen({
        name: customerModalName,
        isOpen: false,
      })
    );
  }, [id]);

  const onEnableCustomer = useCallback(() => {
    dispatch(customerService.enableCustomer(id));
    dispatch(
      modalActions.setModalIsOpen({
        name: customerModalName,
        isOpen: false,
      })
    );
  }, [id]);

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: customerModalName,
          data: null,
        })
      );
    },
    []
  );

  return (
    <CustomerFormWrapper>
      <Form
        validationSchema={createCustomerValidationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        isLoading={isCustomerCreating}
      >
        <NameField name="name" label="Customer’s name" />
        <DescriptionField name="description" label="Description" />
        <ButtonsWrapper component="button">
          {!!id && (
            <SecondaryButton
              color={enable ? 'error' : 'warning'}
              component="button"
              onClick={enable ? onDisableCustomer : onEnableCustomer}
            >
              {enable ? 'Disable' : 'Enable'}
            </SecondaryButton>
          )}
          <Button variant="contained" type="submit" fullWidth={!id}>
            {id ? 'Save' : 'Create'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </CustomerFormWrapper>
  );
});
