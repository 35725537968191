import React from 'react';
import { Button } from '../../../../packages';
import { ModalInline, useModalInline } from '../../../../packages/components/modal/ModalInline';
import GenerateDiagramForm from '../generate-diagram-form';
import Permission from '../../../../packages/components/permissions';

export const GenerateDiagramButton = ({ accountId, onSuccess = () => {} }) => {
  const { isOpen, onClose, onOpen } = useModalInline();

  return (
    <Permission resource='threat-modeling::diagrams'>
      {(actions) =>
        actions.company?.['create']?.allowed ? (
          <>
            <Button onClick={() => onOpen()}>Generate</Button>
            <ModalInline header='Generate diargam' isOpen={isOpen} onClose={onClose}>
              <GenerateDiagramForm
                accountId={accountId}
                onClose={onClose}
                onSuccess={() => {
                  onClose();
                  onSuccess();
                }}
              />
            </ModalInline>
          </>
        ) : null
      }
    </Permission>
  );
};
