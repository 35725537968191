import React, { memo, useState } from 'react';

import TextField from '@mui/material/TextField';
import ButtonDlg from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


export const PatPopup = memo(({open, onClose, initialValue}) => {

    const [dialogValue, setDialogValue] = useState(initialValue)

    const handleInputClose = () => {
        onClose(false);
    };
    const handleInputOk = () => {
        onClose(dialogValue);
    };

    return (
      <Dialog open={open} onClose={handleInputClose}>
        <DialogContent>
          <DialogContentText>
            Please set your PAT
          </DialogContentText>
          <div style={{"text-align": "right", "font-size": "80%"}}><a target="_blank" href="get_pat/index.html">How to get PAT?</a></div>
          <TextField
            autoFocus
            margin="dense"
            id="pat"
            label="Personal Access Token"
            fullWidth
            value={dialogValue}
            onChange={(event) => setDialogValue(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <ButtonDlg onClick={handleInputClose}>Cancel</ButtonDlg>
          <ButtonDlg onClick={handleInputOk}>Ok</ButtonDlg>
        </DialogActions>
      </Dialog>
    );

});