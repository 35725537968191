import React, { memo, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { HelpMessage, Table } from '../../../../packages';

import { policySelectors, policyService } from '../../../../entities';

import { colors } from '../../../../theme';

import {
  HelpMessageWrapper,
  NoResources,
  ResourcesListTableWrapper,
  ResourcesListWrapper,
} from './styled';

import { resourcesHeader } from './consts';
import { formatResourceData } from './utils';

export const ResourcesList = memo(() => {
  const dispatch = useDispatch();
  const { accountId, standardId, ppToolToken, ppFeature, policyGroupId, policyId } = useParams();

  const { isResourcesLoading, selectedPolicyId, resources } = useSelector(
    policySelectors.getPolicyData
  );
  const tableData = useMemo(() => formatResourceData(resources), [resources]);

  const onPageChangeHandler = useCallback(
    (query) => {
      dispatch(
        policyService.getPolicyResources({
          accountId,
          standardId,
          policyGroupId,
          ppToolToken,
          ppFeature,
          policyId,
          query,
        })
      );
    },
    [selectedPolicyId, accountId, ppToolToken, ppFeature]
  );

  return (
    <ResourcesListWrapper>
      <HelpMessageWrapper>
        <HelpMessage
          color={colors.helpMessageColor}
          title="This is the list of resources that were checked during the specific scan.  You can see whether they were compliant or not."
        />
      </HelpMessageWrapper>
      {tableData.length ? (
        <ResourcesListTableWrapper>
          <Table
            name="resources"
            isLoading={isResourcesLoading}
            onPageChange={onPageChangeHandler}
            rows={tableData}
            headCells={resourcesHeader}
            withoutCheck
          />
        </ResourcesListTableWrapper>
      ) : (
        <NoResources>No resources</NoResources>
      )}
    </ResourcesListWrapper>
  );
});
