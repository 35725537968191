import React, { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';

import { Card, HelpMessage, SecondarySelect } from '../../../../packages';
import ChartProgress from '../test/ChartProgress';
import { colors } from '../../../../theme';
import { useURLQueryParam } from '../../../../packages/utils/router';

export function toPercent(value = 0, total = 0) {
  return value ? parseFloat(((value * 100) / total).toFixed(2)) : 0;
}

export const CardStatusProgress = ({ statusProgress, total, style, cardStyle = {} }) => {
  return (
    <Card title='Status progress' style={{ flex: 1, height: '100%', ...cardStyle }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative', fontSize: 14 }}>
        <div
          style={{
            display: 'flex',
            alignContent: 'space-between',
            position: 'absolute',
            top: -40,
            right: 30,
          }}
        >
          <SelectPeriod />
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', maxHeight: 305 }}>
          <ChartProgress statusProgress={statusProgress} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px 30px 16px 30px' }}>
          <div>{/* <span style={{ fontWeight: 700 }}>{total}</span> Total */}</div>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Marker color='#79CD67' margin='0 10px 0 0' />
              Resolved
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Marker color='#056EFF' margin='0 10px 0 35px' />
              In progress
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Marker color='#EA0000' margin='0 10px 0 35px' />
              Unresolved
            </div>
          </div>
          <HelpMessage title='Overall compliance status progress by period.' color={colors.darkBorderColor} />
        </div>
      </div>
    </Card>
  );
};

const Marker = (props) => (
  <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: props.color, margin: props.margin }} />
);

const SelectPeriod = () => {
  const { value, onChange } = useURLQueryParam('range');

  const onSelect = useCallback((item) => {
    onChange(item.id !== -1 ? item.id : '');
  }, []);

  const options = useMemo(
    () => [
      // { id: -1, label: 'All period' },
      { id: 'week', label: 'Last week' },
      { id: 'month', label: 'Last month' },
    ],
    []
  );

  const option = options.find((i) => i.id === value) || options[0]; // Last week is default

  return <SecondarySelect options={options} placeholder='Period' value={option} onChange={onSelect} />;
};

const PureSelect = styled(SecondarySelect)(({ theme }) => ({
  minWidth: '100px',
}));
