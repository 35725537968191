import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '@mui/material';

import { standardSelectors, standardService } from '../../../../entities';

import { AssignStandards } from '../../../../packages';

import { LoaderWrapper } from './styled';

export const AssignStandardsContainer = memo(
  ({ accountId, isPipelineOnboarding, customerId, ...rest }) => {
    const dispatch = useDispatch();
    const items = useSelector(standardSelectors.getAssignStandards);
    const presets = useSelector(standardSelectors.getPresets);
    const isLoading = useSelector(
      standardSelectors.getIsAssignStandardsLoading
    );

    useEffect(() => {
      dispatch(
        standardService.getAssignStandards({ accountId, id: customerId })
      );
    }, [dispatch, accountId, customerId]);

    if (isLoading) {
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    }

    return (
      <AssignStandards
        selectAllByDefault={isPipelineOnboarding}
        items={items}
        presets={presets}
        {...rest}
      />
    );
  }
);
