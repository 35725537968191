import React, { memo } from 'react';

import { colors } from '../../../../theme';

import { ManageTeamButtonWrapper, ManageTeamIcon } from './styled';

export const ManageComponentTeamButton = memo(() => (
  <ManageTeamButtonWrapper>
    <ManageTeamIcon color={colors.darkBorderColor} />
    Team
  </ManageTeamButtonWrapper>
));
