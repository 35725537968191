import React from "react";

const InputNumber = ({ label, value, placeholder, min = 0, max, step = 1, onChange, custom: Custom }) => {
  if (Custom) {
    return (
      <Custom
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
    );
  }

  return (
    <div>
      <div>{label}</div>
      <input
        type="number"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
    </div>
  );
};

export default InputNumber;
