import styled from 'styled-components';
import ExitToAppIcon from 'mdi-react/ExitToAppIcon';

import { sm, xsm } from '../theme';

export const Title = styled.div`
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
`;

export const MainHeader = styled.div`
  display: flex;
  height: 80px;
`;

export const ProfileBlockWrapper = styled.div`
  display: flex;
  gap: 24px;
  /* margin-left: auto;
  padding-left: 20px; */
  align-items: center;
`;

export const MobileProfileWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media screen and (max-width: ${xsm}) {
    display: flex;
  }
`;

export const MobileUserName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  min-width: 75px;
  line-height: 1;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.activeTextColor};
`;

export const MenuButtonWrapperWrapper = styled.div`
  display: flex;
  cursor: pointer;
  gap: 24px;
`;

export const UserName = styled.span`
  display: block;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  /* min-width: 75px; */
  color: ${({ theme }) => theme.colors.activeTextColor};
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AccountIconWrapper = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 64px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.borderColor};
  /* margin-left: 12px; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* @media screen and (max-width: ${sm}) {
    display: none;
  } */
`;
export const AccountIcon = styled.img`
  width: 32px;
`;

export const ExitIcon = styled(ExitToAppIcon)`
  fill: ${({ theme }) => theme.colors.activeIcon};
  margin-right: 6px;
  cursor: pointer;
`;

export const MobileExitIcon = styled(ExitToAppIcon)`
  fill: ${({ theme }) => theme.colors.background};
  cursor: pointer;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;
