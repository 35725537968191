export const appReducerNamespace = 'app';

export const OnboardingSteps = {
  info: 'info',
  register: 'register',
  confirmEmail: 'confirm-email',
  accounts: 'accounts',
  chooseFeature: 'choose-feature',
  selectRegistrationType: 'select-registration-type',
  assignStandards: 'assign-standards',
  readyToScanning: 'ready-to-scanning',
  scanning: 'scanning',
  completed: 'completed',
};
