import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

import {
  CountermeasuresContainer,
  CountermeasuresItem,
  Description,
  ReportCellWrapper,
  SecurityChecksContainer,
  SecurityChecksItem,
  ShowMore,
  CellSimple,
  MtBg,
  MtButton,
  Title,
  WrapControls,
} from './styled';
import branding from '../../../../branding';
import { FeaturePrefix, PathNames } from '../../../../consts';

const getTitleByFieldName = (value, name) => {
  switch (name) {
    case 'name':
    case 'control':
    case 'use_case': {
      return value;
    }
    case 'threat': {
      return value?.name;
    }
    default:
      return null;
  }
};
const getDescriptionByFieldName = (value, name, data) => {
  switch (name) {
    case 'status': {
      return value;
    }
    case 'countermeasures': {
      return (
        <CountermeasuresContainer>
          {!!value && value !== 'N/A' && !!value.length
            ? value.map((a) => <CountermeasuresItem>{a}</CountermeasuresItem>)
            : 'N/A'}
        </CountermeasuresContainer>
      );
    }
    case 'threat': {
      return value?.description;
    }
    case 'security_checks': {
      return (
        <SecurityChecksContainer>
          {!!value && value !== 'N/A' && !!value.length
            ? value.map((check) => (
                <SecurityChecksItem
                  background={
                    data.authority_type === 'private' ? '#fcfeeb' : branding.colors.lightBlue
                  }
                >
                  {check}
                </SecurityChecksItem>
              ))
            : 'N/A'}
        </SecurityChecksContainer>
      );
    }
    default:
      return null;
  }
};

export const ReportCell = ({ isExpand, cellName, toggleExpand, value, resource_path, ...data }) => {
  const [ref, setRef] = useState(null);
  const [isShowMore, setIsShowMore] = useState(isExpand);
  const title = getTitleByFieldName(value, cellName);
  const description = getDescriptionByFieldName(value, cellName, data);

  const shouldTruncate = useMemo(() => ref?.clientHeight && ref?.clientHeight > 80, [ref?.clientHeight]);

  const currentFeaturePrefix = FeaturePrefix.PlatformSecurity;
  //  /:feature/:scanId/:id/account/:accountId/resources/:resource/:control/
  const resourceTo = cellName == "name" && resource_path
    ? `${PathNames.psResource.replace(":feature", currentFeaturePrefix).replace(":scanId", "current").replace(":id", resource_path.customer_id).replace(":accountId", resource_path.account_id).replace(":resource", resource_path.resource_id)}`
    : "";

  return (
    <ReportCellWrapper isExpand={shouldTruncate} shouldTruncate={!isShowMore}>
      {title && <Title shouldTruncate={shouldTruncate}>
        {resourceTo ? <Link to={resourceTo} component={NavLink}>{title}</Link> : title}
        </Title>}
      {description && (
        <>
          <Description shouldTruncate={!isShowMore}>{description}</Description>
          <Description
            shadow
            ref={(el) => {
              if (el) setRef(el);
            }}
          >
            {description}
          </Description>
        </>
      )}
      {shouldTruncate && (
        <ShowMore onClick={() => setIsShowMore(!isShowMore)}>{isShowMore ? ' Show less' : 'Show more'}</ShowMore>
      )}
    </ReportCellWrapper>
  );
};

export const ReportMitresCell = (props) => {
  const { mitres, isExpand } = props;

  const [ref, setRef] = useState(null);
  const [collapses, setCollapses] = useState([]);
  const [isShowMore, setIsShowMore] = useState(isExpand);

  const shouldTruncate = useMemo(() => ref?.clientHeight && ref?.clientHeight > 80, [ref?.clientHeight]);

  const content = useMemo(() => {
    if (Array.isArray(mitres) && mitres.length) {
      const _mitres = [];
      const spoilers = {};
      const spoilers_ = {};
      mitres.map((c) => {
        if (c.code && c.code.indexOf('.') !== -1) {
          const _j = c.code.split('.', 1);
          if (spoilers[_j[0]] === undefined) {
            spoilers[_j[0]] = [];
          }
          spoilers[_j[0]].push(c);
        } else {
          _mitres.push({...c});
          spoilers_[c.code] = ({...c, 'use_ojbs': false, 'ojbs': []});;
        }
      });
      Object.keys(spoilers).map(k => {
        if (spoilers_[k] === undefined) {
          _mitres.push({
            'code': k, 'link': '', 'ojbs': spoilers[k], 'use_ojbs': true, 'addauto': true
          })
        } else {
          spoilers_[k]['ojbs'] = spoilers[k];
          spoilers_[k]['use_ojbs'] = true;
        }
      });
      _mitres.sort((a,b) => a.code && b.code ? a.code.localeCompare(b.code): 0);
      return (
        <div>
          {_mitres.map((c, idx) => (
            <React.Fragment key={c.code}>
              <CellSimple style={c.addauto ? {'opacity': '0.5'/*, 'text-decoration': 'line-through'*/} : {}}>
                {c.link ? <a href={c.link} target="_blank">{c.code}</a> : c.code}
                {c.use_ojbs && collapses.indexOf(c.code) === -1 ? <MtButton onClick={() => {
                  setCollapses([...collapses, c.code]);
                }}>...</MtButton> : null}
              </CellSimple>
              {c.use_ojbs && c.ojbs && collapses && collapses.indexOf(c.code) !== -1 ? <MtBg>
                {c.ojbs.map((_item, _idx) => (<React.Fragment key={c.code}>, <CellSimple>
                  {_item.link ? <a href={_item.link} target="_blank">{_item.code}</a> : _item.code}
                  </CellSimple></React.Fragment>)
                )}
                <MtButton onClick={() => {
                  setCollapses([...collapses].filter(_j => _j !== c.code));
                }}>&lt;&lt;</MtButton>
              </MtBg> : null}
              {idx < _mitres.length - 1 ? `, ` : ''}
            </React.Fragment>
          ))}
        </div>
      )
    } else {
      return 'N/A';
    };
  }, [mitres, collapses]);

  return (
    <ReportCellWrapper isExpand={shouldTruncate} shouldTruncate={!isShowMore}>
      <WrapControls shouldTruncate={!isShowMore}>{content}</WrapControls>
      <WrapControls
        shadow
        ref={(el) => {
          if (el) setRef(el);
        }}
      >
        {content}
      </WrapControls>

      {shouldTruncate && (
        <ShowMore onClick={() => setIsShowMore(!isShowMore)}>{isShowMore ? ' Show less' : 'Show more'}</ShowMore>
      )}
    </ReportCellWrapper>
  );
};

export const ReportSRCell = (props) => {
  const { security_requirements, isExpand } = props;

  const [ref, setRef] = useState(null);
  const [isShowMore, setIsShowMore] = useState(isExpand);

  const shouldTruncate = useMemo(() => ref?.clientHeight && ref?.clientHeight > 80, [ref?.clientHeight]);

  const content = useMemo(() => {
    return security_requirements.map(item => <div>
      <div><span style={{fontWeight: 900 }}>[{item.code}]</span> {item.description}</div>
           {/* <ReportControlsCell label="Controls:" controls={item.control_codes} padding="0" /> */}
           <br />
      </div>)
  }, [security_requirements]);

  return (
    <ReportCellWrapper isExpand={shouldTruncate} shouldTruncate={!isShowMore}>
      <WrapControls shouldTruncate={!isShowMore}>{content}</WrapControls>
      <WrapControls
        shadow
        ref={(el) => {
          if (el) setRef(el);
        }}
      >
        {content}
      </WrapControls>

      {shouldTruncate && (
        <ShowMore onClick={() => setIsShowMore(!isShowMore)}>{isShowMore ? ' Show less' : 'Show more'}</ShowMore>
      )}
    </ReportCellWrapper>
  );
};

export const ReportControlsCell = (props) => {
  const { label, controls, isExpand, resource_attached, resource_path, padding } = props;

  const [ref, setRef] = useState(null);
  const [isShowMore, setIsShowMore] = useState(isExpand);

  const shouldTruncate = useMemo(() => ref?.clientHeight && ref?.clientHeight > 80, [ref?.clientHeight]);

  const currentFeaturePrefix = FeaturePrefix.PlatformSecurity;
  //  /:feature/:scanId/:id/account/:accountId/resources/:resource/:control/
  const controlTo = resource_path
    ? `${PathNames.psResourceControl.replace(":feature", currentFeaturePrefix).replace(":scanId", "current").replace(":id", resource_path.customer_id).replace(":accountId", resource_path.account_id).replace(":resource", resource_path.resource_id)}`
    : "";

    const content = useMemo(() => {
    return Array.isArray(controls) && controls.length ? (
      <div>
        {controls.map((c, idx) => (
          <React.Fragment>
            {label && idx === 0 && <CellSimple style={{ fontWeight: 900 }}>{label}&nbsp;</CellSimple>}
            <CellSimple
              style={{
                padding: c.is_compliant !== null || resource_attached ? '0px 5px' : '',
                background: c.is_compliant ? 'rgb(125, 220, 110)' : (
                    c.is_compliant === false ? 'rgb(255, 65, 65)' : (
                        resource_attached ? 'rgb(194, 197, 203)' : ''
                    )
                ),
              }}
            >
              {c.is_compliant !== null && controlTo ? <Link to={controlTo.replace(":control", c.code)} component={NavLink}>{c.code}</Link> : c.code}
            </CellSimple>
            {idx < controls.length - 1 ? `, ` : ''}
          </React.Fragment>
        ))}
      </div>
    ) : (
      'N/A'
    );
  }, [controls]);

  return (
    <ReportCellWrapper isExpand={shouldTruncate} shouldTruncate={!isShowMore} padding={padding} >
      <WrapControls shouldTruncate={!isShowMore}>{content}</WrapControls>
      <WrapControls
        shadow
        ref={(el) => {
          if (el) setRef(el);
        }}
      >
        {content}
      </WrapControls>

      {shouldTruncate && (
        <ShowMore onClick={() => setIsShowMore(!isShowMore)}>{isShowMore ? ' Show less' : 'Show more'}</ShowMore>
      )}
    </ReportCellWrapper>
  );
};