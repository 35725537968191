import styled from 'styled-components';

import { icons } from '../icons';
import { xsm } from '../../../theme';
import branding from '../../../branding';

export const ViolationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
`;

export const ViolationHelpMessage = styled.div`
  padding: 0 15px;
`;

export const ViolationsCount = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TotalViolations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 80px;

  @media screen and (max-width: ${xsm}) {
    margin-right: 32px;
  }
`;

export const TotalCount = styled.div`
  font-size: 32px;
  line-height: 48px;
  color: #000000;
`;

export const TotalLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: #000000;
`;

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Legend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LegendCircle = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-bottom: 4px;
  background: ${({ theme, color }) => color || theme.colors.activeBackground};
  margin-right: 5px;
`;

export const LegendCount = styled.div`
  display: flex;
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #000000;
`;

export const LegendLabel = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: #000000;
  text-align: center;
`;

export const ViolationsLine = styled.div`
  display: flex;
  width: ${({ percent }) => `${percent ?? 0}%`};
  margin-top: 10px;
  border-top-left-radius: ${({ withBorderRadius }) =>
    withBorderRadius ? '10px' : 0};
  border-bottom-left-radius: ${({ withBorderRadius }) =>
    withBorderRadius ? '10px' : 0};
  height: 20px;
  background: ${({ theme, color }) => color || theme.colors.activeBackground};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ViolationsPercent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const LinesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ViolationsLegend = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const ServiceLegend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  &:first-child {
    margin-right: 60px;
  }
`;

export const ServicePercent = styled.div`
  font-size: 24px;
  line-height: 1;
`;

export const ServiceName = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #000000;
  margin-top: 4px;
`;

export const NoViolationsWrapper = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export const NoViolationsTitle = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 1;
  color: ${branding.colors.deepBlue};
  margin-bottom: 16px;
`;

export const NoViolationsDescription = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 1;
  color: #535353;
`;

export const NoViolationsIcon = styled.img.attrs({
  src: icons.noViolationsIcon,
})`
  width: 70px;
  margin-bottom: 16px;
`;
