import React from 'react';

const IconStatusActive = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.3859 3.14453C11.9086 2.48628 10.2581 2.32321 8.68056 2.67964C7.10301 3.03606 5.68293 3.89289 4.63213 5.12233C3.58132 6.35177 2.95609 7.88795 2.84967 9.50176C2.74326 11.1156 3.16137 12.7206 4.04164 14.0773C4.92191 15.4341 6.21718 16.47 7.73427 17.0305C9.25136 17.591 10.909 17.646 12.4599 17.1875C14.0109 16.7289 15.372 15.7813 16.3404 14.4859C17.3088 13.1905 17.8325 11.6168 17.8334 9.99953V9.23334C17.8334 8.7731 18.2065 8.4 18.6668 8.4C19.127 8.4 19.5001 8.7731 19.5001 9.23334V10C19.499 11.9767 18.8589 13.9006 17.6753 15.4838C16.4917 17.067 14.8281 18.2253 12.9325 18.7857C11.0369 19.3462 9.0109 19.2789 7.15668 18.5938C5.30246 17.9088 3.71935 16.6427 2.64346 14.9845C1.56758 13.3262 1.05656 11.3645 1.18662 9.3921C1.31668 7.41967 2.08085 5.54211 3.36517 4.03946C4.64949 2.53682 6.38513 1.48958 8.31325 1.05395C10.2414 0.618315 12.2587 0.817623 14.0643 1.62215C14.4846 1.80946 14.6736 2.30211 14.4863 2.7225C14.299 3.1429 13.8063 3.33184 13.3859 3.14453ZM19.2557 2.74379C19.5813 3.06906 19.5816 3.5967 19.2563 3.9223L10.923 12.264C10.7667 12.4204 10.5547 12.5083 10.3336 12.5083C10.1125 12.5084 9.9005 12.4206 9.74416 12.2643L7.24416 9.76426C6.91873 9.43882 6.91873 8.91119 7.24416 8.58575C7.5696 8.26031 8.09724 8.26031 8.42268 8.58575L10.3331 10.4962L18.0772 2.74438C18.4025 2.41878 18.9301 2.41851 19.2557 2.74379Z'
        fill='#79CD67'
      />
    </svg>
  );
};

export default IconStatusActive;
