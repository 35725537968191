import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router';

import { saasApi } from '../../entities';

import { RegistrationLayout } from '../../layouts';

import { parseError } from '../../packages/utils/parse';
import { Loader, history } from '../../packages';
import { PathNames, STORAGE_REDIRECT_EXPIRE_KEY, STORAGE_REDIRECT_KEY } from '../../consts';

import { ImageBox, InfoBox, WelcomeLabel, Wrapper } from './styled';
import { NotificationManager } from 'react-notifications';

const expirePeriodMs = 30 * 60 * 60 * 1000; // 30 mins

function confirm(token) {
  saasApi
    .azureSignUp({ token })
    .then((resp) => {
      console.log('saasApi.azureSignUp()', resp);
      if (resp.status_code) {
        NotificationManager.error(resp.detail || 'Error. Registration incomplete.');
        window.localStorage.removeItem(STORAGE_REDIRECT_EXPIRE_KEY);
        window.localStorage.removeItem(STORAGE_REDIRECT_KEY);
        setTimeout(() => {
          history.replace(resp.status_code === 406 ? PathNames.home : PathNames.welcome);
        }, 2000);
        return;
      }

      NotificationManager.success('Registration completed successfully');
      setTimeout(() => {
        history.replace(PathNames.features);
      }, 2000);
    })
    .catch((data) => {
      const error = parseError(data);
      if (error) {
        if (error.code === 'token_not_valid') {
          NotificationManager.error(error.detail || 'Error. Registration incomplete.');
        }
      }
      console.log('azureSignUp', error);
      window.localStorage[STORAGE_REDIRECT_KEY] = `${window.location.origin}${location.pathname}${location.search}`;
      window.localStorage[STORAGE_REDIRECT_EXPIRE_KEY] = Date.now() + expirePeriodMs;
      setTimeout(() => {
        history.replace(PathNames.welcome);
      }, 2000);
    });
}

export const PageConfirmation = memo((props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  useEffect(() => {
    let timer;
    if (location.pathname.startsWith(PathNames.confirmationSaasAzure)) {
      timer = setTimeout(() => confirm(token), 1000);
    }
    return () => clearTimeout(timer);
  }, []);

  return (
    <RegistrationLayout>
      <Wrapper>
        <InfoBox>
          <WelcomeLabel>Confirmation...</WelcomeLabel>
        </InfoBox>
        <ImageBox>
          <Loader />
        </ImageBox>
      </Wrapper>
    </RegistrationLayout>
  );
});
