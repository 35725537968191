import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, TextField } from '../../packages';
import {
  PAGINATION_COMPONENT_TYPES,
  threatModelingApi,
  threatModelingSelectors,
  threatModelingService,
} from '../../entities';
import { PageLayout } from '../../layouts';
import { history, setPagination, usePagination } from '../../packages/utils';
import { Card, NoContent, PageWrapper, WrapHeader } from './styled';
import { formatData, getHeaders } from './utilsComponentTypes';
import Header from '../dashboard/components/header';
import { PathNames } from '../../consts';
import Filters, { FILTERS_BY } from './Filters';
import { DeleteModal, useDeleteModal } from './components/delete-modal/DeleteModal';

const TabComponentTypes = () => {
  const dispatch = useDispatch();
  const { componentTypes, isLoading, companyValue } = useSelector(threatModelingSelectors.getThreatModelingData);

  const [keyUpdate, setKeyUpdate] = useState(0);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setPagination(PAGINATION_COMPONENT_TYPES, { page: 0 });
  }, [search, filters]);

  useEffect(() => {
    const pagination = usePagination(PAGINATION_COMPONENT_TYPES);
    dispatch(threatModelingService.getComponentTypes({ ...pagination, search, ...filters }));
  }, [search, filters, keyUpdate]);

  const onDeleteItem = useDeleteModal();
  const tableData = useMemo(() => formatData(componentTypes, { onDelete: onDeleteItem }), [componentTypes]);

  const onChangeHandler = useCallback(
    (_query) => {
      const query = { ..._query, search, ...filters };
      if (companyValue && companyValue.value) {
        query.company = companyValue.value;
      }
      dispatch(
        threatModelingService.getComponentTypes({
          ...query,
        })
      );
    },
    [companyValue, search, filters]
  );

  return (
    <PageLayout withoutSidebar>
      <PageWrapper>
        <Header
          rightComponent={
            <WrapHeader>
              <TextField value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' />
              <Button
                size='medium'
                type='submit'
                style={{ width: 140, height: 40 }}
                onClick={() => history.push(PathNames.componentType.replace(':componentTypeId', 'new'))}
              >
                Create
              </Button>
            </WrapHeader>
          }
        />
        <Card>
          {tableData.length ? (
            <div>
              <Table
                isLoading={isLoading}
                name={PAGINATION_COMPONENT_TYPES}
                // defaultOrder='desc'
                // defaultOrderingName='created_at'
                // defaultOrderingValue='-created_at'
                rows={tableData}
                withoutCheck
                rowsPerPage={10}
                onPageChange={onChangeHandler}
                onSort={onChangeHandler}
                headCells={getHeaders()}
                // onSelect={onDiagramSelect}
              />
            </div>
          ) : (
            <NoContent>No content</NoContent>
          )}
          <Filters
            onChange={setFilters}
            filters={[FILTERS_BY.COMPONENT_TYPE_COMPANY, FILTERS_BY.COMPONENT_TYPE_SERVICE]}
          />
        </Card>
      </PageWrapper>
      <DeleteModal
        header='Delete component type'
        api={threatModelingApi.deleteComponentType}
        onSuccess={() => setKeyUpdate(Date.now())}
      />
    </PageLayout>
  );
};

export default TabComponentTypes;
