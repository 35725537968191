import React, { useCallback, useMemo, useState } from 'react';
import { formatData } from './formatData';
import { CustomerAccountsWrapper } from '../../../accounts/components/customer-accounts/styled';
import TableNew from '../../../../packages/components/table-new';
import useHeaders from './headers';
import useApiData from '../../../../hooks/useApiData';
import { appSelectors, threatModelingApi } from '../../../../entities';
import { useSelector } from 'react-redux';
import { NoComponents } from '../../styled';
import { useURLQueryParam } from '../../../../packages/utils/router';
import { Button } from '../../../../packages';
import { getActions } from '../../../../packages/components/permissions';

const TableReport = (props) => {
  const { diagramId, isReportExpand, keyMutate } = props;
  const [apiQuery, setApiQuery] = useState({});
  const { selectedCustomerId } = useSelector(appSelectors.getAppData);
  const { value: component, onChange: onChangeComponents } = useURLQueryParam('components');
  const { value: control_code, onChange: onChangeControls } = useURLQueryParam('controls');

  const onResetFilters = useCallback(() => {
    onChangeComponents();
    onChangeControls();
  }, [onChangeComponents, onChangeControls]);

  const onChangeHandler = useCallback(
    (query) => {
      setApiQuery(query);
    },
    [selectedCustomerId]
  );

  const query = useMemo(() => {
    const newApiQuery = { ...apiQuery, page: 1 };

    if (JSON.stringify(newApiQuery) !== JSON.stringify(apiQuery)) {
      setApiQuery({ ...apiQuery, page: 1 });
    }

    const cont = {};
    if (component) {
      cont.component = component;
    }
    if (control_code) {
      cont.control_code = control_code;
    }

    return cont;
  }, [component, control_code]);

  const { data, dirty, loading, mutate } = useApiData(
    threatModelingApi.getReport,
    [{ id: diagramId, query: { ...apiQuery, ...query } }],
    {
      count: 0,
      next: null,
      previous: null,
      results: [],
      filters: {
        components: [{ value: -1, title: 'All' }],
      },
      control_codes: [{ value: -1, title: 'All', is_present: true }],
    },
    keyMutate
  );

  const profile = useSelector(appSelectors.getProfile);
  const threatActions = getActions("threat-modeling::diagrams::components::threats");

  const preparedData = useMemo(
    () => ({
      ...data,
      results: formatData(data.results, mutate, profile, threatActions),
    }),
    [data]
  );

  const headers = useHeaders(data.filters.components, data.filters.control_codes);

  return (
    <CustomerAccountsWrapper>
      {preparedData.count ? (
        <TableNew
          onPageChange={onChangeHandler}
          onSort={onChangeHandler}
          count={preparedData.count}
          rows={preparedData.results}
          paginationSize={10}
          headCells={headers}
          isAllExtand={isReportExpand}
          withoutCheck
          withCollapse
          resetCurrentPage={`${component}-${control_code}`}
        />
      ) : (
        <>
          {component || control_code ? (
            <TableNew
              onPageChange={onChangeHandler}
              onSort={onChangeHandler}
              count={preparedData.count}
              rows={preparedData.results}
              paginationSize={10}
              headCells={headers}
              isAllExtand={isReportExpand}
              withoutCheck
              withCollapse
            />
          ) : (
            <NoComponents>No reports found</NoComponents>
          )}
        </>
      )}
      {!preparedData.count && (component || control_code) && (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <NoComponents>No reports found</NoComponents>
          <Button onClick={onResetFilters}>Reset filters</Button>
        </div>
      )}
      {!preparedData.count && <div style={{ height: 100 }} />}
    </CustomerAccountsWrapper>
  );
};

export default TableReport;
