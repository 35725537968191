export const useQuery = (name) => {
  try {
    return JSON.parse(sessionStorage.getItem(`${name}-query`));
  } catch (e) {
    return {};
  }
};

export const usePage = (name) => {
  try {
    return JSON.parse(sessionStorage.getItem(`${name}-page`));
  } catch (e) {
    return {};
  }
};

export const setQuery = (name, newQueryParams) => {
  const query = useQuery(name);
  const data = { ...query, ...newQueryParams };

  const fData = Object.keys(data)
    .filter((key) => data[key] !== undefined)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: data[key],
      }),
      {}
    );

  sessionStorage.setItem(`${name}-query`, JSON.stringify(fData));
};
