import React from 'react';

import { OverallComplianceWrapper } from './styled';
import { Card } from '../../../../packages';
import { TMOverallCompliance } from '../test/MultiPie';

const toInt = (val) => Math.round(parseFloat(`${val}`));

export const CardOverallCompliance = ({
  unresolved,
  inProgress,
  resolved,
  withResource,
  withoutResource,
  overallPercent = 0,
  style,
  cardStyle,
}) => {
  return (
    <Card
      title='Overall Compliance'
      helpMessage="Overall compliance % of all the customer's accounts and compliance % per each service (if exists) - Azure accounts and AWS accounts."
      style={cardStyle}
    >
      <OverallComplianceWrapper style={style}>
        <div style={{}}>
          <div style={{ position: 'relative' }}>
            <TMOverallCompliance
              width='236px'
              height='236px'
              unresolved={unresolved}
              inProgress={inProgress}
              resolved={resolved}
              withResource={withResource}
              withoutResource={withoutResource}
            />
            <div style={{ position: 'absolute', top: 185, right: 130, whiteSpace: 'nowrap', fontSize: 15 }}>
              Resource <span style={{ fontWeight: 600 }}>{toInt(withResource)}%</span>
            </div>
            <div style={{ position: 'absolute', top: 214, right: 130, whiteSpace: 'nowrap', fontSize: 15 }}>
              Status <span style={{ fontWeight: 600 }}>{toInt(resolved)}%</span>
            </div>
          </div>
        </div>
        {/* <ChartRadial size={236} data={data} /> */}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', fontFamily: 'Raleway' }}>
          <div>
            <div style={{ fontSize: '24px', color: '#79CD67', fontWeight: 600 }}>{toInt(resolved)}%</div>
            <div
              style={{
                fontSize: '14px',
                color: '#000000',
              }}
            >
              Resolved
            </div>
          </div>
          <div>
            <div style={{ fontSize: '24px', color: '#2170F6', fontWeight: 600 }}>{toInt(inProgress)}%</div>
            <div
              style={{
                fontSize: '14px',
                color: '#000000',
              }}
            >
              In progress
            </div>
          </div>
          <div>
            <div style={{ fontSize: '24px', color: '#EA0000', fontWeight: 600 }}>{toInt(unresolved)}%</div>
            <div
              style={{
                fontSize: '14px',
                color: '#000000',
              }}
            >
              Unresolved
            </div>
          </div>
        </div>
      </OverallComplianceWrapper>
    </Card>
  );
};
