import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { Button } from '@mui/material';

import { Select, TextField } from '../../../../packages';

export const AccountFormWrapper = styled.div`
  padding: 5px 24px;
`;

export const ServiceInfo = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #535353;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const InputField = muiStyled(TextField)(() => ({
  marginBottom: 12,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const SelectField = styled(Select)``;

export const OAuthButton = muiStyled(Button)();

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OAuthButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 4px 14px 12px 14px;
`;
