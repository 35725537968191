import styled from 'styled-components';

export const ResourcesListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
`;

export const NoResources = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
`;

export const ResourcesListTableWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;

export const HelpMessageWrapper = styled.div`
  position: absolute;
  right: 35px;
  top: 30px;
`;
