import styled from 'styled-components';
import ArrowLeftCircleIcon from 'mdi-react/ArrowLeftCircleIcon';

import { NavLink } from 'react-router-dom';

import { md, scrollable, xsm } from '../../theme';

export const DiagramResultPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-top: -53px;

  @media screen and (max-width: ${md}) {
    flex-direction: column;
  }
`;

export const ComponentsCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  padding: 20px;
  width: calc(100% - 20px);
  margin-left: 20px;
  max-height: 620.5px;

  @media screen and (max-width: ${md}) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const ReportCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 0;
  width: 100%;
  margin-top: 20px;
`;

export const ReportCardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const ReportHeader = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #1a1a1a;
`;

export const SideWrapper = styled.div`
  width: 50%;
  max-width: 50%;
  height: 100%;

  @media screen and (max-width: ${md}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const SideWrapperPP = styled.div`
  height: 100%;

  @media screen and (max-width: ${md}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const DiagramViewCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  padding: 0;
  height: 620.5px;
  max-height: 620.5px;
`;

export const NoComponents = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
  padding: 50px 0;
  width: 100%;
`;

export const BackButtonLink = styled(NavLink)`
  height: 32px;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: initial;
    text-decoration: none;
  }
`;

export const DiagramResultHeader = styled.div`
  display: flex;
  padding-bottom: 16px;
  margin-top: 53px;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: ${md}) {
    margin-bottom: 20px;
  }
`;

export const BackButton = styled(ArrowLeftCircleIcon)`
  margin-right: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    fill: ${({ theme }) => theme.colors.darkBorderColor};
    transform: scale(1.2);
  }
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  word-break: break-word;
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TruncateTitle = styled.span`
  font-size: 32px;
  line-height: 48px;
  word-break: break-word;
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  font-weight: 600;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: ${xsm}) {
    margin-top: 20px;
    flex-wrap: wrap-reverse;
  }
`;

export const StatWrapper = styled.div`
  margin-left: 20px;
  width: calc(100% - 20px);

  @media screen and (max-width: ${md}) {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
`;

export const CRUDButtonsWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  height: 40px;

  @media screen and (max-width: ${md}) {
    margin-left: 0px;
  }

  @media screen and (max-width: ${xsm}) {
    margin-top: 16px;
    width: 100%;
    justify-content: flex-end;
  }
`;
