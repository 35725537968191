import styled from 'styled-components';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import BackspaceIcon from 'mdi-react/BackspaceOutlineIcon';

export const DeleteIcon = styled(TrashCanOutlineIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.warningIcon};
`;

export const BackIcon = styled(BackspaceIcon)`
  cursor: pointer;
  /* fill: ${({ theme }) => theme.colors.warningIcon}; */
`;
