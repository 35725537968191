import { createSlice } from '@reduxjs/toolkit';

export const accountDetailsinitialState = {
  isLoading: false,
  isScansLoading: false,
  isExportingScans: false,
  scans: [],
};

const STORE_NAME = '@accountDetails';

export const accountDetailsReducerNamespace = 'accountDetails';

export const accountDetailsStore = createSlice({
  name: STORE_NAME,
  initialState: accountDetailsinitialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsExportingScans: (state, action) => {
      state.isExportingScans = action.payload;
    },
    setIsScansLoading: (state, action) => {
      state.isScansLoading = action.payload;
    },
    setScans: (state, action) => {
      state.scans = action.payload;
    },
    resetState: () => accountDetailsinitialState,
  },
});

export const accountDetailsReducer = accountDetailsStore.reducer;
export const accountDetailsActions = accountDetailsStore.actions;
