import React, { useCallback, useState } from 'react';

import { Button, SecondaryButton } from '../../../../packages';

import { ButtonsWrapper, ProfileFormWrapper } from './styled';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';

const UserResendInviteForm = (props) => {
  const { emails = [], onCancel = () => {}, onSuccess = () => {} } = props;
  const [loading, setLoading] = useState(false);

  const onResend = useCallback(() => {
    setLoading(true);

    customerDetailsApi
      .teamResendInviteUsers({ emails })
      .then(() => {
        NotificationManager.success(`Users invites has been sended`);
        onSuccess(true);
      })
      .catch(() => {
        NotificationManager.error('Server error.');
        onCancel();
      });
  }, [onSuccess]);

  return (
    <ProfileFormWrapper>
      <div style={{ marginBottom: 24, fontSize: 14, fontFamily: 'Open Sans' }}>
        Do you really want to resend the invitation for{' '}
        <span style={{ fontWeight: 900, color: '#000000' }}>“{emails.join(', ')}”</span>?
      </div>
      <ButtonsWrapper>
        <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
          {'Cancel'}
        </SecondaryButton>
        <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }} onClick={onResend}>
          {loading ? 'Sending...' : 'Yes'}
        </Button>
      </ButtonsWrapper>
    </ProfileFormWrapper>
  );
};

export default UserResendInviteForm;
