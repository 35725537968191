import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import serverStatusUrl from '../../images/server-status.svg';

import {
  ContinueButtonWrapper,
  Image,
  ImageBox,
  InfoBox,
  Label,
  WelcomeLabel,
  Wrapper,
} from '../styled';
import { Button, history } from '../../../../packages';
import { OnboardingSteps, appActions } from '../../../../entities';
import { PathNames } from '../../../../consts';
import branding from '../../../../branding';

export const SelectRegistrationTypeStep = memo(() => {
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  const source = query.get('source');

  const logInWithHandler = useCallback(() => {
    history.push({
      pathname: `${PathNames.login}/?token=${token}&source=${source}`,
    });
  }, [dispatch, token]);

  const signUpWithNewEmail = useCallback(() => {
    dispatch(appActions.setOnboardingStep(OnboardingSteps.register));
  }, [dispatch, token]);

  return (
    <Wrapper>
      <InfoBox>
        <WelcomeLabel>Welcome to {branding.platform}</WelcomeLabel>
        <Label>
          Hi! You are going to add the pipeline to {branding.name}. If you already have
          {branding.name} account you can log in and add your pipeline to existing
          account. Otherwise, you can pass the onboarding flow and set up new
          account in {branding.name}.
        </Label>
        <ContinueButtonWrapper>
          <Button
            sx={{ marginRight: '20px', width: 150, minWidth: 100 }}
            variant="contained"
            onClick={logInWithHandler}
          >
            Log in
          </Button>
          <Button
            variant="contained"
            onClick={signUpWithNewEmail}
            sx={{ width: 150, minWidth: 100 }}
          >
            Sign up
          </Button>
        </ContinueButtonWrapper>
      </InfoBox>
      <ImageBox>
        <Image src={serverStatusUrl} />
      </ImageBox>
    </Wrapper>
  );
});
