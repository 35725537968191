import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';

const MenuButtonWrapperWrapper = styled.div`
  display: inline-block;
  ${({ disabled }) => `
    cursor: ${disabled ? "auto" : "pointer"};
  `}
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownMenu = (props) => {
  const { data = [{ key: 'some_unique_key_not_required', label: 'Mock', onClick: () => {}, hidden: false }], children, disabled = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <MenuButtonWrapperWrapper onClick={disabled ? () => {} : onOpenMenu} disabled={disabled}>{children}</MenuButtonWrapperWrapper>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuWrapper>
          {data
            .filter((item) => !item.hidden)
            .map((item) => (
              <MenuItem
                key={`${item.key}-${item.label}`}
                onClick={() => {
                  if (!item.disabled && item.onClick) {
                    item.onClick();
                    onCloseMenu();
                  }
                }}
              >
                {item.label}
              </MenuItem>
            ))}
        </MenuWrapper>
      </Popover>
    </>
  );
};

export default DropdownMenu;
