import { useCallback, useEffect, useMemo, useState } from 'react';

const useApiData = (fetcher, args = [], defaultData = null, keyMutate) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultData);
  const [timestamp, setTimestamp] = useState(0);

  const mutate = useCallback(() => setTimestamp(Date.now()), []);

  useEffect(() => {
    if (!fetcher) {
      return;
    }

    setLoading(true);

    fetcher(...args)
      .then((d) => {
        setData(d);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error', err);
        setLoading(false);
      });
  }, [fetcher, keyMutate, timestamp, JSON.stringify(args)]);

  const dirty = (data || data?.length) && loading;

  return useMemo(
    () => ({
      loading,
      dirty,
      data,
      mutate,
    }),
    [loading, dirty, data, mutate]
  );
};

export default useApiData;
