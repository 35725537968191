import React, { memo } from 'react';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';
import branding from '../../../branding';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';


export const ManageTeamsButton = memo(
  ({ onClick, title = 'Manage Teams', color = colors.darkBorderColor, showTitle, teams }) => (
    <Tooltip title={title}>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer'
          }}
            onClick={onClick}
          >
            <div style={{
              fontSize: 13,
              fontWeight: 600,
              color: color,
              marginRight: 8,
              marginLeft: 8,
            }}>
              {teams.length}
            </div>
            <AccountMultipleIcon color={color} />
          </div>

          {!!showTitle && title}
        </div>
    </Tooltip>
  )
);
