import { wretch } from '../app';
import { downloadFile } from '../../packages';

const getCustomers = (query) => wretch('customers/').query(query).get().json();

const getCustomersPipeline = (query) =>
  wretch('customers/pipeline/').query(query).get().json();

const getCustomerById = (id) => wretch(`customers/${id}/`).get().json();

const getCustomerByIdPipeline = (id) => wretch(`customers/${id}/pipeline`).get().json();

const createCustomer = (requestModel) =>
  wretch('customers/').post(requestModel).json();

const updateCustomer = (id, requestModel) =>
  wretch(`customers/${id}/`).put(requestModel).json();

const disableCustomer = (id) => wretch(`customers/${id}/disable/`).post().res();

const enableCustomer = (id) => wretch(`customers/${id}/enable/`).post().res();

const scanCustomers = (requestModel) =>
  wretch('customers/start_scan/').post(requestModel).res();

const scanCustomersPipeline = (requestModel) =>
  wretch('customers/start_scan/pipeline/').post(requestModel).res();

const getAccountTypes = () => wretch('customers/account-types/').get().json();

const getPipelineAccountTypes = () =>
  wretch('customers/account-types/pipeline/').get().json();

const exportAccounts = ({ id, query }) =>
  downloadFile(`customers/${id}/accounts/export/`, query);

const getResourcesStat = ({ id }) =>
  wretch(`customers/${id}/resources_stats/`).get().json();

const getResourcesStatPipeline = ({ id }) =>
  wretch(`customers/${id}/resources_stats/pipeline/`).get().json();

const addAccount = (requestModel) =>
  wretch('customers/add_account/').post(requestModel).json();

const addGithubPipelineAccount = (requestModel) =>
  wretch('customers/add_github_account/').post(requestModel).json();

export const customerApi = {
  getCustomers,
  getCustomerById,
  getCustomerByIdPipeline,
  getCustomersPipeline,
  scanCustomers,
  createCustomer,
  updateCustomer,
  enableCustomer,
  disableCustomer,
  getAccountTypes,
  getPipelineAccountTypes,
  exportAccounts,
  getResourcesStat,
  getResourcesStatPipeline,
  addAccount,
  scanCustomersPipeline,
  addGithubPipelineAccount,
};
