import React from 'react';

const IconStatusBlock = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.4735 5.3186C3.44714 6.60166 2.83341 8.22916 2.83341 10C2.83341 14.1421 6.19128 17.5 10.3334 17.5C12.1043 17.5 13.7318 16.8863 15.0148 15.8599L4.4735 5.3186ZM5.65201 4.14009L16.1933 14.6814C17.2197 13.3984 17.8334 11.7709 17.8334 10C17.8334 5.85787 14.4755 2.50001 10.3334 2.50001C8.56257 2.50001 6.93507 3.11374 5.65201 4.14009ZM1.16675 10C1.16675 4.9374 5.2708 0.833344 10.3334 0.833344C15.396 0.833344 19.5001 4.9374 19.5001 10C19.5001 15.0626 15.396 19.1667 10.3334 19.1667C5.2708 19.1667 1.16675 15.0626 1.16675 10Z'
        fill='#FF3D3D'
      />
    </svg>
  );
};

export default IconStatusBlock;
