import React, { useCallback, useRef, useState } from 'react';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { TextField } from '../../components';
import { DeleteIcon } from '../../../pages/diagram-result/components/delete-selected-components-button/styled';

const Wrap = styled(FormControl)(({ theme }) => ({
  backgroundColor: 'hsl(0, 0%, 90%)',
  borderRadius: '4px',
  overflow: 'hidden',
}));

const PureInputLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 1rem;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const ListItem = styled(MenuItem)(({ theme }) => ({
  height: 40,
  padding: '0 6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between !important',
}));

export const TodoList = (props) => {
  const { name, label, value = [], error, helperText, renderValue, onChange: onChangeHandler, ...rest } = props;
  const refList = useRef(null);
  const onChangeField = useCallback(
    (newList) => {
      onChangeHandler({ target: { name, value: newList } });
    },
    [name]
  );
  const [current, setCurrent] = useState('');
  const [editIdx, setEditIdx] = useState(null);

  const onChange = useCallback((e) => {
    setCurrent(e.target.value);
  }, []);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
      }

      if (e.key === 'Escape') {
        setEditIdx(null);
        setCurrent('');
        e.stopPropagation();
        e.preventDefault();
      }

      if (e.key === 'Enter' && !!current) {
        if (editIdx === null) {
          const newList = [...value, { text: current }];
          onChangeField(newList);
        } else {
          const newList = [...value];
          newList[editIdx].text = current;
          onChangeField(newList);
        }
        setCurrent('');
        setEditIdx(null);
      }
    },
    [name, value, current, onChangeHandler]
  );

  const inputFocus = useCallback(() => {
    setTimeout(() => refList.current.parentNode.querySelector('input').focus(), 0);
  }, []);

  const onEdit = useCallback(
    (idx, text) => {
      setEditIdx(idx);
      setCurrent(text);
      inputFocus();
    },
    [value]
  );

  const onDelete = useCallback(
    (idx) => {
      console.log(4444, idx, value);
      setEditIdx(null);
      setCurrent('');
      const newList = [...value];
      newList.splice(idx, 1);
      onChangeField(newList);
    },
    [value]
  );

  const stopEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <FormControl error={error} style={{ margin: '0 0 16px 0' }}>
      {!!label && <PureInputLabel>{label}</PureInputLabel>}
      <TextField
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={current}
        placeholder='Enter the text and press the key "Enter" or double click on an existing item to edit it.'
        style={{ backgroundColor: 'white' }}
      />
      <Wrap ref={refList}>
        {(value || []).map((item, idx) => (
          <ListItem key={`${item.id}-${item.text}`} onDoubleClick={() => onEdit(idx, item.text)}>
            <span>{item.text}</span>
            <span onClick={stopEvent} onDoubleClick={stopEvent}>
              <DeleteIcon onClick={() => onDelete(idx)} />
            </span>
          </ListItem>
        ))}
      </Wrap>
      <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  );
};
