import React, { memo } from 'react';

import { Link, Tooltip } from '@mui/material';

import { NavLink } from 'react-router-dom';

import { PathNames } from '../../../../consts';

import { CustomerNameWrapper } from './styled';
import { getCurrentFeaturePrefix } from '../../../../packages';

export const CustomerName = memo(({ value, id }) => (
  <CustomerNameWrapper>
    <Tooltip title={value} arrow>
      <Link
        component={NavLink}
        to={PathNames.customerResults
          .replace(':feature', getCurrentFeaturePrefix())
          .replace(':id', id)}
        underline="hover"
      >
        {value}
      </Link>
    </Tooltip>
  </CustomerNameWrapper>
));
