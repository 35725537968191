import React from 'react';
import branding from '../../branding';

const IconCheck = (props) => {
  const { style } = props;

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' style={style}>
      <path
        d='M14.7251 5.23151C14.6457 5.15815 14.5513 5.09993 14.4472 5.06019C14.3432 5.02046 14.2316 5 14.1189 5C14.0062 5 13.8946 5.02046 13.7905 5.06019C13.6865 5.09993 13.592 5.15815 13.5126 5.23151L7.15139 11.0701L4.47882 8.61259C4.3964 8.53961 4.29911 8.48223 4.1925 8.44372C4.08589 8.40521 3.97205 8.38633 3.85748 8.38814C3.74291 8.38996 3.62985 8.41244 3.52476 8.45431C3.41967 8.49618 3.3246 8.55661 3.24499 8.63215C3.16538 8.7077 3.10278 8.79687 3.06077 8.89459C3.01875 8.99231 2.99815 9.09666 3.00013 9.20167C3.00211 9.30669 3.02664 9.41032 3.07232 9.50665C3.11799 9.60298 3.18392 9.69012 3.26634 9.76309L6.54515 12.7685C6.62453 12.8418 6.71897 12.9001 6.82302 12.9398C6.92707 12.9795 7.03867 13 7.15139 13C7.26411 13 7.37572 12.9795 7.47977 12.9398C7.58382 12.9001 7.67826 12.8418 7.75763 12.7685L14.7251 6.38201C14.8118 6.30872 14.881 6.21977 14.9283 6.12077C14.9756 6.02176 15 5.91485 15 5.80676C15 5.69867 14.9756 5.59176 14.9283 5.49275C14.881 5.39375 14.8118 5.3048 14.7251 5.23151Z'
        fill={branding.colors.primary}
      />
    </svg>
  );
};

export default IconCheck;
