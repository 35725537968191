import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { CircularProgress, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { PathNames } from '../../consts';

import { LoaderWrapper } from '../../app';
import { PageLayout, getAccountTabs } from '../../layouts';

import {
  customerDetailsSelectors,
  policyGroupSelectors,
  policyGroupService,
  standardSelectors,
} from '../../entities';

import { InfoCard } from '../../packages';

import { OverviewContent } from './styled';
import { PoliciesCard } from './components';

export const PolicyGroupOverviewPage = () => {
  const dispatch = useDispatch();
  const { id, accountId, standardId, policyGroupId, scanId, ppToolToken, ppFeature } =
    useParams();
  const { isLoading } = useSelector(policyGroupSelectors.getPolicyGroupData);
  const currentPolicyGroup = useSelector((state) =>
    standardSelectors.getPolicyGroupById(state, policyGroupId)
  );
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );

  useEffect(() => {
    dispatch(
      policyGroupService.init({
        id,
        accountId,
        standardId,
        policyGroupId,
        scanId,
        ppToolToken,
        ppFeature,
      })
    );
  }, [scanId, accountId, standardId, policyGroupId, ppToolToken, ppFeature]);

  if (isLoading) {
    return (
      <PageLayout tabs={getAccountTabs(selectedAccount)}>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout tabs={getAccountTabs(selectedAccount)}>
      <OverviewContent>
        {ppToolToken !== undefined ? (
          <Link
            component={NavLink}
            to={PathNames.ppStandardOverview
              .replace(':ppToolToken', ppToolToken)
              .replace(':ppFeature', ppFeature)
              .replace(':standardId', standardId)}
          >
            &lt; BACK
          </Link>
        ) : null}
        {!!currentPolicyGroup && (
          <InfoCard
            name={currentPolicyGroup.name}
            descr={currentPolicyGroup.descr}
          />
        )}
        <PoliciesCard />
      </OverviewContent>
    </PageLayout>
  );
};
