import { useEffect, useState } from 'react';

const useIntersection = (
  el,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  }
) => {
  const [intersection, setIntersection] = useState(null);

  useEffect(() => {
    if (el && typeof IntersectionObserver === 'function') {
      const handler = function (entries) {
        setIntersection(entries[0]);
      };

      const observer = new IntersectionObserver(handler, options);

      observer.observe(el);

      return function () {
        setIntersection(null);
        observer.disconnect();
      };
    }
    return function () {};
  }, [el, JSON.stringify(options)]);

  return intersection;
};

export default useIntersection;
