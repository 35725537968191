import React, { memo } from 'react';

import { IconButton } from '@mui/material';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';

import { ProgressButton } from './styled';

export const ProgressIcon = memo(
  ({ title = 'Scanning in progress', color = colors.inProgress, disabled }) => {
    if (disabled) {
      return null;
    }

    return (
      <Tooltip title={title}>
        <IconButton>
          <ProgressButton color={color} />
        </IconButton>
      </Tooltip>
    );
  }
);
