import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import serverStatusUrl from '../onboarding/images/server-status.svg';

import { Button, history } from '../../packages';
import { PathNames } from '../../consts';
import { RegistrationLayout } from '../../layouts';

import { WelcomeTxt } from './WelcomeTxt';

import {
  ContinueButtonWrapper,
  Image,
  ImageBox,
  InfoBox,
  Wrapper,
} from './styled';

export const Welcome = memo(() => {
  const dispatch = useDispatch();

  const logInWithHandler = useCallback(() => {
    history.push({ pathname: `${PathNames.login}` });
  }, [dispatch]);

  const signUpWithNewEmail = useCallback(() => {
    history.push({ pathname: `${PathNames.signUp}` });
  }, [dispatch]);

  return (
    <RegistrationLayout>
      <Wrapper>
        <InfoBox>
          <WelcomeTxt />
          <ContinueButtonWrapper>
            <Button
              sx={{ marginRight: '20px', width: 150, minWidth: 100 }}
              variant="contained"
              onClick={logInWithHandler}
            >
              Log in
            </Button>
            <Button
              variant="contained"
              onClick={signUpWithNewEmail}
              sx={{ width: 150, minWidth: 100 }}
            >
              Sign up
            </Button>
          </ContinueButtonWrapper>
        </InfoBox>
        <ImageBox>
          <Image src={serverStatusUrl} />
        </ImageBox>
      </Wrapper>
    </RegistrationLayout>
  );
});
