import React, { useCallback, useMemo } from 'react';
import { NotificationManager } from 'react-notifications';

import { threatModelingApi } from '../../../../../entities';
import { capitalizeFirstLetter } from '../../../../../packages/utils/string';
import IconPriorityLow from '../../../../../packages/icons/IconPriorityLow';
import IconPriorityMedium from '../../../../../packages/icons/IconPriorityMedium';
import IconPriorityHigh from '../../../../../packages/icons/IconPriorityHigh';
import IconUnresolved from '../../../../../packages/icons/IconUnresolved';
import DropdownMenu from '../../../../../packages/components/dropdown-menu';

const ReportPriority = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  UNDEFINED: 'undefined',
};

const PriorityStyle = {
  [ReportPriority.LOW]: {
    color: '#79cd6719',
    icon: <IconPriorityLow />,
  },
  [ReportPriority.MEDIUM]: {
    color: '#f1914019',
    icon: <IconPriorityMedium />,
  },
  [ReportPriority.HIGH]: {
    color: '#ea000019',
    icon: <IconPriorityHigh />,
  },
  [ReportPriority.UNDEFINED]: {
    color: '#8989897d',
    icon: <IconUnresolved />,
  },
};

export const ComponentPriority = (props) => {
  const { value } = props;

  const data = PriorityStyle[value] || PriorityStyle[ReportPriority.UNDEFINED];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        minWidth: 136,
        height: 28,
        borderRadius: 4,
        fontWeight: 600,
        background: data.color,
      }}
    >
      {data.icon}
      <span style={{ marginLeft: 4 }}>{capitalizeFirstLetter(value) || 'Undefined'}</span>
    </div>
  );
};

const MenuPriorityLabel = (props) => {
  const { value } = props;

  const icon = PriorityStyle[value]?.icon;

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 28, minWidth: 112 }}>
      {icon}
      <span style={{ marginLeft: 8, fontWeight: 600, color: '#000' }}>{capitalizeFirstLetter(value)}</span>
    </div>
  );
};

const Priorities = [ReportPriority.HIGH, ReportPriority.MEDIUM, ReportPriority.LOW];

export const MenuReportPriority = (props) => {
  const { children, reportId, disabled, current, onSuccess } = props;

  const updatePriority = useCallback(
    (value) => {
      threatModelingApi
        .patchReport(reportId, { priority: value })
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          NotificationManager.error('Server error');
        });
    },
    [reportId, onSuccess]
  );

  const data = useMemo(
    () =>
      Priorities.filter((i) => i !== current).map((i) => ({
        key: i,
        label: <MenuPriorityLabel value={i} />,
        onClick: () => (disabled ? () => {} : updatePriority(i)),
      })),
    [current, updatePriority]
  );

  return <DropdownMenu data={data} disabled={disabled}>{children}</DropdownMenu>;
};
