import React, { memo, useCallback, useEffect } from 'react';

import { Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  SecondaryButton,
  modalActions,
  modalSelectors,
} from '../../../../packages';

import {
  threatModelingSelectors,
  threatModelingService,
} from '../../../../entities';

import { ButtonsWrapper, StartScanContent, Title } from './styled';
import { deleteDiagramModal } from './consts';

export const DeleteDiagramModal = memo(() => {
  const dispatch = useDispatch();
  const diagramsForDelete = useSelector((state) =>
    modalSelectors.getModalData(state, deleteDiagramModal)
  );
  const { companyValue } = useSelector(
    threatModelingSelectors.getThreatModelingData
  );

  const onContinueClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        threatModelingService.deleteDiagrams({
          ids: diagramsForDelete,
          company: companyValue?.value,
        })
      );
      dispatch(
        modalActions.setModalIsOpen({
          name: deleteDiagramModal,
          isOpen: false,
        })
      );
    },
    [diagramsForDelete]
  );

  const onCancelClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalIsOpen({
          name: deleteDiagramModal,
          isOpen: false,
        })
      );
    },
    [diagramsForDelete]
  );

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: deleteDiagramModal,
          data: null,
        })
      );
    },
    []
  );

  return (
    <Modal name={deleteDiagramModal} header="Delete diagrams">
      <StartScanContent>
        <Title>
          {`You selected ${diagramsForDelete?.length} ${
            diagramsForDelete?.length > 1 ? 'diagrams' : 'diagram'
          }. By continuing you are deleting selected ${
            diagramsForDelete?.length > 1 ? 'diagrams' : 'diagram'
          }.`}
        </Title>
        <ButtonsWrapper component="button">
          <Button size="medium" onClick={onCancelClick}>
            Cancel
          </Button>
          <SecondaryButton size="medium" onClick={onContinueClickHandler}>
            Delete
          </SecondaryButton>
        </ButtonsWrapper>
      </StartScanContent>
    </Modal>
  );
});
