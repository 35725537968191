import React, { memo } from 'react';

import { InfoFullBox, Label, WelcomeLabel, Wrapper } from './styled';

import { RegistrationLayout } from '../../layouts';

import { ChooseFeatureForm } from './choose-feature-form';

export const ChooseFeature = memo(() => (
  <RegistrationLayout>
    <Wrapper>
      <InfoFullBox>
        <WelcomeLabel>Choose features</WelcomeLabel>
        <Label>Please select the lifecycle stages to get started</Label>
        <ChooseFeatureForm />
      </InfoFullBox>
    </Wrapper>
  </RegistrationLayout>
));
