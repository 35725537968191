import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  isRegisterLoading: false,
  isCodeSubmitting: false,
  registrationEmail: '',
  registrationData: null,
};

const STORE_NAME = '@auth';

export const authStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsRegisterLoading: (state, action) => {
      state.isRegisterLoading = action.payload;
    },
    setIsCodeSubmitting: (state, action) => {
      state.isCodeSubmitting = action.payload;
    },
    setRegistrationEmail: (state, action) => {
      state.registrationEmail = action.payload;
    },
    setRegistrationData: (state, action) => {
      state.registrationData = action.payload;
    },
    resetState: () => initialState,
  },
});

export const authReducer = authStore.reducer;
export const authActions = authStore.actions;
