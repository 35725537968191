import React, { memo, useCallback } from 'react';

import { ManageTeamsButton } from '../../../../packages';
import { useCustomerAccountsContext } from '../customer-accounts/CustomerAccounts';

export const ManageAccountTeamsButton = memo(({ accountId, teams, onSuccess: onSuccessOut }) => {
  const { onOpenManageTeams, onCloseManageTeams } = useCustomerAccountsContext();

  const onSuccess = useCallback(() => {
    onSuccessOut();
    onCloseManageTeams();
  }, [onSuccessOut, onCloseManageTeams]);

  return <ManageTeamsButton onClick={() => onOpenManageTeams({ accountId, teams, onSuccess })} teams={teams} />
});
