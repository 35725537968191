import React, { useEffect, useState } from 'react';

export const Timer = ({ initialSeconds = 0, onExpireAction }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
        if(onExpireAction) onExpireAction()
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (seconds === 0) return '0';

  return seconds < 10 ? `0${seconds}` : seconds;
};
