import { Form as FormikForm } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Loader } from '../../../packages';
import { appApi, customerDetailsApi } from '../../../entities';
import { ContinueButtonWrapper } from '../../onboarding/steps/styled';
import { AccountFormWrapper, InputField } from './styled';
import { registerFormValidationSchema } from './validate';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../packages/utils/parse';
import { useHistory } from 'react-router';
import { PathNames } from '../../../consts';
import branding from '../../../branding';

const FormWrapper = styled(FormikForm)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '&>*': {
    flex: 0.5,
    flexBasis: '47.6%',
  },
  '&>*:nth-child(2n+1)': {
    marginRight: '24px !important',
  },
}));

export const FormInviteToTeam = (props) => {
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const history = useHistory();

  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');

  const initialValues = {
    first_name: '',
    last_name: '',
    password: '',
    password2: '',
  };

  useEffect(() => {
    customerDetailsApi
      .inviteUserAccept({ code: `${code}` })
      .then((response) => {
        /*
        {
            "id": 8,
            company: {
                "id": 110,
                "name": "Yurii"
            },
            "member": {
                "id": 112,
                "username": null,
                "first_name": null,
                "last_name": null,
                "email": "adasdasd@ssfdsd.asd",
                "is_owner": false,
                "status": "active",
                "avatar": null,
                "teams_count": 4
            },
            "email": "adasdasd@ssfdsd.asd",
            "code": "goHFIVdnRR3kg2kFqZK12guTkaXXuEe6BsaRNX4SLLjrdNNU9MvOPiy8l2ANb072",
            "status": "accepted",
            "created_at": "2023-05-29T13:13:48.842229Z",
            "updated_at": "2023-05-30T11:42:16.821821Z"
        }
        */

        if (!response.email) {
          return;
        }

        const existUser = !!response?.member?.first_name;

        if (existUser) {
          // Checking active session
          appApi
            .getUser()
            .then(() => {
              history.replace(`${PathNames.home}`);
            })
            .catch(() => {
              history.replace(`${PathNames.login}?code=${code}`);
            });

          NotificationManager.success('You have been successfully invited');
        } else {
          setCompany(response?.company?.name);
          setEmail(response.email);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(true);

        const dataErr = parseError(err);

        if (dataErr?.code?.[0] === 'Invalid code') {
          NotificationManager.error('Invalid or outdated link');
        } else {
          NotificationManager.error('Server error');
        }

        history.replace(PathNames.welcome);
      });
  }, [code, history]);

  const onSubmit = useCallback(
    (values, helpers) => {
      customerDetailsApi
        .inviteUserComplete({
          code: `${code}`,
          first_name: values.first_name,
          last_name: values.last_name,
          password: values.password,
        })
        .then((response) => {
          NotificationManager.success('You have been successfully invited');
          history.replace(`${PathNames.login}?email=${encodeURIComponent(email)}`);
        })
        .catch(() => {
          NotificationManager.error('Server error');
        });
    },
    [history, code, email]
  );

  if (loading) {
    return (
      <AccountFormWrapper>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
        </div>
      </AccountFormWrapper>
    );
  }

  return (
    <AccountFormWrapper>
      <div style={{ fontFamily: 'Raleway', lineHeight: '24px' }}>
        You are invited to the company <span style={{ fontWeight: 700 }}>"{company}"</span>. Fill in your personal
        details and create a password to sign up.
      </div>
      <div
        style={{
          fontFamily: 'Raleway',
          color: branding.colors.primary,
          border: '1px solid #BFBFBF',
          borderRadius: '8px',
          padding: '16px',
          margin: '24px 0',
        }}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: '12px',
          }}
        >
          Your email
        </div>
        <div
          style={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {email}
        </div>
      </div>
      <Form
        validationSchema={registerFormValidationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        customFormWrapper={FormWrapper}
      >
        <InputField name='first_name' label='First name' />
        <InputField name='last_name' label='Last name' autocomplete='off' />
        <InputField name='password' label='Password' type='password' autocomplete='off' />
        <InputField name='password2' label='Confirm Password' type='password' />
        <ContinueButtonWrapper component='button'>
          <Button type='submit' sx={{ width: 150, minWidth: 100 }}>
            Sign Up
          </Button>
        </ContinueButtonWrapper>
      </Form>
    </AccountFormWrapper>
  );
};
