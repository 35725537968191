import React from 'react';
import styled from 'styled-components';

import { useURLQueryParam } from '../../../../../packages/utils/router';

const CardMostUsed = (props) => {
  const { param, name, count, percent, unit = '' } = props;

  const { value: active } = useURLQueryParam(param);

  return (
    <CardWrapper>
      <div
        style={{ fontSize: 16, lineHeight: '24px', textOverflow: 'ellipsis', maxHeight: 70, overflow: 'hidden' }}
        title={name}
      >
        {name}
      </div>
      <div style={{ fontSize: 24, lineHeight: '24px', textAlign: 'right' }}>
        {count}
        {!!percent && (
          <span style={{ marginLeft: 5, fontSize: 16 }}>
            ({percent} {unit})
          </span>
        )}
        {!percent && unit}
      </div>
    </CardWrapper>
  );
};

export default CardMostUsed;

export const CardWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 196px;
  width: 196px;
  height: 149px;
  margin-right: 15px;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(6, 23, 74, 0.15);
  transition: 0.2s;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid rgba(33, 112, 246, 0.5);
  }
`;
