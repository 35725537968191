import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { appSelectors, customerDetailsApi } from '../../../../entities';

import { CustomerAccountsWrapper, TableWrapper } from '../../../accounts/components/customer-accounts/styled';
import TableNew from '../../../../packages/components/table-new';
import { ModalInline, useModalInline } from '../../../../packages/components/modal/ModalInline';
import TeamsMemberList from '../teams-member';
import headers from './headers';
import { formatData } from './formatData';
import useApiData from '../../../../hooks/useApiData';
import branding from '../../../../branding';

export const TableUsers = (props) => {
  const { keyUpdate, onOpenAddToTeam, onOpenDelete, onOpenBlock, onOpenResend, onSelect: onSelectOutside, rolesVisible, rolesEditable } = props;
  const [apiQuery, setApiQuery] = useState({});
  const { selectedCustomerId } = useSelector(appSelectors.getAppData);
  const { isOpen, onClose, onOpen, modalProps } = useModalInline();

  const onChangeHandler = useCallback(
    (query) => {
      setApiQuery(query);
    },
    [selectedCustomerId]
  );

  const { data, dirty, loading, mutate } = useApiData(customerDetailsApi.getUsers, [apiQuery, keyUpdate], {
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  let rolesData = [];
  if (rolesVisible) {
    rolesData = useApiData(customerDetailsApi.getRoles, [apiQuery], {
      count: 0,
      next: null,
      previous: null,
      results: [],
    }).data.results;
  }

  const profile = useSelector(appSelectors.getProfile);
  const currentUserId = profile.member_id;

  const preparedData = useMemo(
    () => ({
      ...data,
      results: formatData(data.results, {
        onOpenTeamsMember: onOpen,
        onOpenAddToTeam,
        onOpenDelete,
        onOpenBlock,
        onOpenResend,
        roles: rolesData,
        rolesEditable,
        onRoleUpdate: mutate,
        currentUserId,
      }),
    }),
    [data]
  );

  const onSelect = useCallback(
    (ids = []) => {
      const selected = [];

      ids.forEach((id) => {
        const user = data.results.find((i) => i.id === id);
        if (user) {
          selected.push({ id: user.id, email: user.email });
        }
      });

      onSelectOutside(selected);
    },
    [onSelectOutside, data]
  );

  return (
    <CustomerAccountsWrapper>
      <TableWrapper>
        <TableNew
          onPageChange={onChangeHandler}
          onSort={onChangeHandler}
          count={preparedData.count}
          rows={preparedData.results}
          headCells={headers}
          onSelect={onSelect}
        />
      </TableWrapper>
      <ModalInline
        isOpen={isOpen}
        header={
          <div>
            <div
              style={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '28px',
                color: '#000000',
              }}
            >
              {modalProps?.full_name || modalProps?.email}’s teams
            </div>
            <div style={{ color: branding.colors.primary, fontSize: 13 }}>{modalProps.email}</div>
          </div>
        }
        onClose={onClose}
      >
        <TeamsMemberList onCancel={onClose} onSuccess={onClose} data={modalProps} />
      </ModalInline>
    </CustomerAccountsWrapper>
  );
};
