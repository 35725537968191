import React, { memo } from 'react';
import { ChartContext } from './initChart';

const ChartProgress = memo((props) => {
  const { statusProgress = [] } = props;

  const maxTotal = Math.max(
    statusProgress.map((i) => i.statuses.in_progress + i.statuses.resolved + +i.statuses.unresolved)
  );

  const status = statusProgress.map((i) => {
    const total = i.statuses.in_progress + i.statuses.resolved + +i.statuses.unresolved;

    const percents = {
      resolved: i.statuses.resolved + i.statuses.in_progress + i.statuses.unresolved,
      inProgress: i.statuses.in_progress + i.statuses.unresolved,
      unresolved: i.statuses.unresolved,
    };

    return { ...i, total, percents };
  });

  const barThickness = 12;

  const data = {
    datasets: [
      {
        type: 'bar',
        label: 'Resolved',
        data: status.map((i) => i.percents.resolved),
        backgroundColor: '#79CD67',
        grouped: false,
        barThickness,
        order: 3,
      },
      {
        type: 'bar',
        label: 'In progress',
        data: status.map((i) => i.percents.inProgress),
        backgroundColor: '#2170F6',
        grouped: false,
        barThickness,
        order: 2,
      },
      {
        type: 'bar',
        label: 'Unresolved',
        data: status.map((i) => i.percents.unresolved),
        backgroundColor: '#D32F2F',
        grouped: false,
        barThickness,
        order: 1,
      },
      {
        type: 'line',
        label: 'Unresolved',
        display: false,
        data: status.map((i) => i.percents.unresolved),
        borderColor: '#FFA3A3',
        backgroundColor: '#d32f2f52',
        tension: 0.3,
        fill: true,
        order: 4,
      },
    ],
    labels: status.map((i) => new Date(i.date).toLocaleDateString()),
  };

  const config = {
    type: 'scatter',
    data,
    options: {
      scales: {
        y: {
          min: 0,
          max: maxTotal,
          beginAtZero: true,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const index = context.dataIndex;
              try {
                const type = context.dataset.label;
                let value = context.raw;
                if (type === 'Resolved') {
                  const d = statusProgress[index];
                  value = value - d.statuses.in_progress - d.statuses.unresolved;
                }
                if (type === 'In progress') {
                  const d = statusProgress[index];
                  value = value - d.statuses.unresolved;
                }

                return `${value} ${context.dataset.label}`;
              } catch (err) {
                console.error('Undefined label', context, err);
                return context.formattedValue;
              }
            },
          },
        },
      },
    },
  };

  return (
    <ChartContext
      // TODO key is required!
      key={Date.now()}
      name='progress'
      config={config}
      style={{ width: '100%', marginLeft: 30 }}
    />
  );
});

export default ChartProgress;
