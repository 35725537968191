import * as yup from 'yup';

export const createCustomerValidationSchema = yup.object({
  name: yup
    .string('Enter customer name')
    .required('Name is required')
    .max(256, 'Name should be of maximum 256 characters length'),
  description: yup
    .string('Enter customer description')
    .max(500, 'Description should be of maximum 500 characters length')
    .required('Description is required'),
});
