import {DeleteDiagramButton, FileName} from './components';
import {AccountName} from '../accounts/components/account-name';
import { Button } from '../../packages';

export const getDiagramsHeaders = (diagramsActions = {}) => {
  const diagramsHeaders = [
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Import Date',
      align: 'left',
      sort: 'created_at',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'File name',
      align: 'left',
      sort: 'filename',
    },

    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Source type',
      align: 'left',
      sort: 'type',
    },
    {
      id: 'stage',
      numeric: false,
      disablePadding: false,
      label: 'Stage',
      align: 'left',
      sort: 'stage',
    },
    {
      id: 'account',
      numeric: false,
      disablePadding: false,
      label: 'Account',
      align: 'left',
      sort: 'account_name',
    },
    {
      id: 'components_count',
      numeric: false,
      disablePadding: false,
      label: 'Components',
      align: 'right',
      sort: 'components_count',
    },
  ];

  if (diagramsActions?.company?.['delete']?.allowed) {
    diagramsHeaders.push({
      id: 'delete',
      numeric: true,
      disablePadding: false,
      align: 'center',
      label: '',
    });
  }

  return diagramsHeaders;
};

export const getDiagramsHeadersPlus = (diagramsActions = {}) => {
  const diagramsHeadersPlus = [...getDiagramsHeaders(diagramsActions).slice(0, -2), ...[{
    id: 'company',
    numeric: false,
    disablePadding: false,
    label: 'Company',
    align: 'left',
    sort: 'company_name',
  }], ...getDiagramsHeaders(diagramsActions).slice(-2)]

  return diagramsHeadersPlus;
};

export const formatDiagramsData = (diagrams, diagramsActions = {}, startDiagramParse) =>
  diagrams?.map((diagram) => {
      const {
        filename, created_at, components_count, stage, stage_verbose, id, type_verbose,
        account_name, account_customer_id, account, company_name, is_manual, to_delete
      } = diagram;

      const data = {
        created_at: {
          value: created_at
            ? `${new Date(created_at).toLocaleDateString()} ${new Date(
              created_at
            ).toLocaleTimeString()}`
            : '-',
          align: 'left',
        },
        name: { value: filename, align: 'left', component: () => <FileName id={id} value={filename} stage={stage} isManual={is_manual} /> },
        type: { value: is_manual ? "Manual" : type_verbose, align: 'left' },
        stage: {
            value: stage_verbose,
            align: 'left',
            component: () => !is_manual && ['new'].includes(stage) 
              ? <Button onClick={() => startDiagramParse(id)}>Parse</Button>
              : <>{stage_verbose}</>
          },
        account: account ?
          {
            value: account_name, accountId: account, customerId: account_customer_id,
            align: 'left', component: AccountName
          } : {
            value: '-', align: 'left'
          },
        company: { value: company_name, align: 'left' },
        components_count: { value: components_count || 'N/A', align: 'right' },
        data: { ...diagram, enable: true },
        id,
      };

      if (diagramsActions?.company?.['delete']?.allowed) {
        data.delete = {
          component: DeleteDiagramButton,
          align: 'center',
        };
      }

      if (to_delete) {
        data.delete = { value: '...', align: 'left' };
        data.name = { value: `...deleting... ${filename}`, align: 'left' };
      }

      return data;
    }
  );
