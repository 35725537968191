import React, { useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";

import { Table } from "../../../../packages";

import { NoScanHistory, ScanResultsTableWrapper, ScanResultsWrapper } from "./styled";

import { scanHistoryHeader } from "./consts";
import { formatResultsData } from "./utils";
import { platformSecurityService } from "../../../../entities/platform-security/service";
import { platformSecuritySelectors } from "../../../../entities/platform-security/selectors";

export const ResourcesTable = ({ searchValue }) => {
  const dispatch = useDispatch();
  const { accountId, scanId, ppToolToken, ppFeature } = useParams();

  const { resources, isLoading } = useSelector(platformSecuritySelectors.getResourcesData);

  const tableData = useMemo(() => formatResultsData(resources), [resources]);

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        platformSecurityService.getResources({
          scanId,
          accountId,
          ppFeature,
          ppToolToken,
          search: searchValue,
          ...query,
        })
      );
    },
    [accountId, scanId, searchValue, ppFeature, ppToolToken]
  );

  return (
    <ScanResultsWrapper>
      <ScanResultsTableWrapper>
          <Table
            name="ps-resources"
            isLoading={isLoading}
            onPageChange={onChangeHandler}
            onSort={onChangeHandler}
            rows={tableData}
            headCells={scanHistoryHeader}
            withoutCheck
          />
        </ScanResultsTableWrapper>
        {!isLoading && !tableData.length && <NoScanHistory>Resources list is empty</NoScanHistory>}
    </ScanResultsWrapper>
  );
};
