import styled from 'styled-components';

import { lg, scrollable, xsm } from '../../theme';

export const Wrapper = styled.div`
  min-height: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  background: ${({ theme, background }) =>
    background || theme.colors.secondaryBackground};

  ${scrollable}
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 80px 120px;

  @media screen and (max-width: ${lg}) {
    padding: 80px;
  }

  @media screen and (max-width: ${xsm}) {
    padding: 40px;
  }
`;

export const ProgressLine = styled.div`
  height: 8px;
  width: 100%;
  background: ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
`;

export const Progress = styled.div`
  height: 8px;
  background: ${({ theme }) => theme.colors.activeBackground};
  border-radius: 8px;
  transition: 0.3s;
  width: ${({ width }) => width};
`;
