import React, { memo, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FormControlLabel, Radio } from '@mui/material';

import { Button, Card, Checkbox } from '../../../packages';

import { appSelectors, appService } from '../../../entities';

import { ContinueButtonWrapper } from '../styled';

import {
  CardContent,
  CardDescription,
  CheckboxLabel,
  FeaturesList,
  RadiLabel,
  Wrapper,
} from './styled';

export const ChooseFeatureForm = memo(() => {
  const dispatch = useDispatch();
  const features = useSelector(appSelectors.getFeatures);
  const { user } = useSelector(appSelectors.getAppData);
  const [selectedFeatures, setSelectedFeatures] = useState(
    user.features_req || []
  );
  const [continueWith, setContinueWith] = useState(null);
  const toggleFeature = useCallback(
    (value, slug) => {
      if (selectedFeatures.includes(value)) {
        const filteredList = selectedFeatures.filter((name) => name !== value);

        setSelectedFeatures(filteredList);
      } else {
        setContinueWith(slug);
        setSelectedFeatures([...selectedFeatures, value]);
      }
    },
    [selectedFeatures, setSelectedFeatures]
  );

  const onContinue = useCallback(() => {
    dispatch(appService.setFeatures(selectedFeatures, continueWith));
  }, [selectedFeatures, continueWith]);

  return (
    <Wrapper>
      <FeaturesList>
        {features?.map(
          ({ id, name, is_active, description, is_visible, slug }) =>
            is_visible ? (
              <Card
                key={id}
                disabled={!is_active}
                style={{
                  minWidth: 280,
                  maxWidth: 400,
                  width: '100%',
                  marginBottom: 20,
                  marginRight: 20,
                }}
                headerStyle={{ padding: '0 24px' }}
                title={
                  user.features && user.features.indexOf(id) !== -1 ? (
                    <CheckboxLabel>✅ {name}</CheckboxLabel>
                  ) : (
                    <Checkbox
                      id={id}
                      label={<CheckboxLabel>{name}</CheckboxLabel>}
                      disabled={!is_active}
                      checked={
                        selectedFeatures && selectedFeatures.indexOf(id) !== -1
                      }
                      onChange={() => {
                        toggleFeature(id, slug);
                      }}
                    />
                  )
                }
                helpMessage={!is_active && 'Coming soon'}
              >
                <CardContent>
                  <CardDescription>{description}</CardDescription>
                  {selectedFeatures.includes(id) &&
                    user.features &&
                    user.features.indexOf(id) !== -1 && (
                      <FormControlLabel
                        checked={continueWith === slug}
                        disabled={!is_active}
                        control={<Radio />}
                        onChange={() => {
                          setContinueWith(slug);
                        }}
                        sx={{ marginTop: 'auto' }}
                        label={<RadiLabel>Continue with</RadiLabel>}
                      />
                    )}
                </CardContent>
              </Card>
            ) : null
        )}
      </FeaturesList>
      <ContinueButtonWrapper component="button">
        <Button
          variant="contained"
          type="submit"
          onClick={onContinue}
          disabled={false}
          sx={{ width: 150, minWidth: 100 }}
        >
          Continue
        </Button>
      </ContinueButtonWrapper>
    </Wrapper>
  );
});
