import React from 'react';
import IconMoreVertical from '../../../../packages/icons/IconMoreVertical';
import { STATUS } from './status';
import { UserMenu } from '../../../../layouts/UserMenu';
import branding from '../../../../branding';
import {ComponentRole, MenuUserRole} from './components/role';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

const ComponentStatusDefault = (props) => <div>{props.value}</div>;

export const formatData = (data, { onOpenTeamsMember, onOpenAddToTeam, onOpenDelete, onOpenBlock, onOpenResend, roles, rolesEditable, onRoleUpdate, currentUserId }) =>
  data.map((item) => {
    const { id, first_name, last_name, email, role, teams_count, status } = item;

    const ComponentStatus = STATUS[status] || ComponentStatusDefault;

    const full_name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    const roleDisabled = !rolesEditable || item.is_owner === true || item.id === currentUserId;

    return {
      first_name: {
        value: full_name,
        align: 'left',
        // component: EnableIcon
      },
      email: {
        align: 'left',
        component: () => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 4 }}>{email}</div>
            {/*<IconCopy />*/}
          </div>
        ),
      },
      role: {
        align: 'left',
        component: () => (
          <MenuUserRole user={item} roles={roles} onSuccess={onRoleUpdate} disabled={roleDisabled}>
            <ComponentRole value={role} disabled={roleDisabled}/>
          </MenuUserRole>
        ),
        styles: {
          paddingTop: '12px !important',
          verticalAlign: 'top',
        },
      },
      teams_count: {
        align: 'left',
        component: () => (
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => onOpenTeamsMember({ ...item, full_name })}
          >
            <div style={{ fontSize: 13, fontWeight: 600, color: branding.colors.primary, marginRight: 8 }}>{teams_count}</div>
            <AccountMultipleIcon />
          </div>
        ),
      },
      status: {
        component: () => (
          <div style={{ fontSize: 13, fontWeight: 600 }}>
            <ComponentStatus value={status} />
          </div>
        ),
        align: 'left',
      },
      _more: {
        align: 'right',
        component: () => (
          <UserMenu
            onOpenAddToTeam={() => onOpenAddToTeam({ userIds: [id], emails: [email] })}
            onOpenDelete={onOpenDelete}
            onOpenActivate={() => onOpenBlock({ emails: [email], activateUser: true })}
            hideActivate={status !== 'blocked'}
            onOpenBlock={() => onOpenBlock({ emails: [email] })}
            hideBlock={status === 'blocked'}
            onOpenResend={() => onOpenResend({ emails: [email] })}
            hideResend={status !== 'pending'}
          >
            <IconMoreVertical />
          </UserMenu>
        ),
      },
      data: { ...item, enable: true },
      id,
    };
  });
