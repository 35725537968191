import React from "react";

const Select = ({ label, value, options = [{ value: "-", label: "-" }], onChange, custom: Custom }) => {
  if (Custom) {
    return <Custom label={label} value={value} options={options} onChange={onChange} />;
  }

  return (
    <div>
      <div>{label}</div>
      <select onChange={onChange}>
        {options.map((i) => (
          <option key={i.value} value={i.value} selected={i.value == value}>
            {i.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
