import customIcon from './custom.png';
import noViolationsIcon from './no-violations.png';
import azureIcon from './azure.png';
import awsIcon from './aws.png';
import arrowIcon from './arrow.png';
import githubIcon from './github.png';

export const icons = {
  customIcon,
  noViolationsIcon,
  azureIcon,
  awsIcon,
  githubIcon,
  arrowIcon,
};
