import React from 'react';

const IconPriorityMedium = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.5 5.25C1.5 4.83579 1.83579 4.5 2.25 4.5H15.75C16.1642 4.5 16.5 4.83579 16.5 5.25C16.5 5.66421 16.1642 6 15.75 6H2.25C1.83579 6 1.5 5.66421 1.5 5.25ZM1.5 12.75C1.5 12.3358 1.83579 12 2.25 12H15.75C16.1642 12 16.5 12.3358 16.5 12.75C16.5 13.1642 16.1642 13.5 15.75 13.5H2.25C1.83579 13.5 1.5 13.1642 1.5 12.75Z'
        fill='#F19140'
      />
    </svg>
  );
};

export default IconPriorityMedium;
