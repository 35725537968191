import styled from 'styled-components';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { SecondaryButton } from '../../../../packages';

export const DeleteIcon = styled(TrashCanOutlineIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.warningIcon};
`;

export const DeleteButton = styled(SecondaryButton)`
  border-color: ${({ theme }) => theme.colors.warningIcon} !important;
`;
