import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { Loader } from '../../packages';

import { appSelectors, appService } from '../../entities';

import { HeaderWithoutProfile } from '../headers';

import { GlobalLoaderWrapper } from '../../app';

import { PrivacyPolicyModal } from '../onboarding-layouts/modals';

import { Content, LayoutWrapper, Wrapper } from './styled';

export const RegistrationLayout = memo(({ children, styleContent }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { isLayoutReady } = useSelector(appSelectors.getAppData);

  useEffect(() => {
    if (!isLayoutReady) {
      dispatch(appService.initAppLayout(params));
    }
  }, []);

  if (!isLayoutReady) {
    return (
      <GlobalLoaderWrapper>
        <Loader />
      </GlobalLoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderWithoutProfile />
      <LayoutWrapper>
        <Content style={styleContent}>{children}</Content>
      </LayoutWrapper>
      <PrivacyPolicyModal />
    </Wrapper>
  );
});
