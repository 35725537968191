import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  ConnectionMode,
  Controls,
  MarkerType,
  MiniMap,
  Panel,
  ReactFlowProvider,
  addEdge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useNodesState,
  useReactFlow,
} from 'reactflow';

import TextUpdaterNode from './nodes/TextUpdaterNode';
import RandomHandleNode from './nodes/RandomHandleNode';
import CustomToolbar from './nodes/CustomToolbar';
import FormNodeDetails from './details/FormNodeDetails';
import FormEdgeDetails from './details/FormEdgeDetails';
import Library from './library';

import 'reactflow/dist/style.css';
import './style.css';

import DefaultNode from './nodes/DefaultNode';
import LibraryExample from './library/example';
import { Button } from '../button';
import { ButtonStyle } from './styles';
import CustomConnectionLine from './connections/CustomConnectionLine';
import connectionLineStyle from './connections/connectionLineStyle';
import FloatingEdge from './connections/FloatingEdge';
import ConnectionLine from './connections/ConnectionLine';
import useBeforeUnloadEvent from '../../../hooks/useBeforeUnloadEvent';
import { PanelDetailsWrapper } from '../editor-modal/styled';
import { MerkerDefaultColor, MerkerDefaultSize } from './details/node-details/constants';

const defaultEdgeOptions = { animated: true };

export const createNodeByTemplate = ({
  id,
  img,
  type,
  type_verbose,
  generic_type,
  label,
  generic_type_verbose,
  x,
  y,
}) => ({
  id,
  data: {
    img,
    shape: img ? undefined : 'rect',
    _meta: {
      type,
      generic_type,
      type_verbose,
      generic_type_verbose,
    },
    label,
    style: {
      width: 70,
      height: 70,
    },
    handles: '01010101',
    labelStyle: {
      textWrap: 'nowrap',
      alignSelf: 'flex-end',
      marginBottom: -15,
    },
  },
  width: 70,
  height: 70,
  position: {
    x,
    y,
  },
});

const nodeColor = (node) => {
  switch (node.type) {
    case 'input':
      return '#6ede87';
    case 'output':
      return '#6865A5';
    default:
      return '#ff0072';
  }
};

export const EDITOR_MODE = {
  EDIT: 'EDIT',
  PREVIEW: 'PREVIEW',
};

function getDefaultConnector(edge) {
  return {
    ...edge,
    hidden: false,
    animated: false,
    type: ConnectionLineType.SmoothStep,
    markerStart: {
      // type: MarkerType.ArrowClosed,
      width: MerkerDefaultSize,
      height: MerkerDefaultSize,
      color: MerkerDefaultColor,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: MerkerDefaultSize,
      height: MerkerDefaultSize,
      color: MerkerDefaultColor,
    },
  };
}

const defaultBackground = {
  color: '#ccc',
  variant: BackgroundVariant.Lines,
  gap: 10,
};

const Editor = (props) => {
  const {
    editorStorageKey = 'editor-storage-key',
    initial = { nodes: [], edges: [], background: {} },
    mode = EDITOR_MODE.EDIT,
    getNodeId = () => `id_${+new Date()}`,
    width,
    height,
    controls,
    onElementSettings,
    onSave: onSaveOut = async () => {},
    onChangeNotice = () => {},
    changed,
    registerSavingInitialization = (cb) => {},
    registerRemoveUnsavedData = (cb) => {},
    onConfirmLoadUnsavedData = (confirm = () => {}, cancel = () => {}) => {},
    libraries,
    libraryTopElement,
    hideBasicLibrary,
  } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState(initial?.nodes || []);
  const initEdges = useMemo(() => initial?.edges || [], []);
  const [edges, setEdges] = useState(initEdges);
  const [background, setBackground] = useState(
    initial?.background ? { ...defaultBackground, ...initial.background } : { ...defaultBackground }
  );
  const setVariant = useCallback((val) => setBackground({ ...background, variant: val }), [background]);

  const [rfInstance, setRfInstance] = useState(null);
  const { setViewport } = useReactFlow();

  useEffect(() => {
    const initValue = `${initial?.background?.variant}-${initial?.background?.gap}-${initial?.background?.color}`;
    const currentValue = `${background?.variant}-${background?.gap}-${background?.color}`;

    if (currentValue !== initValue) {
      onChangeNotice();
    }
  }, [initial?.background, background, onChangeNotice]);

  const onEdgesChange = useCallback(
    (changes) => {
      let newEdges = [...edges];
      let changed = false;
      changes.forEach((e) => {
        if (e.type === 'remove') {
          changed = true;
          newEdges = newEdges.filter((i) => i.id !== e.id);
        }
      });

      if (changed) {
        setEdges(newEdges);
      }
    },
    [edges]
  );

  const onChange = useCallback(
    (args, cb = () => {}) => {
      if (args[0]?.[0]?.type === 'dimensions') {
        return;
      }
      onChangeNotice();
      cb(...args);
    },
    [onChangeNotice]
  );

  useEffect(() => {
    if (nodes.length !== initial?.nodes?.length || edges.length !== initial?.edges?.length) {
      onChangeNotice();
    }
  }, [onChangeNotice, nodes.length, edges.length, initial?.nodes?.length, initial?.edges?.length]);

  const onNodeChange = useCallback(
    (changes) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id == changes.id) {
            if (!node.data) {
              node.data = {};
            }

            if (changes.data._meta) {
              node.data._meta = changes.data._meta;
            }

            if (!node.data.style) {
              node.data.style = {};
            }

            if (!node.data.labelStyle) {
              node.data.labelStyle = {};
            }

            if (changes.data.label) {
              node.data.label = changes.data.label;
            }

            if ('isUnder' in changes.data) {
              if (changes.data.isUnder) {
                node.data.isUnder = true;
              } else {
                delete node.data.isUnder;
              }
            }

            if (changes.data.labelStyle) {
              node.data.labelStyle = { ...node.data.labelStyle, ...changes.data.labelStyle };
            }

            node.data.style = { ...node.data.style, ...(changes.data?.style || {}) };

            if (changes.data.shape) {
              node.data.shape = changes.data.shape;
              const { width, height } = changes.data.style || {};
              if (width) {
                node.data.style.width = width;
              }
              if (height) {
                node.data.style.height = height;
              }
            }
          }

          return node;
        })
      );
    },
    [setNodes]
  );

  const onEdgeChange = useCallback(
    (changes) => {
      const newEdges = [...edges];
      const idx = newEdges.findIndex((i) => i.id === changes.id);
      console.log("onEdgeChange", {edges, changes, idx});
      if (idx !== -1) {
        newEdges[idx] = {
          ...newEdges[idx],
          ...changes,
          markerStart: {
            ...(newEdges[idx]?.markerStart || {}),
            ...(changes?.markerStart || {}),
          },
          markerEnd: {
            ...(newEdges[idx]?.markerEnd || {}),
            ...(changes?.markerEnd || {}),
          },
        };
      }

      setEdges(newEdges);
    },
    [rfInstance, edges, setEdges]
  );

  const nodeTypes = useMemo(
    () => ({
      default: (nodeProps) => <DefaultNode {...nodeProps} onChange={onNodeChange} onSettings={onElementSettings} />,
      // textUpdater: (nodeProps) => <TextUpdaterNode {...nodeProps} onChange={onNodeChange} />,
      // randomHandle: RandomHandleNode,
    }),
    [onElementSettings, onNodeChange]
  );

  const edgeTypes = useMemo(
    () => ({
      floating: FloatingEdge,
    }),
    []
  );

  const onConnect = useCallback(
    (connection) => {
      console.log("onConnect", { connection });
      return setEdges((eds) => addEdge(getDefaultConnector({ ...connection }), eds));
    },
    [setEdges]
  );

  const [selectedNodeIdx, setSelectedNodeIdx] = useState(-1);
  const selectedNode = nodes[selectedNodeIdx];

  const onNodeClick = useCallback(
    (event, node) => {
      setSelectedNodeIdx(nodes.findIndex((i) => i.id == node.id));
      setSelectedEdgeIdx(-1);
    },
    [nodes]
  );

  const [selectedEdgeIdx, setSelectedEdgeIdx] = useState(-1);
  const selectedEdge = edges[selectedEdgeIdx];

  const onEdgeClick = useCallback(
    (event, edge) => {
      /*
    {
        "animated": true,
        "source": "id_1702579337714",
        "sourceHandle": "d",
        "target": "id_1702579342520",
        "targetHandle": "b",
        "id": "reactflow__edge-id_1702579337714d-id_1702579342520b",
        "selected": true
    }
    */
      const idx = edges.findIndex((i) => i.id === edge.id);
      setSelectedEdgeIdx(idx);
      setSelectedNodeIdx(-1);
    },
    [edges]
  );

  const onPaneClick = useCallback((event) => {
    setSelectedNodeIdx(-1);
    setSelectedEdgeIdx(-1);
  }, []);

  const localDiagramGetData = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      const data = { ...flow, background };
      return data;
    }
    return null;
  }, [rfInstance, background]);

  const localDiagramSave = useCallback(
    async (force = true) => {
      if (!force) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }

      if (rfInstance) {
        const data = localDiagramGetData();
        localStorage.setItem(editorStorageKey, JSON.stringify(data));
      }
    },
    [editorStorageKey, localDiagramGetData]
  );

  const localDiagramDelete = useCallback(() => {
    console.log('localDiagramDelete', editorStorageKey);
    localStorage.removeItem(editorStorageKey);
  }, [editorStorageKey]);

  const onSave = useCallback(async () => {
    const data = localDiagramGetData();
    const result = await onSaveOut(data);
    console.log('localDiagramDelete', result);
    // if result = true was saved successfully
    if (result) {
      localDiagramDelete();
      return true;
    }
  }, [rfInstance, background, localDiagramGetData, localDiagramDelete, onSaveOut]);

  // Internal logic of BeforeUnloadEvent
  const refChanged = useRef();
  refChanged.current = changed;
  const onBeforeUnload = useCallback(() => {
    localDiagramSave();
  }, [refChanged, localDiagramDelete, localDiagramSave]);
  useBeforeUnloadEvent(changed, onBeforeUnload);
  // END Internal logic of BeforeUnloadEvent

  useEffect(() => {
    registerSavingInitialization(onSave);
  }, [onSave, registerSavingInitialization]);

  useEffect(() => {
    registerRemoveUnsavedData(localDiagramDelete);
  }, [localDiagramDelete, registerRemoveUnsavedData]);

  // Restore data from the storage
  useEffect(() => {
    if (mode !== EDITOR_MODE.EDIT) {
      return;
    }

    const data = localStorage.getItem(editorStorageKey);

    if (data) {
      onConfirmLoadUnsavedData(
        () => {
          try {
            const data = localStorage.getItem(editorStorageKey);
            const flow = JSON.parse(data);

            if (flow) {
              const { x = 0, y = 0, zoom = 1 } = flow.viewport;
              setNodes(flow.nodes || []); // crash

              setEdges(flow.edges || []);
              setViewport({ x, y, zoom });
              setBackground(flow.background || { ...defaultBackground });
              onChangeNotice();
            }
          } catch (err) {
            console.log(`Error restore saved data for key <${editorStorageKey}>`, data);
          }
        },
        () => {
          localDiagramDelete();
        }
      );
    } else {
      console.log(`No saved data for key <${editorStorageKey}>`);
    }
  }, [mode, editorStorageKey]);

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const onNodesDeleteManual = useCallback(
    (deleted) => {
      onNodesDelete(deleted);
      const ids = deleted.map((i) => i.id);
      const newNodes = nodes.filter((i) => !ids.includes(i.id));
      setNodes(newNodes);
    },
    [onNodesDelete, nodes, setNodes]
  );

  const onEdgesDeleteManual = useCallback(
    (deleted) => {
      const ids = deleted.map((i) => i.id);
      const newEdges = edges.filter((e) => !ids.includes(e.id));
      setEdges(newEdges);
    },
    [edges, setEdges]
  );

  const onResize = useCallback((e) => {
    console.log('onResize', e);
  }, []);

  const refBoard = useRef(null);

  const onSelectElement = useCallback(
    (item) => {
      const { shape, handles, img, style, labelStyle, label = 'Label', isUnder, _meta } = item;
      const flow = rfInstance.toObject();

      let sumX = 0;
      let sumY = 0;
      flow.nodes.forEach((n) => {
        sumX += n.position.x;
        sumY += n.position.y;
      });

      const newNode = {
        id: getNodeId(),
        data: { label, handles, shape, img, style, labelStyle, isUnder, _meta },
        position: {
          x: !flow.nodes.length ? 0 : sumX / flow.nodes.length,
          y: !flow.nodes.length ? 0 : sumY / flow.nodes.length,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [rfInstance]
  );
console.log({nodes, edges});
  return (
    <div className='diagram-editor' style={{ width, height }}>
      {mode === EDITOR_MODE.EDIT && (
        <div className='left-panel'>
          {libraryTopElement}
          <Library
            onSelect={onSelectElement}
            data={libraries || [{ title: 'Example', data: { list: LibraryExample } }]}
            hideBasicLibrary={hideBasicLibrary}
          />
        </div>
      )}
      <div className='board' ref={refBoard}>
        <ReactFlow
          key={edges.length === 0 ? 'edges-zero' : 'edges'}
          nodes={nodes.map((i) => (i.data.isUnder ? { ...i, zIndex: -1 } : i))}
          nodeTypes={nodeTypes}
          edges={edges}
          defaultEdgeOptions={defaultEdgeOptions}
          snapGrid={[10, 10]}
          snapToGrid={true}
          onInit={setRfInstance}
          onNodesChange={mode === EDITOR_MODE.EDIT ? (...args) => onChange(args, onNodesChange) : undefined}
          onEdgesChange={mode === EDITOR_MODE.EDIT ? (...args) => onChange(args, onEdgesChange) : undefined}
          onConnect={mode === EDITOR_MODE.EDIT ? onConnect : undefined}
          onNodeClick={mode === EDITOR_MODE.EDIT ? onNodeClick : undefined}
          onEdgeClick={mode === EDITOR_MODE.EDIT ? onEdgeClick : undefined}
          onPaneClick={mode === EDITOR_MODE.EDIT ? onPaneClick : undefined}
          onNodesDelete={mode === EDITOR_MODE.EDIT ? onNodesDelete : undefined}
          // onResizeCapture={onResize}
          fitView
          // connectionLineComponent={ConnectionLine}
          // connectionLineStyle={connectionLineStyle}
          connectionMode={ConnectionMode.Loose}
          deleteKeyCode={['Backspace', 'Delete']}
        >
          <CustomToolbar data={{ toolbarVisible: true }} />
          <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
          <Controls />
          {background.variant === BackgroundVariant.Lines && (
            <Background
              id='b1'
              color={background.color || defaultBackground.color}
              variant={background.variant}
              gap={background.gap || defaultBackground.gap}
            />
          )}
          {background.variant === BackgroundVariant.Cross && (
            <Background
              id='b2'
              color={background.color || defaultBackground.color}
              variant={background.variant}
              gap={background.gap || defaultBackground.gap}
            />
          )}
          {background.variant === BackgroundVariant.Dots && (
            <Background
              id='b3'
              color={background.color || defaultBackground.color}
              variant={background.variant}
              gap={background.gap || defaultBackground.gap}
            />
          )}
          {mode === EDITOR_MODE.EDIT && (
            <Panel position='top-left'>
              <div className='panel-variants'>
                <Button onClick={() => setVariant(BackgroundVariant.Dots)} style={ButtonStyle}>
                  dots
                </Button>
                <Button onClick={() => setVariant(BackgroundVariant.Lines)} style={ButtonStyle}>
                  lines
                </Button>
                <Button onClick={() => setVariant(BackgroundVariant.Cross)} style={ButtonStyle}>
                  cross
                </Button>
              </div>
            </Panel>
          )}
          {mode === EDITOR_MODE.EDIT && (
            <Panel position='top-left'>
              <div className='panel-controls'>
                {/* <button onClick={onSave}>Save</button> */}
                {/* <button onClick={onRestore}>Restore</button> */}
              </div>
            </Panel>
          )}
        </ReactFlow>
      </div>
      {mode === EDITOR_MODE.EDIT && (
        <PanelDetailsWrapper>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Details</h2>
            {(selectedNode || selectedEdge) && (
              <Button
                onClick={() =>
                  selectedNode ? onNodesDeleteManual([selectedNode]) : onEdgesDeleteManual([selectedEdge])
                }
                style={{ ...ButtonStyle, backgroundColor: 'red' }}
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            {selectedNode && (
              <FormNodeDetails key={selectedNodeIdx} {...selectedNode} onChange={onNodeChange} controls={controls} />
            )}
            {selectedEdge && (
              <FormEdgeDetails key={selectedEdgeIdx} {...selectedEdge} onChange={onEdgeChange} controls={controls} />
            )}
          </div>
        </PanelDetailsWrapper>
      )}
    </div>
  );
};

export default (props) => (
  <ReactFlowProvider>
    <Editor {...props} />
  </ReactFlowProvider>
);
