import React from 'react';

import DropdownMenu from '../packages/components/dropdown-menu';
import Permission from '../packages/components/permissions';

export const TeamUserMenu = (props) => {
  const {
    children,
    onOpenRemoveMember,
    onOpenActivate,
    onOpenBlock,
    onOpenResend,
    hideResend = false,
    hideActivate = false,
    hideBlock = false,
  } = props;
  return (
    <Permission resource="company::users">
      {(actions) => (
        <DropdownMenu
          data={[
            {
              label: 'Re-send invite',
              onClick: onOpenResend,
              hidden: (
                hideResend || !actions.company['invite']?.allowed
              ),
            },
            {
              label: 'Activate user',
              onClick: onOpenActivate,
              hidden: (
                hideActivate || !(actions.company['block']?.allowed || actions.team['block']?.allowed)
              ),
            },
            {
              label: 'Block user',
              onClick: onOpenBlock,
              hidden: (
                hideBlock || !(actions.company['block']?.allowed || actions.team['block']?.allowed)
              ),
            },
          ]}
        >
          {children}
        </DropdownMenu>
      )}
    </Permission>
  );
};
