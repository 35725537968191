import styled from 'styled-components';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

export const ArrowIcon = styled(ChevronLeftIcon)`
  margin-right: 12px;
`;

export const BackButtonContent = styled.div`
  margin-right: 8px;
  font-family: Raleway, sans-serif;
`;
