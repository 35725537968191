import React, { memo } from 'react';
import { Chart } from 'react-charts';

import { ChartWrapper } from './styled';

export const PureChart = memo(({ chartData, styles = {}, colorPalette }) => {
  const ticksCount = Math.max(...chartData.map((line) => line.data.length)) - 1;

  const primaryAxis = React.useMemo(
    () => ({
      scaleType: 'time',
      tickCount: ticksCount,
      getValue: (datum) => new Date(datum.primary),
      formatters: {
        scale(value) {
          return new Date(value).toLocaleDateString();
        },
      },
    }),
    [ticksCount]
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    []
  );

  const getSeriesStyle = React.useCallback((series) => {
    if (colorPalette) {
      return {
        fill: colorPalette[series.label],
        stroke: colorPalette[series.label],
      };
    }

    return {};
  }, []);

  if (!chartData || chartData.every((chart) => chart.data.length < 2))
    return null;

  return (
    <ChartWrapper style={styles}>
      <Chart
        options={{
          data: chartData,
          primaryAxis,
          getSeriesStyle,
          secondaryAxes,
        }}
      />
    </ChartWrapper>
  );
});
