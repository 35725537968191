import React, { memo } from 'react';

import { colors } from '../../../../theme';

import { Step } from './Step';
import {
  ArrowRight,
  AwsButton,
  Comment,
  DisabledAwsButton,
  HighlightedText,
  Image,
  ListItem,
  ListTopic,
  ListWrapper,
  PermissionLabel,
} from './styled';

import Image1 from './imgs/AzDev-1.png';
import Image2 from './imgs/AzDev-2.png';
import Image3 from './imgs/AzDev-3.png';
import Image4 from './imgs/AzDev-4.png';
import branding from '../../../../branding';

export const AzureDevSteps = memo(() => (
  <>
  <Step title="Step 1">
    <ListWrapper>
      <ListTopic>
      Go to AzureDevOps dashboard (<a href="https://dev.azure.com/" target="_blank">https://dev.azure.com/</a>)
      </ListTopic>
      <ListTopic>
      Click <HighlightedText>User Settings</HighlightedText> at right-top corner and choice <HighlightedText>Personal Access token</HighlightedText>
      </ListTopic>
      <ListTopic>
        Set <HighlightedText>User name</HighlightedText>
      </ListTopic>
      <ListTopic>
        <Image src={Image1}/>
      </ListTopic>
    </ListWrapper>
  </Step>
  <Step title="Step 2">
    <ListWrapper>
      <ListTopic>
        At next page click <AwsButton>+ New Token</AwsButton>
      </ListTopic>
      <ListTopic>
        Fill the <HighlightedText>Name</HighlightedText>, choice  <HighlightedText>Organisation</HighlightedText> which you want to check.
      </ListTopic>
      <ListTopic>
        Set Expiration at <HighlightedText>90 days</HighlightedText> (or select <HighlightedText>Custom defined</HighlightedText> and choice more)
      </ListTopic>
      <ListTopic>
        <Image src={Image2}/>
      </ListTopic>
    </ListWrapper>
  </Step>
  <Step title="Step 3">
    <ListWrapper>
      <ListTopic>
      Set the <HighlightedText>Scopes</HighlightedText> to <HighlightedText>Custom defined</HighlightedText>
      </ListTopic>
      <Comment>we does not recommend to allow Full Access</Comment>
      <ListTopic>
      Expand the list by clicking <HighlightedText>Show all scopes</HighlightedText>
      </ListTopic>
      <ListTopic>
      And enable:
      </ListTopic>
      <ListItem><PermissionLabel>Build (Read)</PermissionLabel></ListItem>
      <ListItem><PermissionLabel>Code (Read)</PermissionLabel></ListItem>
      <ListItem><PermissionLabel>Graph (Read)</PermissionLabel></ListItem>
      <ListItem><PermissionLabel>Identity (Read)</PermissionLabel></ListItem>
      <ListItem><PermissionLabel>Member Entitlement Management (Read)</PermissionLabel></ListItem>
      <ListItem><PermissionLabel>Project and Team (Read)</PermissionLabel></ListItem>
      <ListItem><PermissionLabel>Packaging (Read)</PermissionLabel></ListItem>
      <ListTopic>
      <Image src={Image3}/>
      </ListTopic>
    </ListWrapper>
  </Step>
  <Step title="Step 4">
    <ListWrapper>
    <ListTopic>
      And click <AwsButton>Create</AwsButton>
    </ListTopic>
    <ListTopic>
      At next screen you need to copy your <HighlightedText>PAT</HighlightedText>
      <Comment>note you can only do this on this screen, when it closes you can't copy it again</Comment>
    </ListTopic>
    <ListTopic>
    Also copy the slug of your organization. The easiest way to do this is to copy it from the URL of the browser
    <Comment>note that the organization that you put to {branding.name} must be the same one that you choice when creating the PAT</Comment>
    </ListTopic>

    <ListTopic>
      <Image src={Image4}/>
    </ListTopic>
    </ListWrapper>
    </Step>
    <Step title="Step 5">
      <ListWrapper>
      <ListTopic>
        Put the received <HighlightedText>Personal Access Token</HighlightedText> and the slug of the <HighlightedText>Organization</HighlightedText> in the {branding.name} UI
      </ListTopic>
    </ListWrapper>
  </Step>
</>
));
