import styled from 'styled-components';
import branding from '../../../../branding';

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ToolsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.thirdBorderColor};
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayColorByStatus = {
  recognized: 'rgba(121, 205, 103, 0.1)',
  unrecognized: 'rgba(231, 61, 61, 0.1)',
  manual: 'rgba(5, 43, 160, 0.1)',
};

const BorderColorByStatus = {
  recognized: '#79CD67',
  unrecognized: '#EA0000',
  manual: branding.colors.deepBlue,
};

export const ComponentBorder = styled.div`
  border: 0.5px solid
    ${({ status }) =>
      BorderColorByStatus[status] ?? BorderColorByStatus.unrecognized};
  background: ${({ status }) =>
    OverlayColorByStatus[status] ?? OverlayColorByStatus.unrecognized};
  ${({ width, height, x, y }) => ({
    width,
    height,
    position: 'absolute',
    left: x,
    top: y,
  })};
`;

export const ComponentNumber = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3px;
  top: -4px;
  padding: 1px;
  left: -0.5px;
  min-width: 4px;
  height: 4px;
  color: white;
  background: ${({ status }) =>
    BorderColorByStatus[status] ?? BorderColorByStatus.unrecognized};
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBorderColor};
`;

export const HeaderText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  max-width: 700px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ZoomButton = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  color: black;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 24px;
`;

export const CurrentZoomValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 12px;
  margin-right: 12px;
`;
