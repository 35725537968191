import { useCallback, useEffect, useRef, useState } from 'react';

const useDataFullList = (request, stop = false, keyUpdate) => {
  const fast = useRef([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPage = useCallback(
    (page = 1) => {
      setLoading(true);
      request({ page })
        .then((response) => {
          const newData = [...fast.current, ...response.results];
          fast.current = newData;
          setData(newData);
          if (response.next) {
            getPage(page + 1);
          } else {
            setLoading(false);
          }
        })
        // crash prevention
        .catch((err) => {
          return err;
        });
    },
    [data.map((i) => i.id).join(',')]
  );

  useEffect(() => {
    if (stop) {
      return;
    }

    getPage(1);
  }, [stop]);

  const onUpdate = useCallback(() => {
    fast.current = [];
    setData([]);
    getPage(1);
  }, []);

  return { data, onUpdate, loading };
};

export default useDataFullList;
