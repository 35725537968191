import React, { memo, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from '@mui/material';

import {
  Button,
  Checkbox,
  Form,
  SecondaryButton,
  modalActions,
} from '../../../packages';

import {
  OnboardingSteps,
  appActions,
  appSelectors,
  authApi,
  authSelectors,
  authService,
} from '../../../entities';

import {
  CheckboxWrapper,
  ContinueButtonWrapper,
} from '../../onboarding/steps/styled';

import { colors } from '../../../theme';

import { privacyPolicyModalName } from '../../../layouts/onboarding-layouts/modals';

import { AccountFormWrapper, InputField } from './styled';
import { registerFormValidationSchema } from './validate';

export const RegisterForm = memo(({ onSubmitHandler, onBackHandler }) => {
  const dispatch = useDispatch();
  const { isPolicyAgree } = useSelector(appSelectors.getAppData);
  const { isRegisterLoading } = useSelector(authSelectors.getAuthData);
  const customer = useSelector(appSelectors.getCustomer);
  const { registrationEmail } = useSelector(authSelectors.getAuthData);

  const [generating, setGenerating] = useState(false);

  const initialValues = {
    name: '',
    company_name: '',
    email: '',
    password1: '',
    password2: '',
  };

  const onSubmit = useCallback(
    (values) => {
      dispatch(authService.registerByToken(values));
    },
    [dispatch]
  );

  const agreeWithPolicy = useCallback((value) => {
    dispatch(appActions.setIsPolicyAgree(value));
  }, []);

  const showPrivacyPolicyModal = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      modalActions.setModalIsOpen({
        name: privacyPolicyModalName,
        isOpen: true,
      })
    );
  }, []);

  const backToTheSelectRegistrationType = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      appActions.setOnboardingStep(OnboardingSteps.selectRegistrationType)
    );
  }, []);

  const onChange = useCallback((name, value, e, setFieldValue, context) => {
    if (
      name === 'email' &&
      value &&
      value.length > 3 &&
      value.indexOf('@') !== -1
    ) {
      if (!generating) {
        setGenerating(true);
      }

      authApi.generateCompanyName(value)
        .then(data => {
          context.setFieldValue('company_name', data?.names[0]);
          setGenerating(false);
        })
        .catch(() => {
          setGenerating(false);
      });
    }
  }, []);

  return (
    <AccountFormWrapper>
      <Form
        validationSchema={registerFormValidationSchema}
        initialValues={initialValues}
        onSubmit={onSubmitHandler ?? onSubmit}
        enableReinitialize
        isLoading={isRegisterLoading}
        onChange={onChange}
      >
        <InputField name="name" label="Your name" />
        <InputField name="email" label="Email" />
        <InputField name="company_name" label="Company name" />
        <InputField name="password1" label="Password" type="password" />
        <InputField name="password2" label="Confirm Password" type="password" />
        <CheckboxWrapper component="field">
          <Checkbox
            label={
              <div>
                {'I agree with '}
                <Link
                  sx={{
                    color: colors.activeTextColor,
                    textDecorationColor: colors.activeTextColor,
                  }}
                  onClick={showPrivacyPolicyModal}
                  underline="always"
                >
                  privacy policy
                </Link>
              </div>
            }
            onChange={agreeWithPolicy}
            defaultValue={isPolicyAgree}
          />
        </CheckboxWrapper>
        <ContinueButtonWrapper component="button">
          <SecondaryButton
            component="button"
            variant="outlined"
            onClick={onBackHandler ?? backToTheSelectRegistrationType}
            sx={{ width: 150, minWidth: 100, marginRight: '20px' }}
          >
            Back
          </SecondaryButton>
          <Button
            variant="contained"
            type="submit"
            disabled={!isPolicyAgree}
            sx={{ width: 150, minWidth: 100 }}
          >
            Continue
          </Button>
        </ContinueButtonWrapper>
      </Form>
    </AccountFormWrapper>
  );
});
