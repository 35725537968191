import { setPagination, useQuery } from '../../packages';

import { policyGroupSelectors } from '../policy-group';

import { appService } from '../app';

import { policyApi } from './api';
import { policyActions } from './store';

const getPolicyResources =
  ({
    scanId,
    accountId,
    standardId,
    policyGroupId,
    policyId,
    ppToolToken,
    ppFeature,
    query,
  }) =>
  async (dispatch) => {
    try {
      dispatch(policyActions.setIsResourcesLoading(true));
      const { page = 0, ...rest } = query || {};
      const otherQueryParams = useQuery('resources');

      const params = {
        scanId,
        accountId,
        standardId,
        policyGroupId,
        policyId,
        ppToolToken:
          ppToolToken && ppFeature ? `${ppToolToken}/${ppFeature}` : undefined,
        query: {
          page: page + 1,
          ...rest,
          ...otherQueryParams,
        },
      };

      const { results, ...pagination } =
        ppToolToken === undefined
          ? await policyApi.getResources(params)
          : await policyApi.getResourcesPP(params);

      dispatch(policyActions.setResources(results));
      setPagination('resources', { ...pagination, page });

      dispatch(policyActions.setIsResourcesLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

const init =
  ({
    id,
    accountId,
    standardId,
    policyGroupId,
    ppToolToken,
    ppFeature,
    policyId,
    scanId,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch(policyActions.setIsLoading(true));
      dispatch(policyActions.setSelectedPolicyId(policyId));

      const currentPolicy = policyGroupSelectors.getPolicyById(
        getState(),
        policyId
      );

      await dispatch(
        appService.getPipelineData({ ppToolToken, accountId, id })
      );
      dispatch(policyActions.setCurrentPolicy(currentPolicy));
      await dispatch(
        getPolicyResources({
          accountId,
          standardId,
          policyGroupId,
          ppToolToken,
          ppFeature,
          policyId,
          scanId,
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(policyActions.setIsLoading(false));
    }
  };

export const policyService = {
  init,
  getPolicyResources,
};
