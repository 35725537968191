import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../../packages';
import {
  threatModelingSelectors,
  threatModelingService,
} from '../../../../entities';

export const ThreatAnalysisButton = memo(() => {
  const dispatch = useDispatch();

  const { wasChanged, currentDiagram } = useSelector(
    threatModelingSelectors.getThreatModelingData
  );

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(threatModelingService.getReport({ id: currentDiagram.id }, true));
    },
    [currentDiagram]
  );

  return (
    <Button
      variant="contained"
      sx={{ marginLeft: 'auto', display: 'block' }}
      onClick={onClickHandler}
      disabled={!wasChanged}
    >
      Threat Analysis
    </Button>
  );
});
