import React, { memo, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { authService } from '../../entities';

import { Loader } from '../../packages';

import { GlobalLoaderWrapper } from '../../app';
import { AddPipelineModal } from '../auth/add-pipeline-modal';

export const LinkAuthPage = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authService.linkAuth());
  }, []);

  return (
    <GlobalLoaderWrapper>
      <Loader />
      <AddPipelineModal />
    </GlobalLoaderWrapper>
  );
});
