import styled from 'styled-components';

import { scrollable } from '../../../../theme';

export const Wrapper = styled.div`
  min-height: 100%;
  max-height: 700px;
  padding: 5px 30px;
  background: ${({ theme }) => theme.colors.secondaryBackground};

  ${scrollable}
`;

export const Label = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  font-size: 16px;
  line-height: 24px;
`;

export const ListTopic = styled(Label)`
  margin-bottom: 16px;
`;

export const ListWrapper = styled.div`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  font-size: 16px;
  line-height: 24px;
  padding-left: 20px;
`;

export const Title = styled(Label)`
  font-size: 32px;
  margin-top: 16px;
  line-height: 48px;
  font-weight: 600;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
`;

export const Topic = styled(Label)`
  font-size: 24px;
  margin-top: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const SubTopic = styled(Label)`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 16px;
`;

export const TopicLastUpdated = styled(Topic)`
  margin-top: 32px;
`;

export const ButtonsWrapper = styled(Topic)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 0;

  button {
    min-width: 100px;
  }
`;
