import React, { memo, useCallback, useEffect } from 'react';

import { OAuthButton, OAuthButtonWrapper } from './styled';

export const AuthButton = memo(
  ({ label, url, onChange, helperText, error, ...rest }) => {
    const onClickHandler = useCallback(() => {
      // TODO replace test url to real
      window.open(
        `${window.location.origin}/airbot-api/v1/auth/oauth_redirect/?code=0.AV4AkOv7_d_E2Ua4WHOqw_juPDlA_skobqZCjgOrr_HeTNVeADQ....`,
        label,
        'width=500,height=500'
      );
    }, []);

    useEffect(() => {
      window.close_auth_popup = ({ code }) => {
        console.log('sent code=', code);
        const input = document.createElement('input');

        input.addEventListener('change', (event) => {
          // eslint-disable-next-line no-param-reassign
          event.target.value = code;
          // eslint-disable-next-line no-param-reassign
          event.target.name = 'code';

          onChange(event);
        });

        input.dispatchEvent(new Event('change'));
      };
    }, []);

    return (
      <OAuthButtonWrapper>
        <OAuthButton
          onClick={onClickHandler}
          color={error && 'error'}
          variant="text"
          {...rest}
        >
          {label}
        </OAuthButton>
        {error && helperText}
      </OAuthButtonWrapper>
    );
  }
);
