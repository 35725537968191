export const resourcesHeader = [
  {
    id: 'indicator',
    numeric: false,
    disablePadding: true,
    label: 'Compliance',
    align: 'center',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Resource name',
    align: 'left',
  },
  {
    id: 'lastScan',
    numeric: false,
    disablePadding: false,
    label: 'Last scan',
    align: 'left',
  },
];
