import styled from 'styled-components';

export const AddPipelineContent = styled.div`
  padding: 0 24px;
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;
