import React, { memo } from 'react';

import { colors } from '../../../../theme';

import { EditButtonWrapper, EditIcon } from './styled';

export const EditComponentButton = memo(() => (
  <EditButtonWrapper>
    <EditIcon color={colors.darkBorderColor} />
    Edit
  </EditButtonWrapper>
));
