import { DEFAULT_BORDER_WIDTH } from '../../controls/ControlBorders';

export const Shapes = {
  RECT: 'rect',
  ELLIPSE: 'ellipse',
  CYLINDER: 'cylinder',
  PENTAGON: 'pentagon',
  HEXAGON: 'hexagon',
  TRIANGLE: 'triangle',
};
export const synteticShapes = [Shapes.RECT, Shapes.ELLIPSE];

const LibraryBasic = [
  {
    label: 'Area',
    shape: Shapes.RECT,
    width: 600,
    height: 500,
    handles: '00000000',
    style: {
      borderRadius: 10,
      borderWidth: DEFAULT_BORDER_WIDTH,
      borderStyle: 'dashed',
      backgroundColor: 'rgb(255 255 255 / 0%)',
    },
    isUnder: true,
  },
  {
    label: 'Horizontal area',
    shape: Shapes.RECT,
    width: 600,
    height: 500,
    handles: '00000000',
    style: { borderStyle: 'dashed', borderWidth: '2px 0px 2px 0px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    isUnder: true,
  },
  {
    label: 'Vertical area',
    shape: Shapes.RECT,
    width: 600,
    height: 500,
    handles: '00000000',
    style: { borderStyle: 'dashed', borderWidth: '0px 2px 0px 2px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    isUnder: true,
  },
  {
    label: 'Data Store',
    shape: Shapes.RECT,
    handles: '00000000',
    style: { borderWidth: '4px 0px 4px 0px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    isUnder: true,
  },
  {
    label: 'Trust Boundary',
    shape: Shapes.RECT,
    width: 300,
    height: 10,
    handles: '00000000',
    style: { borderStyle: 'dashed', borderWidth: '2px 0px 0px 0px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    labelStyle: { transform: 'translateX(0px) translateY(-15px)' },
    isUnder: true,
  },
  {
    label: 'Trust Boundary',
    shape: Shapes.RECT,
    width: 10,
    height: 300,
    handles: '00000000',
    style: { borderStyle: 'dashed', borderWidth: '0px 0px 0px 2px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    labelStyle: { transform: 'translateX(30px) translateY(0px)' },
    isUnder: true,
  },
  {
    label: 'Process',
    shape: Shapes.ELLIPSE,
    handles: '01010101',
    style: { backgroundColor: '#000' },
    labelStyle: { color: '#fff' },
  },
  {
    label: 'Process',
    shape: Shapes.ELLIPSE,
    handles: '01010101',
  },
  {
    label: 'Interactor',
    shape: Shapes.RECT,
    width: 70,
    height: 40,
    handles: '01010101',
    style: { backgroundColor: '#000' },
    labelStyle: { color: '#fff' },
  },
  {
    label: 'Interactor',
    shape: Shapes.RECT,
    width: 70,
    height: 40,
    handles: '01010101',
  },
  {
    id: -1,
    label: 'Text block',
    handles: '01000100',
  },
  {
    label: 'Horizontal line',
    shape: Shapes.RECT,
    width: 600,
    height: 10,
    handles: '00000000',
    style: { borderStyle: 'dashed', borderWidth: '2px 0px 0px 0px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    isUnder: true,
  },
  {
    label: 'Vertical line',
    shape: Shapes.RECT,
    width: 10,
    height: 600,
    handles: '00000000',
    style: { borderStyle: 'dashed', borderWidth: '0px 0px 0px 2px', backgroundColor: 'rgb(255 255 255 / 0%)' },
    isUnder: true,
  },
  {
    label: 'Rectangle',
    shape: Shapes.RECT,
    // width: 50,
    // height: 50,
    handles: '01010101',
  },
  {
    label: 'Rounded rectangle',
    shape: Shapes.RECT,
    handles: '01010101',
    // width: 50,
    // height: 50,
    style: { borderRadius: 10 },
  },
  {
    label: 'Circle',
    shape: Shapes.ELLIPSE,
    handles: '01010101',
    width: 70,
    height: 70,
  },
  // {
  //   label: 'Ellipse',
  //   shape: Shapes.ELLIPSE,
  //   handles: '01010101',
  //   // width: 70,
  //   // height: 35,
  // },
  {
    label: 'Cylinder',
    shape: Shapes.CYLINDER,
    handles: '01000100',
    // width: 50,
    // height: 50,
  },
  {
    label: 'Pentagon',
    shape: Shapes.PENTAGON,
    handles: '01000100',
    width: 70,
    height: 64,
  },
  {
    label: 'Hexagon',
    shape: Shapes.HEXAGON,
    handles: '01000100',
    // width: 50,
    // height: 50,
  },
  {
    label: 'Triangle',
    shape: Shapes.TRIANGLE,
    handles: '01001010',
    width: 70,
    height: 60,
  },
];

export default LibraryBasic;
