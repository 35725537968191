import React from 'react';
import IconMoreVertical from '../../../../packages/icons/IconMoreVertical';
import IconCopy from '../../../../packages/icons/IconCopy';
import { STATUS } from '../table-users/status';
import { TeamUserMenu } from '../../../../layouts/TeamUserMenu';
import branding from '../../../../branding';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

const ComponentStatusDefault = (props) => <div>{props.value}</div>;

export const formatData = (
  data,
  { teamId, teamName, onOpenTeamsMember, onOpenRemoveMember, onOpenBlock, onOpenResend }
) =>
  data.map((item) => {
    const { id, first_name, last_name, email, teams_count, status } = item;

    const ComponentStatus = STATUS[status] || ComponentStatusDefault;

    const full_name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    return {
      first_name: {
        value: full_name,
        align: 'left',
        // component: EnableIcon
      },
      email: {
        align: 'left',
        component: () => (
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ marginRight: 4 }}>{email}</div>
            <IconCopy />
          </div>
        ),
      },
      teams_count: {
        align: 'left',
        component: () => (
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => onOpenTeamsMember({ ...item, full_name })}
          >
            <div style={{ fontSize: 13, fontWeight: 600, color: branding.colors.primary, marginRight: 8 }}>{teams_count}</div>
            <AccountMultipleIcon />
          </div>
        ),
      },
      status: {
        component: () => (
          <div style={{ fontSize: 13, fontWeight: 600 }}>
            <ComponentStatus value={status} />
          </div>
        ),
        align: 'left',
      },
      _more: {
        align: 'right',
        component: () => (
          <TeamUserMenu
            onOpenRemoveMember={() => onOpenRemoveMember({ ids: [id], emails: [email], teamId, teamName })}
            onOpenActivate={() => onOpenBlock({ emails: [email], activateUser: true })}
            hideActivate={status !== 'blocked'}
            onOpenBlock={() => onOpenBlock({ emails: [email] })}
            hideBlock={status === 'blocked'}
            onOpenResend={() => onOpenResend({ emails: [email] })}
            hideResend={status !== 'pending'}
          >
            <IconMoreVertical />
          </TeamUserMenu>
        ),
      },
      data: { ...item, enable: true },
      id,
    };
  });
