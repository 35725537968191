import { NotificationManager } from 'react-notifications';

import {
  getCurrentFeaturePrefix,
  setPagination,
  useQuery,
} from '../../packages';

import {
  customerDetailsApi,
  customerDetailsService,
} from '../customer-details';

import { appService } from '../app';

import { FeaturePrefix } from '../../consts';

import { standardApi } from './api';
import { standardActions } from './store';
import { standardSelectors } from './selectors';

const getStandards =
  ({ accountId, scanId, ppToolToken, ppFeature, query }) =>
  async (dispatch, getState) => {
    try {
      const { page = 0, ...rest } = query || {};
      const { standards } = standardSelectors.getStandardData(getState());
      const otherQueryParams = useQuery('standards');

      const params = {
        scanId,
        accountId,
        ppToolToken:
          ppToolToken && ppFeature ? `${ppToolToken}/${ppFeature}` : undefined,
        query: {
          page: page + 1,
          ...rest,
          ...otherQueryParams,
        },
      };

      const { results, ...pagination } =
        ppToolToken === undefined
          ? await standardApi.getStandards(params)
          : await standardApi.getStandardsPP(params);

      // const { results, ...pagination } = await standardApi.getStandards(params);

      setPagination('standards', { ...pagination, page });

      let resultStandards;

      if (page) {
        resultStandards = [...standards, ...results];
        dispatch(standardActions.setStandards(resultStandards));
      } else {
        resultStandards = results;
        dispatch(standardActions.setStandards(resultStandards));
      }

      const activeStandardsCount = standards.filter(
        (standard) => standard.compliance !== null
      ).length;
      const newActiveStandardsCount = resultStandards.filter(
        (standard) => standard.compliance !== null
      ).length;

      if (newActiveStandardsCount === activeStandardsCount && pagination.next) {
        dispatch(
          getStandards({
            accountId,
            ppToolToken,
            query: { ...query, page: page + 1 },
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

const joinAccountByPat = async ({pat, org, ppToolToken}) =>
  await standardApi.joinAccountByPatPP({
    requestBody: { pat },
    ppToolToken, org,
  });

const getPolicyGroups =
  ({ accountId, standardId, scanId, ppToolToken, ppFeature, query }) =>
  async (dispatch) => {
    try {
      dispatch(standardActions.setIsPolicyGroupsLoading(true));
      const { page = 0, ...rest } = query || {};
      const otherQueryParams = useQuery('policy-groups');
      const params = {
        scanId,
        accountId,
        standardId,
        ppToolToken,
        query: {
          page: page + 1,
          ...rest,
          ...otherQueryParams,
        },
      };
      console.log(ppToolToken, 'ppToolToken');

      const { results, ...pagination } =
        ppToolToken === undefined
          ? await standardApi.getPolicyGroups(params)
          : await standardApi.getPolicyGroupsPP(params);

      dispatch(standardActions.setPolicyGroups(results));
      setPagination('policy-groups', { ...pagination, page });

      dispatch(standardActions.setIsPolicyGroupsLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

const getAssignStandards =
  ({ accountId, id }) =>
  async (dispatch) => {
    try {
      dispatch(standardActions.setIsAssignStandardsLoading(true));

      const assignStandards = await standardApi.getAssignStandards({
        id,
        accountId,
      });

      dispatch(standardActions.setAssignStandards(assignStandards));
      dispatch(standardActions.setIsAssignStandardsLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

const saveAssignStandards =
  ({ accountId, id, regs }) =>
  async (dispatch) => {
    try {
      if (regs.length) {
        await standardApi.saveAssignStandards({
          id,
          accountId,
          requestModel: {
            regs,
          },
        });

        await dispatch(getAssignStandards({ accountId, id }));

        NotificationManager.success('Successfully saved');
      } else {
        NotificationManager.error(
          'You need to assign at least one Standard to your account.'
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

const getPresets = () => async (dispatch) => {
  try {
    const presets = await standardApi.getPresets();

    dispatch(standardActions.setPresets(presets));
  } catch (e) {
    console.error(e);
  }
};

const getPolicyGroupById =
  ({ accountId, standardId, scanId, policyGroupId }) =>
  async (dispatch) => {
    try {
      const response = await standardApi.getPolicyGroupById({
        scanId,
        accountId,
        standardId,
        policyGroupId,
      });

      dispatch(standardActions.setPolicyGroups([response]));
    } catch (e) {
      console.error(e);
    }
  };

const getCustomerStandards =
  ({ customerId, query }) =>
  async (dispatch, getState) => {
    try {
      dispatch(standardActions.setIsCustomerStandardsLoading(true));
      const { page = 0, ...rest } = query || {};
      const { customerStandards } = standardSelectors.getStandardData(
        getState()
      );

      const otherQueryParams = useQuery('customer-standards');
      const currentFeaturePrefix = getCurrentFeaturePrefix();

      const apiMethod =
        currentFeaturePrefix === FeaturePrefix.PlatformSecurity
          ? standardApi.getCustomerStandards
          : standardApi.getCustomerStandardsPipeline;

      const { results, ...pagination } = await apiMethod({
        customerId,
        query: {
          page: page + 1,
          ...rest,
          ...otherQueryParams,
        },
      });

      setPagination('customer-standards', { ...pagination, page });

      dispatch(standardActions.setIsCustomerStandardsLoading(false));
      // TODO make this code more universal
      let resultStandards;

      if (page) {
        resultStandards = [...customerStandards, ...results];
        dispatch(standardActions.setCustomerStandards(resultStandards));
      } else {
        resultStandards = results;
        dispatch(standardActions.setCustomerStandards(resultStandards));
      }

      const activeStandardsCount = customerStandards.filter(
        (standard) => standard.compliance !== null
      ).length;
      const newActiveStandardsCount = resultStandards.filter(
        (standard) => standard.compliance !== null
      ).length;

      if (newActiveStandardsCount === activeStandardsCount && pagination.next) {
        dispatch(
          getCustomerStandards({
            customerId,
            query: { ...query, page: page + 1 },
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

const getStandardById =
  ({ accountId, standardId, scanId }) =>
  async (dispatch) => {
    try {
      const response = await standardApi.getStandardById({
        scanId,
        accountId,
        standardId,
      });

      dispatch(standardActions.setStandards([response]));
    } catch (e) {
      console.error(e);
    }
  };

const getStandardChartModel =
  ({ accountId, standardId, scanId, ppToolToken }) =>
  async (dispatch) => {
    try {
      const response =
        ppToolToken === undefined
          ? await standardApi.getStandardChartModel({
              scanId,
              accountId,
              standardId,
            })
          : await standardApi.getStandardChartModelPP({
              standardId,
              ppToolToken,
            });

      dispatch(standardActions.setStandardChartData(response));
    } catch (e) {
      console.error(e);
    }
  };

const init =
  ({ id, accountId, scanId, ppToolToken, ppFeature }) =>
  async (dispatch) => {
    try {
      dispatch(standardActions.setIsLoading(true));

      await dispatch(
        getStandards({ accountId, scanId, ppToolToken, ppFeature })
      );
      await dispatch(
        customerDetailsService.getResourcesStat({
          id,
          accountId,
          ppToolToken,
          ppFeature,
        })
      );
      dispatch(appService.getPipelineData({ ppToolToken, accountId, id }));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(standardActions.setIsLoading(false));
    }
  };

const getStandardStats =
  ({ accountId, standardId, scanId, ppToolToken, ppFeature }) =>
  async (dispatch) => {
    try {
      const response =
        ppToolToken === undefined
          ? await standardApi.getStandardStats({
              scanId,
              accountId,
              standardId,
            })
          : await standardApi.getStandardStatsPP({
              standardId,
              ppToolToken:
                ppToolToken && ppFeature
                  ? `${ppToolToken}/${ppFeature}`
                  : undefined,
            });

      await dispatch(standardActions.setStandardStats(response));
    } catch (e) {
      console.error(e);
    }
  };

const initOverview =
  ({ id, accountId, standardId, scanId, ppToolToken, ppFeature }) =>
  async (dispatch) => {
    try {
      dispatch(standardActions.setIsLoading(true));
      await dispatch(
        getPolicyGroups({
          scanId,
          accountId,
          standardId,
          ppToolToken:
            ppToolToken && ppFeature
              ? `${ppToolToken}/${ppFeature}`
              : undefined,
          query: {
            page: 0,
          },
        })
      );
      await dispatch(
        getStandardStats({
          accountId,
          standardId,
          scanId,
          ppToolToken,
          ppFeature,
        })
      );
      await dispatch(
        appService.getPipelineData({ ppToolToken, accountId, id })
      );
      await dispatch(
        getStandardChartModel({
          accountId,
          standardId,
          scanId,
          ppToolToken,
          ppFeature,
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(standardActions.setIsLoading(false));
    }
  };

const toggleActiveStatus =
  ({ accountId, standardId, scanId, isActive }) =>
  async (dispatch, getState) => {
    try {
      const { standards } = await standardSelectors.getStandardData(getState());

      if (isActive) {
        await standardApi.setInactive({ accountId, standardId, scanId });
      } else {
        await standardApi.setActive({ accountId, standardId, scanId });
      }

      const updatedStandards = standards.map((standard) => {
        if (String(standard.id) === String(standardId)) {
          return {
            ...standard,
            active: !isActive,
          };
        }

        return standard;
      });

      dispatch(standardActions.setStandards(updatedStandards));
    } catch (e) {
      console.error(e);
    }
  };

export const standardService = {
  init,
  getPresets,
  saveAssignStandards,
  getAssignStandards,
  initOverview,
  getStandards,
  joinAccountByPat,
  getStandardStats,
  toggleActiveStatus,
  getStandardById,
  getPolicyGroupById,
  getPolicyGroups,
  getCustomerStandards,
  getStandardChartModel,
};
