import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDiagramResultContext } from '../../context/DiagramResultContext';
import { SecondaryButton } from '../../../../packages';
import { threatModelingService } from '../../../../entities';

export const ButtonRelatedAccounts = () => {
  const { diagramId } = useParams();
  const context = useDiagramResultContext();
  const dispatch = useDispatch();

  const onClickHandler = useCallback(
    (e) => {
      context.onOpenRelatedAccounts({
        diagramId: diagramId,
        onSuccess: () => {
          context.onCloseRelatedAccounts();
          dispatch(threatModelingService.initDiagramResult({ id: diagramId }));
        },
      });
    },
    [diagramId]
  );

  return (
    <SecondaryButton variant='contained' sx={{ marginBottom: '20px', display: 'block' }} onClick={onClickHandler}>
      Related accounts ({context.diagram.data?.related_accounts?.length || 0})
    </SecondaryButton>
  );
};
