import { ScanAccountButton } from '../scan-account-button';
import { AccountName } from '../account-name';
import { EditAccountButton } from '../edit-account-button';
import { ManageAccountTeamsButton } from '../manage-teams-button';
import { EnableIcon } from '../../../../packages';
import { GenerateDiagramButton } from '../generate-diagram-button';
import {FeaturePrefix} from '../../../../consts';

export const formatAccountsData = (accounts, accountsActions, currentFeaturePrefix) =>
  accounts.map((account) => {
    const { name, last_scan, compliance, policies_bad, id, service, enable } =
      account;

    const tableData = {
      enable: { value: enable, align: 'left', component: EnableIcon },
      service: { value: service, align: 'left' },
      name: { value: name, align: 'left', component: AccountName },
      lastScan: {
        value: last_scan
          ? `${new Date(last_scan * 1000).toLocaleDateString()} ${new Date(
            last_scan * 1000
          ).toLocaleTimeString()}`
          : '-',
        align: 'center',
      },
      compliance: { value: String(compliance ?? '-'), align: 'right' },
      policiesViolated: { value: String(policies_bad ?? '-'), align: 'right' },
      data: account,
      id,
    };

    if (currentFeaturePrefix === FeaturePrefix.PlatformSecurity) {
      tableData.generateDiagram = {
        component: () => <GenerateDiagramButton accountId={id}/>,
        align: 'right',
      };
    }

    if (accountsActions.company?.["manage-teams"]?.allowed) {
      tableData.manageTeams = {
        component: ManageAccountTeamsButton,
        align: 'right',
        customerId: account.customer_id,
        accountId: account.id,
        teams: account.teams,
        onSuccess: () => {},
      };
    }

    if (
      accountsActions.company?.["start-scan"]?.allowed ||
      accountsActions.team?.["start-scan"]?.allowed
    ) {
      tableData.startNewScan = {
        component: ScanAccountButton,
        align: 'right',
      };
    }

    if (accountsActions.company?.["update"]?.allowed) {
      tableData.edit = {
        component: EditAccountButton,
        align: 'right',
      };
    }

    return tableData;
  });
