import React, { memo } from 'react';

import { IconButton } from '@mui/material';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';

import { StartScanIcon } from './styled';

export const ScanButton = memo(
  ({
    onClick,
    title = 'Scan selected',
    message = '',
    color = colors.darkBorderColor,
    disabled,
  }) => {
    if (disabled) {
      return null;
    }

    if (message) {
      return message;
    }

    return (
      <Tooltip title={title}>
        <IconButton onClick={onClick} disabled={disabled}>
          <StartScanIcon color={color} />
        </IconButton>
      </Tooltip>
    );
  }
);
