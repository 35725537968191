import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import BasicShapes from '../library/basic/shapes';
import { synteticShapes } from '../library/basic';

/*
export type NodeProps<T = any> = {
  id: string;
  data: T;
  dragHandle?: boolean;
  type?: string;
  selected?: boolean;
  isConnectable?: boolean;
  zIndex?: number;
  xPos: number;
  yPos: number;
  dragging: boolean;
  targetPosition?: Position;
  sourcePosition?: Position;
};
*/

/*
  Handles:
  mask:  10101100
  names: abcdefgh

  a      b      c
  h             d
  g      f      e

*/

const names = 'abcdefgh';
const positions = {
  a: Position.Top,
  b: Position.Top,
  c: Position.Top,
  d: Position.Right,
  e: Position.Bottom,
  f: Position.Bottom,
  g: Position.Bottom,
  h: Position.Left,
};

const styles = {
  a: { left: '0%' },
  b: {},
  c: { left: '100%' },
  d: {},
  e: { left: '0%' },
  f: {},
  g: { left: '100%' },
  h: {},
};
function handleActive(name = '', mask = '11111111') {
  const idx = names.indexOf(name);

  return mask[idx] === '1';
}

function DefaultNode(props) {
  const { data } = props;
  const { label, shape, img, handles = '11111111', style = {}, labelStyle = {} } = data || {};

  const shapeData = BasicShapes.find((i) => i.shape === shape);

  const onSettings = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      props.onSettings(props, props.onChange);
    },
    [props, props.onSettings]
  );

  const Shape = shapeData?.icon;
  const { width, height } = style;

  const className = useMemo(() => {
    const list = ['element-item'];

    if (synteticShapes.includes(shape)) {
      list.push('as-shape');
    }

    return list.join(' ');
  }, [Shape]);

  const ref = useRef(null);
  // TODO Forced setting of the value visibility = 'visible'
  // Bug the like https://github.com/xyflow/xyflow/issues/3270
  // @ts-ignore
  if (ref && ref?.current) {
    const el = ref?.current;
    el.parentNode.style.visibility = 'visible';
  }

  return (
    <div ref={ref} className={className} style={{ ...style, backgroundColor: !Shape ? style?.backgroundColor : undefined }}>
      {Shape && (
        <div className='shape'>
          <Shape backgroundColor={style?.backgroundColor} />
        </div>
      )}
      {img && <img className='icon' src={img} />}
      <div className='label' style={labelStyle}>
        {label}
      </div>
      {props.onSettings && <img className='icon-element-settings' src={`/icons/settings.png`} onClick={onSettings} />}
      {handleActive('a', handles) && (
        <>
          <Handle
            id='a'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.a}
            style={styles.a}
          />
          <Handle
            id='a'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.a}
            style={styles.a}
          />
        </>
      )}

      {handleActive('b', handles) && (
        <>
          <Handle
            id='b'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.b}
            style={styles.b}
          />
          <Handle
            id='b'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.b}
            style={styles.b}
          />
        </>
      )}

      {handleActive('c', handles) && (
        <>
          <Handle
            id='c'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.c}
            style={styles.c}
          />
          <Handle
            id='c'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.c}
            style={styles.c}
          />
        </>
      )}

      {handleActive('d', handles) && (
        <>
          <Handle
            id='d'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.d}
            style={styles.d}
          />
          <Handle
            id='d'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.d}
            style={styles.d}
          />
        </>
      )}

      {handleActive('e', handles) && (
        <>
          <Handle
            id='e'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.e}
            style={styles.e}
          />
          <Handle
            id='e'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.e}
            style={styles.e}
          />
        </>
      )}

      {handleActive('f', handles) && (
        <>
          <Handle
            id='f'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.f}
            style={styles.f}
          />
          <Handle
            id='f'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.f}
            style={styles.f}
          />
        </>
      )}

      {handleActive('g', handles) && (
        <>
          <Handle
            id='g'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.g}
            style={styles.g}
          />
          <Handle
            id='g'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.g}
            style={styles.g}
          />
        </>
      )}

      {handleActive('h', handles) && (
        <>
          <Handle
            id='h'
            type='target'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.h}
            style={styles.h}
          />
          <Handle
            id='h'
            type='source'
            isConnectable
            isConnectableStart
            isConnectableEnd
            position={positions.h}
            style={styles.h}
          />
        </>
      )}
    </div>
  );
}

export default DefaultNode;
