import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  SecondaryButton,
  Tooltip,
  modalActions,
} from '../../../../packages';
import { threatModelingSelectors } from '../../../../entities';
import { deleteDiagramModal } from '../delete-diagram-modal';

import { DeleteButton, DeleteIcon } from './styled';

export const DeleteSelectedDiagramsButton = memo(() => {
  const dispatch = useDispatch();
  const { selectedDiagrams, diagrams } = useSelector(
    threatModelingSelectors.getThreatModelingData
  );

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      if (selectedDiagrams.length) {
        const diagramsForDelete = selectedDiagrams.filter((id) =>
          diagrams.find((diagram) => diagram.id === id)
        );

        dispatch(
          modalActions.setModalIsOpen({
            name: deleteDiagramModal,
            isOpen: true,
          })
        );
        dispatch(
          modalActions.setModalData({
            name: deleteDiagramModal,
            data: diagramsForDelete,
          })
        );
      }
    },
    [diagrams, selectedDiagrams]
  );
  return (
    <Tooltip title="Delete selected">
      <DeleteButton
        sx={{ marginLeft: 'auto', display: 'block' }}
        onClick={onClickHandler}
      >
        <DeleteIcon />
      </DeleteButton>
    </Tooltip>
  );
});
