import React, { memo, useCallback } from 'react';

import { FeaturePrefix, PathNames } from '../../../consts';

import { Tooltip } from '../../../packages/components';

import { AccordionSummary, ChildWrapper, PipelineIcon } from './styled';
import {getCurrentFeaturePrefix} from '../../../packages';

export const ServiceAccount = memo(
  ({
    customerId,
    accountId,
    accountName,
    selectedAccountId,
    onAccountSelect,
    activeTabRef,
    service,
  }) => {
    const isActiveTab = String(selectedAccountId) === String(accountId);

    const onAccountSelectHandler = useCallback(() => {
      if (onAccountSelect) onAccountSelect(accountId);
    }, [accountId]);

    return (
      <AccordionSummary
        withoutExpandIcon
        sx={{ background: 'inherit' }}
        onClick={onAccountSelectHandler}
      >
        {service === 'pipeline' && (
          <Tooltip title="It`s pipeline">
            <div>
              <PipelineIcon />
            </div>
          </Tooltip>
        )}

        <ChildWrapper
          isActiveTab={isActiveTab}
          ref={isActiveTab ? activeTabRef : null}
          to={PathNames.accountOverview
            .replace(':feature', getCurrentFeaturePrefix())
            .replace(':scanId', 'current')
            .replace(':id', customerId)
            .replace(':accountId', accountId)}
        >
          {accountName}
        </ChildWrapper>
      </AccordionSummary>
    );
  }
);
