import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { memo, useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { EditComponentButton } from '../edit-component-button';
import { DeleteComponentsButton } from '../delete-components-button';
import { modalActions } from '../../../../packages';
import { componentsModalName } from '../add-component-modal';
import { deleteComponentsModal } from '../delete-components-modal';
import IconChainSolid from '../../../../packages/icons/IconChainSolid';
import { EditButtonWrapper } from '../edit-component-button/styled';
import { useDiagramResultContext } from '../../context/DiagramResultContext';
import Permission from '../../../../packages/components/permissions';
import { ManageComponentTeamButton } from '../manage-component-team-button';

const ITEM_HEIGHT = 48;

export const MenuComponentButton = memo(({ id, diagramId, node_id, resourceId, team, onUpdate = () => {} }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const { onOpenAssignResource, onOpenAssignedTeam, onCloseAssignedTeam } = useDiagramResultContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditModalOpen = useCallback(() => {
    dispatch(
      modalActions.setModalData({
        name: componentsModalName,
        data: id,
      })
    );
    dispatch(
      modalActions.setModalIsOpen({
        name: componentsModalName,
        isOpen: true,
      })
    );
    setAnchorEl(null);
  }, [dispatch]);

  const onDeleteClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalIsOpen({
          name: deleteComponentsModal,
          isOpen: true,
        })
      );
      dispatch(
        modalActions.setModalData({
          name: deleteComponentsModal,
          data: { ids: [id], diagramId, node_id, onUpdate },
        })
      );
      setAnchorEl(null);
    },
    [id, diagramId, node_id, onUpdate]
  );

  const onTeamAssigned = () => {
    onCloseAssignedTeam();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <Permission resource="threat-modeling::diagrams::components">
          {(actions) => (actions.company?.["update"]?.allowed ?
              <MenuItem key="edit" onClick={onEditModalOpen}>
                <EditComponentButton />
              </MenuItem>
              : null
          )}
        </Permission>
        <Permission resource="threat-modeling::diagrams::components">
          {(actions) => (actions.company?.['manage-teams']?.allowed ?
              <MenuItem
                key="manage-teams"
                onClick={() => {
                  onOpenAssignedTeam({ componentId: id, team, onSuccess: onTeamAssigned });
                }}
              >
                <ManageComponentTeamButton/>
              </MenuItem>
              : null
          )}
        </Permission>
        <Permission resource="threat-modeling::diagrams::components::resources">
          {(actions) => (actions.company?.["view"]?.allowed ?
              <MenuItem key="resource" onClick={() => onOpenAssignResource({ diagramId, componentId: id, resourceId, onSuccess: onUpdate })}>
                <EditButtonWrapper ><IconChainSolid /> Resource</EditButtonWrapper>
              </MenuItem>
              : null
          )}
        </Permission>
        <Permission resource="threat-modeling::diagrams::components">
          {(actions) => (actions.company?.["delete"]?.allowed ?
              <MenuItem key="delete" onClick={onDeleteClick}>
                <DeleteComponentsButton />
              </MenuItem>
              : null
          )}
        </Permission>
      </Menu>
    </div>
  );
});
