import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Tooltip, modalActions } from '../../../../packages';
import { threatModelingSelectors } from '../../../../entities';
import { deleteComponentsModal } from '../delete-components-modal';

import { DeleteButton, DeleteIcon } from './styled';

export const DeleteSelectedComponentsButton = memo(() => {
  const dispatch = useDispatch();
  const { selectedComponents, currentDiagram } = useSelector(
    threatModelingSelectors.getThreatModelingData
  );

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      if (selectedComponents.length && currentDiagram?.components) {
        const componentsForDelete = selectedComponents.filter((id) =>
          currentDiagram.components.find((diagramId) => diagramId === id)
        );

        dispatch(
          modalActions.setModalIsOpen({
            name: deleteComponentsModal,
            isOpen: true,
          })
        );
        dispatch(
          modalActions.setModalData({
            name: deleteComponentsModal,
            data: { ids: componentsForDelete, diagramId: currentDiagram.id },
          })
        );
      }
    },
    [currentDiagram, selectedComponents]
  );
  return (
    <Tooltip title="Delete selected">
      <DeleteButton
        sx={{ display: 'block', marginRight: '15px' }}
        onClick={onClickHandler}
      >
        <DeleteIcon />
      </DeleteButton>
    </Tooltip>
  );
});
