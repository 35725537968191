import { createSelector } from 'reselect';

import { threatModelinginitialState, threatModelingReducerNamespace } from './store';

const getThreatModelingData = (state) =>
  state[threatModelingReducerNamespace] || threatModelinginitialState;

const getDiagram = createSelector(
  (state) => state[threatModelingReducerNamespace].currentDiagram,
  (currentDiagram) => currentDiagram
);

const getSelectedComponents = createSelector(
  (state) => state[threatModelingReducerNamespace].selectedComponents,
  (selectedComponents) => selectedComponents
);

const getCurrentDiagram = createSelector(
  (state) => state[threatModelingReducerNamespace].currentDiagram,
  (currentDiagram) => currentDiagram
);

const getComponentById = (state, id) => {
  const currentDiagram = getCurrentDiagram(state);

  return currentDiagram?.components?.find((component) => component.id === id);
};

export const threatModelingSelectors = {
  getDiagram,
  getComponentById,
  getThreatModelingData,
  getSelectedComponents,
};
