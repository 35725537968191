import { Link } from "@mui/material";
import { NavLink } from "react-router-dom";

export const formatResultsData = (scanHistory) =>
  scanHistory.map((scan, index) => {
    const {
      id,
      title,
      policies_compliant_percent,
      policies_total,
      policies_compliant,
      policies_violated,
      controls_total,
      last_scan,
      path,
      res_id,
      account,
      node,
    } = scan;

    return {
      title: {
        component: () => (
          <Link component={NavLink} to={`${id}/`}>
            {title}
          </Link>
        ),
        align: "left",
      },
      policies_compliant_percent: { value: String(policies_compliant_percent.toFixed(2) + " %" ?? "-"), align: "left" },
      controls_total: { value: String(controls_total ?? "-"), align: "center" },
      policies_violated: { value: String(policies_violated ?? "-"), align: "center" },
      policies_total: { value: String(policies_total ?? "-"), align: "center" },
      policies_compliant: { value: String(policies_compliant ?? "-"), align: "center" },
      last_scan: {
        value: last_scan
          ? `${new Date(last_scan * 1000).toLocaleDateString()} ${new Date(last_scan * 1000).toLocaleTimeString()}`
          : "-",
        align: "center",
      },
      data: { ...scan },
      id: index,
    };
  });
