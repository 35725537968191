import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { SecondaryButton } from '../../packages';
import { md, scrollable, xsm } from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background};
  min-height: 100%;
  max-height: 100%;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Title = styled.div`
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 88px);
  padding: 20px;
  max-width: 100%;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${scrollable}
`;

export const PipelineContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height:100vh;
  padding: 20px;
  max-width: 100%;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${scrollable}
`;

export const BreadcrumbsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  margin-bottom: 20px;
`;

export const Toolbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;

  @media screen and (max-width: ${xsm}) {
    flex-wrap: wrap;
  }
`;

export const TabsWrapper = styled.div`
  max-width: 650px;
  width: 100%;
  min-height: 40px;
  max-height: 40px;

  @media screen and (max-width: ${xsm}) {
    margin-bottom: 20px;
  }
`;

export const ExportsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

export const ExportLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor};
  margin-right: 16px;
  margin-left: 16px;
  white-space: pre;
`;

export const CSVExportButton = muiStyled(SecondaryButton)`
  margin-right: 16px;
  font-family: Raleway, sans-serif;
`;

export const JSONExportButton = muiStyled(SecondaryButton)`
  font-family: Raleway, sans-serif;
`;

export const LeftSidebar = styled.div`
  min-width: 360px;
  max-height: calc(100vh - 88px);

  @media screen and (max-width: ${md}) {
    min-width: 302px;
    position: fixed;
    z-index: 2;
    height: calc(100vh - 88px);
    display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'block' : 'none')};
  }

  ${scrollable}
`;
