import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useURLQuery, useURLQueryParam } from '../../packages/utils/router';
import IconArrowLeftShort from '../../packages/icons/IconArrowLeftShort';
import IconCheck from '../../packages/icons/IconCheck';
import { Button, SecondaryButton } from '../../packages/components/button';
import useApiData from '../../hooks/useApiData';
import { appSelectors, customerDetailsApi } from '../../entities';
import branding from '../../branding';
import { scrollable } from '../../theme';

const SidebarThreatModeling = () => {
  const [, onChange] = useURLQuery();
  const { value: teams } = useURLQueryParam('teams');
  const { value: users } = useURLQueryParam('users');

  const [selectedTeams, setSelectedTeams] = useState(teams ? `${teams}`.split(',').map((v) => parseInt(v)) : []);
  const [selectedUsers, setSelectedUsers] = useState(users ? `${users}`.split(',').map((v) => parseInt(v)) : []);

  const members = useApiData(customerDetailsApi.getTeamsAndMembers, [], []);
  const usersWithoutTeam = useApiData(customerDetailsApi.getUsersWithoutTeam, [], []);

  const user = useSelector(appSelectors.getUser);
  const meId = user.company_member_id;

  const isAssignMe = useMemo(() => selectedUsers.includes(meId), [meId, selectedUsers]);
  const toggleAssignMe = useCallback(
    () => setSelectedUsers(isAssignMe ? selectedUsers.filter((i) => i !== meId) : [...selectedUsers, meId]),
    [selectedUsers, meId]
  );

  const relations = useMemo(() => {
    const cont = {};

    members.data.forEach((team) => {
      if (!cont[team.id]) {
        cont[team.id] = [];
      }

      cont[team.id] = [...cont[team.id], ...team.members.map((user) => user.id)];
    });

    return cont;
  }, [members]);

  const list = useMemo(() => {
    let arr = [...members.data];

    arr = [...arr, { id: -1, members: usersWithoutTeam.data }];

    return arr;
  }, [members.data, usersWithoutTeam.data]);

  const onSelectTeam = useCallback(
    (teamId, selected = false) => {
      if (selected) {
        setSelectedTeams([...selectedTeams, teamId]);
        setSelectedUsers([...selectedUsers, ...(relations[teamId] || [])]);
      } else {
        setSelectedTeams(selectedTeams.filter((id) => id != teamId));
        setSelectedUsers(selectedUsers.filter((userId) => !(relations[teamId] || []).includes(userId)));
      }
    },
    [selectedTeams, relations]
  );

  const onSelectUser = useCallback(
    (userId, selected = false) => {
      if (selected) {
        setSelectedUsers([...selectedUsers, userId]);
      } else {
        setSelectedUsers(selectedUsers.filter((id) => id != userId));
      }
    },
    [selectedUsers]
  );

  const onReset = useCallback(() => {
    setSelectedTeams([]);
    setSelectedUsers([]);
    const query = {
      teams: '',
      users: '',
    };

    onChange(query);
  }, []);

  const onApply = useCallback(() => {
    const query = {
      teams: selectedTeams.join(','),
      users: selectedUsers.join(','),
    };

    onChange(query);
  }, [selectedTeams, selectedUsers, onChange]);

  const onAll = useCallback(() => {
    let teams = [];
    let users = [];
    members.data.forEach((team) => {
      teams.push(team.id);
      team.members.forEach((user) => {
        users.push(user.id);
      });
    });

    usersWithoutTeam.data.forEach((user) => {
      users.push(user.id);
    });

    teams = [...new Set(teams)];
    users = [...new Set(users)];

    setSelectedTeams(teams);
    setSelectedUsers(users);
  }, [members.data, usersWithoutTeam.data]);

  return (
    <div style={{ height: '100%', backgroundColor: 'white', padding: '41px 21px 0 25px' }}>
      <div style={{ fontWeight: 600, fontSize: '24px' }}>Responsible</div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '35px 0 16px' }}>
        <div
          style={{
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2F6CF6',
            cursor: 'pointer',
          }}
          onClick={onAll}
        >
          All teams & users
        </div>
        <div
          style={{
            padding: '2px 8px',
            background: isAssignMe ? branding.colors.blue : branding.colors.lightBlue,
            color: isAssignMe ? 'white' : 'black',
            transition: 'all 0.3s',
            borderRadius: 4,
            cursor: 'pointer',
          }}
          onClick={toggleAssignMe}
        >
          Assigned to Me
        </div>
      </div>
      <TeamsList
        list={list}
        selectedTeams={selectedTeams}
        selectedUsers={selectedUsers}
        onSelectTeam={onSelectTeam}
        onSelectUser={onSelectUser}
      />
      <div style={{ display: 'flex' }}>
        <Button style={{ flex: 1, marginRight: 28 }} onClick={onApply}>
          Apply
        </Button>
        <SecondaryButton style={{ flex: 1 }} onClick={onReset}>
          Reset
        </SecondaryButton>
      </div>
    </div>
  );
};

export default SidebarThreatModeling;

const getLabel = (firstName = '', lastName = '', email) => {
  return firstName ? `${firstName} ${lastName}` : email;
};

const TeamsList = (props) => {
  const {
    list,
    selectedTeams = [],
    selectedUsers = [],
    onSelectTeam = (teamId, selected = false) => {},
    onSelectUser = (userId, selected = false) => {},
  } = props;

  const [opened, setOpened] = useState([]);

  const onOpen = useCallback(
    (e, id, selected = false) => {
      if (selected) {
        setOpened([...opened, id]);
      } else {
        setOpened(opened.filter((id) => id !== id));
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [opened]
  );

  return (
    <WrapList>
      {list.map((team) => (
        <div key={`team-${team.id}`}>
          <div
            onClick={(e) => {
              const sel = !selectedTeams.includes(team.id);
              onSelectTeam(team.id, sel);
              onOpen(e, team.id, sel);
            }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {team.id !== -1 && (
              <>
                {selectedTeams.includes(team.id) ? <IconCheck /> : <IconEmpty />}
                <div style={{ cursor: 'pointer' }}>{team.name}</div>
                <div style={{ fontWeight: 700, marginLeft: 12 }}>{` ${team.members.length} `}</div>
                <Space />
                <div onClick={(e) => onOpen(e, team.id, !opened.includes(team.id))}>
                  <Arrow opened={opened.includes(team.id)} />
                </div>
              </>
            )}
          </div>
          {!!team.members.length && (
            <div style={team.id === -1 ? {} : { marginLeft: '16px' }}>
              {team.members.map((user) => (
                <div
                  key={`user-${user.id}`}
                  onClick={() => onSelectUser(user.id, !selectedUsers.includes(user.id))}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: opened.includes(team.id) || team.id === -1 ? 32 : 0,
                    overflow: 'hidden',
                    transition: 'height 0.1s',
                  }}
                >
                  {selectedUsers.includes(user.id) ? <IconCheck /> : <IconEmpty />}
                  <div style={{ cursor: 'pointer' }}>{getLabel(user.first_name, user.last_name, user.email)}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </WrapList>
  );
};

const WrapList = styled.div`
  max-height: calc(100vh - 400px);
  margin-bottom: 20px;
  overflow-y: auto;
  color: ${branding.colors.primary};
  ${scrollable}
`;

const Space = () => <div style={{ flex: 1 }} />;

const IconEmpty = () => <div style={{ width: '18px' }} />;

const Arrow = (props) => {
  const { opened = false, onClick = (e) => {} } = props;

  return (
    <IconArrowLeftShort
      color='#BFBFBF'
      style={{
        transition: 'transform 0.3s',
        transform: opened ? 'rotate(270deg)' : 'rotate(180deg)',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
};
