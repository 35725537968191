import { ComplianceStatus } from '../../../../packages';

export const formatResourceData = (resources) =>
  resources.map((scan, _index) => {
    const { resource, timestamp, is_compliant } = scan;

    return {
      indicator: { align: 'center', component: ComplianceStatus },
      name: { value: resource, align: 'left' },
      lastScan: {
        value: `${new Date(timestamp * 1000).toLocaleDateString()} ${new Date(
          timestamp * 1000
        ).toLocaleTimeString()}`,
        align: 'left',
      },
      data: { ...scan, compliancePercent: is_compliant ? 100 : 0 },
      id: _index,
    };
  });
