import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LibraryBasic, { synteticShapes } from './basic';
import BasicShapes from './basic/shapes';
import { LABEL_DEFAULT_FONT_SIZE, LabelVerticalAligns, labelVerticalAlignStyles } from '../details/node-details/constants';
import useIntersection from '../../../utils/intersection';

const ElementWithIconDefaultStyle = { ...labelVerticalAlignStyles[LabelVerticalAligns.BOTTOM], textWrap: 'nowrap' };
ElementWithIconDefaultStyle.marginBottom = ElementWithIconDefaultStyle.marginBottom(LABEL_DEFAULT_FONT_SIZE);

const IconDefault = (props) => <div className='icon-default' style={props.style} />;

const libraryDataDefault = {
  list: [],
  ended: true,
  loading: false,
  onNextPage: () => {},
};

const Library = (props) => {
  const { onSelect, data, hideBasicLibrary = false } = props;
  const [show, setShow] = useState(true);

  const libraries = useMemo(() => data.map((d) => ({ ...d, ...Object.assign({ ...libraryDataDefault }, d) })), [data]);

  return (
    <div className='library-panel'>
      {!hideBasicLibrary && (
        <>
          <div className='title' onClick={() => setShow(!show)}>
            Basic
          </div>
          <div className={`list ${show ? 'show' : ''}`}>
            {LibraryBasic.map((item, idx) => {
              const shape = BasicShapes.find((s) => s.shape === item.shape);

              const icon = shape?.icon || IconDefault;

              const label = item.label;

              const style = {
                ...(shape?.style || {}),
                ...(item?.style || {}),
              };

              const labelStyle = {
                ...(shape?.labelStyle || {}),
                ...(item?.labelStyle || {}),
              };

              if (item.width || shape?.width) {
                style.width = item.width || shape.width;
              }

              if (item.height || shape?.height) {
                style.height = item.height || shape.height;
              }

              return (
                <LibraryItem
                  key={`${item.label}-${item.id}-${idx}`}
                  label={label}
                  style={style}
                  icon={icon}
                  onSelect={() => {
                    onSelect({
                      label,
                      handles: item.handles,
                      shape: item.shape,
                      style,
                      labelStyle,
                      isUnder: item.isUnder,
                    });
                  }}
                />
              );
            })}
          </div>
        </>
      )}
      {libraries.map(({ title, data, open }) => (
        <LibraryList key={title} title={title} data={data} open={open} onSelect={onSelect} />
      ))}
    </div>
  );
};

export default Library;

const LibraryList = (props) => {
  const { title, data, open, onSelect } = props;
  const [show, setShow] = useState(open);

  const ref = useMemo(() => ({ current: null }), []);
  const [el, setEl] = useState(null);
  const intersection = useIntersection(el);

  const intersectionRef = useCallback(
    (elem) => {
      if (elem && (!ref.current || ref.current !== elem)) {
        ref.current = elem;
        setEl(elem);
      }
    },
    [ref]
  );

  const ratio = intersection?.intersectionRatio || 0;

  useEffect(() => {
    if (data?.ended === true) {
      return;
    }

    if (intersection && ratio > 0.1) {
      data?.onNextPage();
    }
  }, [ratio, data?.ended]);

  return (
    <>
      <div className='title' onClick={() => setShow(!show)}>
        {title}
      </div>
      <div className={`list ${show ? 'show' : ''}`}>
        {data.list.map((item) => {
          const shape = BasicShapes.find((s) => s.shape === item.shape);

          const icon = shape?.icon || (synteticShapes.includes(item.shape) ? IconDefault : undefined);
          const img = item.icon;

          const label = item.label;

          const style = {
            ...(shape?.style || {}),
            ...(item?.style || {}),
          };

          if (item.width || shape?.width) {
            style.width = item.width || shape.width;
          }

          if (item.height || shape?.height) {
            style.height = item.height || shape.height;
          }

          return (
            <LibraryItem
              key={`${item.label}-${item.id}`}
              label={label}
              style={style}
              icon={icon}
              img={img}
              onSelect={() =>
                onSelect({
                  label,
                  handles: item.handles,
                  shape: item.shape,
                  img,
                  style,
                  labelStyle: item.labelStyle || { ...ElementWithIconDefaultStyle },
                  isUnder: item.isUnder,
                  _meta: item._meta,
                })
              }
            />
          );
        })}
        {!data?.ended && (
          <div ref={intersectionRef} style={{ margin: 10, textAlign: 'center' }}>
            Loading
          </div>
        )}
      </div>
    </>
  );
};

const LibraryItem = (props) => {
  const Icon = props.icon;

  return (
    <div className='component-item' onClick={props.onSelect}>
      <div className='icon'>
        {Icon && (
          <Icon
            style={{
              ...(props.style || {}),
              width: props.style?.width && props.style.width > 70 ? 70 : props.style?.width,
              height: props.style?.height && props.style.height > 70 ? 70 : props.style?.height,
            }}
          />
        )}
        {props.img && <img src={props.img} />}
      </div>
      <div className='label'>{props.label}</div>
    </div>
  );
};
