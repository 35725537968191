import React, { memo } from 'react';

import { AdditionalLogo } from './AdditionalLogo';
import {
  AdditionalLogoTitle,
  FullWidthLogoWrapper,
  HeaderWithoutProfileWrapper,
} from './styled';
import branding from '../../branding';

export const HeaderWithoutProfile = memo(() => (
  <HeaderWithoutProfileWrapper>
    <FullWidthLogoWrapper>
      <AdditionalLogo />
      {!branding.isBank && <AdditionalLogoTitle>{branding.platform}</AdditionalLogoTitle>}
    </FullWidthLogoWrapper>
  </HeaderWithoutProfileWrapper>
));
