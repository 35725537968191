import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { TextField } from '../../../../packages';

export const CustomerFormWrapper = styled.div`
  padding: 5px 24px;
`;

export const NameField = muiStyled(TextField)``;

export const DescriptionField = muiStyled(TextField)(() => ({
  marginTop: 12,
  marginBottom: 12,
}));

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
