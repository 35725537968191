import React, { memo, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

import {
  appSelectors,
  appService,
  customerDetailsSelectors,
} from '../../entities';

import { LoaderWrapper } from '../../app';
import { PageLayout } from '../../layouts';

import { FeaturePrefix, FeatureSlugs, PathNames } from '../../consts';

import platformSecurity from './conponents/images/platform-security.svg';
import pipelineSecurity from './conponents/images/pipeline-security.svg';
import threatModeling from './conponents/images/threat-modeling.svg';

import { CardsList, Description, Title, Wrapper } from './styled';
import { FeatureCard } from './conponents/feature-card';
import branding from '../../branding';
import {getActions} from '../../packages/components/permissions';

export const Home = memo(() => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const features = useSelector(appSelectors.getFeatures);
  const { user } = useSelector(appSelectors.getAppData);
  const currentCustomer = useSelector(appSelectors.getCustomer);
  const [isExpand, setIsExpand] = useState(false);

  const toggleExpand = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsExpand(!isExpand);
    },
    [setIsExpand, isExpand]
  );

  if (isLoading) {
    return (
      <PageLayout>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  const FeaturesImages = {
    [FeatureSlugs.PlatformSecurity]: platformSecurity,
    [FeatureSlugs.ThreatModelling]: threatModeling,
    [FeatureSlugs.PipelineSecurity]: pipelineSecurity,
  };

  const FeaturesLinks = {
    [FeatureSlugs.PlatformSecurity]: PathNames.customerResults
      .replace(':feature', FeaturePrefix.PlatformSecurity)
      .replace(':id', currentCustomer?.id),
    [FeatureSlugs.ThreatModelling]: PathNames.diagrams,
    [FeatureSlugs.PipelineSecurity]: PathNames.customerResults
      .replace(':feature', FeaturePrefix.PipelineSecurity)
      .replace(':id', currentCustomer?.id),
  };

  const cards = features.map((feature) => {
    const pipelineActions = getActions("pipeline-security::accounts");
    const threatModelingActions = getActions("threat-modeling::diagrams");
    const platformActions = getActions("platform-security::accounts");

    const permissions = {
      "pipeline-security": pipelineActions,
      "threat-modeling": threatModelingActions,
      "platform-security": platformActions,
    };

    let isDisabled = !user.features?.includes(feature.slug) && !user.is_superuser;
    isDisabled |= !(
      permissions[feature.slug]?.company?.["view"]?.allowed
      || permissions[feature.slug]?.team?.["view"]?.allowed
      || permissions[feature.slug]?.self?.["view"]?.allowed
    );

    const isComingSoon = !feature.is_active;

    const isReq =
      user.features_req?.includes(feature.slug) && !user.is_superuser;

    return {
      name: feature.name,
      description: feature.description,
      image: FeaturesImages[feature.slug] ?? '',
      to: FeaturesLinks[feature.slug] ?? '',
      onClick: () => dispatch(appService.reinitFeature()),
      background:
        isDisabled && !isComingSoon
          ? '#4F63A2'
          : !isComingSoon
          ? branding.colors.deepBlue
          : '#7783A8',
      isComingSoon,
      requested: isReq,
      disabled: isDisabled,
    };
  });

  return (
    <PageLayout withoutSidebar>
      <Wrapper>
        <Title>
          Welcome to
          <span>{branding.name}</span>
          powered by {branding.poweredBy}
        </Title>
        <Description>
          Please select the lifecycle stages to get started
        </Description>
        <CardsList>
          {cards.map((props) => (
            <FeatureCard
              key={props.name}
              isExpand={isExpand}
              toggleExpand={toggleExpand}
              {...props}
            />
          ))}
        </CardsList>
      </Wrapper>
    </PageLayout>
  );
});
