import { FeaturePrefix } from '../../consts';

export const getCurrentFeaturePrefix = () => {
  if (window.location.pathname.includes(FeaturePrefix.PlatformSecurity)) {
    return FeaturePrefix.PlatformSecurity;
  }
  if (window.location.pathname.includes(FeaturePrefix.ThreatModelling)) {
    return FeaturePrefix.ThreatModelling;
  }
  if (window.location.pathname.includes(FeaturePrefix.PipelineSecurity)) {
    return FeaturePrefix.PipelineSecurity;
  }

  return FeaturePrefix.PlatformSecurity;
};
