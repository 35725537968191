import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { Modal, modalActions, setAccessToken } from '../../../packages';

import { appSelectors, authService } from '../../../entities';

import { CustomerList } from '../customers-list';

import { AddPipelineContent, ButtonsWrapper, Title } from './styled';
import { addPipelineModal } from './consts';

export const AddPipelineModal = memo(() => {
  const dispatch = useDispatch();
  const { menuItems } = useSelector(appSelectors.getAppData);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const onContinueClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(authService.addPipelineAndRedirect(selectedCustomer));
    },
    [selectedCustomer]
  );

  const onCancelHandler = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      modalActions.setModalIsOpen({
        name: addPipelineModal,
        isOpen: false,
      })
    );
    setAccessToken('');
  }, []);

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: addPipelineModal,
          data: null,
        })
      );
    },
    []
  );

  return (
    <Modal
      name={addPipelineModal}
      header="Add pipeline"
      headerStyles={{ padding: '40px 26px 0 24px' }}
    >
      <AddPipelineContent>
        <Title>
          Yoy have several customers. By continuing you are adding pipeline for
          one of them.
        </Title>
        <CustomerList
          menuItems={menuItems}
          setSelectedCustomer={setSelectedCustomer}
        />
        <ButtonsWrapper component="button">
          <Button size="medium" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            disabled={!selectedCustomer}
            onClick={onContinueClickHandler}
          >
            Continue
          </Button>
        </ButtonsWrapper>
      </AddPipelineContent>
    </Modal>
  );
});
