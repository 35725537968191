import styled from 'styled-components';

import arrowUrl from '../icons/arrow.png';

export const ArrowRight = styled.img.attrs({
  src: arrowUrl,
})`
  margin-right: 16px;
  width: 20px;
  height: 20px;
`;
