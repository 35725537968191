import React, { memo } from 'react';

import {
  Label,
  WelcomeLabel,
} from './styled';
import branding from '../../branding';

export const WelcomeTxt = memo(() => {
  return (
    <>
        <WelcomeLabel>Welcome to {branding.name}!</WelcomeLabel>
        <Label>We provide comprehensive security solutions, including <strong>CI/CD pipeline security, automated threat modeling, and platform security</strong> for multi-cloud environments.</Label>
        <Label>Sign up for an account directly or integrate {branding.name} with your Azure DevOps, Github or Jenkins pipelines. After onboarding, you can configure {branding.name} for your security and compliance requirements, such as NIST, CMMC, ISO, Fedramp or customised frameworks, and leverage our solutions for proactive protection throughout the development lifecycle. Use our reporting features to monitor your security posture, demonstrate ROI, generate actionable roadmaps based on security insights, and prioritise remediation efforts.</Label>
        <Label>Here are videos for more onboarding and support information - <a href="https://youtube.com/playlist?list=PLnW8hs1GG7Xr9vbgvRch-5h7i-kF2Cvp-" target="_blank">{branding.name} - powered by {branding.poweredBy}</a></Label>
    </>)
    ;
});
