import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ScanSelectedButton, modalActions } from '../../../../packages';
import { customerStartScanAllModal } from '../start-scan-all-modal/consts';
import { customerSelectors } from '../../../../entities';

export const ScanSelectedCustomersButton = memo(() => {
  const dispatch = useDispatch();
  const { customers, selectedCustomerIds } = useSelector(
    customerSelectors.getCustomerData
  );

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      if (selectedCustomerIds.length) {
        const customersForScan = selectedCustomerIds.filter(
          (id) => customers.find((customer) => customer.id === id)?.enable
        );

        dispatch(
          modalActions.setModalIsOpen({
            name: customerStartScanAllModal,
            isOpen: true,
          })
        );
        dispatch(
          modalActions.setModalData({
            name: customerStartScanAllModal,
            data: customersForScan,
          })
        );
      }
    },
    [selectedCustomerIds, customers]
  );

  return (
    <ScanSelectedButton sx={{ marginLeft: '16px' }} onClick={onClickHandler} />
  );
});
