import styled from 'styled-components';

export const ScanResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
`;

export const NoScanHistory = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
`;

export const ScanResultsTableWrapper = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  width: 100%;
`;
