import React, { memo } from 'react';

import { Card } from '../card';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';

import { FeaturePrefix } from '../../../consts';

import {
  Legend,
  LegendCircle,
  LegendCount,
  LegendLabel,
  LegendWrapper,
  LinesWrapper,
  ServiceLegend,
  ServiceName,
  ServicePercent,
  TotalCount,
  TotalLabel,
  TotalViolations,
  ViolationHelpMessage,
  ViolationsCount,
  ViolationsLegend,
  ViolationsLine,
  ViolationsPercent,
  ViolationsWrapper,
} from './styled';
import { NoViolations } from './NoViolations';

export const PolicyViolations = memo(({ violationStats, featureName }) => {
  const violationLines = [
    {
      count: violationStats.unset,
      stat: `${violationStats.unset} not defined`,
      percent: violationStats.unsetPercent,
      color: colors.unset,
      title: 'Not defined',
    },
    {
      count: violationStats.low,
      stat: `${violationStats.low} low`,
      percent: violationStats.lowPercent,
      color: colors.lowWarning,
      title: 'Low',
    },
    {
      count: violationStats.medium,
      stat: `${violationStats.medium} medium`,
      percent: violationStats.mediumPercent,
      color: colors.warning,
      title: 'Medium',
    },
    {
      count: violationStats.high,
      stat: `${violationStats.high} high`,
      percent: violationStats.highPercent,
      color: colors.danger,
      title: 'High',
    },
  ].filter(({ percent }) => percent);

  const legendData = (
    featureName === FeaturePrefix.PlatformSecurity
      ? [
          { title: 'Azure', percent: violationStats.azurePercent },
          { title: 'AWS', percent: violationStats.awsPercent },
        ]
      : [
          { title: 'AzureDev', percent: violationStats.azuredevPercent },
          { title: 'GithubDev', percent: violationStats.githubdevPercent },
        ]
  ).filter(({ percent }) => percent);

  return (
    <Card
      title={
        featureName === FeaturePrefix.PlatformSecurity
          ? 'Policy Violations'
          : 'Checks Violations'
      }
      helpMessage={
        <ViolationHelpMessage>
          <ul>
            <li>Number of policies violated of all customer's accounts.</li>
            <li>
              Hover over the progress bar line and you will see the number of
              policies violated by their severity level (not defined, low,
              medium, high/critical).
            </li>
            <li>
              Number of policies violated per each service - Azure and AWS
              (overall for all available accounts in each service).
            </li>
          </ul>
        </ViolationHelpMessage>
      }
      style={{ flexGrow: 2 }}
    >
      {violationStats.total ? (
        <ViolationsWrapper>
          {!!legendData.length && (
            <ViolationsLegend>
              {legendData.map(({ title, percent }) => (
                <ServiceLegend>
                  <ServicePercent>{percent}</ServicePercent>
                  <ServiceName>{title}</ServiceName>
                </ServiceLegend>
              ))}
            </ViolationsLegend>
          )}
          <ViolationsPercent>
            <LinesWrapper>
              {violationLines.map(({ percent, color, stat }) => (
                <Tooltip title={stat} arrow>
                  <ViolationsLine percent={percent} color={color} />
                </Tooltip>
              ))}
            </LinesWrapper>
          </ViolationsPercent>
          <ViolationsCount>
            <TotalViolations>
              <TotalCount>{violationStats.total}</TotalCount>
              <TotalLabel>Total</TotalLabel>
            </TotalViolations>
            <LegendWrapper>
              {violationLines.map(({ count, color, title }) => (
                <Legend>
                  <LegendCircle color={color} />
                  <LegendCount>{count}</LegendCount>
                  <LegendLabel>{title}</LegendLabel>
                </Legend>
              ))}
            </LegendWrapper>
          </ViolationsCount>
        </ViolationsWrapper>
      ) : (
        <NoViolations />
      )}
    </Card>
  );
});
