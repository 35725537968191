import { useEffect } from 'react';

const useBeforeUnloadEvent = (changed, onBefore = () => {}) => {
  useEffect(() => {
    function callBeforeUnload(event) {
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
      onBefore();
    }

    if (changed) {
      window.addEventListener('beforeunload', callBeforeUnload, { capture: true });
    }

    return () => {
      window.removeEventListener('beforeunload', callBeforeUnload, { capture: true });
    };
  }, [onBefore, changed]);
};

export default useBeforeUnloadEvent;
