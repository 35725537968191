import { createSelector } from 'reselect';

import { appReducerNamespace } from './consts';
import { appInitialState } from './store';

const getAppData = (state) => state[appReducerNamespace] || appInitialState;

const getMenuItemById = (state, id) => {
  const { menuItems } = getAppData(state);

  return menuItems.find((menuItem) => String(menuItem.id) === String(id));
};
const getMenuAccountById = (state, id) => {
  const { menuAccounts } = getAppData(state);

  return menuAccounts.find((menuItem) => String(menuItem.id) === String(id));
};

const getCustomer = createSelector(
  (state) => state[appReducerNamespace].selectedCustomer,
  (selectedCustomer) => selectedCustomer
);

const getAccount = createSelector(
  (state) => state[appReducerNamespace].selectedAccount,
  (selectedAccount) => selectedAccount
);

const getUser = createSelector(
  (state) => state[appReducerNamespace].user,
  (user) => user
);

const getProfile = createSelector(
  (state) => state[appReducerNamespace].profile,
  (profile) => profile
);

const getIsSidebarOpen = (state) => state[appReducerNamespace].isSidebarOpen;

const getPipelineData = (state) => state[appReducerNamespace]?.pipelineData;
const getFeatures = (state) => state[appReducerNamespace]?.features;

const getIsPipelineOnboarding = () =>
  window.location.pathname.includes('pipeline');

export const appSelectors = {
  getAppData,
  getAccount,
  getCustomer,
  getFeatures,
  getUser,
  getProfile,
  getPipelineData,
  getMenuItemById,
  getIsSidebarOpen,
  getMenuAccountById,
  getIsPipelineOnboarding,
};
