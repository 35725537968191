export const diagramsHeaders = [
  {
    id: 'select',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'index',
    numeric: false,
    disablePadding: true,
    label: 'No',
    align: 'left',
    sort: 'number',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    align: 'left',
    sort: 'status',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Component Description',
    align: 'left',
    sort: 'label',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Component type',
    align: 'left',
    sort: 'generic_type',
  },
  {
    id: 'team',
    numeric: false,
    disablePadding: false,
    label: 'Team',
    align: 'left',
  },
  {
    id: 'resource',
    numeric: false,
    disablePadding: false,
    label: 'Resource',
    align: 'left',
    sort: 'resource',
  },
  // {
  //   id: 'flow',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Flow',
  //   align: 'left',
  // },
  {
    id: 'menu',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: '',
  },
];

export const reportHeaders = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Component type/Generic type',
    align: 'left',
  },
  {
    id: 'threat',
    numeric: false,
    disablePadding: false,
    label: 'Threat',
    align: 'left',
  },
  {
    id: 'countermeasures',
    numeric: false,
    disablePadding: false,
    label: 'Countermeasures',
    align: 'left',
  },
  {
    id: 'control',
    numeric: false,
    disablePadding: false,
    label: 'Controls',
    align: 'left',
  },
  {
    id: 'mitres',
    numeric: false,
    disablePadding: false,
    label: 'MITRE ATT&CK',
    align: 'left',
  },
  {
    id: 'security_checks',
    numeric: false,
    disablePadding: false,
    label: 'Applicable Security Checks',
    align: 'left',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    align: 'left',
  },
];
