import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import {
  Card,
  HelpMessage,
  Search,
  getCurrentFeaturePrefix,
} from '../../../../packages';

import { policyGroupSelectors, policyGroupService } from '../../../../entities';

import { colors } from '../../../../theme';

import { Policies } from '../policies';

import { FeaturePrefix } from '../../../../consts';

import { CardHeader, EmptyMessage, HeaderLabel } from './styled';

export const PoliciesCard = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  const { accountId, standardId, policyGroupId, ppToolToken, scanId, ppFeature } =
    useParams();
  const { policies } = useSelector(policyGroupSelectors.getPolicyGroupData);

  const onSearchHandler = useCallback(
    (value) => {
      setSearchValue(value);
      dispatch(
        policyGroupService.getPolicies({
          scanId,
          accountId,
          standardId,
          ppToolToken,
          ppFeature,
          policyGroupId,
          query: {
            search: value,
          },
        })
      );
    },
    [scanId, accountId, standardId, policyGroupId, ppToolToken, ppFeature]
  );
  const currentFeaturePrefix = getCurrentFeaturePrefix();

  return (
    <Card
      header={
        <CardHeader>
          <HeaderLabel>
            {currentFeaturePrefix === FeaturePrefix.PlatformSecurity
              ? 'Policies compliance'
              : 'Checks compliance'}
          </HeaderLabel>
          <Search
            styles={{
              color: colors.textColor,
              borderBottom: `1px solid ${colors.borderColor}`,
            }}
            wrapperStyles={{
              width: 'auto',
              marginLeft: 'auto',
              marginRight: '10px',
            }}
            onSearch={onSearchHandler}
          />
          <HelpMessage
            color={colors.darkBorderColor}
            title="This is the list of policies of a chosen Control. Green mark in Compliance column means the policy was compliant during a specific scan, red - non-compliant/violated. Each policy has its severity level which can be low, medium, hight/critical or not defined(no severity level set for the policy). Click on the policy name will forward you to the policy details page with policy description and the list of resources checked in front of the policy."
          />
        </CardHeader>
      }
      style={{ marginBottom: 20 }}
    >
      {policies.length ? (
        <Policies searchValue={searchValue} />
      ) : (
        <EmptyMessage>No policies</EmptyMessage>
      )}
    </Card>
  );
};
