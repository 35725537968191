import { createSlice } from '@reduxjs/toolkit';

import { CUSTOMER_STEPS } from './consts';

export const customerDetailinitialState = {
  isLoading: false,
  isAccountCreating: false,
  isAccountsLoading: false,
  isScansLoading: false,
  customerScans: [],
  customerStats: [],
  resourcesStat: null,
  customerTrends: [],
  currentService: null,
  selectedAccountsIds: [],
  currentStep: CUSTOMER_STEPS.scanResults,
  accounts: [],
  accountsCount: 0,
};

const STORE_NAME = '@customerDetails';

export const customerDetailsReducerNamespace = 'customerDetails';

export const customerDetailsStore = createSlice({
  name: STORE_NAME,
  initialState: customerDetailinitialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsAccountCreating: (state, action) => {
      state.isAccountCreating = action.payload;
    },
    setIsAccountsLoading: (state, action) => {
      state.isAccountsLoading = action.payload;
    },
    setIsScansLoading: (state, action) => {
      state.isScansLoading = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setSelectedAccountsIds: (state, action) => {
      state.selectedAccountsIds = action.payload;
    },
    setAccountsCount: (state, action) => {
      state.accountsCount = action.payload;
    },
    setCustomerScans: (state, action) => {
      state.customerScans = action.payload;
    },
    setCustomerStats: (state, action) => {
      state.customerStats = action.payload;
    },
    setCustomerTrends: (state, action) => {
      state.customerTrends = action.payload;
    },
    setCurrentService: (state, action) => {
      state.currentService = action.payload;
    },
    setResourcesStat: (state, action) => {
      state.resourcesStat = action.payload;
    },
    resetState: () => customerDetailinitialState,
  },
});

export const customerDetailsReducer = customerDetailsStore.reducer;
export const customerDetailsActions = customerDetailsStore.actions;
