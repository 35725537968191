import React, { memo, useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  SecondarySelect,
  debounce,
  getCurrentFeaturePrefix,
  modalActions,
  usePagination,
} from '../../../packages';

import { appSelectors, appService } from '../../../entities';

import { FeaturePrefix, PathNames } from '../../../consts';

import { ListHeader } from '../list-header';

import { customerModalName } from '../../page-layout';

import { CustomerListLabel, CustomerListWrapper } from './styled';

export const CustomerList = memo(({ menuItems, closeSidebar }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { page, count } = usePagination('menu-items');
  const { isMenuItemsLoading, user } = useSelector(appSelectors.getAppData);
  const selectedCustomer = useSelector(appSelectors.getCustomer);

  const defaultOption = useMemo(
    () => ({
      label: selectedCustomer.name,
      value: selectedCustomer.id,
    }),
    [selectedCustomer]
  );

  const formattedOptions = useMemo(
    () =>
      menuItems.map((customer) => ({
        label: customer.name,
        value: customer.id,
      })),
    [menuItems]
  );

  const onCustomerCreate = useCallback(() => {
    dispatch(
      modalActions.setModalIsOpen({
        name: customerModalName,
        isOpen: true,
      })
    );
  }, []);

  const onSearch = useCallback(
    (newValue) => {
      dispatch(appService.getMenuItems({ search: newValue }));
    },
    [dispatch]
  );

  const onSearchHandlerWithDebounce = useMemo(
    () => debounce((newValue) => onSearch(newValue), 500),
    [onSearch]
  );

  const handleInputChange = useCallback(
    (newValue) => {
      const newInputValue = newValue.replace(/\W/g, '');
      setInputValue(newInputValue);

      onSearchHandlerWithDebounce(newInputValue);
    },
    [onSearchHandlerWithDebounce]
  );

  const onBlurHandler = useCallback(() => {
    handleInputChange('');
  }, []);

  const onChangeHandler = useCallback(
    ({ value }) => {
      dispatch(appService.onSelectedCustomerChange(value));
    },
    [menuItems]
  );

  const onMenuScrollToBottom = useCallback(() => {
    dispatch(appService.getMenuItems({ search: inputValue, page: page + 1 }));
  }, [inputValue, page, dispatch]);

  return (
    <CustomerListWrapper>
      <ListHeader
        onLinkClick={closeSidebar}
        count={count}
        label="Customers"
        labelLink={PathNames.customers.replace(
          ':feature',
          getCurrentFeaturePrefix()
        )}
        onAddButtonClick={onCustomerCreate}
        hideAddButton={!user.is_superuser}
      />
      <CustomerListLabel>Choose customer</CustomerListLabel>
      <SecondarySelect
        isLoading={isMenuItemsLoading}
        options={formattedOptions}
        onBlur={onBlurHandler}
        defaultValue={defaultOption}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onChange={onChangeHandler}
        onInputChange={handleInputChange}
      />
    </CustomerListWrapper>
  );
});
