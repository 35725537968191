import React, { memo, useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useApiData from '../../../../../hooks/useApiData';
import { Button, Form, SecondaryButton, modalActions, modalSelectors } from '../../../../../packages';

import { threatModelingApi, threatModelingSelectors, threatModelingService } from '../../../../../entities';
import { InputNamePopup } from '../input-name-popup/InputNamePopup';

import { componentsModalName } from '../consts';

import {
  ButtonsWrapper,
  ComponentDescriptionField,
  ComponentsFormWrapper,
  SelectField,
  SelectLazyField,
} from './styled';
import { createComponentValidationSchema } from './validate';
import { useLazyList } from '../../../../../packages/utils/list';

export const ComponentsFormInline = memo(
  ({ diagramId, component, onCancel: onCancelOut, onSubmit: onSubmitOut, loading = false }) => {
    // const {id: componentId } = component;
    const dispatch = useDispatch();
    // const ref = useRef();
    const { componentsOptions, isComponentUpdating } = useSelector(threatModelingSelectors.getThreatModelingData);
    // const componentId = useSelector((state) =>
    //   modalSelectors.getModalData(state, componentsModalName)
    // );
    // const selectedComponent = useSelector((state) =>
    //   threatModelingSelectors.getComponentById(state, componentId)
    // );

    const typeOptions =
      componentsOptions?.type?.map((type) => ({
        label: type.display_name,
        value: type.value,
      })) ?? [];
    typeOptions.unshift({ label: '<Add new Component>', value: -1 });

    const genericTypeOptions =
      componentsOptions?.generic_type?.map((type) => ({
        label: type.display_name,
        value: type.value,
      })) ?? [];

    const [search, setSearch] = useState('');
    const isActiveSearch = search.length >= 3;
    const resources = useLazyList(threatModelingApi.getDiagramResources, [diagramId], isActiveSearch ? { search } : {});

    const resourcesOptions = useMemo(
      () => [
        ...resources.data?.map((res) => ({
          label: res.title,
          value: res.id,
        })),
      ],
      [resources.data]
    );

    const { label, type, generic_type, resource } = component || {};
    const currentType = typeOptions.find((t) => t.value === type);
    const currentGenericType = genericTypeOptions.find((t) => t.value === generic_type);

    const [dialogOpen, setDialogOpen] = useState(false);

    const currentResource = useApiData(resource ? threatModelingApi.getResource : null, [resource]);

    const initialValues = {
      label: label || '',
      type: currentType || '',
      generic_type: currentGenericType || '',
      resource: currentResource.data ? { value: currentResource.data.id, label: currentResource.data.title } : '',
    };

    const onCancel = useCallback(() => {
      onCancelOut();
      // dispatch(
      //   modalActions.setModalIsOpen({
      //     name: componentsModalName,
      //     isOpen: false,
      //   })
      // );
    }, [onCancelOut]);

    let setFormVal = null;

    const handleInputDialogClose = useCallback(async (val) => {
      if (val === false) {
        setDialogOpen(false);
        return;
      }

      try {
        const { id: newComponentId, name: newComponentName } = await threatModelingService.createComponentType(val);

        dispatch(threatModelingService.getComponentsOptions({}));

        setFormVal('type', {
          label: newComponentName,
          value: newComponentId,
        });
      } catch (e) {
        console.warn(e);
      }
      setDialogOpen(false);
    }, []);

    const onChangeTypeHandler = useCallback(async (name, val, evt, setVal) => {
      if (name === 'type') {
        if (val && val['value'] === -1) {
          setFormVal = setVal;
          setDialogOpen(true);
        } else {
          const { generic_id, generic_name } = await threatModelingService.getGenericForBaseType(val.value);
          if (generic_id) {
            setVal('generic_type', {
              label: generic_name,
              value: generic_id,
            });
          }
        }
      }
    }, []);

    const onSubmit = useCallback(
      (values) => {
        // threatModelingService.addOrUpdateComponents
        // dispatch(
        //   threatModelingService.addOrUpdateComponents({
        //     id: componentId,
        //     requestModel: {
        //       label: values.label,
        //       type: values.type.value,
        //       generic_type: values.generic_type.value,
        //       resource: values.resource ? values.resource?.value : null,
        //     },
        //   })
        // );

        const data = {
          label: values.label,
          type: values.type.value,
          generic_type: values.generic_type.value,
          resource: values.resource ? values.resource?.value : null,
        };

        if (component?.id) {
          data.id = component?.id;
        }

        if (component?.node_id) {
          data.node_id = component?.node_id;
        }

        onSubmitOut(data);
      },
      [component?.id, onSubmitOut]
    );

    // useEffect(
    //   () => () => {
    //     dispatch(
    //       modalActions.setModalData({
    //         name: componentsModalName,
    //         data: null,
    //       })
    //     );
    //   },
    //   []
    // );

    return (
      <ComponentsFormWrapper>
        <Form
          validationSchema={createComponentValidationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onChange={onChangeTypeHandler}
          isLoading={isComponentUpdating}
          enableReinitialize
        >
          <ComponentDescriptionField name='label' label='Component description' />
          <SelectField name='type' label='Type' options={typeOptions} component='field' menuPosition='absolute' />
          <SelectField name='generic_type' label='Generic type' options={genericTypeOptions} component='field' />
          <SelectLazyField
            key={resourcesOptions.length}
            name='resource'
            label='Resource'
            placeholder='Start typing (3 characters minimum)'
            options={resourcesOptions}
            component='field'
            intersectionRef={resources.intersectionRef}
            search={search}
            onSearch={setSearch}
          />
          <ButtonsWrapper component='button'>
            <SecondaryButton onClick={onCancel} sx={{ width: '140px' }}>
              Cancel
            </SecondaryButton>
            <Button variant='contained' type='submit' sx={{ width: '140px' }}>
              {component?.id ? (loading ? 'Saving' : 'Save') : loading ? 'Adding' : 'Add'}
            </Button>
          </ButtonsWrapper>
        </Form>
        <InputNamePopup open={dialogOpen} onClose={handleInputDialogClose} initialValue={label} />
      </ComponentsFormWrapper>
    );
  }
);
