import React, { memo, useEffect, useRef, useState } from 'react';

import DefaultTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { TextareaField } from '../textarea/TextField';

const PureTextField = styled(DefaultTextField)(({ theme }) => ({
  '& .MuiTextField-root': {
    boxSizing: 'border-box',
  },
  '& .MuiInputBase-input': {
    padding: 10,
    fontFamily: 'Raleway, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Raleway, sans-serif',
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-active': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-selected': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiOutlinedInput-input': {
    color: theme.colors.textColor,
    fontFamily: 'Raleway, sans-serif',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: theme.colors.activeTextColor,

    '&.Mui-focused fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '& fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '&:hover fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '&:focus fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
  },
}));

const PureFileField = styled(DefaultTextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  '& .MuiInputBase-root': {
    position: 'unset',
  },
  '& .MuiInputBase-input': {
    position: 'absolute',
    height: 'unset',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    cursor: 'pointer',
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent !important',
    },
  },
}));

export const checkIsUrl = (value = '') => {
  return `${value}`.startsWith('http');
}

const InputField = (props) => {
  const { children, value, onSelectFile = () => {}, ...rest } = props;
  const [src, setSrc] = useState();
  const [file, setFile] = useState();
  const ref = useRef(null);

  useEffect(() => {
    if (!value || !ref.current || checkIsUrl(value)) {
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      setSrc(e.target.result);
    };

    const tFile = ref.current.querySelector('input').files?.[0];
    setFile(tFile);
    onSelectFile(tFile);

    reader.readAsDataURL(tFile || value);
  }, [value, onSelectFile]);

  return (
    <div style={{ position: 'relative', marginBottom: 12 }}>
      <div>{typeof children === 'function' ? children({ ...props, value: src || props.value, file }) : children}</div>
      <PureFileField ref={ref} size='small' {...rest} accept='image/png, image/jpeg' />
    </div>
  );
};

export const TextField = memo((props) => {
  const { type, children } = props;

  if (type === 'file' && children) {
    return <InputField {...props} />;
  }

  if (type === 'textarea') {
    <TextareaField />;
  }

  return <PureTextField size='small' {...props} />;
});
