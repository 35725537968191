import React, { memo, useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";

import { Table } from "../../../../packages";

import {
  platformSecuritySelectors,
  platformSecurityService,
  policyGroupSelectors,
  policyGroupService,
} from "../../../../entities";

import { NoScanHistory, ScanResultsTableWrapper, ScanResultsWrapper } from "./styled";

import { scanHistoryHeader } from "./consts";
import { formatPolicesData } from "./utils";

export const Policies = memo(({ searchValue }) => {
  const dispatch = useDispatch();
  const { id, accountId, resource, control, scanId, ppToolToken, ppFeature } = useParams();

  const { data, isLoading } = useSelector((state) =>
    platformSecuritySelectors.getResourceControlPolicies(state, resource, control)
  );

  const tableData = useMemo(() => formatPolicesData(data), [data]);

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        platformSecurityService.getResourceControlPolicies({
          id,
          accountId,
          resourceId: resource,
          controlId: control,
          scanId,
          ppToolToken,
          ppFeature,
          search: searchValue,
          ...query,
        })
      );
    },
    [accountId, resource, control, scanId, searchValue, ppToolToken, ppFeature]
  );

  return (
    <ScanResultsWrapper>
      <ScanResultsTableWrapper>
        <Table
          name="ps-resource-control-policies"
          isLoading={isLoading}
          onPageChange={onChangeHandler}
          onSort={onChangeHandler}
          rows={tableData}
          headCells={scanHistoryHeader}
          withoutCheck
        />
      </ScanResultsTableWrapper>
    </ScanResultsWrapper>
  );
});
