import React, { memo } from 'react';

import { CollapseIcon, ExpandIcon, MaximizeAllWrapper } from './styled';

export const MaximizeAllButton = memo(({ toggleExpand, isExpand }) => (
  <MaximizeAllWrapper onClick={toggleExpand}>
    {isExpand ? <CollapseIcon /> : <ExpandIcon />}
    {isExpand ? 'Minimize All' : 'Maximize all'}
  </MaximizeAllWrapper>
));
