import { PathNames } from '../../consts';
import { getCurrentFeaturePrefix } from '../../packages';

export const tabNames = {
  accounts: 'accounts',
  scanHistory: 'scan-history',
  overallScanResults: 'overall-scan-results',
};

export const tabs = [
  {
    title: 'Overall scan results',
    name: tabNames.overallScanResults,
    to: () =>
      PathNames.customerResults.replace(':feature', getCurrentFeaturePrefix()),
  },
  {
    title: 'Accounts',
    name: tabNames.accounts,
    to: () =>
      PathNames.customerAccounts.replace(':feature', getCurrentFeaturePrefix()),
  },
  {
    title: 'Scan history',
    name: tabNames.scanHistory,
    to: () =>
      PathNames.customerScanHistory.replace(
        ':feature',
        getCurrentFeaturePrefix()
      ),
  },
];

export const accountTabNames = {
  scan: 'scan',
  accountScanHistory: 'account-scan-history',
  accountResources: 'account-resources',
};

export const getAccountTabs = (acc) => [
  {
    title: acc && acc.service === 'pipeline' ? 'Deploy data' : 'Scan data',
    name: accountTabNames.scan,
    to: () =>
      PathNames.accountOverview.replace(':feature', getCurrentFeaturePrefix()),
  },
  {
    title:
      acc && acc.service === 'pipeline' ? 'Deploy history' : 'Scan history',
    name: accountTabNames.accountScanHistory,
    to: () =>
      PathNames.customerScanHistory.replace(
        ':feature',
        getCurrentFeaturePrefix()
      ),
  },
];

export const getPlSecAccountTabs = (acc) => [
  {
    title: 'Group by Regulatory',
    name: accountTabNames.scan,
    to: () =>
      PathNames.accountOverview.replace(':feature', getCurrentFeaturePrefix()),
  },
  {
    title: 'Group by Resource',
    name: accountTabNames.accountResources,
    to: () =>
      PathNames.psResources.replace(
        ':feature',
        getCurrentFeaturePrefix()
      ),
  },
];