import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { FourthSelect, SecondaryTextField } from '../../../../../packages';
import { FourthSelectLazy } from '../../../../../packages/components/select/FourthSelect';

export const ComponentsFormWrapper = styled.div`
  padding: 5px 24px;
`;

export const ComponentDescriptionField = muiStyled(SecondaryTextField)`
  margin-bottom: 0px;
`;

export const SelectField = styled(FourthSelect)``;

export const SelectLazyField = styled(FourthSelectLazy)``;

export const WrapLazyField = styled.div`
  margin-bottom: 10px;
  & > div {
    width: 100%;

    & > div:first-child {
      margin-top: 0px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;
