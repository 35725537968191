import React, { memo, useMemo } from 'react';

import { useSelector } from 'react-redux';

import {customerDetailsSelectors} from '../../../../entities';
import { StatTable } from '../../../../packages';

export const AccountStat = memo(() => {
  const accountStat = useSelector(customerDetailsSelectors.getResourcesStat);

  const stats = useMemo(() => {
    if (!accountStat) return {};

    return {
      compliant: accountStat.compliance,
      passed: accountStat.resources_passed,
      scanned: accountStat.resources,
      failed: accountStat.resources_bad,
    };
  }, [accountStat]);

  return <StatTable {...stats} />;
});
