import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';

import { scrollable } from '../../../theme';
import branding from '../../../branding';

export const Scrollable = styled.div`
  position: absolute;
  height: 290px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  pointer-events: all;

  ${scrollable};

  & > div > div {
    cursor: pointer;
    padding: 8px 18px;
    background: transparent;

    transition: background 0.3s;

    &:hover {
      background: #f4f4f4;
    }
  }
`;

export const listFilter = (name, search) => {
  if (!search) {
    return true;
  }

  return `${name}`.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
};

export const List = (props) => {
  const { data = [{ value: -1, title: 'Item' }], active, onSelect = (id) => {} } = props;

  const [element, setElement] = useState(null);

  const setActiveElement = useCallback((el) => {
    if (el) {
      setElement(el);
    }
  }, []);

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [element]);

  return (
    <div>
      {data.map((item) => (
        <div
          key={item.value}
          ref={active === item.value ? setActiveElement : undefined}
          style={{ ...(active === item.value ? { color: branding.colors.primary, background: '#E6E6E6' } : {}) }}
          onClick={() => onSelect(item.value)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
