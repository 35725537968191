export const customerModalName = 'customer';

export const diagramsHeaders = [
  {
    id: 'created_at',
    numeric: false,
    disablePadding: true,
    label: 'Import Date',
    align: 'left',
    sort: 'created_at',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'File name',
    align: 'left',
    sort: 'filename',
  },

  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Source type',
    align: 'left',
    sort: 'type',
  },
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Stage',
    align: 'left',
    sort: 'stage',
  },
  {
    id: 'account',
    numeric: false,
    disablePadding: false,
    label: 'Account',
    align: 'left',
    sort: 'account_name',
  },
  {
    id: 'components_count',
    numeric: false,
    disablePadding: false,
    label: 'Components',
    align: 'right',
    sort: 'components_count',
  },
  {
    id: 'delete',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: '',
  },
];

export const diagramsHeadersPlus = [...diagramsHeaders.slice(0, -2), ...[{
  id: 'company',
  numeric: false,
  disablePadding: false,
  label: 'Company',
  align: 'left',
  sort: 'company_name',
}], ...diagramsHeaders.slice(-2)]