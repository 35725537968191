import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { Modal, modalSelectors } from '../../../../packages';

import { customerDetailsSelectors } from '../../../../entities';

import { accountModalName } from './consts';
import { AddAccountModalContent } from './AddAccountModalContent';

export const AddAccountModal = memo(() => {
  const data = useSelector((state) =>
    modalSelectors.getModalData(state, accountModalName)
  );
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, data)
  );

  return (
    <Modal
      name={accountModalName}
      header={selectedAccount ? 'Edit account' : 'Add new account'}
      styles={{ width: '500px' }}
    >
      <AddAccountModalContent />
    </Modal>
  );
});
