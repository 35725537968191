import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import { NavLink } from 'react-router-dom';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';

import { BreadcrumbLink, WrapBreadcrumbs } from './styled';

export const Breadcrumbs = ({ items }) => {
  const mappedItems = [...items];

  if (items.length === 1) {
    // mock item for cases when we have only one item
    mappedItems.push({ name: '', to: 0 });
  }

  return (
    <WrapBreadcrumbs>
    <MuiBreadcrumbs>
      {mappedItems.map(({ name, to }, index) => {
        if (!name) {
          return <div />;
        }

        return (
          <Tooltip title={name} arrow>
            <BreadcrumbLink
              key={`${name}-breadcrumbs-${index}`}
              underline="hover"
              color={
                index === items.length - 1
                  ? colors.activeBreadcrumbsText
                  : colors.breadcrumbsText
              }
              to={to}
              component={NavLink}
            >
              {name}
            </BreadcrumbLink>
          </Tooltip>
        );
      })}
    </MuiBreadcrumbs>
    <BreadcrumbLink to={window.location.pathname.replace(/[^\/]+\/?$/gi, "")} component={NavLink} underline="hover">Back</BreadcrumbLink>
    </WrapBreadcrumbs>
  );
};
