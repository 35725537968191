import React, { useMemo } from 'react';

import { PageLayout } from '../../layouts';
import ProfileContentLayout from '../../layouts/page-layout/ProfileContentLayout';
import useApiData from '../../hooks/useApiData';
import { customerDetailsApi } from '../../entities';
import branding from '../../branding';

const FEATURE_GROUPS = {
  pipelineSecurity: 'pipeline-security',
  threatModeling: 'threat-modeling',
  platformSecurity: 'platform-security',
};

const FEATURE_GROUP_NAMES = {
  [FEATURE_GROUPS.pipelineSecurity]: 'Pipeline security',
  [FEATURE_GROUPS.threatModeling]: 'Threat modeling',
  [FEATURE_GROUPS.platformSecurity]: 'Platform security',
};

const GROUPS_ORDER = [FEATURE_GROUPS.pipelineSecurity, FEATURE_GROUPS.threatModeling, FEATURE_GROUPS.platformSecurity];

const FEATURE_NAMES_BY_PREFIX = {
  azure_dev_scan: 'Azure dev scan limit',
  github_dev_scan: 'Github dev scan limit',
  tm_diagram_upload: 'Tm diagram upload limit',
  aws_scan: 'Aws scan limit',
  azure_scan: 'Azure scan limit',
};

const VALUE_SUFFICS = {
  LIMIT_VALUE: '_limit',
  IS_UNLIMITED: '_is_unlimited',
};

export const PageFeatures = () => {
  const { data, dirty, loading, mutate } = useApiData(customerDetailsApi.getCurrent, [], {});

  /*
  {
   {
    "id": 110,
    "name": "Company name",
    "status": "completed",
    "features": {
          "pipeline-security": {
              "azure_dev_scan_limit": 1,
              "azure_dev_scan_is_unlimited": false,
              "github_dev_scan_limit": 1,
              "github_dev_scan_is_unlimited": false
          },
          "threat-modeling": {
              "tm_diagram_upload_limit": 10,
              "tm_diagram_upload_is_unlimited": false
          },
          "platform-security": {
              "aws_scan_limit": 1,
              "aws_scan_is_unlimited": false,
              "azure_scan_limit": 1,
              "azure_scan_is_unlimited": false
          }
      }
    }
  }
  */

  const list = useMemo(() => {
    const features = data?.features;

    if (!features) {
      return [];
    }

    const temp = [];

    GROUPS_ORDER.forEach((group) => {
      if (!features[group]) {
        return;
      }

      Object.keys(features[group]).forEach((key) => {
        const featureName = key.replace(VALUE_SUFFICS.LIMIT_VALUE, '').replace(VALUE_SUFFICS.IS_UNLIMITED, '');

        if (temp.find((i) => i.featureName === featureName)) {
          // already exist
          return;
        }

        const title = FEATURE_NAMES_BY_PREFIX[featureName];

        if (!title) {
          // Not defined
          return;
        }

        const limitValue = features[group][`${featureName}${VALUE_SUFFICS.LIMIT_VALUE}`];
        const isUnlimited = features[group][`${featureName}${VALUE_SUFFICS.IS_UNLIMITED}`];

        temp.push({
          featureName,
          group,
          title,
          limitValue,
          isUnlimited,
        });
      });
    });

    return temp;
  }, [data]);

  const listByGroup = useMemo(
    () =>
      list.sort((a, b) => {
        const idxA = GROUPS_ORDER.findIndex((group) => group === a.group);
        const idxB = GROUPS_ORDER.findIndex((group) => group === b.group);

        if (idxA > idxB) {
          return 1;
        }

        if (idxA === idxB) {
          return 0;
        }

        return -1;
      }),
    [list]
  );

  return (
    <PageLayout>
      <ProfileContentLayout title='' styleContent={{ padding: 0, background: 'unset' }}>
        <div
          style={{
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '34px',
            lineHeight: '40px',
            color: branding.colors.primary,
          }}
        >
          {data.name}
        </div>
        {listByGroup.map((i, idx) => {
          let group = null;

          if (i.group !== listByGroup?.[idx - 1]?.group) {
            return (
              <>
                <TitleGroup key={`group-${idx}`} title={FEATURE_GROUP_NAMES[i.group]} />
                <TileFeature
                  key={i.featureName}
                  title={i.title}
                  limitValue={i.limitValue}
                  isUnlimited={i.isUnlimited}
                />
              </>
            );
          }

          return (
            <TileFeature key={i.featureName} title={i.title} limitValue={i.limitValue} isUnlimited={i.isUnlimited} />
          );
        })}
      </ProfileContentLayout>
    </PageLayout>
  );
};

const TitleGroup = (props) => {
  const { title } = props;

  return (
    <div
      style={{
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#535353',
        margin: '20px 0 0 0',
      }}
    >
      {title}
    </div>
  );
};

const TileFeature = (props) => {
  const { title, limitValue, isUnlimited } = props;

  return (
    <div
      style={{
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        display: 'inline-block',
        padding: '20px',
        background: '#FFFFFF',
        borderRadius: '10px',
        marginRight: '13px',
        marginTop: '12px',
        minWidth: '330px',
      }}
    >
      <div
        style={{
          fontSize: '16px',
          color: '#000000',
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: '24px',
          color: branding.colors.primary,
        }}
      >
        {isUnlimited ? 'Unlimited' : limitValue}
      </div>
    </div>
  );
};
