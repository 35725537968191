import React, { useMemo } from 'react';
import { threatModelingApi } from '../../entities';
import { PageLayout } from '../../layouts';
import { DiagramsPageWrapper } from './styled';
import { DeleteDiagramModal } from './components';
import Header from './components/header';
import { CardOverallCompliance } from './components/card-overall-compliance';
import { CardStatusProgress, toPercent } from './components/card-status-progress';
import { useURLQueryParam } from '../../packages/utils/router';
import useApiData from '../../hooks/useApiData';
import TMMostUsed from './components/most-used';
import ButtonSelectDiagrams from './components/button-select-diagrams';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import branding from '../../branding';
import ButtonExportPdf from './components/button-export-pdf';

export const PageDashboard = () => {
  const { value: teams } = useURLQueryParam('teams');
  const { value: users } = useURLQueryParam('users');
  const { value: diagrams } = useURLQueryParam('diagrams');
  const { value: range } = useURLQueryParam('range');

  const query = useMemo(() => {
    const cont = {};
    if (teams) {
      cont.teams = teams;
    }

    if (users) {
      cont.users = users;
    }

    if (diagrams) {
      cont.diagrams = diagrams;
    }

    if (range) {
      cont.range = range;
    }
    return cont;
  }, [teams, users, diagrams, range]);

  const status = useApiData(threatModelingApi.dashboardStatusProgressCompliance, [query], {
    overall_compliance: {
      status: {
        // count
        unresolved: 0,
        in_progress: 0,
        resolved: 0,
      },
      with_resource: 0, // percent
    },
    status_progress: [],
  });

  const overallCompliance = status.data.overall_compliance;
  const statusProgress = status.data.status_progress;
  const countAll =
    overallCompliance.status.unresolved + overallCompliance.status.in_progress + overallCompliance.status.resolved;
  const unresolved = toPercent(overallCompliance.status.unresolved, countAll);
  const inProgress = toPercent(overallCompliance.status.in_progress, countAll);
  const resolved = toPercent(overallCompliance.status.resolved, countAll);
  const withResource = overallCompliance.with_resource;
  const withoutResource = 100 - overallCompliance.with_resource;

  const exportPdf = () => {
    const input = document.getElementById('dashboard-report-wrapper');
    html2canvas(input, {
      scrollY: -window.scrollY,
      scrollX: -window.scrollX,
      scale: 1.5,
      dpi: 300,
    }).then((canvas) => {
        const margin = 5;
        const img = canvas.toDataURL('image/png');
        const doc = new jsPDF('l', 'mm', 'a4');
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        doc.setFillColor(branding.colors.lightBlue);
        doc.rect(0, 0, width, height, 'F');

        const imgWidth = width - (2 * margin);
        const imgHeight = height - (2 * margin);
        const ratio = imgHeight / imgWidth;
        const xMargin = imgWidth * (1 - ratio) / 2;

        doc.addImage(
          img,
          'PNG',
          xMargin,
          margin,
          imgWidth * ratio,
          imgHeight,
        );

        doc.save('dashboard.pdf');
      });
  };

  return (
    <PageLayout>
      <DiagramsPageWrapper>
        <Header style={{ width: 1055 }} rightComponent={
          <div style={{ display: 'flex' }}>
          <ButtonExportPdf onClick={ exportPdf } />
          <ButtonSelectDiagrams />
          </div>
        }/>
        <div id="dashboard-report-wrapper" style={{ display: 'flex', flexFlow: 'column', width: 1055, backgroundColor: branding.colors.lightBlue }}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <CardOverallCompliance
              // keyUpdate={history.location.search}
              cardStyle={{ minWidth: 330, marginRight: 22 }}
              unresolved={unresolved}
              inProgress={inProgress}
              resolved={resolved}
              withResource={withResource}
              withoutResource={withoutResource}
            />
            <CardStatusProgress statusProgress={statusProgress}/>
          </div>
          <TMMostUsed query={query} />
        </div>
      </DiagramsPageWrapper>
      <DeleteDiagramModal/>
    </PageLayout>
  );
};
