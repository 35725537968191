const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = ({ x, y, radius, spread, startAngle, endAngle }) => {
  const innerStart = polarToCartesian(x, y, radius, endAngle);
  const innerEnd = polarToCartesian(x, y, radius, startAngle);
  const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
  const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    outerStart.x,
    outerStart.y,
    'A',
    radius + spread,
    radius + spread,
    0,
    largeArcFlag,
    0,
    outerEnd.x,
    outerEnd.y,
    'L',
    innerEnd.x,
    innerEnd.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    1,
    innerStart.x,
    innerStart.y,
    'L',
    outerStart.x,
    outerStart.y,
    'Z',
  ].join(' ');

  return d;
};
const formatPercentsToAngle = (percent) => (180 * percent) / 100;

const getArcSettings = ({
  x = 100,
  y = 120,
  radius = 100,
  spread = 20,
  startAngle = 0,
  endAngle = 180,
}) => ({
  x,
  y,
  radius,
  spread,
  startAngle,
  endAngle,
});

export const getLinePath = (percent, radius) =>
  describeArc(
    getArcSettings({ endAngle: formatPercentsToAngle(percent), radius })
  );
