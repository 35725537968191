import { useSelector } from 'react-redux';
import { appSelectors } from '../../../entities';

export const getActions = (resource) => {
  const profile = useSelector(appSelectors.getProfile);

  if (!(profile && profile.permissions)) {
    return {
      company: [],
      team: [],
      self: [],
    };
  }

  const permsCompany = profile.permissions.filter(i => i.scope === "company" && i.resource === resource);
  const permsTeam = profile.permissions.filter(i => i.scope === "team" && i.resource === resource);
  const permsSelf = profile.permissions.filter(i => i.scope === "self" && i.resource === resource);

  let actionsCompany = {};
  for (const permission of permsCompany) {
    actionsCompany[permission.action] = permission;
  }

  let actionsTeam = {};
  for (const permission of permsTeam) {
    actionsTeam[permission.action] = permission;
  }

  let actionsSelf = {};
  for (const permission of permsSelf) {
    actionsSelf[permission.action] = permission;
  }

  return {
    company: actionsCompany,
    team: actionsTeam,
    self: actionsSelf,
  };
};

const Permission = (props) => {
  const { resource, children } = props;
  const actions = getActions(resource);

  return children(actions);
};

export default Permission;
