import styled from 'styled-components';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import CheckboxMarkedCircleOutlineIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.darkBorderColor};
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  height: 112px;
  margin-bottom: 16px;
  transition: 0.2s;

  :hover {
    transform: scale(1.01);
  }

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const ServiceInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ServiceLogo = styled.img`
  width: 80px;
  height: 80px;
  margin-left: 24px;
`;

export const ServiceName = styled.div`
  display: flex;
  margin-left: 16px;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBorderColor};
`;

export const PlusIcon = styled(PlusCircleOutlineIcon)`
  margin-right: 36px;
`;

export const CheckIcon = styled(CheckboxMarkedCircleOutlineIcon)`
  margin-right: 36px;
`;
