import React, { useCallback } from 'react';
import { ConnectionLineType, MarkerType } from 'reactflow';
import InputText from '../controls/InputText';
import Select from '../controls/Select';
import Checkbox from '../controls/Checkbox';

const FormEdgeDetails = (props) => {
  const {
    id,
    label,
    type,
    animated,
    markerStart = {},
    markerEnd = {},
    data = {},
    onChange: onChangeOut,
    controls,
  } = props;

  console.log('FormEdgeDetails', props);
  const onFieldChange = useCallback(
    (field, value) => {
      const changes = {
        id: `${id}`,
        data: {},
      };

      if (field === 'markerStart' || field === 'markerEnd') {
        changes[field] = value
          ? { type: value }
          : { ...(field === 'markerStart' ? markerStart : markerEnd), type: value };
        changes.type = type;
      } else {
        changes[field] = value;
      }

      onChangeOut(changes);
    },
    [id, type, onChangeOut, markerStart, markerEnd]
  );

  return (
    <div>
      <h3>Label</h3>
      <InputText
        label='Text'
        placeholder='Label'
        value={label}
        onChange={(e) => onFieldChange('label', e.target.value)}
        custom={controls?.text}
      />
      <h3>Connection</h3>
      <Checkbox
        label='Animated'
        value={animated}
        onChange={(e) => onFieldChange('animated', e.target.checked)}
        custom={controls?.checkbox}
      />
      <Select
        label='Line type'
        value={type || ConnectionLineType.Bezier}
        options={[
          { value: ConnectionLineType.Bezier, label: 'Bezier' },
          { value: ConnectionLineType.Straight, label: 'Straight' },
          { value: ConnectionLineType.Step, label: 'Step' },
          { value: ConnectionLineType.SmoothStep, label: 'SmoothStep' },
          { value: ConnectionLineType.SimpleBezier, label: 'SimpleBezier' },
        ]}
        onChange={(e) => onFieldChange('type', prepareConnectionLineType(e))}
        custom={controls?.select}
      />
      <Select
        label='Marker start'
        value={markerStart?.type || '-'}
        options={[
          { value: '', label: '-' },
          { value: MarkerType.Arrow, label: 'Arrow' },
          { value: MarkerType.ArrowClosed, label: 'Arrow closed' },
        ]}
        onChange={(e) => onFieldChange('markerStart', prepareMarker(e))}
        custom={controls?.select}
      />
      <Select
        label='Marker end'
        value={markerEnd?.type || '-'}
        options={[
          { value: '', label: '-' },
          { value: MarkerType.Arrow, label: 'Arrow' },
          { value: MarkerType.ArrowClosed, label: 'Arrow closed' },
        ]}
        onChange={(e) => onFieldChange('markerEnd', prepareMarker(e))}
        custom={controls?.select}
      />
    </div>
  );
};

export default FormEdgeDetails;

const prepareConnectionLineType = (e) => {
  const val = e.target.options[e.target.selectedIndex].value;

  return val === ConnectionLineType.Bezier ? undefined : val;
};

const prepareMarker = (e) => {
  const val = e.target.options[e.target.selectedIndex].value;

  return val;
};
