import React from "react";

const InputText = ({ label, value, placeholder, onChange, custom: Custom }) => {
  if (Custom) {
    return <Custom label={label} value={value} placeholder={placeholder} onChange={onChange} />;
  }

  return (
    <div>
      <div>{label}</div>
      <input type="text" value={value} placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default InputText;
