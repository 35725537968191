import { setPagination, useQuery } from "../../packages";

import { platformSecurityApi } from "./api";
import { platformSecurityActions } from "./store";

const getResources = (query) => async (dispatch) => {
  const KEY = "ps-resources";
  try {
    const { scanId, accountId, page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery(KEY);
    dispatch(platformSecurityActions.setIsLoading(true));
    const { results, ...pagination } = await platformSecurityApi.getResources(accountId, scanId, {
      page: page + 1,
      ...otherQueryParams,
      ...rest,
    });
    console.log("results", results);
    dispatch(platformSecurityActions.setResources(results));

    setPagination(KEY, { ...pagination, page });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(platformSecurityActions.setIsLoading(false));
  }
};

const getResourcesStats = (scanId, accountId) => async (dispatch) => {
  try {
    const result = await platformSecurityApi.getResourcesStats(accountId, scanId);
    dispatch(platformSecurityActions.setResourcesStats(result));
  } catch (e) {
    console.error(e);
  }
};

const getResource = (accountId, scanId, resourceId) => async (dispatch) => {
  try {
    const data = await platformSecurityApi.getResource(accountId, scanId, resourceId);
    dispatch(platformSecurityActions.setResource({ resourceId, data }));
  } catch (e) {
    console.error(e);
  }
};

const getResourceControls = (query) => async (dispatch) => {
  const KEY = "ps-resource-controls";
  try {
    const { scanId, accountId, resourceId, page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery(KEY);
    dispatch(platformSecurityActions.setResourceControlsIsLoading(true));
    const { results, ...pagination } = await platformSecurityApi.getResourceControls(accountId, scanId, resourceId, {
      page: page + 1,
      ...otherQueryParams,
      ...rest,
    });

    dispatch(platformSecurityActions.setResourceControls({ resourceId, data: results }));

    setPagination(KEY, { ...pagination, page });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(platformSecurityActions.setResourceControlsIsLoading(false));
  }
};

const getResourceControlPolicies = (query) => async (dispatch) => {
  const KEY = "ps-resource-control-policies";
  try {
    const { scanId, accountId, resourceId, controlId, page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery(KEY);
    dispatch(platformSecurityActions.setResourceControlPoliciesIsLoading(true));
    const { results, ...pagination } = await platformSecurityApi.getResourceControlPolicies(
      accountId,
      scanId,
      resourceId,
      controlId,
      {
        page: page + 1,
        ...otherQueryParams,
        ...rest,
      }
    );

    dispatch(platformSecurityActions.setResourceControlPolicies({ resourceId, controlId, data: results }));

    setPagination(KEY, { ...pagination, page });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(platformSecurityActions.setResourceControlPoliciesIsLoading(false));
  }
};

export const platformSecurityService = {
  getResources,
  getResource,
  getResourcesStats,
  getResourceControls,
  getResourceControlPolicies,
};
