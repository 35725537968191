import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useIntersection from "./intersection";

export const useLazyList = (request, args = [], query = {}) => {
  const ref = useMemo(() => ({ current: null }), []);
  const [el, setEl] = useState(null);
  const intersection = useIntersection(el);

  const intersectionRef = useCallback(
    (elem) => {
      if (elem && (!ref.current || ref.current !== elem)) {
        ref.current = elem;
        setEl(elem);
      }
    },
    [ref]
  );

  const fast = useRef([]);
  const currentPage = useRef();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [ended, setEnded] = useState(false);

  useEffect(() => {
    currentPage.current = 0;
    fast.current = [];
    setData([]);
    setPage(1);
    setEnded(false);
  }, [JSON.stringify(args), JSON.stringify(query)]);

  const getPage = useCallback(() => {
    if (ended === true || currentPage.current > page) {
      return;
    }
    currentPage.current = page + 1;

    request(...args, { page, ...(query || {}) })
      .then((response) => {
        const newData = [...fast.current, ...response.results];
        fast.current = newData;
        setData(newData);
        if (response.next) {
          setPage(page + 1);
        } else {
          setEnded(true);
        }
      })
      .catch((e) => {
        // Error: {"detail":"Invalid page."}
        setPage(1);
      });
  }, [page, ended]);

  const ratio = intersection?.intersectionRatio || 0;

  useEffect(() => {
    if (ended === true || currentPage.current > page) {
      return;
    }

    if (intersection && ratio > 0.1) {
      getPage();
    }
  }, [ratio, ended, page]);

  return useMemo(() => ({ data, current: currentPage.current, ended, intersectionRef }), [data.length, page, ended]);
};
