import { NotificationManager } from 'react-notifications';

export const handleError = (error) => {
  console.error(error);
  try {
    const errorModel = JSON.parse(error.message);

    const errorMessage = errorModel.detail || errorModel.error;

    if (errorMessage) {
      NotificationManager.error(
        JSON.parse(error.message).detail || JSON.parse(error.message).error
      );

      return;
    }

    const formErrors = Object.entries(errorModel);

    if (formErrors.length) {
      formErrors.forEach(([_field, errors]) => {
        NotificationManager.error(errors);
      });
    }
  } catch (e) {
    NotificationManager.error(
      'Something went wrong, please contact with your administrator'
    );
  }
};
