import React from 'react';
import { styled } from '@mui/material/styles';
import DefaultTextField from '@mui/material/TextField';
import { Checkbox } from '../checkbox/Checkbox';

const PureTextField = styled(DefaultTextField)(({ theme }) => ({
  '& .MuiTextField-root': {
    boxSizing: 'border-box',
  },
  '& .MuiInputBase-input': {
    padding: 10,
    fontFamily: 'Raleway, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Raleway, sans-serif',
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-active': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-selected': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiOutlinedInput-input': {
    color: theme.colors.textColor,
    fontFamily: 'Raleway, sans-serif',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: theme.colors.activeTextColor,

    '&.Mui-focused fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '& fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '&:hover fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '&:focus fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
  },
}));

const PureFileField = styled(DefaultTextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  '& .MuiInputBase-root': {
    position: 'unset',
  },
  '& .MuiInputBase-input': {
    position: 'absolute',
    height: 'unset',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    cursor: 'pointer',
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent !important',
    },
  },
}));

export const CheckboxField = (props) => {
  const { label, id, name, color, onChange, style, ...rest } = props;

  return (
    <Checkbox
      label={label}
      id={id || name}
      name={name}
      color={color || 'primary'}
      component='field'
      onChange={(val, event) => onChange(event, val)}
      style={style}
      {...rest}
    />
  );
};
