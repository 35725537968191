import styled from 'styled-components';
import { scrollable } from '../../../theme';

export const WrapperList = styled.div`
  min-height: ${props => props.height || 205}px;
  max-height: ${props => props.height || 205}px;
  padding: 0;
  background: ${({ theme }) => theme.colors.secondaryBackground};

  ${scrollable}
`;
