import styled from 'styled-components';
import MagnifyMinusOutlineIcon from 'mdi-react/MagnifyMinusOutlineIcon';

export const EmptyCustomerWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const NotFoundIcon = styled(MagnifyMinusOutlineIcon)`
  display: block;
  fill: ${({ theme }) => theme.colors.darkBorderColor};
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const MessageWrapper = styled.p`
  color: ${({ theme }) => theme.colors.darkBorderColor};
  font-size: 16px;
  max-width: 500px;
  text-align: center;
`;

export const LinkToCustomerDetails = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.activeBackground};
  cursor: pointer;
`;

export const AccountName = styled.span`
  font-weight: 600;
`;
