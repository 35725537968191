import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { memo, useCallback } from 'react';

import { Box } from '@mui/material';

import { Tooltip } from '../../../../packages';

import { SelectComponentIcon } from './styled';

export const SelectComponentButton = memo(({ number: id, position = {} }) => {
  const onClickHandler = useCallback(() => {
    const { zoomToElement } = window.transformComponentRef;
    zoomToElement(`${id}-element-highlight`);

    if (window.innerWidth <= 1024) {
      const diagramView = document.getElementById('diagram-view');

      diagramView.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [id]);

  const shouldShowButton = Object.keys(position).length;
  const Wrapper = shouldShowButton ? Tooltip : Box;

  return (
    <Wrapper title={shouldShowButton ? 'Show component' : 'Not selected'}>
      <IconButton onClick={onClickHandler} disabled={!shouldShowButton}>
        {shouldShowButton ? <SelectComponentIcon /> : <div />}
      </IconButton>
    </Wrapper>
  );
});
