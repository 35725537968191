import React, { useCallback, useState } from "react";

import { useDispatch } from "react-redux";

import { useParams } from "react-router";

import { Card, HelpMessage, Search } from "../../../../packages";

import { colors } from "../../../../theme";
import { CardHeader, HeaderLabel } from "./styled";
import { platformSecurityService } from "../../../../entities/platform-security/service";
import { ResourcesTable } from "../resources-table";

export const ResourcesList = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const params = useParams();
  const { accountId, scanId, ppToolToken, ppFeature } = params;

  const onSearchHandler = useCallback(
    (value) => {
      setSearchValue(value);
      dispatch(
        platformSecurityService.getResources({
          scanId,
          accountId,
          ppFeature,
          ppToolToken,
          search: value,
        })
      );
    },
    [accountId, scanId, ppToolToken, ppFeature]
  );

  return (
    <Card
      header={
        <CardHeader>
          <HeaderLabel>
            {"List of resources"}
          </HeaderLabel>
          <Search
            styles={{
              color: colors.textColor,
              borderBottom: `1px solid ${colors.borderColor}`,
            }}
            wrapperStyles={{
              width: "auto",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            searchValue={searchValue}
            onSearch={onSearchHandler}
          />
          <HelpMessage
            color={colors.helpMessageColor}
            title="This is the list of Controls (policy groups) of a chosen Standard. Each control has its compliance % according to a specific scan results. Click on the Control’s name to view the list of policies in it and its compliance status."
          />
        </CardHeader>
      }
      style={{ marginBottom: 20 }}
    >
      <ResourcesTable searchValue={searchValue} />
    </Card>
  );
};
