import styled from 'styled-components';

import { sm, xsm } from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;

  @media screen and (max-width: ${sm}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const PipelineInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  max-height: 90px;

  @media screen and (max-width: ${sm}) {
    margin-top: 20px;
    width: 100%;
    max-height: unset;
  }
`;

export const PipelineDataLabel = styled.div`
  overflow: hidden;
  max-height: 12px;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor};
  text-align: left;
  margin-bottom: 8px;
`;

export const Separator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.activeTextColor};
  max-width:0px;
`;

export const PipelineMenu = styled.div`
  font-size: 12px;
  line-height: 1;
  display: flex;
  max-height: 55px;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.activeTextColor};
  border: ${({ theme }) => theme.colors.activeTextColor} 1px solid;
  border-radius: 6px;
  text-align: left;
  margin: 8px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 360px;
  border-radius: 8px 0 0 8px;
  background: ${({ theme }) => theme.colors.activeBackground};

  @media screen and (max-width: ${sm}) {
    width: 100%;
  }
`;

/*
export const AristiunLogo = styled.div`
  font-weight: bold;
  font-size: 28px;
  height: 100%;
  display: flex;
  margin-left: 30px;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.activeBackground};

  @media screen and (max-width: ${sm}) {
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;
*/

export const LogoTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  margin-left: 12px;
`;

export const LogoSvg = styled.svg`
  fill: none;
  width: 88px;
  height: 56px;
`;
