import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { colors, md, xsm } from '../../../theme';
import { SecondaryTextField, TextField } from '../../../packages';

import arrowUrl from '../images/arrow.png';
import fileIconUrl from '../images/file.png';

export const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: ${md}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Box = styled.div`
  flex-grow: 1;
  min-width: 520px;
`;

export const Title = styled.h1`
  font-size: 48px;
  line-height: 72px;
  font-weight: 400;
  color: ${() => colors.textColor};
`;

export const WarningLabel = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #535353;
  margin-right: 5px;
`;

export const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #535353;
`;

export const WelcomeLabel = styled(Title)`
  margin-bottom: 16px;

  @media screen and (max-width: ${xsm}) {
    font-size: 42px;
  }
`;

export const InfoBox = styled(Box)`
  max-width: 520px;
  width: 100%;
  min-width: 400px;

  @media screen and (max-width: ${xsm}) {
    min-width: 300px;
  }
`;

export const ImageBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${md}) {
    display: none;
  }
`;

export const UserNameField = muiStyled(TextField)(() => ({
  marginTop: 48,
  marginBottom: 12,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: 15,
  },
}));

export const ConfirmationCodeField = muiStyled(TextField)(() => ({
  marginTop: 48,
  marginBottom: 12,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: 15,
  },
}));

export const EmailField = muiStyled(SecondaryTextField)(() => ({
  marginTop: 18,
  width: '100%',
  marginBottom: 0,
}));

export const CheckboxWrapper = styled.div`
  margin-top: 32px;
`;

export const ContinueButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  @media screen and (max-width: ${md}) {
    margin-bottom: 9vh;
  }
`;

export const StartScanButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const HelpMessage = styled.div`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  margin-top: 32px;
`;

export const Image = styled.img``;

export const ArrowRight = styled.img.attrs({
  src: arrowUrl,
})`
  margin-right: 16px;
  width: 20px;
  height: 20px;
`;

export const FileIcon = styled.img.attrs({
  src: fileIconUrl,
})`
  margin-right: 16px;
  width: 20px;
  height: 20px;
`;

export const ScanningPageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ScanningStatus = styled.div`
  font-size: 48px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.textColor};
  text-align: center;
`;

export const ScanningDescription = styled.div`
  font-size: 16px;
  line-height: 1;
  margin-top: 32px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  text-align: center;
`;
