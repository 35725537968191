import React, { useCallback, useEffect, useRef, useState } from 'react';
import { customerApi, threatModelingApi } from '../../entities';
import useSelectLazyField from '../../packages/utils/useSelectLazyField';
import { WrapClearFilters, WrapFilter } from './styled';
import { Select } from '../../packages';

export const FILTERS_BY = {
  THREAT_COMPANY: 'component_type__company_id',
  COMPONENT_TYPE_COMPANY: 'company_id',
  COMPONENT: 'component_type',
  THREAT_SERVICE: 'component_type__service',
  COMPONENT_TYPE_SERVICE: 'service',
  REQUIRE_MODERATION: 'require_moderation',
  IS_DRAFT: 'is_draft',
};

const Filters = (props) => {
  const { filters = [], onChange } = props;
  const [query, setQuery] = useState({});

  const updateQuery = useCallback(
    (name, value) => {
      const newQuery = { ...query };
      if (value === undefined || value == '') {
        delete newQuery[name];
      } else {
        newQuery[name] = value;
      }
      setQuery(newQuery);
    },
    [query]
  );
  const refUpdate = useRef();
  refUpdate.current = updateQuery;

  useEffect(() => {
    onChange(query);
  }, [query]);

  const SelectThreatCompanyOptions = useSelectLazyField(
    {
      label: 'By company',
      request: threatModelingApi.getModerationCompanies,
      mapFunc: (i) => ({ id: i.id, label: i.name }),
      onChange: (e) => {
        console.log(e);
        refUpdate.current(FILTERS_BY.THREAT_COMPANY, e.target.value?.id);
      },
      keyUpdate: query[FILTERS_BY.THREAT_COMPANY],
      value: query[FILTERS_BY.THREAT_COMPANY],
    },
    { withSearch: true }
  );

  const SelectComponentTypeCompanyOptions = useSelectLazyField(
    {
      label: 'By company',
      request: threatModelingApi.getModerationCompanies,
      mapFunc: (i) => ({ id: i.id, label: i.name }),
      onChange: (e) => {
        console.log(e);
        refUpdate.current(FILTERS_BY.COMPONENT_TYPE_COMPANY, e.target.value?.id);
      },
      keyUpdate: query[FILTERS_BY.COMPONENT_TYPE_COMPANY],
      value: query[FILTERS_BY.COMPONENT_TYPE_COMPANY],
    },
    { withSearch: true }
  );

  const SelectComponentsOptions = useSelectLazyField(
    {
      label: 'By name',
      request: customerApi.getCustomersPipeline,
      mapFunc: (i) => ({ id: i.id, label: i.name }),
      onChange: (e) => {
        console.log(e);
        refUpdate.current(FILTERS_BY.COMPONENT, e.target.value?.id);
      },
      keyUpdate: query[FILTERS_BY.COMPONENT],
      value: query[FILTERS_BY.COMPONENT],
    },
    { withSearch: true }
  );

  return (
    <WrapFilter>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
        <h2 className='title'>Filter</h2>
        {!!Object.keys(query).length && (
          <WrapClearFilters onClick={() => setQuery({})}>
            <span>✖ Clear all filters</span>
          </WrapClearFilters>
        )}
      </div>
      <div className='content'>
        {filters.includes(FILTERS_BY.IS_DRAFT) && (
          <Select
            label='By is draft'
            options={[
              { value: '', label: 'All' },
              { value: 1, label: 'Yes' },
              { value: 0, label: 'No' },
            ]}
            onChange={(e) => updateQuery('is_draft', e.target.value)}
            value={query[FILTERS_BY.IS_DRAFT] || ''}
          />
        )}
        {filters.includes(FILTERS_BY.COMPONENT_TYPE_COMPANY) && SelectComponentTypeCompanyOptions}
        {filters.includes(FILTERS_BY.THREAT_COMPANY) && SelectThreatCompanyOptions}
        {filters.includes(FILTERS_BY.COMPONENT) && SelectComponentsOptions}
        {filters.includes(FILTERS_BY.THREAT_SERVICE) && (
          <Select
            label='By service'
            options={[
              { value: '', label: 'All' },
              { value: 'aws', label: 'Aws' },
              { value: 'azure', label: 'Azure' },
              { value: 'other', label: 'Other' },
            ]}
            onChange={(e) => updateQuery(FILTERS_BY.THREAT_SERVICE, e.target.value)}
            value={query[FILTERS_BY.THREAT_SERVICE] || ''}
          />
        )}
        {filters.includes(FILTERS_BY.COMPONENT_TYPE_SERVICE) && (
          <Select
            label='By service'
            options={[
              { value: '', label: 'All' },
              { value: 'aws', label: 'Aws' },
              { value: 'azure', label: 'Azure' },
              { value: 'other', label: 'Other' },
            ]}
            onChange={(e) => updateQuery(FILTERS_BY.COMPONENT_TYPE_SERVICE, e.target.value)}
            value={query[FILTERS_BY.COMPONENT_TYPE_SERVICE] || ''}
          />
        )}
        {/* TODO hidden */}
        {false && filters.includes(FILTERS_BY.REQUIRE_MODERATION) && (
          <Select
            label='By require moderation'
            options={[
              { value: '', label: 'All' },
              { value: '1', label: 'Yes' },
              { value: '0', label: 'No' },
            ]}
            onChange={(e) => updateQuery('require_moderation', e.target.value)}
            value={query[FILTERS_BY.REQUIRE_MODERATION] || ''}
          />
        )}
      </div>
    </WrapFilter>
  );
};

export default Filters;
