import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloseIconButton, ModalHeader } from '../modal/styled';
import { styled as muiStyled } from '@mui/material/styles';
import DiagramEditor from '../diagram-editor';
import { TextField } from '../input';
import { Select } from '../../controls/select';
import { threatModelingApi } from '../../../entities';
import { ModalInline, useModalInline } from '../modal/ModalInline';
import { ComponentsFormInline } from '../../../pages/diagram-result/components/add-component-modal/components-form/ComponentsFormInline';
import { parseError } from '../../utils/parse';
import { NotificationManager } from 'react-notifications';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Button } from '../button';
import { history } from '../../utils';
import { PathNames } from '../../../consts';
import { AdditionalLogo } from '../../../layouts/headers/AdditionalLogo';
import NavigationPrompt from '../../../NavigationPromt';
import { EditorModalWrapper } from './styled';
import branding from '../../../branding';

const COMPONENT_CREATE_ALWAYS = true;

export const InputField = muiStyled(TextField)(() => ({
  marginBottom: 12,
  '&:last-child': {
    marginBottom: 0,
  },
}));

const controls = {
  text: (props) => <InputField type='text' {...props} />,
  number: (props) => <InputField type='number' {...props} />,
  checkbox: (props) => <FormControlLabel control={<Checkbox {...props} checked={props.value} />} label={props.label} />,
  color: (props) => <InputField type='color' {...props} style={{ width: '100%' }} />,
  select: (props) => (
    <Select
      {...props}
      onChange={(e) => {
        const value = e.target.value;
        const idx = props.options.findIndex((i) => i.value === value);

        // Data is simulated: const val = e.target.options[e.target.selectedIndex].value;
        props.onChange({
          target: {
            selectedIndex: idx,
            options: props.options,
          },
        });
      }}
      options={props.options.map((i) => ({ id: i.value, label: i.label }))}
    />
  ),
  button: ({ children, ...props }) => <Button {...props}>{children}</Button>,
};

export const EditorModal = ({
  editorStorageKey = 'editor-new-diagram',
  diagramId,
  isOpen,
  onClose: onCloseOut,
  name: initName = `Diagram-${Date.now()}`,
  header,
  styles,
  headerStyles,
  props,
  onUpdate = () => {},
  existingComponents = [],
  onUpdateExistingComponents = () => {},
}) => {
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [blockUnloadEvent, setBlockUnloadEvent] = useState(false);
  const [name, setName] = useState(initName);

  const savingInitialization = useRef();
  const setSavingInitialization = useCallback((cb) => (savingInitialization.current = cb), [savingInitialization]);

  const removeUnsavedDate = useRef();
  const setRemoveUnsavedDate = useCallback((cb) => (removeUnsavedDate.current = cb), [removeUnsavedDate]);

  const onSave = useCallback(
    async (data = { nodes: [], edges: [], background: {}, viewport: {} }) => {
      setSaving(true);
      if (diagramId) {
        const components = [];
        data.nodes.forEach((n) => {
          const meta = n.data?._meta || {};

          const exist = existingComponents.find((c) => c.manual_data?.id === n.id);
          if (exist) {
            components.push({ id: exist.id, label: n.data.label, manual_data: { id: n.id }, ...meta });
          } else {
            if (COMPONENT_CREATE_ALWAYS || meta.type) {
              components.push({ label: n.data.label, manual_data: { id: n.id }, ...meta });
            }
          }
        });

        const duplicated = {};
        existingComponents.forEach((i) => {
          if (!i.manual_data?.id) {
            components.push({ id: i.id, delete: true });
            return;
          }

          if (duplicated[i.manual_data?.id]) {
            components.push({ id: i.id, delete: true });
            return;
          }

          duplicated[i.manual_data?.id] = true;

          const find = data.nodes.find((n) => i.manual_data?.id === n.id);
          if (!find) {
            components.push({ id: i.id, delete: true });
          }
        });

        const result = await threatModelingApi
          .updateManualDiagram(diagramId, { manual_data: data, components, links: [] })
          .catch((err) => {
            console.log('threatModelingApi.updateManualDiagram', err);
            const text = parseError(err).detail || 'Server error';
            NotificationManager.error(text);
          });

        if (result) {
          setSaving(false);
          setChanged(false);
          onUpdate();
          onUpdateExistingComponents();
          return true;
        }
      } else {
        let result = await threatModelingApi.createManualDiagram({ filename: name }).catch((err) => {
          console.log('threatModelingApi.createManualDiagram', err);
          const error = parseError(err);
          const text = error.filename ? 'Diagram name is required' : 'Server error';
          NotificationManager.error(text);
        });

        /*
          {
              "id": 444,
              "filename": "manual-1702971312029",
              "manual_data": null,
              "preview": null,
              "company_name": "Yurii",
              "created_at": "2023-12-19T07:35:12.137375Z"
          }
        */

        if (result?.id) {
          const components = [];
          data.nodes.forEach((n) => {
            const meta = n.data?._meta || {};
            if (COMPONENT_CREATE_ALWAYS || meta.type) {
              components.push({ label: n.data.label, manual_data: { id: n.id }, ...meta });
            }
          });

          result = await threatModelingApi
            .updateManualDiagram(result.id, { manual_data: data, components, links: [] })
            .catch((err) => {
              console.log('threatModelingApi.updateManualDiagram', err);
              const text = parseError(err).detail || 'Server error';
              NotificationManager.error(text);
            });

          if (result) {
            setChanged(false);
            setSaving(false);
            onUpdate();
            history.push({
              pathname: PathNames.diagramResult.replace(':diagramId', result.id),
            });
            return true;
          }
        }
      }
    },
    [diagramId, existingComponents, onUpdate, setChanged, setSaving, onUpdateExistingComponents, name]
  );

  const [loadingComponent, setLoadingComponent] = useState(false);
  const {
    isOpen: isOpenComponent,
    onOpen: onOpenComponent,
    onClose: onCloseComponent,
    modalProps: modalPropsComponent,
  } = useModalInline();

  const onElementSettings = useCallback(
    (node, updateElement) => {
      const exist = existingComponents.find((i) => i.manual_data?.id === node.id);

      const node_id = node.id;
      const id = node.data?._meta?.id || exist?.id;
      const label = node.data?.label || exist?.label;
      const type = node.data?._meta?.type || exist?.type;
      const generic_type = node.data?._meta?.generic_type || exist?.generic_type;
      const resource = node.data?._meta?.resource || exist?.resource;

      const data = { id, node_id, label, type, generic_type, resource, updateElement };
      console.log('data', data);
      onOpenComponent(data);
    },
    [onOpenComponent, existingComponents]
  );

  const onSubmitComponent = useCallback(
    async (data) => {
      setLoadingComponent(true);

      const { label, ..._meta } = data;
      modalPropsComponent.updateElement({
        id: modalPropsComponent.node_id,
        data: {
          label,
          _meta,
        },
      });

      onCloseComponent();
      setLoadingComponent(false);
    },
    [diagramId, onCloseComponent, existingComponents, onUpdate, modalPropsComponent]
  );

  const transform = useCallback((item) => {
    /*
    {
        "id": 87,
        "name": "Azure Policy",
        "icon": "http://airbot.syntech.info/media/threatre/identified/Azur_7O88Iyw.svg",
        "type": 350,
        "type_verbose": "Azure Policy",
        "generic_type": null,
        "generic_type_verbose": null,
        "manual_data": null
    }
    */
    const { type, type_verbose, generic_type, generic_type_verbose } = item;

    const _meta = {
      type,
      type_verbose,
      generic_type,
      generic_type_verbose,
    };

    return {
      id: item.id,
      label: item.name,
      icon: item.icon,
      width: 70,
      height: 70,
      handles: item.manual_data?.handles || '01010101',
      _meta,
    };
  }, []);

  const [search, setSearch] = useState('');
  const activeSearch = search.length > 2;
  const librarySearch = useLibrary('', search, activeSearch, transform);
  const libraryAWS = useLibrary('aws', search, !activeSearch, transform);
  const libraryAzure = useLibrary('azure', search, !activeSearch, transform);

  const { isOpen: isOpenEditorClose, onOpen: onOpenEditorClose, onClose: onCloseEditorClose } = useModalInline();

  const {
    isOpen: isOpenUnsavedData,
    onOpen: onOpenUnsavedData,
    onClose: onCloseUnsavedData,
    modalProps: modalPropsUnsavedData,
  } = useModalInline();

  const onConfirmLoadUnsavedData = useCallback(
    (confirm, cancel) => {
      onOpenUnsavedData({ confirm, cancel });
    },
    [onOpenUnsavedData]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <EditorModalWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 24px 0 0' }}>
        <div style={{ display: 'inline-flex', fontSize: 25, fontWeight: 600 }}>
          <div
            style={{
              display: 'inline-flex',
              backgroundColor: branding.isBank ? undefined : '#2F6CF6',
              width: 210,
              height: 50,
            }}
          >
            <div style={{ display: 'flex', transform: branding.isBank ? 'scale(0.8)' : 'scale(0.7)' }}>
              <AdditionalLogo />
              {!branding.isBank && (
                <div style={{ margin: '5px 0 0 20px', color: 'white', transform: 'scale(1.2)' }}>Aristiun</div>
              )}
            </div>
          </div>
          <div style={{ margin: '5px 0 0 5px' }}>Diagram Editor</div>
          {console.log('NAME', name)}
          <div style={{ paddingLeft: 24, display: 'flex', alignItems: 'center' }}>
            <InputField
              placeholder='Diagram name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!!diagramId}
            />
          </div>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          {changed && (
            <Button
              onClick={async () => {
                setBlockUnloadEvent(true);
                const result = await savingInitialization.current?.();
                setBlockUnloadEvent(false);
              }}
              style={{ display: 'inline-block', margin: 'auto 0', padding: '2px 5px', height: 26 }}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
          )}
          <Button
            onClick={() => {
              if (changed) {
                onOpenEditorClose();
              } else {
                onCloseOut();
              }
            }}
            style={{ display: 'inline-block', margin: 'auto 0', padding: '2px 5px', height: 26 }}
          >
            Close
          </Button>
        </div>
        {/* <CloseIconButton
        onClick={() => {
          if (changed) {
            if (confirm('You have made changes, do you want to save them?')) {
              savingInitialization.current?.();
            }
            onClose();
            setChanged(false);
          } else {
            onClose();
          }
        }}
      /> */}
      </div>
      <div>
        <DiagramEditor
          editorStorageKey={editorStorageKey}
          width='100vw'
          height='calc(100vh - 56px)'
          {...props}
          controls={controls}
          onSave={onSave}
          onElementSettings={onElementSettings}
          onChangeNotice={() => setChanged(true)}
          changed={changed}
          registerSavingInitialization={(cb) => setSavingInitialization(cb)}
          registerRemoveUnsavedData={(cb) => setRemoveUnsavedDate(cb)}
          onConfirmLoadUnsavedData={onConfirmLoadUnsavedData}
          hideBasicLibrary={activeSearch}
          libraries={
            activeSearch
              ? [{ title: 'Result', data: librarySearch, open: true }]
              : [
                  { title: 'AWS', data: libraryAWS },
                  { title: 'Azure', data: libraryAzure },
                ]
          }
          libraryTopElement={
            <div>
              <InputField
                placeholder='Search'
                style={{ width: '204px', height: 40, margin: '3px' }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          }
        />
      </div>
      <ModalInline header='Component' isOpen={isOpenComponent} onClose={onCloseComponent}>
        <ComponentsFormInline
          key={`${diagramId}${modalPropsComponent?.type}${modalPropsComponent?.generic_type}`}
          diagramId={diagramId}
          component={modalPropsComponent}
          onCancel={onCloseComponent}
          onSubmit={onSubmitComponent}
          loading={loadingComponent}
        />
      </ModalInline>
      <ModalInline isOpen={isOpenEditorClose} header='Close editor' onClose={onCloseEditorClose}>
        <div>
          <div style={{ padding: '0 26px 16px 26px' }}>
            You haven't saved your data. Should close the editor anyway?
          </div>
          <div style={{ display: 'flex', gap: 26, justifyContent: 'flex-end', paddingRight: 26 }}>
            <Button
              onClick={() => {
                onCloseEditorClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeUnsavedDate.current?.();
                setChanged(false);
                onCloseEditorClose();
                onCloseOut();
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </ModalInline>
      <ModalInline isOpen={isOpenUnsavedData} header='Unsaved diagram data' onClose={onCloseUnsavedData}>
        <div>
          <div style={{ padding: '0 26px 16px 26px' }}>You have unsaved data. Show it?</div>
          <div style={{ display: 'flex', gap: 26, justifyContent: 'flex-end', paddingRight: 26 }}>
            <Button
              onClick={() => {
                setChanged(false);
                modalPropsUnsavedData.cancel?.();
                onCloseUnsavedData();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                modalPropsUnsavedData.confirm?.();
                setChanged(true);
                onCloseUnsavedData();
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </ModalInline>
      <NavigationPrompt when={changed && !blockUnloadEvent}>
        {({ isActive, onConfirm, onCancel }) => (
          <ModalInline isOpen={changed} header='Close editor' onClose={onCancel}>
            <div>
              <div style={{ padding: '0 26px 16px 26px' }}>
                You haven't saved your data. Should close the editor anyway?
              </div>
              <div style={{ display: 'flex', gap: 26, justifyContent: 'flex-end', paddingRight: 26 }}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  onClick={() => {
                    removeUnsavedDate.current?.();
                    setChanged(false);
                    onCancel();
                    onCloseOut();
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </ModalInline>
        )}
      </NavigationPrompt>
    </EditorModalWrapper>
  );
};

const useLibrary = (service, search, active = true, transform = (item) => item) => {
  const [page, setPage] = useState(1);
  const [elements, setElements] = useState({});
  const loadingCont = useRef({});

  const mainKey = `${service}_${search}`;

  useEffect(() => setPage(1), [search]);

  useEffect(() => {
    const key = `${mainKey}_${page}`;

    if (active && !loadingCont.current[key]) {
      loadingCont.current[key] = true;
      threatModelingApi
        .getManualDiagramLibrary(service, search, page)
        .then((d) => {
          const newElements = { ...elements };
          if (!newElements[mainKey]) {
            newElements[mainKey] = [];
          }

          loadingCont.current[key] = false;
          if (newElements[mainKey].findIndex((i) => i.next === d.next) === -1) {
            newElements[mainKey].push(d);
          }
          setElements(newElements);
        })
        .catch(() => {
          loadingCont.current[key] = false;
        });
    }
  }, [page, mainKey, active]);

  const list = useMemo(() => {
    let arr = [];

    if (elements[mainKey]) {
      elements[mainKey].forEach((d) => {
        arr = [...arr, ...d.results];
      });
    }

    return arr.map(transform);
  }, [elements, transform]);

  const ended = useMemo(
    () => elements[mainKey] && elements[mainKey]?.[elements[mainKey]?.length - 1]?.next === null,
    [elements, page, mainKey]
  );

  const loading = loadingCont.current?.[`${mainKey}_${page}`] || false;

  return {
    ended,
    onNextPage: () => (ended ? undefined : !loading && setPage(page + 1)),
    list,
    loading,
  };
};
