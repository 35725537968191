import { modalReducer, modalReducerNamespace } from './packages';
import {
  accountDetailsReducer,
  accountDetailsReducerNamespace,
  appReducer,
  appReducerNamespace,
  authReducer,
  authReducerNamespace,
  customerDetailsReducer,
  customerDetailsReducerNamespace,
  customerReducer,
  customerReducerNamespace,
  platformSecurityReducer,
  platformSecurityReducerNamespace,
  policyGroupReducer,
  policyGroupReducerNamespace,
  policyReducer,
  policyReducerNamespace,
  standardReducer,
  standardReducerNamespace,
  threatModelingReducer,
  threatModelingReducerNamespace,
} from './entities';

export const rootReducer = {
  [appReducerNamespace]: appReducer,
  [authReducerNamespace]: authReducer,
  [policyReducerNamespace]: policyReducer,
  [standardReducerNamespace]: standardReducer,
  [customerReducerNamespace]: customerReducer,
  [modalReducerNamespace]: modalReducer,
  [policyGroupReducerNamespace]: policyGroupReducer,
  [customerDetailsReducerNamespace]: customerDetailsReducer,
  [accountDetailsReducerNamespace]: accountDetailsReducer,
  [threatModelingReducerNamespace]: threatModelingReducer,
  [platformSecurityReducerNamespace]: platformSecurityReducer,
};
