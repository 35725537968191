import React, { memo } from 'react';

import { colors } from '../../../../theme';

import { Step } from './Step';
import {
  ArrowRight,
  AwsButton,
  Comment,
  DisabledAwsButton,
  HighlightedText,
  Image,
  ListItem,
  ListTopic,
  ListWrapper,
  PermissionLabel,
} from './styled';

import attachExisting from './icons/attach-existing.png';
import swap from './icons/swap.png';
import radioAws from './icons/radio-aws.png';
import radioLambda from './icons/radio-lambda.png';
import check from './icons/check.png';
import { JSONBlock } from './JSONBlock';
import { JSON_CONTENT } from './consts';
import branding from '../../../../branding';

export const AwsSteps = memo(() => (
  <>
    <Step title="Step 1">
      <ListWrapper>
        <ListTopic>
          Go to AWS console <ArrowRight />
          <HighlightedText color={colors.warning}>Users</HighlightedText>
        </ListTopic>
      </ListWrapper>
    </Step>
    <Step title="Step 2">
      <ListWrapper>
        <ListItem>
          Add new user by press button<AwsButton>Add users</AwsButton>
        </ListItem>
        <ListItem>
          Set <HighlightedText>User name</HighlightedText> (in the input field)
        </ListItem>
        <ListItem>
          <HighlightedText>Select AWS credential type,</HighlightedText>
          Set checkbox – <Image src={check} width="20px" />
          <HighlightedText>Access key Programmatic access</HighlightedText>
        </ListItem>
        <ListItem>
          Press <AwsButton>Next: Permissions</AwsButton>
        </ListItem>
        <ListItem>
          At the permissions page choose tab
          <Image src={attachExisting} width="140px" />
        </ListItem>
        <ListItem>
          Press button<DisabledAwsButton>Create policy</DisabledAwsButton>
        </ListItem>
        <ListItem>
          On <HighlightedText>Create policy</HighlightedText> page choose
          <HighlightedText>JSON</HighlightedText> and paste content:
        </ListItem>
        <JSONBlock value={JSON_CONTENT} />
        <ListItem>
          Press button<AwsButton>Next: Tags</AwsButton>
        </ListItem>
        <ListItem>
          Set policy <HighlightedText>Name</HighlightedText> (in the input
          field)
        </ListItem>
        <ListItem>
          Press button<AwsButton>Create policy</AwsButton>
        </ListItem>
        <ListItem>
          Go back to <HighlightedText>Add User</HighlightedText> screen.
        </ListItem>
        <ListItem>
          Click refresh <Image src={swap} width="30px" />
          User filter to find created Policy
        </ListItem>
        <ListItem>
          Choose it (set checkbox)
          <Image src={check} width="20px" />
          <HighlightedText>[Policy name]</HighlightedText>
        </ListItem>
        <ListItem>
          Press button<AwsButton>Next: Tags</AwsButton>
        </ListItem>
        <ListItem>
          Skip two screens by click<AwsButton>Next</AwsButton>
        </ListItem>
        <ListItem>
          At the last screen, click
          <HighlightedText color={colors.secondaryActiveTextColor}>
            Show
          </HighlightedText>
          the access secret.
        </ListItem>
        <ListItem>
          Copy values <HighlightedText>Access ID</HighlightedText> and
          <HighlightedText>Access secret</HighlightedText>
        </ListItem>
      </ListWrapper>
    </Step>
    <Step title="Step 3">
      <ListWrapper>
        <ListTopic>Optional, if you want to use custom rules:</ListTopic>
        <ListItem>
          Go to <HighlightedText color={colors.warning}>Roles</HighlightedText>
        </ListItem>
        <ListItem>
          Press button<AwsButton>Create role</AwsButton>
        </ListItem>
        <ListItem>On the next screen, choose type Use case</ListItem>
        <ListItem>
          Press button<AwsButton>Next</AwsButton>
        </ListItem>
        <ListItem>
          On the permissions page, you need to add 3 required permissions:
        </ListItem>
        <PermissionLabel>AWSConfigRole,</PermissionLabel>
        <PermissionLabel>AWSLambdaBasicExecutionRole,</PermissionLabel>
        <PermissionLabel>AWSConfigRulesExecutionRole</PermissionLabel>
        <Comment>We recommend using the filter.</Comment>
        <Comment>
          You can add more permissions as you need more capabilities to provide
          detailed or flexible checks.
        </Comment>
        <ListItem>
          When all permissions were selected, click<AwsButton>Next</AwsButton>
        </ListItem>
        <ListItem>
          On the next screen, set the role name and click
          <Image src={radioAws} width="100px" />
          Use case
          <Image src={radioLambda} width="80px" />
        </ListItem>
        <ListItem>
          Press button
          <AwsButton>Next</AwsButton>
        </ListItem>
        <ListItem>
          You will be forwarded to the list. Choose your next role
          <Image src={check} width="20px" />
          <HighlightedText>[Role name]</HighlightedText>
        </ListItem>
        <ListItem>
          Copy the <HighlightedText>ARN</HighlightedText> and send this ARN to
          {branding.platform}
        </ListItem>
      </ListWrapper>
    </Step>
  </>
));
