export const accountServiceModalName = 'account-service';
export const JSON_CONTENT =
  '{\n' +
  '    "Version": "2012-10-17",\n' +
  '    "Statement": [\n' +
  '        {\n' +
  '            "Sid": "VisualEditor0",\n' +
  '            "Effect": "Allow",\n' +
  '            "Action": [\n' +
  '                "lambda:CreateFunction",\n' +
  '                "config:GetAggregateConfigRuleComplianceSummary",\n' +
  '                "lambda:ListFunctions",\n' +
  '                "iam:PassRole",\n' +
  '                "config:PutConfigRule",\n' +
  '                "lambda:GetFunction",\n' +
  '                "lambda:PublishLayerVersion",\n' +
  '                "config:StartConfigRulesEvaluation",\n' +
  '                "config:GetComplianceDetailsByResource",\n' +
  '                "lambda:UpdateFunctionCode",\n' +
  '                "config:GetComplianceDetailsByConfigRule",\n' +
  '                "lambda:AddPermission",\n' +
  '                "config:DescribeConfigRules",\n' +
  '                "lambda:DeleteFunction",\n' +
  '                "lambda:PublishVersion",\n' +
  '                "config:DeleteConfigRule"\n' +
  '            ],\n' +
  '            "Resource": "*"\n' +
  '        }\n' +
  '    ]\n' +
  '}';
