import { setPagination, useQuery } from '../../packages';

import { appService } from '../app';

import { policyGroupApi } from './api';
import { policyGroupActions } from './store';

const getPolicies =
  ({
    accountId,
    standardId,
    policyGroupId,
    scanId,
    ppToolToken,
    ppFeature,
    query,
  }) =>
  async (dispatch) => {
    try {
      dispatch(policyGroupActions.setIsPoliciesLoading(true));
      const { page = 0, ...rest } = query || {};
      const otherQueryParams = useQuery('policies');

      const params = {
        scanId,
        accountId,
        standardId,
        policyGroupId,
        ppToolToken:
          ppToolToken && ppFeature ? `${ppToolToken}/${ppFeature}` : undefined,
        query: {
          page: page + 1,
          ...rest,
          ...otherQueryParams,
        },
      };
      const { results, ...pagination } =
        ppToolToken === undefined
          ? await policyGroupApi.getPolicies(params)
          : await policyGroupApi.getPoliciesPP(params);

      dispatch(policyGroupActions.setPolicies(results));
      setPagination('policies', { ...pagination, page });

      dispatch(policyGroupActions.setIsPoliciesLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

const getPolicyById =
  ({ accountId, standardId, policyGroupId, policyId, scanId }) =>
  async (dispatch) => {
    try {
      const response = await policyGroupApi.getPoliciesById({
        scanId,
        accountId,
        standardId,
        policyGroupId,
        policyId,
      });

      dispatch(policyGroupActions.setPolicies([response]));

      return response;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

const getPolicyGroupChartModel =
  ({ accountId, standardId, policyGroupId, ppToolToken, ppFeature, scanId }) =>
  async (dispatch) => {
    try {
      const response =
        ppToolToken === undefined
          ? await policyGroupApi.getPolicyGroupChartModel({
              scanId,
              accountId,
              standardId,
              policyGroupId,
            })
          : await policyGroupApi.getPolicyGroupChartModelPP({
              ppToolToken:
                ppToolToken && ppFeature
                  ? `${ppToolToken}/${ppFeature}`
                  : undefined,
              standardId,
              policyGroupId,
            });

      dispatch(policyGroupActions.setPolicyGroupChartData(response));
    } catch (e) {
      console.error(e);
    }
  };

const getPolicyGroupStats =
  ({ accountId, standardId, policyGroupId, ppToolToken, ppFeature, scanId }) =>
  async (dispatch) => {
    try {
      const response =
        ppToolToken === undefined
          ? await policyGroupApi.getPolicyGroupStats({
              scanId,
              accountId,
              standardId,
              policyGroupId,
            })
          : await policyGroupApi.getPolicyGroupStatsPP({
              ppToolToken:
                ppToolToken && ppFeature
                  ? `${ppToolToken}/${ppFeature}`
                  : undefined,
              standardId,
              policyGroupId,
            });

      await dispatch(policyGroupActions.setPolicyGroupStats(response));
    } catch (e) {
      console.error(e);
    }
  };

const init =
  ({
    id,
    accountId,
    standardId,
    policyGroupId,
    ppToolToken,
    ppFeature,
    scanId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(policyGroupActions.setIsLoading(true));

      await dispatch(
        getPolicies({
          accountId,
          standardId,
          policyGroupId,
          ppToolToken,
          ppFeature,
          scanId,
        })
      );
      await dispatch(
        getPolicyGroupStats({
          accountId,
          standardId,
          policyGroupId,
          ppToolToken,
          ppFeature,
          scanId,
        })
      );

      await dispatch(
        appService.getPipelineData({ ppToolToken, accountId, id })
      );
      await dispatch(
        getPolicyGroupChartModel({
          accountId,
          standardId,
          policyGroupId,
          ppToolToken,
          ppFeature,
          scanId,
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(policyGroupActions.setIsLoading(false));
    }
  };

const exportStandardScanHistory =
  ({ id, accountId, scanId, standardId, query }) =>
  async () => {
    try {
      await policyGroupApi.exportStandardScanHistory({
        id,
        scanId,
        accountId,
        standardId,
        query,
      });
    } catch (e) {
      console.error(e);
    }
  };

const exportPolicyGroupScanHistory =
  ({ id, accountId, scanId, standardId, policyGroupId, query }) =>
  async () => {
    try {
      await policyGroupApi.exportPolicyGroupScanHistory({
        id,
        scanId,
        accountId,
        standardId,
        policyGroupId,
        query,
      });
    } catch (e) {
      console.error(e);
    }
  };

export const policyGroupService = {
  init,
  getPolicies,
  getPolicyGroupStats,
  getPolicyById,
  exportStandardScanHistory,
  exportPolicyGroupScanHistory,
};
