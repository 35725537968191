import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Card, Chart, formatChartModel } from '../../../../packages';

import { standardSelectors } from '../../../../entities';

import { severityChartPalette } from '../../../../theme';

import { EmptyMessage } from './styled';

export const PolicyGroupTrend = () => {
  const { standardChartData } = useSelector(standardSelectors.getStandardData);

  const chartData = useMemo(
    () => formatChartModel(standardChartData),
    [standardChartData]
  );

  if (standardChartData.length < 2) {
    return null;
  }

  return (
    <Card
      title="Trend of Number of Policy Violations"
      helpMessage="On this chart you may view the trend of number of policies (of a chosen standard) violated (according to specific scan results) grouped by their severity level: low, medium, highcritical. Some policies have no severity level set, they are grouped as Not defined severity."
      style={{ marginBottom: 20 }}
    >
      {standardChartData.length ? (
        <Chart chartData={chartData} colorPalette={severityChartPalette} />
      ) : (
        <EmptyMessage>No scanning history</EmptyMessage>
      )}
    </Card>
  );
};
