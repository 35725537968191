import { EnableIcon } from '../../packages';

import {
  CustomerName,
  EditCustomerButton,
  ScanCustomerButton,
} from './components';

export const formatCustomersData = (customers) =>
  customers.map((customer) => {
    const { name, last_scan, compliance, policies_bad, id, enable } = customer;

    return {
      enable: { value: enable, align: 'left', component: EnableIcon },
      name: { value: name, align: 'left', component: CustomerName },
      lastScan: {
        value: last_scan
          ? `${new Date(last_scan * 1000).toLocaleDateString()} ${new Date(
              last_scan * 1000
            ).toLocaleTimeString()}`
          : '-',
        align: 'center',
      },
      compliance: {
        value: String(compliance ?? 'No scan results'),
        align: 'center',
      },
      policiesViolated: { value: String(policies_bad ?? '-'), align: 'center' },
      startNewScan: { component: ScanCustomerButton, align: 'center' },
      edit: { component: EditCustomerButton, align: 'center' },
      data: customer,
      id,
    };
  });
