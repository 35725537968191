import React, { useCallback, useMemo } from 'react';
import { NotificationManager } from 'react-notifications';

import { capitalizeFirstLetter } from '../../../../../packages/utils/string';
import DropdownMenu from '../../../../../packages/components/dropdown-menu';
import IconRoleDropdown from '../../../../../packages/icons/IconRoleDropdown';
import { customerDetailsApi } from '../../../../../entities';

export const ComponentRole = (props) => {
  const { value, disabled } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        minWidth: 136,
        height: 28,
        borderRadius: 4,
        fontWeight: 600,
        background: disabled ? "#F2F2F8" : "#EBF2FE",
      }}
    >
      <span style={{ marginLeft: 4 }}>{capitalizeFirstLetter(value.name) || 'Undefined'}</span>
      { disabled ? null : <IconRoleDropdown /> }
    </div>
  );
};

const MenuRoleLabel = (props) => {
  const { value } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 28, minWidth: 112 }}>
      <span style={{ marginLeft: 8, fontWeight: 600, color: '#000' }}>{capitalizeFirstLetter(value)}</span>
    </div>
  );
};

export const MenuUserRole = (props) => {
  const { children, roles, user, onSuccess, disabled } = props;

  if (disabled) {
    return children;
  }

  const updateRole = useCallback(
    (role) => {
      customerDetailsApi
        .updateUserRole(user.id, { role_id: role.id })
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          NotificationManager.error('Server error');
        });
    },
    [user, onSuccess]
  );

  const data = useMemo(
    () =>
      roles.filter((role) => role.id !== user.role.id).map((role) => ({
        key: role.id,
        label: <MenuRoleLabel value={role.name} />,
        onClick: () => updateRole(role),
      })),
    [user, updateRole]
  );

  return <DropdownMenu data={data}>{children}</DropdownMenu>;
};
