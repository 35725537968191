import styled from 'styled-components';
import { scrollable } from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WrapperList = styled.div`
  max-height: 200px;
  ${scrollable}
`;

export const SelectLabel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 8px;
  margin-top: 32px;
`;

export const FourthSelectLabel = styled(SelectLabel)`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme, error }) =>
    error ? theme.colors.danger : theme.colors.textColor};
  margin-bottom: 8px;
  margin-top: 8px;
`;
