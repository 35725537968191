import { PathNames } from '../../consts';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { DeleteIcon } from './components/delete-button/styled';

/*
{
    "id": 651,
    "slug": "cyberark",
    "name": "CyberArk",
    "service": "other",
    "diagrams_keywords": [],
    "text_keywords": [],
    "text_direct_keywords": [],
    "created_at": "2023-04-07T12:40:45.639346",
    "last_threats_update": "2023-04-16T00:00:37.984502",
    "company": null,
    "generic_type": null
}
*/

export const getHeaders = (diagramsActions = {}) => {
  const headers = [
    {
      id: 'slug',
      numeric: false,
      disablePadding: true,
      label: 'Slug',
      align: 'left',
      sort: 'slug',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      align: 'left',
      sort: 'name',
    },
    {
      id: 'service',
      numeric: false,
      disablePadding: false,
      label: 'Service',
      align: 'left',
      sort: 'service',
    },
    {
      id: 'generic_type',
      numeric: false,
      disablePadding: false,
      label: 'Generic type',
      align: 'left',
      sort: 'generic_type',
    },

    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: 'Company',
      align: 'left',
      sort: 'company',
    },
    {
      id: 'is_draft',
      numeric: false,
      disablePadding: false,
      label: 'Draft',
      align: 'left',
      sort: 'is_draft',
    },
    {
      id: 'last_threats_update',
      numeric: false,
      disablePadding: false,
      label: 'Last threats update',
      align: 'left',
      sort: 'last_threats_update',
    },
    {
      id: 'delete',
      numeric: true,
      disablePadding: false,
      align: 'center',
      label: '',
    },
  ];

  return headers;
};

// export const getHeadersPlus = (actions = {}) => {
//   const diagramsHeadersPlus = getHeaders();

//   return diagramsHeadersPlus;
// };

const formatDate = (val) => (val ? `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}` : '-');

export const formatData = (list, actions = { onDelete: (item) => {} }) =>
  list?.map((item) => {
    const { id, slug, service, name, generic_type, company, last_threats_update, is_draft } = item;

    const data = {
      slug: {
        value: slug,
        align: 'left',
      },
      name: {
        value: name,
        align: 'left',
        component: () => <NavLink to={PathNames.componentType.replace(':componentTypeId', id)}>{name}</NavLink>,
      },
      service: {
        value: service,
        align: 'left',
      },
      generic_type: {
        value: generic_type ? generic_type.name : '-',
        align: 'left',
      },
      company: {
        value: company?.name || '-',
        align: 'left',
      },
      is_draft: {
        value: is_draft ? 'True' : 'False',
        align: 'left',
      },
      last_threats_update: {
        value: formatDate(last_threats_update),
        align: 'left',
      },

      delete: {
        component: () => <DeleteIcon onClick={() => actions.onDelete(item)} />,
        align: 'center',
      },
      data: { ...item, enable: true },
    };

    return data;
  });
