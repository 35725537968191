import React, { memo } from 'react';

import { Link, Tooltip } from '@mui/material';

import { NavLink } from 'react-router-dom';

import { PathNames } from '../../../../consts';

import { FileNameWrapper } from './styled';

export const FileName = memo(({ value, id, stage, isManual }) => (
  <FileNameWrapper>
    <Tooltip title={value} arrow>
      {(isManual && ['parse_error','new', 'processing'].includes(stage)) ? (
        <>{value}</>
      ) : (
        <Link component={NavLink} to={PathNames.diagramResult.replace(':diagramId', id)} underline='hover'>
          {value}
        </Link>
      )}
    </Tooltip>
  </FileNameWrapper>
));
