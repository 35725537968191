import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CounterLabel = styled(NavLink)`
  color: ${({ theme }) => theme.colors.activeTextColor};
  margin-right: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-decoration-line: underline;
`;

export const Counter = styled.div`
  min-width: 24px;
  padding: 0 5px;
  height: 24px;
  background: ${({ theme }) => theme.colors.activeBackground};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled.div`
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.activeBackground};
  border-radius: 8px;
  cursor: pointer;
`;

export const AddIcon = styled(PlusIcon)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.secondaryBackground};
`;
