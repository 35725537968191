import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {Button, Checkbox, getCurrentFeaturePrefix} from '../../../../packages';

import {
  OnboardingSteps,
  appActions,
  appSelectors,
  appService,
  customerDetailsSelectors,
  customerService,
} from '../../../../entities';

import {
  ArrowRight,
  CheckboxWrapper,
  EmailField,
  HelpMessage,
  Image,
  ImageBox,
  InfoBox,
  Label,
  ServiceList,
  StartScanButtonWrapper,
  WelcomeLabel,
  Wrapper,
} from '../styled';
import { ServiceButton } from '../../service-button';
import progressOverview from '../../images/progress-overview.svg';
import branding from '../../../../branding';
import { FeaturePrefix } from '../../../../consts';
import Permission from '../../../../packages/components/permissions';

export const ReadyToScanning = memo(({ onServiceCreate }) => {
  const currentFeaturePrefix = getCurrentFeaturePrefix();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const customer = useSelector(appSelectors.getCustomer);
  const { accounts } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const { user } = useSelector(appSelectors.getAppData);
  const onEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  useEffect(() => {
    setEmail(user.email);
  }, []);

  const startScanningHandler = useCallback(() => {
    dispatch(appService.updateUser(user.id, { email }));
    dispatch(customerService.scanSelectedCustomers([customer.id]));
    dispatch(appActions.setOnboardingStep(OnboardingSteps.scanning));
  }, [user, email]);

  const isAzureAccountCreated = useMemo(
    () => !!accounts.find(({ service }) => service === 'azure'),
    [accounts]
  );

  const isAwsAccountCreated = useMemo(
    () => !!accounts.find(({ service }) => service === 'aws'),
    [accounts]
  );

  return (
    <Wrapper>
      <InfoBox>
        <WelcomeLabel>Ready to start scanning</WelcomeLabel>
        <Label>
          Now you can launch your first demo scan for the account(s) you have
          added on the previous step. Note, that it is possible to have and
          launch a scan for Azure and AWS accounts. Scans may take up to 24 hrs
          to complete, during which you won’t be able to check any results.
          After the scan is completed, you will be notified via email. To check
          results, you will need to login into {branding.name}.
        </Label>
        <ServiceList>
          <ServiceButton
            key="azure"
            name="Microsoft Azure"
            type="azure"
            isCreated={isAzureAccountCreated}
            onClick={onServiceCreate}
          />
          <ServiceButton
            key="aws"
            name="Amazon Web Services"
            type="aws"
            isCreated={isAwsAccountCreated}
            onClick={onServiceCreate}
          />
        </ServiceList>
        <HelpMessage>Scanning process can take up to 24 hours</HelpMessage>
        <CheckboxWrapper>
          <Checkbox label="Mail me about the completion of scanning" />
        </CheckboxWrapper>
        <EmailField
          name="email"
          label="Email"
          onChange={onEmailChange}
          value={email}
        />
        <Permission resource={
          currentFeaturePrefix === FeaturePrefix.PlatformSecurity ?
            'platform-security::accounts' : 'pipeline-security::accounts'
        }>
          {(actions) => (
            (actions.company?.['start-scan'].allowed || actions.team?.['start-scan'].allowed) ?
              <StartScanButtonWrapper>
                <Button
                  variant="contained"
                  onClick={startScanningHandler}
                  sx={{ maxWidth: '350px', minWidth: '300px', width: '100%' }}
                >
                  <ArrowRight/>
                  Start scanning
                </Button>
              </StartScanButtonWrapper>
              : null
          )}
        </Permission>
      </InfoBox>
      <ImageBox>
        <Image src={progressOverview} />
      </ImageBox>
    </Wrapper>
  );
});
