import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { SecondaryTextField } from '../../packages/components/input';

export const SidebarWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.sidebarBackground};
  position: relative;
  padding: 24px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CustomerNameField = muiStyled(SecondaryTextField)(({ theme }) => ({
  width: '100%',
  marginBottom: '12px',
  '& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused': {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1,
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1,
    color: theme.colors.activeTextColor,
  },
  '& .MuiInput-input.Mui-disabled': {
    background: theme.colors.sidebarInfoBoxBackground,
  },
}));

export const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: center;
  padding: 0 16px;
  min-height: 40px;
  background: ${({ theme }) => theme.colors.sidebarInfoBoxBackground};
  border-radius: 8px;
`;

export const InfoLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor};
`;

export const InfoValue = styled.div`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 16px;
  max-height: 120px;
  background: ${({ theme }) => theme.colors.sidebarInfoBoxBackground};
  border-radius: 8px;
`;

export const DescriptionLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.activeTextColor};
`;

export const DescriptionValue = styled.div`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  max-height: 72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
`;
