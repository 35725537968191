import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { OnboardingLayout } from '../../layouts';

import { OnboardingSteps, appSelectors } from '../../entities';

import {
  AccountsStep,
  AssignStandardStep,
  ReadyToScanningStep,
  ScanningStep,
} from './steps';

export const OnboardingPage = memo(() => {
  const { onboardingStep } = useSelector(appSelectors.getAppData);

  return (
    <OnboardingLayout>
      {onboardingStep === OnboardingSteps.accounts && <AccountsStep />}
      {onboardingStep === OnboardingSteps.assignStandards && (
        <AssignStandardStep />
      )}
      {onboardingStep === OnboardingSteps.readyToScanning && (
        <ReadyToScanningStep />
      )}
      {onboardingStep === OnboardingSteps.scanning && <ScanningStep />}
    </OnboardingLayout>
  );
});
