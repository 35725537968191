import styled from 'styled-components';

import { xsm } from '../../theme';

export const CustomersPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomersCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;

export const NoCustomers = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
  padding: 50px 0;
`;

export const ControlsBarWrapper = styled.div`
  display: flex;
`;

export const CustomersHeader = styled.div`
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;

  @media screen and (max-width: ${xsm}) {
    flex-wrap: wrap;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  @media screen and (max-width: ${xsm}) {
    margin-top: 20px;
  }
`;
