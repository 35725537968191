import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { Modal, SecondaryButton, modalActions, modalSelectors } from '../../../../packages';
import { ButtonsWrapper, StartScanContent, Title } from './styled';
import { deleteModal } from './consts';

export const useDeleteModal = () => {
  const dispatch = useDispatch();

  return (item) => {
    dispatch(
      modalActions.setModalIsOpen({
        name: deleteModal,
        isOpen: true,
        data: item,
      })
    );
    dispatch(
      modalActions.setModalData({
        name: deleteModal,
        data: item,
      })
    );
  };
};

export const DeleteModal = (props) => {
  const { api, header, onSuccess } = props;
  const dispatch = useDispatch();
  const item = useSelector((state) => modalSelectors.getModalData(state, deleteModal));

  const onConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      api(item.id)
        .then(() => {
          onSuccess();
          dispatch(
            modalActions.setModalIsOpen({
              name: deleteModal,
              isOpen: false,
            })
          );
        })
        .catch();
    },
    [item, api, onSuccess]
  );

  const onCancelClick = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      modalActions.setModalIsOpen({
        name: deleteModal,
        isOpen: false,
      })
    );
  }, []);

  return (
    <Modal name={deleteModal} header={header}>
      <StartScanContent>
        <Title>{`By continuing you are deleting selected item.`}</Title>
        <ButtonsWrapper component='button'>
          <Button size='medium' onClick={onCancelClick}>
            Cancel
          </Button>
          <SecondaryButton size='medium' onClick={onConfirm}>
            Delete
          </SecondaryButton>
        </ButtonsWrapper>
      </StartScanContent>
    </Modal>
  );
};
