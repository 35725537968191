import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import { NotificationManager } from 'react-notifications';

import { customerDetailsApi, threatModelingApi } from '../../../entities';
import { Button, CheckList } from '../../../packages';
import { scrollable } from '../../../theme';
import { capitalizeFirstLetter } from '../../../packages/utils/string';
import useApiData from '../../../hooks/useApiData';
import branding from '../../../branding';
import {DeleteSelectedDiagramsButton} from '../../dashboard/components';
import Permission from '../../../packages/components/permissions';
import useDataFullList from '../../../hooks/useDataFullList';

export const Scrollable = styled.div`
  position: absolute;
  height: 290px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  ${({ active }) => `
  opacity: ${active ? 1 : 0};
  pointer-events: ${active ? 'all' : 'none'};
  `}

  ${scrollable};

  & > div > div {
    cursor: pointer;
    padding: 8px 18px;
    background: transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    transition: background 0.3s;

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const listFilter = (value, search) => {
  if (!search) {
    return true;
  }

  return value === search;
};

const ModalAssignRelatedAccounts = (props) => {
  const { diagramId, onSuccess } = props;

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [service, setServise] = useState('');

  const accounts = useDataAccounts();
  const diagram = useApiData(threatModelingApi.getDiagramById, [diagramId]);

  useEffect(() => {
    if (diagram.data && Array.isArray(diagram.data.related_accounts)) {
      setSelected(diagram.data.related_accounts);
    }
  }, [diagram.data]);

  const list = useMemo(
    () =>
      accounts
        .map((i) => ({
          id: i.id,
          label: `${capitalizeFirstLetter(i.service)} ${i.name}`,
          service: i.service,
        }))
        .filter((i) => listFilter(i.service, service)),
    [accounts, service]
  );

  const services = useMemo(() => {
    const arr = [...new Set(accounts.map((i) => i.service))];

    return [
      { value: '', label: 'All' },
      ...arr.map((i) => ({
        value: i,
        label: `${capitalizeFirstLetter(i)}`,
      })),
    ];
  }, [accounts]);

  const onSelectServise = useCallback((val) => {
    if (val === -1) {
      setSelected([]);
      setServise('');
    } else {
      setServise(val);
    }
  }, []);

  const onSelect = useCallback(
    (id, value) => {
      if (!value) {
        setSelected([...selected, id]);
      } else {
        setSelected(selected.filter((i) => i !== id));
      }
    },
    [selected]
  );

  const updateAssigned = useCallback(() => {
    setLoading(true);

    threatModelingApi
      .patchDiagramById(diagramId, { related_accounts: selected })
      .then(() => {
        setLoading(false);
        NotificationManager.info('Accounts has been assigned successful');
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
        NotificationManager.error('Server error');
      });
  }, [diagramId, selected, onSuccess]);

  return (
    <div style={{ width: 416, margin: '0 26px' }}>
      <div style={{ display: 'flex' }}>
        <Permission resource="threat-modeling::diagrams::related-accounts">
          {(actions) => (actions.company?.["update"]?.allowed ?
            <div
              style={{
                padding: 8,
                background: selected.length ? branding.colors.blue : branding.colors.lightBlue,
                color: selected.length ? 'white' : 'gray',
                marginTop: 10,
                marginLeft: 8,
                width: 40,
                height: 40,
                borderRadius: 8,
                fontSize: 32,
                lineHeight: 1.2,
                display: 'flex',
                justifyContent: 'center',
                cursor: selected.length ? 'pointer' : 'default',
              }}
              onClick={() => setSelected([])}
            >
              &#x2DF;
            </div>
              : null
          )}
        </Permission>
        <SelectCustom label='Choose service' options={services} value={service} onChange={onSelectServise} />
      </div>
      <CheckListWrapper>
        <Permission resource="threat-modeling::diagrams::related-accounts">
          {(actions) => (
            <CheckList
              label=''
              selectedItems={selected}
              items={list}
              disabled={!actions.company?.["update"]?.allowed}
              onItemClick={actions.company?.["update"]?.allowed ? onSelect : () => {}}
            />
          )}
        </Permission>
      </CheckListWrapper>
      <Permission resource="threat-modeling::diagrams::related-accounts">
        {(actions) => (actions.company?.["update"]?.allowed ?
            <div>
              <Button
                variant='contained'
                size='medium'
                type='submit'
                style={{ width: '100%', height: 40 }}
                onClick={updateAssigned}
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </div>
            : null
        )}
      </Permission>
    </div>
  );
};

export default ModalAssignRelatedAccounts;

const SelectCustom = (props) => {
  const { label, value, options = [], onChange = (val) => {} } = props;
  const onChangeHandler = useCallback((ev, elem) => onChange(ev.target.value), []);

  return (
    <PureFormControl>
      <PureInputLabel size='small'>{label}</PureInputLabel>
      <PureSelect label={label} value={value} onChange={onChangeHandler} size='small' fullWidth>
        {options?.map((option) => (
          <PureMenuItem key={option.value} value={option.value}>
            {option.label}
          </PureMenuItem>
        ))}
      </PureSelect>
    </PureFormControl>
  );
};

const CheckListWrapper = styled.div`
  min-height: 300px;
  display: flex;
  margin: 24px 0;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
`;

// TODO duplicated
const PureFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: '10px !important',
}));

// TODO duplicated
const PureInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.colors.activeTextColor,
  fontFamily: 'Raleway, sans-serif',
}));

// TODO duplicated
const PureSelect = styled(MuiSelect)(({ theme }) => ({
  width: '100%',
  fontFamily: 'Raleway, sans-serif',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.colors.activeTextColor,
    '&.Mui-focused fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '& fieldset': {
      borderColor: `${theme.colors.activeTextColor} !important`,

      '&:hover': {
        borderColor: `${theme.colors.activeTextColor} !important`,
      },
    },
  },
}));

// TODO duplicated
const PureMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Raleway, sans-serif',
}));

const useDataAccounts = () => {
  const { data } =  useDataFullList(customerDetailsApi.getAccounts);
  return data;
};
