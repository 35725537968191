import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { scrollable } from '../../../../theme';
import branding from '../../../../branding';
import {customerDetailsApi, accountDetailsApi, accountDetailsService} from '../../../../entities';
import { Button, getCurrentFeaturePrefix, TextField } from '../../../../packages';
import {useDispatch} from 'react-redux';
import useDataFullList from '../../../../hooks/useDataFullList';

export const Scrollable = styled.div`
  position: absolute;
  height: 290px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  ${scrollable};

  & > div > div {
    cursor: pointer;
    padding: 8px 18px;
    background: transparent;

    transition: background 0.3s;

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const listFilter = (name, search) => {
  if (!search) {
    return true;
  }

  return `${name}`.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
};

export const ManageTeamsModal = (props) => {
  const { accountId, teams: initialTeams = [], onSuccess } = props;
  const dispatch = useDispatch();

  const [selectedTeams, setSelectedTeams] = useState(initialTeams);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const onSearch = useCallback((e) => setSearch(e.target.value), []);

  const teamsData = useDataTeams();

  const listTeams = useMemo(
    () =>
      teamsData
        .map((i) => ({
          value: i.id,
          title: i.name,
        }))
        .filter((i) => listFilter(i.title, search)),
    [teamsData, search]
  );

  const selectedTeamsStore = [...initialTeams];

  const onSelect = useCallback((value) => {
    const index = selectedTeamsStore.indexOf(value);
    if (index === -1) {
      selectedTeamsStore.push(value);
      setSelectedTeams([...selectedTeamsStore]);
    } else {
      selectedTeamsStore.splice(index, 1);
      setSelectedTeams([...selectedTeamsStore]);
    }
  }, []);

  const updateAssigned = useCallback(() => {
    setLoading(true);

    dispatch(
      accountDetailsService.updateAccountsTeam({
        accountId,
        teamIds: selectedTeams,
      })
    );
    onSuccess();

    setLoading(false);
  }, [accountId, selectedTeams, onSuccess]);

  return (
    <div style={{ width: 416, margin: '0 26px' }}>
      <TextField
        label={'Teams'}
        placeholder='Start typing'
        style={{ width: '100%', marginTop: 5 }}
        autoFocus
        onChange={onSearch}
      />
      <div style={{ position: 'relative', height: 290, margin: '16px 0' }}>
        <Scrollable>
          <List data={listTeams} active={selectedTeams} onSelect={(val) => onSelect(val)} />
        </Scrollable>
      </div>
      <div>
        <Button
          variant='contained'
          size='medium'
          type='submit'
          style={{ width: '100%', height: 40 }}
          onClick={updateAssigned}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

const List = (props) => {
  const { data = [{ value: -1, title: 'Item' }], active, onSelect = (id) => {} } = props;

  return (
    <div>
      {data.map((item) => (
        <div
          key={item.value}
          // ref={active.indexOf(item.value) !== -1 ? setActiveElements : undefined}
          style={{ ...(active.indexOf(item.value) !== -1 ? { color: '#F2F2F2', background: branding.colors.primary } : {}) }}
          onClick={() => onSelect(item.value)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

const useDataTeams = () => {
  const { data } = useDataFullList(customerDetailsApi.getTeams);
  return data;
};