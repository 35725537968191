import React, { useCallback, useMemo, useState } from 'react';
import { Box, Modal as MUIModal } from '@mui/material';

import { styled as muiStyled } from '@mui/material/styles';

import styled from 'styled-components';

import { ms, scrollable, sm, xsm } from '../../../theme';

import { CloseIconButton, ModalHeader } from './styled';

const MUIBox = muiStyled(Box)(
  () => `
  @media screen and (max-width: ${ms}) {
      width: 700px;
  }
  @media screen and (max-width: ${sm}) {
      width: 540px;
  }
  @media screen and (max-width: ${xsm}) {
      width: 320px;
  }
    max-height: calc(100% - 50px);
`
);

const Content = styled.div`
  border-radius: 10px;
  margin: 24px 0;
  max-height: 70vh;

  @media screen and (max-height: ${ms}) {
    max-height: 90vh;
  }

  overflow: auto;
  ${scrollable}
`;

const getStyles = (styles) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '98%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  ...styles,
});

export const ModalInline = ({ children, isOpen, onClose, header, styles, headerStyles }) => {
  return (
    <MUIModal open={isOpen} onClose={onClose} disableAutoFocus>
      <MUIBox sx={getStyles(styles)}>
        <ModalHeader headerStyles={headerStyles}>{header}</ModalHeader>
        <CloseIconButton onClick={onClose} />
        <Content>{children}</Content>
      </MUIBox>
    </MUIModal>
  );
};

export const useModalInline = () => {
  const [keyUpdate, setKeyUpdate] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const onOpen = useCallback((props) => {
    setOpen(true);
    setModalProps(props || {});
  }, []);

  const onClose = useCallback((update = false) => {
    setOpen(false);
    setModalProps({});
    if (update === true) {
      setKeyUpdate(Date.now());
    }
  }, []);

  return useMemo(
    () => ({
      keyUpdate,
      isOpen,
      onOpen,
      onClose,
      modalProps,
    }),
    [keyUpdate, isOpen, onOpen, onClose, modalProps]
  );
};
