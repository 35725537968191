import React, { memo, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { Table } from '../../../../packages';

import { standardSelectors, standardService } from '../../../../entities';

import {
  NoScanHistory,
  ScanResultsTableWrapper,
  ScanResultsWrapper,
} from './styled';

import { scanHistoryHeader } from './consts';
import { formatScanResultsData } from './utils';

export const PolicyGroups = memo(({ searchValue }) => {
  const dispatch = useDispatch();
  const { accountId, standardId, scanId, ppToolToken, ppFeature } = useParams();

  const { policyGroups, isPolicyGroupsLoading } = useSelector(
    standardSelectors.getStandardData
  );

  const tableData = useMemo(
    () => formatScanResultsData(policyGroups),
    [policyGroups]
  );

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        standardService.getPolicyGroups({
          scanId,
          accountId,
          ppFeature,
          standardId,
          ppToolToken,
          query: { ...query, search: searchValue },
        })
      );
    },
    [accountId, standardId, scanId, searchValue, ppFeature, ppToolToken]
  );

  if (!policyGroups.length) return null;

  return (
    <ScanResultsWrapper>
      {tableData.length ? (
        <ScanResultsTableWrapper>
          <Table
            name="policy-groups"
            isLoading={isPolicyGroupsLoading}
            onPageChange={onChangeHandler}
            onSort={onChangeHandler}
            rows={tableData}
            headCells={scanHistoryHeader}
            withoutCheck
          />
        </ScanResultsTableWrapper>
      ) : (
        <NoScanHistory>Scan history is empty</NoScanHistory>
      )}
    </ScanResultsWrapper>
  );
});
