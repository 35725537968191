import React from 'react';

const IconUnresolved = () => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6739 8.99929L14.2537 5.42723C14.4105 5.27044 14.4986 5.05779 14.4986 4.83605C14.4986 4.61432 14.4105 4.40167 14.2537 4.24487C14.097 4.08808 13.8843 4 13.6626 4C13.4409 4 13.2283 4.08808 13.0715 4.24487L9.5 7.82526L5.92845 4.24487C5.77168 4.08808 5.55906 4 5.33736 4C5.11565 4 4.90303 4.08808 4.74626 4.24487C4.58949 4.40167 4.50142 4.61432 4.50142 4.83605C4.50142 5.05779 4.58949 5.27044 4.74626 5.42723L8.32613 8.99929L4.74626 12.5713C4.66823 12.6488 4.60629 12.7408 4.56403 12.8423C4.52176 12.9438 4.5 13.0526 4.5 13.1625C4.5 13.2724 4.52176 13.3813 4.56403 13.4827C4.60629 13.5842 4.66823 13.6763 4.74626 13.7537C4.82365 13.8317 4.91573 13.8937 5.01718 13.936C5.11864 13.9782 5.22745 14 5.33736 14C5.44726 14 5.55608 13.9782 5.65753 13.936C5.75898 13.8937 5.85106 13.8317 5.92845 13.7537L9.5 10.1733L13.0715 13.7537C13.1489 13.8317 13.241 13.8937 13.3425 13.936C13.4439 13.9782 13.5527 14 13.6626 14C13.7725 14 13.8814 13.9782 13.9828 13.936C14.0843 13.8937 14.1763 13.8317 14.2537 13.7537C14.3318 13.6763 14.3937 13.5842 14.436 13.4827C14.4782 13.3813 14.5 13.2724 14.5 13.1625C14.5 13.0526 14.4782 12.9438 14.436 12.8423C14.3937 12.7408 14.3318 12.6488 14.2537 12.5713L10.6739 8.99929Z'
        fill='#EA0000'
      />
    </svg>
  );
};

export default IconUnresolved;
