import React, { memo } from 'react';

import { StandardsWrapper } from './styled';
import { Standard } from './Standard';

export const Standards = memo(({ items }) => (
  <StandardsWrapper>
    {items.map((item) => (
      <Standard {...item} />
    ))}
  </StandardsWrapper>
));
