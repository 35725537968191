const headers = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    sort: 'first_name',
    label: 'Full name',
    align: 'left',
  },
  {
    id: 'email',
    numeric: false,
    sort: 'email',
    disablePadding: true,
    label: 'Email',
    align: 'left',
  },
  {
    id: 'teams_count',
    numeric: false,
    disablePadding: true,
    label: 'Teams member',
    align: 'left',
  },
  {
    id: 'status',
    disablePadding: true,
    label: 'Status',
    align: 'left',
  },
  {
    id: '_more',
    align: 'left',
  },
];

export default headers;
