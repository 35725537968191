import BasicShapes from '../../library/basic/shapes';
import {
  LABEL_DEFAULT_FONT_SIZE,
  LabelVerticalAligns,
  VerticalAlignValues,
  labelVerticalAlignStyles,
} from './constants';

export const prepareInt = (val) => {
  const v = parseInt(val, 10);

  return Number.isNaN(v) ? undefined : v;
};

export const prepareFloat = (val) => {
  const v = parseFloat(val);

  return Number.isNaN(v) ? undefined : v;
};

export function prepareFontSize(e, alignSelf) {
  let style = { fontSize: prepareInt(e.target.value) };

  if (alignSelf === VerticalAlignValues.FLEX_START) {
    style = { ...style, ...labelVerticalAlignStyles[LabelVerticalAligns.TOP] };
  } else if (alignSelf === VerticalAlignValues.FLEX_END) {
    style = { ...style, ...labelVerticalAlignStyles[LabelVerticalAligns.BOTTOM] };
  } else {
    style = { ...style, ...labelVerticalAlignStyles[LabelVerticalAligns.CENTER] };
  }

  Object.keys(style).forEach((property) => {
    if (property.startsWith('margin') && typeof style[property] === 'function') {
      style[property] = style[property](style.fontSize || LABEL_DEFAULT_FONT_SIZE);
    }
  });

  return style;
}

export const prepareLabelVerticalAlign = (e, fontSize = LABEL_DEFAULT_FONT_SIZE) => {
  const val = e.target.options[e.target.selectedIndex].value;
  const style = { ...labelVerticalAlignStyles[val] };

  if (!style) {
    return {};
  }

  Object.keys(style).forEach((property) => {
    if (property.startsWith('margin') && typeof style[property] === 'function') {
      style[property] = style[property](fontSize);
    }
  });

  return style;
};

export const prepareShape = (e) => {
  const val = e.target.options[e.target.selectedIndex].value;
  const shape = BasicShapes.find((i) => i.shape === val);
  return { shape: val, style: { ...(shape?.style || {}), width: shape?.width, height: shape?.height } };
};

export const prepareSelectValue = (e) => {
  const val = e.target.options[e.target.selectedIndex].value;
  return val;
};
