import { FeaturePrefix, FeatureSlugs, PathNames } from '../../consts';

import {
  getAccessToken,
  getCurrentFeaturePrefix,
  history,
  setPagination,
  useQuery,
} from '../../packages';

import { customerApi, customerSelectors, customerService } from '../customer';

import {
  customerDetailsApi,
  customerDetailsSelectors,
  customerDetailsService,
} from '../customer-details';

import { standardService } from '../standard';

import { policyGroupService } from '../policy-group';

import { policyActions } from '../policy';

import { authService } from '../auth';

import { appApi } from './api';

import { appActions } from './store';
import { appSelectors } from './selectors';
import { OnboardingSteps } from './consts';

const getMenuItems = (query) => async (dispatch, getState) => {
  try {
    dispatch(appActions.setIsMenuItemsLoading(true));
    const { menuItems } = appSelectors.getAppData(getState());
    const { page = 0, ...rest } = query || {};
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerApi.getCustomers
        : customerApi.getCustomersPipeline;

    const { results, ...pagination } = await apiMethod({
      page: page + 1,
      enable: true,
      ...rest,
    });

    setPagination('menu-items', { ...pagination, page });

    if (page === 0) {
      dispatch(appActions.setMenuItems(results));
    } else {
      dispatch(appActions.setMenuItems([...menuItems, ...results]));
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(appActions.setIsMenuItemsLoading(false));
  }
};

const getMenuAccounts = (id, query) => async (dispatch, getState) => {
  try {
    const { page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery('menu-accounts');
    const { menuAccounts } = appSelectors.getAppData(getState());

    const currentFeaturePrefix = getCurrentFeaturePrefix();
    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerDetailsApi.getCustomerAccounts
        : customerDetailsApi.getCustomerAccountsPipeline;
    const { results, ...pagination } = await apiMethod(id, {
      page: page + 1,
      enable: true,
      ...rest,
      ...otherQueryParams,
    });

    if (page === 0) {
      dispatch(appActions.setMenuAccounts(results));
    } else {
      dispatch(appActions.setMenuAccounts([...menuAccounts, ...results]));
    }

    dispatch(
      appActions.setMenuAccountsCount({
        awsCount: pagination.details.aws_count,
        azureCount: pagination.details.azure_count,
        pipelineCount: pagination.details.pipeline_count,
        count: pagination.count,
      })
    );
    setPagination('menu-accounts', { ...pagination, page });
  } catch (e) {
    console.error(e);
  }
};

const initialRequests = () => async (dispatch) => {
  try {
    await dispatch(getMenuItems());
    await dispatch(getFeatures());
    await dispatch(customerService.getCustomers());
    await dispatch(customerService.getAccountTypes());
    await dispatch(standardService.getPresets());
  } catch (e) {
    console.error(e);
  }
};

const reinitFeature = () => async (dispatch, getState) => {
  try {
    const currentCustomer = await appSelectors.getCustomer(getState());

    // TODO improve this logic, should implement other method to update data after redirect
    setTimeout(async () => {
      await dispatch(getMenuItems());
      await dispatch(customerService.getCustomers());
      const updatedCustomer = await dispatch(
        customerService.getCustomerById({ id: currentCustomer.id })
      );
      await dispatch(appActions.setSelectedCustomer(updatedCustomer));
      await dispatch(customerService.getAccountTypes());
      await dispatch(getMenuAccounts(currentCustomer?.id));
    }, 0);
  } catch (e) {
    console.error(e);
  }
};

const getOnboardingStatus = () => async (dispatch, getState) => {
  try {
    const { user } = await appSelectors.getAppData(getState());
    const { accounts } = await customerDetailsSelectors.getCustomerDetailsData(
      getState()
    );
    const hasCreatedAccounts = !!accounts.length;

    if (user.is_superuser) return OnboardingSteps.completed;

    switch (true) {
      case hasCreatedAccounts && user.scan_status === 'completed':
        return OnboardingSteps.completed;
      case accounts.length > 0:
        return OnboardingSteps.assignStandards;
      case hasCreatedAccounts &&
        (user.scan_status === 'inprogress' ||
          user.scan_status === 'current_finished'):
        return OnboardingSteps.scanning;
      case hasCreatedAccounts:
        return OnboardingSteps.accounts;
      default:
        return OnboardingSteps.accounts;
    }
  } catch (e) {
    console.error(e);

    // TODO implement logic for this case
    return OnboardingSteps.accounts;
  }
};

const initByParams = (params) => async (dispatch) => {
  try {
    await dispatch(appActions.setIsReadyDataByParams(false));

    const { id, accountId, standardId, scanId, policyGroupId, policyId } =
      params;

    await dispatch(getSelectedCustomer(params));

    if (id && accountId) {
      const selectedAccount = await dispatch(
        customerDetailsService.getAccountById({ accountId, id })
      );
      dispatch(appActions.setSelectedAccount(selectedAccount));
    }

    if (standardId && accountId) {
      dispatch(
        standardService.getStandardById({ accountId, standardId, scanId })
      );
    }

    if (standardId && accountId && policyGroupId) {
      dispatch(
        standardService.getPolicyGroupById({
          scanId,
          accountId,
          standardId,
          policyGroupId,
        })
      );
    }

    if (standardId && accountId && policyGroupId && policyId) {
      const currentPolicy = await dispatch(
        policyGroupService.getPolicyById({
          scanId,
          accountId,
          standardId,
          policyGroupId,
          policyId,
        })
      );

      dispatch(policyActions.setCurrentPolicy(currentPolicy));
    }

    await dispatch(appActions.setIsReadyDataByParams(true));
  } catch (e) {
    console.error(e);
  }
};

const getSelectedCustomer = (params) => async (dispatch, getState) => {
  try {
    const customers = await customerSelectors.getCustomers(getState());

    const customerId = params?.id ?? customers[0]?.id;

    if (!customerId) {
      // TODO Stopping the request with the undefined id
      return;
    }

    dispatch(appActions.setSelectedCustomerId(customerId));

    const selectedCustomer = await dispatch(
      customerService.getCustomerById({ id: customerId })
    );

    await dispatch(customerDetailsService.getCustomerAccounts(customerId));

    dispatch(appActions.setSelectedCustomer(selectedCustomer));

    return selectedCustomer;
  } catch (e) {
    console.error(e);

    return null;
  }
};

const getUser = () => async (dispatch) => {
    const user = await appApi.getUser();
    const profile = await appApi.getProfile();

    dispatch(appActions.setUser(user));
    dispatch(appActions.setProfile(profile));

    return user;
};

const getFeatures = () => async (dispatch) => {
  try {
    const features = await appApi.getFeatures();

    dispatch(appActions.setFeatures(features));

    return features;
  } catch (e) {
    console.error(e);
  }
};

const init = async (dispatch) => {
  try {
    dispatch(appActions.setIsReady(false));
    // TODO improve this condition
    const { pathname } = history.location;

    if (
      // TODO need to add AuhtRoute
      pathname.startsWith(PathNames.linkAuth) ||
      pathname.startsWith(PathNames.pipelineOnboarding) ||
      pathname.startsWith(PathNames.oauthRedirect) ||
      pathname.startsWith(PathNames.verification) ||
      pathname.startsWith(PathNames.inviteToCompany)
    ) {
      return;
    }

    const token = getAccessToken();
    if (token) {
      await dispatch(getUser());
      await dispatch(initialRequests());
    } else {
      throw { status: 401 };
    }
  }
finally {
    dispatch(appActions.setIsReady(true));
  }
};

const initOnboarding = () => async (dispatch) => {
  try {
    dispatch(appActions.setIsOnboardingReady(false));

    await dispatch(initialRequests());

    const selectedCustomer = await dispatch(getSelectedCustomer());

    const onboardingStep = await dispatch(getOnboardingStatus());
    dispatch(appActions.setOnboardingStep(onboardingStep));

    if (onboardingStep === OnboardingSteps.completed) {
      history.push({
        pathname: PathNames.customerResults
          .replace(':feature', FeaturePrefix.PlatformSecurity)
          .replace(':id', selectedCustomer.id),
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(appActions.setIsOnboardingReady(true));
  }
};

const getPipelineData =
  ({ ppToolToken, id, accountId }) =>
  async (dispatch, getState) => {
    try {
      if (ppToolToken) {
        const pipelineData = await appApi.getPipelineDataPP({ ppToolToken });

        dispatch(appActions.setPipelineData(pipelineData));

        return;
      }

      let selectedAccount = await appSelectors.getAccount(getState());

      if (!selectedAccount || selectedAccount.id !== accountId) {
        selectedAccount = await dispatch(
          customerDetailsService.getAccountById({ accountId, id })
        );

        dispatch(appActions.setSelectedAccount(selectedAccount));
      }

      if (selectedAccount?.service === 'pipeline') {
        const pipelineData = await appApi.getPipelineData({ id, accountId });

        dispatch(appActions.setPipelineData(pipelineData));
      }
    } catch (e) {
      console.error(e);
    }
  };

const initPipelineOnboarding = () => async (dispatch) => {
  try {
    dispatch(appActions.setIsOnboardingReady(false));

    try {
      const user = await appApi.getUserWithoutErrorCatcher();

      dispatch(appActions.setUser(user));

      await dispatch(initialRequests());
      await dispatch(getSelectedCustomer());

      dispatch(appActions.setOnboardingStep(OnboardingSteps.assignStandards));
    } catch (e) {
      try {
        const registrationData = await dispatch(
          authService.getRegistrationData()
        );

        const { has_account, is_verified } = registrationData;

        if (has_account && !is_verified) {
          dispatch(appActions.setOnboardingStep(OnboardingSteps.confirmEmail));

          return;
        }

        dispatch(
          appActions.setOnboardingStep(OnboardingSteps.selectRegistrationType)
        );
      } catch (e) {
        dispatch(
          appActions.setOnboardingStep(OnboardingSteps.selectRegistrationType)
        );
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(appActions.setIsOnboardingReady(true));
  }
};

const initAppLayout = (params) => async (dispatch, getState) => {
  try {
    dispatch(appActions.setIsLayoutReady(false));

    await dispatch(initByParams(params));

    const currentCustomer = await appSelectors.getCustomer(getState());

    if (!currentCustomer) {
      return;
    }

    await dispatch(getMenuAccounts(currentCustomer.id));

    const onboardingStep = await dispatch(getOnboardingStatus());

    dispatch(appActions.setOnboardingStep(onboardingStep));

    /*  if (onboardingStep !== OnboardingSteps.completed) {
      history.push({ pathname: PathNames.onboarding });
    }
*/
    if (history.location.pathname === PathNames.login) {
      history.push({
        pathname: PathNames.customerResults
          .replace(':feature', FeaturePrefix.PlatformSecurity)
          .replace(':id', currentCustomer.id),
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(appActions.setIsLayoutReady(true));
  }
};

const searchMenuItems = (query) => async (dispatch) => {
  try {
    dispatch(appActions.setIsMenuItemsSearching(true));
    const { page = 0, ...rest } = query || {};

    const { results, ...pagination } = await appApi.getMenuItems({
      page: page + 1,
      ...rest,
    });

    setPagination('menu-items', { ...pagination, page });

    const formattedRecords = results.map((record) => ({
      ...record,
      id: record.id.replace('c-', ''),
    }));

    dispatch(appActions.setMenuItems(formattedRecords));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(appActions.setIsMenuItemsSearching(false));
  }
};

const updateUser =
  (id, { email }) =>
  async (dispatch) => {
    try {
      await appApi.updateUser(id, { email });

      await dispatch(getUser());
    } catch (e) {
      console.error(e);
    }
  };

const getProfile = () => async (dispatch) => {
  const profile = await appApi.getProfile();

  dispatch(appActions.setProfile(profile));

  return profile;
};

const updateProfile = async (dispatch, { first_name, last_name, email, avatar }) => {
  if (avatar && typeof avatar !== 'string') {
    await appApi.uploadAvatar({ avatar });
  }
  await appApi.updateProfile({ first_name, last_name, email });

  await dispatch(getUser());
};

const activatePlatformSecurityFeature = () => async (dispatch, getState) => {
  try {
    const { user } = await appSelectors.getAppData(getState());

    if (user.features?.includes(FeatureSlugs.PlatformSecurity)) {
      return;
    }

    const features = await appSelectors.getFeatures(getState());

    const alreadySelectedSlugs = features
      .filter((feature) => user.features.includes(feature.slug))
      .map((feature) => feature.id);

    const platformSecurityFeatureId =
      features.find((feature) => feature.slug === FeatureSlugs.PlatformSecurity)
        ?.id ?? null;

    if (!platformSecurityFeatureId) {
      console.warn('platformSecurityFeatureId id nor defined');
    }
    await appApi.setFeatures({
      features: [platformSecurityFeatureId, ...alreadySelectedSlugs],
    });

    await dispatch(getUser());
  } catch (e) {
    console.error(e);
  }
};

const activatePipelineSecurityFeature = () => async (dispatch, getState) => {
  try {
    const { user } = await appSelectors.getAppData(getState());

    if (user.features?.includes(FeatureSlugs.PipelineSecurity)) {
      return;
    }

    const features = await appSelectors.getFeatures(getState());

    const alreadySelectedSlugs = features
      .filter((feature) => user.features.includes(feature.slug))
      .map((feature) => feature.id);

    const pipelineSecurityFeatureId =
      features.find((feature) => feature.slug === FeatureSlugs.PipelineSecurity)
        ?.id ?? null;

    if (!pipelineSecurityFeatureId) {
      console.warn('pipelineSecurityFeatureId id nor defined');
    }
    await appApi.setFeatures({
      features: [pipelineSecurityFeatureId, ...alreadySelectedSlugs],
    });

    await dispatch(getUser());
  } catch (e) {
    console.error(e);
  }
};

const setFeatures = (features, continueWith) => async (dispatch) => {
  try {
    await appApi.setFeatures({ features });

    await dispatch(getUser());

    if (continueWith) {
      switch (continueWith) {
        case FeatureSlugs.PlatformSecurity: {
          history.push({
            pathname: `${PathNames.onboarding}`.replace(
              ':feature',
              FeaturePrefix.PlatformSecurity
            ),
          });
          dispatch(appService.reinitFeature());
          break;
        }
        case FeatureSlugs.ThreatModelling: {
          history.push({ pathname: `${PathNames.diagrams}` });
          break;
        }
        case FeatureSlugs.PipelineSecurity: {
          history.push({
            pathname: `${PathNames.onboarding}`.replace(
              ':feature',
              FeaturePrefix.PipelineSecurity
            ),
          });
          dispatch(appService.reinitFeature());
          break;
        }
      }
    } else {
      history.push({
        pathname: `${PathNames.home}`
      });
    }
  } catch (e) {
    console.error(e);
  }
};

const updateCompany =
  (id, { status }) =>
  async (dispatch) => {
    try {
      await appApi.updateCompany(id, { status });

      await dispatch(getUser());
    } catch (e) {
      console.error(e);
    }
  };

const onSelectedCustomerChange =
  (newCustomerId) => async (dispatch, getState) => {
    try {
      const currentFeaturePrefix = getCurrentFeaturePrefix();

      const currentCustomer = await appSelectors.getMenuItemById(
        getState(),
        newCustomerId
      );
      dispatch(appActions.setSelectedCustomerId(currentCustomer.id));
      dispatch(appActions.setSelectedMenuItemId(currentCustomer.id));
      dispatch(appActions.setSelectedCustomer(currentCustomer));
      await dispatch(getMenuAccounts(currentCustomer.id));
      await dispatch(
        customerDetailsService.getCustomerAccounts(currentCustomer.id)
      );

      dispatch(appActions.setIsSidebarOpen(false));

      history.push({
        pathname: PathNames.customerResults
          .replace(':feature', currentFeaturePrefix)
          .replace(':id', currentCustomer.id),
      });
    } catch (e) {
      console.error(e);
    }
  };

export const appService = {
  init,
  reinitFeature,
  getFeatures,
  setFeatures,
  initAppLayout,
  getPipelineData,
  initOnboarding,
  initPipelineOnboarding,
  activatePlatformSecurityFeature,
  activatePipelineSecurityFeature,
  onSelectedCustomerChange,
  getUser,
  getProfile,
  updateUser,
  updateProfile,
  getSelectedCustomer,
  updateCompany,
  getOnboardingStatus,
  initialRequests,
  initByParams,
  getMenuItems,
  getMenuAccounts,
  searchMenuItems,
};
