export const LABEL_DEFAULT_FONT_SIZE = 10;

export const LabelVerticalAligns = {
  TOP: 'Top',
  CENTER: 'Center',
  BOTTOM: 'Bottom',
};

export const VerticalAlignValues = {
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
};

export const labelVerticalAlignStyles = {
  [LabelVerticalAligns.TOP]: {
    alignSelf: VerticalAlignValues.FLEX_START,
    marginTop: (fontSize) => -fontSize * 1.5,
    marginBottom: undefined,
  },
  [LabelVerticalAligns.CENTER]: {
    alignSelf: undefined,
    marginTop: undefined,
    marginBottom: undefined,
  },
  [LabelVerticalAligns.BOTTOM]: {
    alignSelf: VerticalAlignValues.FLEX_END,
    marginBottom: (fontSize) => -fontSize * 1.5,
    marginTop: undefined,
  },
};

export const MerkerDefaultSize = 20;
export const MerkerDefaultColor = '#000';
