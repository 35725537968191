import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { scrollable } from '../../../../theme';
import { Button, DumbCheckbox, SecondaryButton, TextField } from '../../../../packages';
import IconFilter from '../../../../packages/icons/IconFilter';
import { ModalInline, useModalInline } from '../../../../packages/components/modal/ModalInline';
import useDataFullList from '../../../../hooks/useDataFullList';
import { threatModelingApi } from '../../../../entities';
import { useURLQueryParam } from '../../../../packages/utils/router';

const ButtonSelectDiagrams = () => {
  const { isOpen, onClose, onOpen } = useModalInline();
  const { value: diagrams } = useURLQueryParam('diagrams');
  const count = diagrams ? `${diagrams}`.split(',').length : 0;

  return (
    <>
      <SecondaryButton onClick={() => onOpen()}>
        Select Diagrams {count ? ` (${count})` : ''} <IconFilter style={{ marginLeft: 16 }} />
      </SecondaryButton>
      <ModalInline isOpen={isOpen} header='Filter by diagrams' onClose={onClose}>
        <SelectDiagrams onCancel={onClose} />
      </ModalInline>
    </>
  );
};

export default ButtonSelectDiagrams;

const SelectDiagrams = (props) => {
  const { onCancel } = props;

  const { value: diagrams, onChange } = useURLQueryParam('diagrams');
  const { data: list } = useDataFullList(threatModelingApi.getDiagrams);
  const [selectedItems, setSelectedItems] = useState(diagrams ? `${diagrams}`.split(',').map((i) => parseInt(i)) : []);
  const [search, setSearch] = useState('');

  const items = useMemo(
    () =>
      list
        .map((i) => ({ id: i.id, label: i.filename }))
        .filter((i) => (search ? `${i.label}`.toLowerCase().indexOf(search.toLowerCase()) !== -1 : true)),
    [list, search]
  );

  const onSearch = useCallback((e) => setSearch(e.target.value), []);

  const onItemClick = useCallback(
    (id, checked) => {
      if (!checked) {
        setSelectedItems([...selectedItems, id]);
      } else {
        setSelectedItems(selectedItems.filter((i) => i != id));
      }
    },
    [selectedItems]
  );

  const onSelect = useCallback(() => {
    onChange(selectedItems.join(','));
    onCancel();
  }, [onCancel, selectedItems]);

  return (
    <div style={{ margin: '0 20px 0 20px', width: 400 }}>
      <TextField
        label='File name'
        placeholder='Start typing'
        style={{ width: '100%', marginTop: 5 }}
        autoFocus
        onChange={onSearch}
      />
      <List>
        {items.map(({ label, id }) => (
          <Item
            key={`${id}-check-list-item`}
            id={id}
            label={label}
            onClick={onItemClick}
            isChecked={selectedItems.includes(id)}
          />
        ))}
      </List>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <Button style={{ flex: 1, marginRight: '16px' }} onClick={onSelect}>
          Save
        </Button>
        <SecondaryButton
          style={{ flex: 1 }}
          onClick={() => {
            onChange();
            onCancel();
          }}
        >
          Reset
        </SecondaryButton>
      </div>
    </div>
  );
};

const Item = memo(({ id, label, isChecked, onClick }) => {
  const onItemClick = useCallback(
    (e) => {
      e.preventDefault();

      onClick(id, isChecked);
    },
    [id, isChecked, onClick]
  );

  return (
    <ItemWrapper onClick={onItemClick} isChecked={isChecked}>
      <ItemCheck>
        <DumbCheckbox value={isChecked} />
      </ItemCheck>
      <LabelWrapper>
        <ItemLabel>{label}</ItemLabel>
      </LabelWrapper>
    </ItemWrapper>
  );
});

const List = styled.div`
  width: 100%;
  max-height: 470px;
  min-height: 470px;
  display: flex;
  flex-direction: column;

  ${scrollable}
`;

const ItemWrapper = styled.div`
  display: flex;
  min-height: 40px;
  cursor: pointer;
  background: ${({ theme, isChecked }) => (isChecked ? theme.colors.background : theme.colors.secondaryBackground)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBorderColor};
`;

const ItemCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 56px);
`;

const ItemLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  max-width: calc(100% - 20px);
  vertical-align: top;
`;
