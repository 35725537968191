import React, { memo } from 'react';

import { Step } from './Step';
import {
  AwsButton,
  ColumnList,
  HighlightedText,
  Image,
  LinkLabel,
  ListItem,
  ListTopic,
  ListWrapper,
} from './styled';
import appRegistration from './icons/app-registration.png';
import copyValue from './icons/copy-value.png';
import accessControl from './icons/access-control.png';
import addRole from './icons/add-role.png';
import addCertificate from './icons/add-certificate.png';
import assignRole from './icons/assign-role.png';

export const AzureSteps = memo(() => (
  <>
    <Step title="Step 1">
      <ListWrapper>
        <ListTopic>Sign in at Microsoft Azure</ListTopic>
      </ListWrapper>
    </Step>
    <Step title="Step 2">
      <ListWrapper>
        <ListTopic>
          Go to Azure App Registrations
          <Image src={appRegistration} width="150px" />
        </ListTopic>
      </ListWrapper>
    </Step>
    <Step title="Step 3">
      <ListWrapper>
        <ListItem>Click new registration</ListItem>

        <ListItem>
          Set the <HighlightedText>Name</HighlightedText> nd choose “Accounts in
          any organizational directory (Any Azure AD directory - Multitenant)”
          as
          <HighlightedText>Supported account type</HighlightedText>
        </ListItem>
        <ListItem>
          Click<AwsButton>Register</AwsButton>
        </ListItem>
      </ListWrapper>
    </Step>
    <Step title="Step 4">
      <ListWrapper>
        <ListItem>
          Copy values of Application
          <HighlightedText>(Client) ID</HighlightedText> and Directory
          <HighlightedText>(Tenant) ID</HighlightedText>
        </ListItem>
        <ListItem>
          Click to<LinkLabel>Add certificate or secret</LinkLabel>
          <Image src={addCertificate} width="100%" />
        </ListItem>
        <ListItem>
          In Client Secrets tab, click on
          <HighlightedText>"New Client Secret"</HighlightedText>
        </ListItem>
        <ListItem>
          Fill <HighlightedText>Description</HighlightedText>and
          <HighlightedText>Expires</HighlightedText> fields.
        </ListItem>
        <ListItem>
          Copy value of <HighlightedText>"Value"</HighlightedText> field
          <Image src={copyValue} width="100%" />
        </ListItem>
      </ListWrapper>
    </Step>
    <Step title="Step 5">
      <ListWrapper>
        <ListItem>Go to account home</ListItem>
        <ListItem>Navigate to your subscription </ListItem>
        <ListItem>You need to assign 3 roles:</ListItem>
        <ListTopic>
          <ColumnList>
            <HighlightedText>– Policy Insights Data Writer</HighlightedText>
            <HighlightedText>– Resource Policy Contributor</HighlightedText>
            <HighlightedText>– Security Assessment Contributor</HighlightedText>
          </ColumnList>
        </ListTopic>
        <ListItem>
          Go to <HighlightedText>Access Control</HighlightedText> and click
          <HighlightedText>Add</HighlightedText> under
          <HighlightedText>Role assignments</HighlightedText>
          <Image src={accessControl} width="100%" />
        </ListItem>
        <ListItem>
          Choose the role
          <HighlightedText>“Policy Insights Data Writer”</HighlightedText> and
          click <HighlightedText>“Next”</HighlightedText>
          <Image src={addRole} width="100%" />
        </ListItem>
        <ListItem>
          On<HighlightedText>“Members”</HighlightedText>tab, choose
          <HighlightedText>“User, group, or service principle”</HighlightedText>
          and then<HighlightedText>“Select Members”</HighlightedText>for
          choosing your application your registered earlier.
        </ListItem>
        <ListItem>
          Click <AwsButton>Next</AwsButton>
        </ListItem>
        <ListItem>
          Review and assign the role.
          <Image src={assignRole} width="100%" />
        </ListItem>
        <ListItem>Repeat it for another two roles.</ListItem>
      </ListWrapper>
    </Step>
    <Step title="Step 6">
      <ListWrapper>
        <ListItem>
          You can use the Application ID, Tenant ID and value you copied earlier
          to complete adding an account.{' '}
        </ListItem>
      </ListWrapper>
    </Step>
  </>
));
