import React, { memo } from 'react';

import { Button } from '../button';

import { ArrowRight } from '../../../pages/onboarding/steps/styled';

import {FeaturePrefix, ScanStatuses} from '../../../consts';

import { icons } from '../icons';

import { Tooltip } from '../tooltip';

import {
  AccountName,
  AvailableCount,
  InfoBox,
  InfoWrapper,
  PipelineDataLabel,
  PipelineInfoWrapper,
  ScanBox,
  ServiceLogo,
  ServiceName,
  Wrapper,
} from './styled';
import Permission from '../permissions';
import {getCurrentFeaturePrefix} from '../../utils';

export const StartScan = memo(
  ({
    currentAccount,
    availableScans = 0,
    onScanClick,
    isUnlimited,
    pipelineData,
  }) => {
    const currentFeaturePrefix = getCurrentFeaturePrefix();
    const IconsByService = {
      aws: icons.awsIcon,
      azure: icons.azureIcon,
      azuredev: icons.azureIcon,
      githubdev: icons.githubIcon,
    };

    return (
      <Wrapper>
        <InfoBox>
          <ServiceLogo
            src={IconsByService[currentAccount.service]}
            alt="service-logo"
          />
          <InfoWrapper>
            <Tooltip
              title={`${currentAccount.service} ${currentAccount.name}`}
              arrow
            >
              <AccountName>{`${currentAccount.service} ${currentAccount.name}`}</AccountName>
            </Tooltip>
            <ServiceName>
              {currentAccount.service === 'aws' && 'Amazon Web Services'}
              {currentAccount.service === 'azure' && 'Microsoft Azure'}
              {currentAccount.service === 'azuredev' && 'Microsoft AzureDev'}
              {currentAccount.service === 'githubdev' && 'GithubDev'}
            </ServiceName>
          </InfoWrapper>
        </InfoBox>
        {currentAccount.service === 'pipeline' && pipelineData && (
          <InfoBox>
            <PipelineInfoWrapper>
              {pipelineData?.resource_group_name && (
                <PipelineDataLabel>
                  Resource group:{' '}
                  <strong>{pipelineData?.resource_group_name}</strong>
                </PipelineDataLabel>
              )}
              {pipelineData?.subscription_id && (
                <PipelineDataLabel>
                  Subscription ID:{' '}
                  <strong>{pipelineData?.subscription_id}</strong>
                </PipelineDataLabel>
              )}
              {pipelineData?.tenant_id && (
                <PipelineDataLabel>
                  Tenant ID: <strong>{pipelineData?.tenant_id}</strong>
                </PipelineDataLabel>
              )}
            </PipelineInfoWrapper>
          </InfoBox>
        )}
        {currentAccount.service !== 'pipeline' && (
          <Permission resource={
            currentFeaturePrefix === FeaturePrefix.PlatformSecurity ?
              'platform-security::accounts' : 'pipeline-security::accounts'
          }>
            {(actions) => (
              (actions.company?.['start-scan'].allowed || actions.team?.['start-scan'].allowed) ?
                <ScanBox>
                  {!isUnlimited && (
                    <AvailableCount>{`Available ${availableScans} scans`}</AvailableCount>
                  )}
                  <Button
                    variant="contained"
                    onClick={onScanClick}
                    disabled={
                      currentAccount.status === ScanStatuses.inprogress ||
                      !availableScans
                    }
                    sx={{ width: '100%' }}
                  >
                    {currentAccount.status === ScanStatuses.inprogress ? (
                      'Scan in progress'
                    ) : (
                      <>
                        <ArrowRight/>
                        Start scanning
                      </>
                    )}
                  </Button>
                </ScanBox>
                : null
            )}
          </Permission>
        )}
      </Wrapper>
    );
  }
);
