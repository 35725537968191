import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { threatModelingApi, threatModelingService } from '../../../entities';
import { Button, TextField } from '../../../packages';
import { scrollable } from '../../../theme';
import { useDispatch } from 'react-redux';
import useApiData from '../../../hooks/useApiData';
import { useDiagramResultContext } from './DiagramResultContext';
import branding from '../../../branding';
import { useLazyList } from '../../../packages/utils/list';
import { SelectLazyField, WrapLazyField } from '../components/add-component-modal/components-form/styled';

export const Scrollable = styled.div`
  height: 290px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  ${scrollable};

  & > div > div {
    cursor: pointer;
    padding: 8px 18px;
    background: transparent;

    transition: background 0.3s;

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const ModalAssignResource = (props) => {
  const { diagramId, componentId, resourceId, disabled, onSuccess = () => {} } = props;
  const dispatch = useDispatch();
  const { onCloseAssignResource } = useDiagramResultContext();

  const [resource, setResource] = useState(resourceId);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const onSearch = useCallback((e) => setSearch(e.target.value), []);
  const [location, setChangeLocation] = useState('');

  const isActiveSearch = search.length >= 3;
  const query = useMemo(() => {
    const cont = {};

    if (location) {
      cont.node = location.value;
    }

    if (isActiveSearch) {
      cont.search = search;
    }

    return cont;
  }, [location, search, isActiveSearch]);

  const lazyList = useLazyList(threatModelingApi.getDiagramResources, [diagramId], query);

  const [searchLoactions, setSearchLoacations] = useState('');
  const isActiveSearchLoactions = searchLoactions.length >= 3;
  const loactions = useLazyList(
    threatModelingApi.getDiagramLoactions,
    [diagramId],
    isActiveSearchLoactions ? { search: searchLoactions } : {}
  );
  const locationsOptions = useMemo(
    () => [
      { label: 'All', value: -1 },
      ...loactions.data?.map((res) => ({
        label: res.path,
        value: res.id,
      })),
    ],
    [loactions.data]
  );

  const currentResource = useApiData(resourceId ? threatModelingApi.getResource : null, [resourceId]);
  const onChangeLocation = useCallback(
    (v) => {
      const value = v.target.value;
      if (value?.value === -1) {
        setChangeLocation('');
        return;
      }

      setChangeLocation(value);
    },
    [locationsOptions]
  );

  const list = useMemo(() => {
    let arr = currentResource.data ? [{ value: currentResource.data.id, title: currentResource.data.title }] : [];

    arr = [
      ...arr,
      ...(lazyList.data || [])?.map((res) => ({
        title: res.title,
        value: res.id,
      })),
    ];

    return arr;
  }, [lazyList.data, search, currentResource.data]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    await dispatch(
      threatModelingService.addOrUpdateComponents({
        id: componentId,
        requestModel: {
          resource: resource || null,
        },
      })
    );
    setLoading(false);
    onSuccess();
    onCloseAssignResource();
  }, [componentId, resource, onCloseAssignResource, onSuccess]);
  console.log('currentResource', currentResource, list);
  return (
    <div style={{ width: 416, margin: '0 26px' }}>
      <WrapLazyField>
        <SelectLazyField
          label='Source'
          placeholder='Start typing (3 characters minimum)'
          options={locationsOptions}
          intersectionRef={loactions.intersectionRef}
          search={searchLoactions}
          onSearch={setSearchLoacations}
          value={location}
          onChange={onChangeLocation}
        />
      </WrapLazyField>
      <TextField
        label='File name'
        placeholder='Start typing (3 characters minimum)'
        style={{ width: '100%', marginTop: 5 }}
        autoFocus
        onChange={onSearch}
      />
      <div style={{ height: 290, margin: '16px 0' }}>
        <Scrollable active>
          <List data={list} active={resource} onSelect={disabled ? () => {} : setResource} />
          <div ref={lazyList.intersectionRef} style={{ width: '100%', height: 10 }} />
        </Scrollable>
      </div>
      {disabled ? null : (
        <div>
          <Button
            variant='contained'
            size='medium'
            type='submit'
            style={{ width: '100%', height: 40 }}
            onClick={onSubmit}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ModalAssignResource;

const List = (props) => {
  const { data = [{ value: -1, title: 'Item' }], active, onSelect = (id) => {} } = props;

  const [element, setElement] = useState(null);

  const setActiveElement = useCallback((el) => {
    if (el) {
      setElement(el);
    }
  }, []);

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [element]);

  return (
    <div>
      {data.map((item) => (
        <div
          key={item.value}
          ref={active === item.value ? setActiveElement : undefined}
          style={{ ...(active === item.value ? { color: branding.colors.primary, background: '#E6E6E6' } : {}) }}
          onClick={() => onSelect(item.value)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
