import React from 'react';
import branding from '../../branding';

const IconEdit = (props) => {
  const { onClick, style } = props;

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={style}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19 3.17157C18.7599 3.17157 18.5221 3.21886 18.3003 3.31075C18.0784 3.40264 17.8769 3.53732 17.7071 3.7071L4.39488 17.0193L3.42521 20.5748L6.98067 19.6051L20.2929 6.29289C20.4627 6.1231 20.5974 5.92154 20.6892 5.69971C20.7811 5.47787 20.8284 5.24011 20.8284 5C20.8284 4.75988 20.7811 4.52212 20.6892 4.30029C20.5974 4.07845 20.4627 3.87689 20.2929 3.7071C20.1231 3.53732 19.9215 3.40264 19.6997 3.31075C19.4779 3.21886 19.2401 3.17157 19 3.17157ZM17.5349 1.46299C17.9994 1.27059 18.4972 1.17157 19 1.17157C19.5027 1.17157 20.0006 1.27059 20.4651 1.46299C20.9296 1.65539 21.3516 1.93739 21.7071 2.29289C22.0626 2.64839 22.3446 3.07043 22.537 3.53492C22.7294 3.99941 22.8284 4.49724 22.8284 5C22.8284 5.50275 22.7294 6.00058 22.537 6.46507C22.3446 6.92956 22.0626 7.3516 21.7071 7.7071L8.2071 21.2071C8.08404 21.3302 7.93101 21.419 7.76311 21.4648L2.26311 22.9648C1.9169 23.0592 1.54664 22.9609 1.29289 22.7071C1.03914 22.4534 0.940807 22.0831 1.03523 21.7369L2.53523 16.2369C2.58102 16.069 2.66983 15.9159 2.79289 15.7929L16.2929 2.29289C16.6484 1.93739 17.0704 1.65539 17.5349 1.46299Z'
        fill={branding.colors.primary}
      />
    </svg>
  );
};

export default IconEdit;
