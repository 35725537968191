export const scanHistoryHeader = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Name",
    align: "center",
  },
  {
    id: "policies_compliant_percent",
    numeric: false,
    sort: "policies_compliant_percent",
    disablePadding: false,
    label: "Policies compliant %",
    align: "left",
  },
  {
    id: "controls_total",
    numeric: false,
    sort: "controls_total",
    disablePadding: false,
    label: "Controls total",
    align: "center",
  },
  {
    id: "policies_violated",
    numeric: false,
    sort: "policies_violated",
    disablePadding: false,
    label: "Policies violated",
    align: "center",
  },
  {
    id: "policies_total",
    numeric: false,
    sort: "policies_total",
    disablePadding: false,
    label: "Policies total",
    align: "center",
  },
  {
    id: "policies_compliant",
    numeric: false,
    sort: "policies_compliant",
    disablePadding: false,
    label: "Policies compliant",
    align: "center",
  },
  {
    id: "last_scan",
    numeric: false,
    sort: "last_scan",
    disablePadding: false,
    label: "Last scan",
    align: "center",
  },
];
