import { ComplianceStatus } from '../../../../packages';
import { PolicyGroupName } from '../policy-group-name';

export const formatScanResultsData = (scanHistory) =>
  scanHistory.map((scan, index) => {
    const {
      name,
      last_scan,
      compliance,
      policies,
      policies_bad,
      severity_data,
      records,
      records_bad,
    } = scan;

    return {
      indicator: { align: 'center', component: ComplianceStatus },
      name: { value: name, align: 'left', component: PolicyGroupName },
      compliance: { value: String(compliance ?? '-'), align: 'center' },
      policies: { value: String(policies ?? '-'), align: 'center' },
      policesViolated: { value: String(policies_bad ?? '-'), align: 'center' },
      high: { value: String(severity_data?.high ?? '-'), align: 'center' },
      resources: { value: String(records ?? '-'), align: 'center' },
      resourcesViolated: { value: String(records_bad ?? '-'), align: 'center' },
      lastScan: {
        value: last_scan
          ? `${new Date(last_scan * 1000).toLocaleDateString()} ${new Date(
              last_scan * 1000
            ).toLocaleTimeString()}`
          : '-',
        align: 'center',
      },
      data: { ...scan, compliancePercent: compliance },
      id: index,
    };
  });
