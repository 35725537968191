import {FeaturePrefix, PathNames} from '../../consts';
import {getCurrentFeaturePrefix} from '../../packages';

export const formatAccountStandards = (standards, { id, accountId, scanId, ppToolToken, ppFeature}, history_push) =>
  standards
    .filter((standard) => standard.compliance !== null)
    .map(
      (standard) => ({
        onClick: () => {
          if (ppToolToken === undefined) {
            history_push({
              pathname: PathNames.standardOverview
                .replace(':feature', getCurrentFeaturePrefix())
                .replace(':id', id)
                .replace(':scanId', scanId ?? 'current')
                .replace(':accountId', accountId)
                .replace(':standardId', standard.id),
            })
          } else {
            history_push(
              PathNames.ppStandardOverview
                .replace(':ppFeature', ppFeature)
                .replace(':ppToolToken', ppToolToken)
                .replace(':standardId', standard.id)
            );
          }
        },
        name: standard.name,
        description: standard.descr,
        isCustom: standard.is_custom,
        percent: standard.compliance,
      }),
      [standards]
    );
