import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Router } from 'react-router';

import { ErrorBoundary, Loader, history } from '../packages';
import { appSelectors, appService } from '../entities';
import { PathNames } from '../consts';

import { AppWrapper, GlobalLoaderWrapper } from './styled';

export const App = ({ children }) => {
  const dispatch = useDispatch();
  const { isReady } = useSelector(appSelectors.getAppData);

  useEffect(() => {
    appService
      .init(dispatch)
      .then(() => {
        const { pathname } = window.location;
        const isHome = pathname === PathNames.welcome;

        if (isHome) {
          history.replace({
            pathname: PathNames.home,
          });
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          const { href, pathname } = window.location;

          const isHome = pathname === PathNames.welcome;

          if (isHome) {
            return;
          }

          const isWelcome = pathname.startsWith(PathNames.welcome);
          if (isWelcome) {
            return;
          }

          const route = `${PathNames.welcome}?redirect=${encodeURIComponent(href)}`;

          history.replace({
            pathname: route,
          });
          history.go(0);
        }
      });
  }, []);

  if (!isReady) {
    return (
      <GlobalLoaderWrapper>
        <Loader />
      </GlobalLoaderWrapper>
    );
  }

  return (
    <AppWrapper>
      <Router history={history}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Router>
    </AppWrapper>
  );
};
