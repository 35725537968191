import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ModalInline, useModalInline } from '../../../packages/components/modal/ModalInline';
import ModalAssignedUser from './ModalAssignedUser';
import ModalAssignRelatedAccounts from './ModalAssignRelatedAccounts';
import ModalAssignResource from './ModalAssignResource';
import useApiData from '../../../hooks/useApiData';
import { threatModelingApi } from '../../../entities';
import {SecondaryButton} from '../../../packages';
import Permission from '../../../packages/components/permissions';
import ModalAssignedTeam from './ModalAssignedTeam';

const Context = createContext({
  onOpenRelatedAccounts: ({ diagramId, onSuccess }) => {},
  onCloseRelatedAccounts: () => {},
  onOpenAssignedTeam: ({ componentId, team, onSuccess }) => {},
  onCloseAssignedTeam: () => {},
  onOpenAssignedUser: ({ reportId, user, onSuccess }) => {},
  onCloseAssignedUser: () => {},
  onOpenAssignResource: ({ diagramId, componentId, resourceId, onSuccess }) => {},
  onCloseAssignResource: () => {},
  diagram: {},
});

const DiagramResultContext = (props) => {
  const { children } = props;
  const { diagramId } = useParams();

  const diagram = useApiData(threatModelingApi.getDiagramById, [diagramId]);

  const {
    isOpen: isOpenRelated,
    onOpen: onOpenRelatedAccounts,
    onClose: onCloseRelatedAccounts,
    modalProps: modalPropsRelated,
  } = useModalInline();
  const {
    isOpen: isOpenAssignedTeam,
    onOpen: onOpenAssignedTeam,
    onClose: onCloseAssignedTeam,
    modalProps: modalPropsAssignedTeam,
  } = useModalInline();
  const {
    isOpen: isOpenAssignedUser,
    onOpen: onOpenAssignedUser,
    onClose: onCloseAssignedUser,
    modalProps: modalPropsAssignedUser,
  } = useModalInline();

  const {
    isOpen: isOpenAssignResource,
    onOpen: onOpenAssignResource,
    onClose: onCloseAssignResource,
    modalProps: modalPropsAssignResource,
  } = useModalInline();

  const valueContex = useMemo(
    () => ({
      diagram,
      onOpenRelatedAccounts,
      onCloseRelatedAccounts: () => {
        diagram.mutate();
        onCloseRelatedAccounts();
      },
      onOpenAssignedTeam,
      onCloseAssignedTeam,
      onOpenAssignedUser,
      onCloseAssignedUser,
      onOpenAssignResource,
      onCloseAssignResource,
    }),
    [
      diagram,
      onOpenRelatedAccounts,
      onCloseRelatedAccounts,
      onOpenAssignedTeam,
      onCloseAssignedTeam,
      onOpenAssignedUser,
      onCloseAssignedUser,
      onOpenAssignResource,
      onCloseAssignResource,
    ]
  );

  return (
    <Context.Provider value={valueContex}>
      {children}
      <ModalInline isOpen={isOpenRelated} onClose={onCloseRelatedAccounts} header='Assign related accounts'>
        <ModalAssignRelatedAccounts diagramId={modalPropsRelated.diagramId} onSuccess={modalPropsRelated.onSuccess} />
      </ModalInline>
      <ModalInline isOpen={isOpenAssignedTeam} onClose={onCloseAssignedTeam} header='Assigned Team'>
        <ModalAssignedTeam
          componentId={modalPropsAssignedTeam.componentId}
          team={modalPropsAssignedTeam.team}
          onSuccess={modalPropsAssignedTeam.onSuccess}
        />
      </ModalInline>
      <ModalInline isOpen={isOpenAssignedUser} onClose={onCloseAssignedUser} header='Assigned User'>
        <ModalAssignedUser
          reportId={modalPropsAssignedUser.reportId}
          user={modalPropsAssignedUser.user}
          onSuccess={modalPropsAssignedUser.onSuccess}
        />
      </ModalInline>
      <ModalInline isOpen={isOpenAssignResource} onClose={onCloseAssignResource} header='Assign resource'>
        <Permission resource="threat-modeling::diagrams::components::resources">
          {(actions) => (
            <ModalAssignResource
              diagramId={modalPropsAssignResource.diagramId}
              componentId={modalPropsAssignResource.componentId}
              resourceId={modalPropsAssignResource.resourceId}
              onSuccess={modalPropsAssignResource.onSuccess}
              disabled={!actions?.company?.["update"]?.allowed}
            />
          )}
        </Permission>

      </ModalInline>
    </Context.Provider>
  );
};

export const useDiagramResultContext = () => useContext(Context);

export default DiagramResultContext;
