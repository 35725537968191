import styled from 'styled-components';

import { xsm } from '../../theme';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  display: flex;
  gap: 30px;
  border-radius: 8px;
  padding: 20px;
  width: 100%;

  & > *:first-child {
    flex: 1;
  }

  & > *:last-child {
    width: 300px;
  }
`;

export const WrapFilter = styled.div`
  .title {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > * {
      flex: 1;
    }
  }
`;

export const NoContent = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
  padding: 50px 0;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${xsm}) {
    flex-wrap: wrap;
  }
`;

export const FileInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${xsm}) {
    margin-top: 20px;
  }
`;

export const WrapTitle = styled.div`
  padding: 0.5rem 0 1rem 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FormTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

export const WrapHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .MuiTextField-root {
    height: 40px;
    background-color: white;
  }

  .MuiTextField-root,
  .MuiOutlinedInput-root {
    height: 40px;
  }
`;

export const WrapClearFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.activeTextColor};
  }
`;
