import styled from 'styled-components';

import successAnimationUrl from './success-animation.gif';

export const SuccessIconGif = styled.img.attrs({
  src: successAnimationUrl,
})`
  width: 300px;
  height: 300px;
`;
