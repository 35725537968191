import React, { memo } from 'react';

import DefaultTextareaField from '@mui/material/TextareaAutosize';
import { styled } from '@mui/material/styles';
import branding from '../../../branding';

const PureTextareaField = styled(DefaultTextareaField)(({ theme }) => ({
  '& .MuiTextField-root': {
    boxSizing: 'border-box',
  },
  '& textarea': {
    borderColor: branding.colors.blue,
  },
  '& textarea:focus-visible': {
    outline: '40px',
  },
  '& .MuiInputBase-input': {
    padding: 10,
    fontFamily: 'Raleway, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Raleway, sans-serif',
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-active': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiInputLabel-root.Mui-selected': {
    color: theme.colors.activeTextColor,
  },
  '& .MuiOutlinedInput-input': {
    color: theme.colors.textColor,
    fontFamily: 'Raleway, sans-serif',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: theme.colors.activeTextColor,

    '&.Mui-focused fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '& fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '&:hover fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
    '&:focus fieldset': {
      borderColor: theme.colors.activeTextColor,
    },
  },
}));

export const TextareaField = memo((props) => {
  const { type, children, style } = props;

  return <PureTextareaField {...props} style={style || { height: 100 }} />;
});
