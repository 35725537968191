import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { ProgressIcon, ScanButton } from '../../../../packages';
import {
  customerDetailsSelectors,
  customerDetailsService,
} from '../../../../entities';
import { ScanStatuses } from '../../../../consts';

export const ScanAccountButton = memo(({ id, disabled, status, service }) => {
  const dispatch = useDispatch();
  const { accountId } = useParams();

  const { availableScans, isUnlimited } = useSelector((state) =>
    customerDetailsSelectors.getAvailableScansCount(state, accountId)
  );

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(customerDetailsService.scanSelectedAccounts([id]));
    },
    [id]
  );

  if (status === ScanStatuses.inprogress) {
    return <ProgressIcon />;
  }

  const message =
    !availableScans && !isUnlimited ? 'Limit is exceeded' : 'Scan account';

  return (
    <ScanButton
      onClick={onClickHandler}
      title={message}
      disabled={disabled || (!availableScans && !isUnlimited) || service === 'pipeline'}
    />
  );
});
