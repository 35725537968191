import styled from 'styled-components';
import AlertIcon from 'mdi-react/CloseCircleOutlineIcon';
import CheckboxMarkedCircleOutlineIcon from 'mdi-react/CheckboxMarkedCircleOutlineIcon';

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme, status }) => theme.colors[status.toLowerCase()]};
`;

export const UnrecognizedIcon = styled(AlertIcon)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  fill: ${({ theme }) => theme.colors.unrecognized};
`;

export const RecognizedIcon = styled(CheckboxMarkedCircleOutlineIcon)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  fill: ${({ theme }) => theme.colors.recognized};
`;

export const ManuallyIcon = styled(CheckboxMarkedCircleOutlineIcon)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  fill: ${({ theme }) => theme.colors.manual};
`;
