import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export const useURLQuery = () => {
  const history = useHistory();

  const query = useMemo(() => {
    const params = new URLSearchParams(history.location.search);
    const cont = {};

    params.forEach((val, param) => {
      cont[param] = val;
    });

    return cont;
  }, [history.location.search]);

  const onChange = useCallback(
    (query = {}, replace = false) => {
      const params = new URLSearchParams(replace ? '' : history.location.search);

      Object.keys(query).forEach((name) => {
        const value = query[name];
        if (value) {
          params.set(name, value);
        } else {
          params.delete(name);
        }
      });

      history.push(`${history.location.pathname}?${params}`);
    },
    [history.location.search]
  );

  return [query, onChange];
};

export const useURLQueryParam = (name) => {
  const history = useHistory();
  const [query] = useURLQuery();

  const onChange = useCallback(
    (value) => {
      const params = new URLSearchParams(history.location.search);

      if (value) {
        params.set(name, value);
      } else {
        params.delete(name);
      }

      history.push(`${history.location.pathname}?${params}`);
    },
    [history.location.search]
  );

  return { value: query[name], onChange };
};
