import React, { memo, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Table } from '../../../../packages';

import {
  appSelectors,
  customerDetailsSelectors,
  customerDetailsService,
} from '../../../../entities';

import {
  NoScanHistory,
  ScanHistoryTableWrapper,
  ScanHistoryWrapper,
} from './styled';

import { scanHistoryHeader } from './consts';
import { formatScanHistoryData } from './utils';

export const ScanHistory = memo(() => {
  const dispatch = useDispatch();
  const { customerScans, isScansLoading } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const { selectedCustomerId } = useSelector(appSelectors.getAppData);
  const tableData = useMemo(
    () => formatScanHistoryData(customerScans),
    [customerScans]
  );

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        customerDetailsService.getCustomerScans(selectedCustomerId, query)
      );
    },
    [selectedCustomerId]
  );

  return (
    <ScanHistoryWrapper>
      {tableData.length ? (
        <ScanHistoryTableWrapper>
          <Table
            name="scans"
            isLoading={isScansLoading}
            onPageChange={onChangeHandler}
            onSort={onChangeHandler}
            rows={tableData}
            headCells={scanHistoryHeader}
            withoutCheck
          />
        </ScanHistoryTableWrapper>
      ) : (
        <NoScanHistory>Scan history is empty</NoScanHistory>
      )}
    </ScanHistoryWrapper>
  );
});
