// const DATA_COUNT = 5;
// const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

import React from 'react';
import { ChartContext } from './initChart';
import { threatModelingApi } from '../../../../entities';
import useApiData from '../../../../hooks/useApiData';

// const labels = Utils.months({ count: 7 });
const data = {
  //   labels: [
  //     'Overall Yay',
  //     'Overall Nay',
  //     'Group A Yay',
  //     'Group A Nay',
  //     'Group B Yay',
  //     'Group B Nay',
  //     'Group C Yay',
  //     'Group C Nay',
  //   ],
  datasets: [
    {
      backgroundColor: ['#EA0000', '#2170F6', '#79CD67'],
      data: [11, 39, 50],
      borderWidth: 0,
      //   cutout: '50%',
      //   radius: '100%',
      //   rotation: 180,
      spacing: 5,
      circumference: 180,
      weight: 28,
      labels: ['Unresolved', 'In progress', 'Ready'],
    },
    {
      //   backgroundColor: ['transparent'],
      //   data: [],
      //   borderWidth: 0,
      //   labels: [],
      //   cutout: '5%',
      //   radius: '1%',
      weight: 5,
    },
    {
      backgroundColor: ['rgba(5, 110, 255, 0.12)', '#056EFF'],
      borderWidth: 0,
      data: [40, 60],
      //   cutout: '50%',
      circumference: 180,
      //   radius: '120%',
      weight: 28,
      labels: ['Unresolved', 'In progress'],
    },

    // {
    //   backgroundColor: ['hsl(100, 100%, 60%)', 'hsl(100, 100%, 35%)'],
    //   data: [20, 80],
    // },
    // {
    //   backgroundColor: ['hsl(180, 100%, 60%)', 'hsl(180, 100%, 35%)'],
    //   data: [10, 90],
    // },
  ],
};

const MultiPie = (props) => {
  const { width, height } = props;

  const config = {
    type: 'doughnut',
    data,
    options: {
      responsive: true,
      plugins: {
        // legend: {
        //   labels: {
        //     generateLabels: function (chart) {
        //       // Get the default label list
        //       const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
        //       const labelsOriginal = original.call(this, chart);
        //       // Build an array of colors used in the datasets of the chart
        //       let datasetColors = chart.data.datasets.map(function (e) {
        //         return e.backgroundColor;
        //       });
        //       datasetColors = datasetColors.flat();
        //       // Modify the color and hide state of each label
        //       labelsOriginal.forEach((label) => {
        //         // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
        //         label.datasetIndex = (label.index - (label.index % 2)) / 2;
        //         // The hidden state must match the dataset's hidden state
        //         label.hidden = !chart.isDatasetVisible(label.datasetIndex);
        //         // Change the color to match the dataset
        //         label.fillStyle = datasetColors[label.index];
        //       });
        //       return labelsOriginal;
        //     },
        //   },
        //   onClick: function (mouseEvent, legendItem, legend) {
        //     // toggle the visibility of the dataset from what it currently is
        //     legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden = legend.chart.isDatasetVisible(
        //       legendItem.datasetIndex
        //     );
        //     legend.chart.update();
        //   },
        // },
        tooltip: {
          callbacks: {
            label: function (context) {
              try {
                const labelIndex = context.dataIndex;
                return context.dataset.labels[labelIndex] + ': ' + context.formattedValue + '%';
              } catch (err) {
                console.error('Undefined labels', context, err);
                return context.formattedValue;
              }
            },
          },
        },
      },
    },
  };

  return <ChartContext name='multi' config={config} width={width} height={height} />;
};

export default MultiPie;

export const TMOverallCompliance = (props) => {
  const { width, height, unresolved, inProgress, resolved, withoutResource, withResource } = props;

  const key = `${unresolved}${inProgress}${resolved}${withoutResource}${withResource}`;

  // console.log('SSSSSSSSSSS', overallCompliance, {unresolved, inProgress, resolved});
  const data = {
    //   labels: [
    //     'Overall Yay',
    //     'Overall Nay',
    //     'Group A Yay',
    //     'Group A Nay',
    //     'Group B Yay',
    //     'Group B Nay',
    //     'Group C Yay',
    //     'Group C Nay',
    //   ],
    datasets: [
      {
        backgroundColor: ['#EA0000', '#2170F6', '#79CD67'],
        data: [unresolved, inProgress, resolved],
        borderWidth: 0,
        //   cutout: '50%',
        //   radius: '100%',
        //   rotation: 180,
        spacing: 5,
        circumference: 180,
        weight: 28,
        labels: ['Unresolved', 'In progress', 'Ready'],
      },
      {
        //   backgroundColor: ['transparent'],
        //   data: [],
        //   borderWidth: 0,
        //   labels: [],
        //   cutout: '5%',
        //   radius: '1%',
        weight: 5,
      },
      {
        backgroundColor: ['rgba(5, 110, 255, 0.12)', '#056EFF'],
        borderWidth: 0,
        data: [withoutResource, withResource],
        //   cutout: '50%',
        circumference: 180,
        //   radius: '120%',
        weight: 28,
        labels: ['Without Resource', 'With Resource'],
      },

      // {
      //   backgroundColor: ['hsl(100, 100%, 60%)', 'hsl(100, 100%, 35%)'],
      //   data: [20, 80],
      // },
      // {
      //   backgroundColor: ['hsl(180, 100%, 60%)', 'hsl(180, 100%, 35%)'],
      //   data: [10, 90],
      // },
    ],
  };

  const config = {
    type: 'doughnut',
    data,
    options: {
      responsive: true,
      plugins: {
        // legend: {
        //   labels: {
        //     generateLabels: function (chart) {
        //       // Get the default label list
        //       const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
        //       const labelsOriginal = original.call(this, chart);
        //       // Build an array of colors used in the datasets of the chart
        //       let datasetColors = chart.data.datasets.map(function (e) {
        //         return e.backgroundColor;
        //       });
        //       datasetColors = datasetColors.flat();
        //       // Modify the color and hide state of each label
        //       labelsOriginal.forEach((label) => {
        //         // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
        //         label.datasetIndex = (label.index - (label.index % 2)) / 2;
        //         // The hidden state must match the dataset's hidden state
        //         label.hidden = !chart.isDatasetVisible(label.datasetIndex);
        //         // Change the color to match the dataset
        //         label.fillStyle = datasetColors[label.index];
        //       });
        //       return labelsOriginal;
        //     },
        //   },
        //   onClick: function (mouseEvent, legendItem, legend) {
        //     // toggle the visibility of the dataset from what it currently is
        //     legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden = legend.chart.isDatasetVisible(
        //       legendItem.datasetIndex
        //     );
        //     legend.chart.update();
        //   },
        // },
        tooltip: {
          callbacks: {
            label: function (context) {
              try {
                const labelIndex = context.dataIndex;
                return context.dataset.labels[labelIndex] + ': ' + context.formattedValue + '%';
              } catch (err) {
                console.error('Undefined labels', context, err);
                return context.formattedValue;
              }
            },
          },
        },
      },
    },
  };

  return <ChartContext key={key} name='multi' config={config} width={width} height={height} />;
};
