import { NotificationManager } from 'react-notifications';

import {
  getCurrentFeaturePrefix,
  modalActions,
  setPagination,
  useQuery,
} from '../../packages';

import { FeaturePrefix, ScanStatuses } from '../../consts';

import { customerSelectors, customerService } from '../customer';

import { appActions, appSelectors, appService } from '../app';

import { standardService } from '../standard';

import { accountModalName } from '../../pages/accounts/consts';

import { customerDetailsApi } from './api';
import { customerDetailsActions } from './store';
import { customerDetailsSelectors } from './selectors';
import { CREDENTIALS_MOCK } from './consts';

const getCustomerAccounts = (id, query) => async (dispatch) => {
  try {
    dispatch(customerDetailsActions.setIsAccountsLoading(true));
    const { page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery('accounts');
    const currentFeaturePrefix = getCurrentFeaturePrefix();
    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerDetailsApi.getCustomerAccounts
        : customerDetailsApi.getCustomerAccountsPipeline;

    const { results, ...pagination } = await apiMethod(id, {
      page: page + 1,
      ...rest,
      ...otherQueryParams,
    });

    dispatch(customerDetailsActions.setAccounts(results));

    dispatch(customerDetailsActions.setAccountsCount(pagination.count));
    setPagination('accounts', { ...pagination, page });

    dispatch(customerDetailsActions.setIsAccountsLoading(false));
  } catch (e) {
    console.error(e);
  }
};

const getCustomerScans = (id, query) => async (dispatch) => {
  try {
    dispatch(customerDetailsActions.setIsScansLoading(true));
    const { page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery('scans');
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerDetailsApi.getCustomerScans
        : customerDetailsApi.getCustomerScansPipeline;

    const { results, ...pagination } = await apiMethod(id, {
      page: page + 1,
      ...rest,
      ...otherQueryParams,
    });

    dispatch(customerDetailsActions.setCustomerScans(results));
    setPagination('scans', { ...pagination, page });

    dispatch(customerDetailsActions.setIsScansLoading(false));
  } catch (e) {
    console.error(e);
  }
};

const getCustomerTrends = (id) => async (dispatch) => {
  try {
    const response = await customerDetailsApi.getCustomerTrends(id);

    dispatch(customerDetailsActions.setCustomerTrends(response));
  } catch (e) {
    console.error(e);
  }
};

const initAccounts = (customerId) => async (dispatch) => {
  try {
    dispatch(customerDetailsActions.setIsLoading(true));
    await dispatch(getCustomerAccounts(customerId));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(customerDetailsActions.setIsLoading(false));
  }
};

const initScanHistory = (customerId) => async (dispatch) => {
  try {
    dispatch(customerDetailsActions.setIsLoading(true));
    await dispatch(getCustomerScans(customerId));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(customerDetailsActions.setIsLoading(false));
  }
};

const getCustomerStats = (id) => async (dispatch) => {
  try {
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerDetailsApi.getCustomerStats
        : customerDetailsApi.getCustomerStatsPipeline;

    const response = await apiMethod(id);

    await dispatch(customerDetailsActions.setCustomerStats(response));
  } catch (e) {
    await dispatch(
      customerDetailsActions.setCustomerStats({
        compliance_details: {},
      })
    );
    console.error(e);
  }
};

const initCustomerResults = (customerId) => async (dispatch) => {
  try {
    dispatch(customerDetailsActions.setIsLoading(true));

    await dispatch(getCustomerStats(customerId));
    await dispatch(getCustomerScans(customerId));
    await dispatch(getCustomerTrends(customerId));
    await dispatch(customerService.getResourcesStat({ id: customerId }));
    await dispatch(standardService.getCustomerStandards({ customerId }));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(customerDetailsActions.setIsLoading(false));
  }
};

const disableAccount = (accountId) => async (dispatch, getState) => {
  try {
    const { selectedCustomerId } = appSelectors.getAppData(getState());

    await customerDetailsApi.disableAccount(selectedCustomerId, accountId);

    await dispatch(getCustomerAccounts(selectedCustomerId));
  } catch (e) {
    console.error(e);
  }
};

const enableAccount = (accountId) => async (dispatch, getState) => {
  try {
    const { selectedCustomerId } = appSelectors.getAppData(getState());

    await customerDetailsApi.enableAccount(selectedCustomerId, accountId);

    await dispatch(getCustomerAccounts(selectedCustomerId));
  } catch (e) {
    console.error(e);
  }
};

const createOrUpdateAccount =
  ({ name, service, ...credentials }, accountId) =>
  async (dispatch, getState) => {
    try {
      dispatch(customerDetailsActions.setIsAccountCreating(true));

      const { selectedCustomerId } = appSelectors.getAppData(getState());
      const fields = customerSelectors.getAccountFormFields(getState());

      const formattedCredentials = fields[service].reduce((object, field) => {
        const value = credentials[field.name];

        return {
          ...object,
          [field.name]:
            value === CREDENTIALS_MOCK || !value ? undefined : value,
        };
      }, {});

      const isCredentialsChanged = Object.values(formattedCredentials).find(
        (value) => !!value
      );

      const requestModel = {
        name,
        service,
        credentials: isCredentialsChanged ? formattedCredentials : undefined,
      };

      let lastCreatedAccount;

      if (accountId) {
        await customerDetailsApi.patchAccount(
          selectedCustomerId,
          accountId,
          requestModel
        );

        NotificationManager.success('Successfully updated');
      } else {
        lastCreatedAccount = await customerDetailsApi.createAccount(
          selectedCustomerId,
          requestModel
        );
        NotificationManager.success('Successfully created');
      }

      dispatch(
        modalActions.setModalIsOpen({
          name: accountModalName,
          isOpen: false,
        })
      );
      dispatch(getCustomerAccounts(selectedCustomerId));
      dispatch(appService.getMenuAccounts(selectedCustomerId));
      dispatch(appActions.setLastCreatedAccount(lastCreatedAccount));
    } catch (e) {
      console.error(e);

      const errorModel = JSON.parse(e.message);
      const errors = Object.values(errorModel);

      errors.forEach((error) => {
        if (typeof error === 'string') {
          NotificationManager.error(error);
        } else if (error.non_field_errors?.length) {
          NotificationManager.error(error.non_field_errors[0]);
        } else if (error?.length) {
          NotificationManager.error(error[0]);
        } else {
          NotificationManager.error('Server error');
        }
      });
    } finally {
      dispatch(customerDetailsActions.setIsAccountCreating(false));
    }
  };

const scanSelectedAccounts = (ids) => async (dispatch, getState) => {
  try {
    const { accounts } = customerDetailsSelectors.getCustomerDetailsData(
      getState()
    );
    const { selectedCustomerId } = appSelectors.getAppData(getState());
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerDetailsApi.scanAccounts
        : customerDetailsApi.scanAccountsPipeline;

    await apiMethod(selectedCustomerId, { items: ids });

    const updatedAccounts = accounts.map((account) => {
      if (ids.includes(account.id)) {
        return { ...account, status: ScanStatuses.inprogress };
      }

      return account;
    });

    await dispatch(customerDetailsActions.setAccounts(updatedAccounts));

    await dispatch(appService.getUser());

    NotificationManager.success('Successfully started');
  } catch (e) {
    console.error(e);
  }
};

const getAccountById =
  ({ id, accountId }) =>
  async (dispatch, getState) => {
    try {
      const { accounts } =
        await customerDetailsSelectors.getCustomerDetailsData(getState);
      const currentFeaturePrefix = getCurrentFeaturePrefix();

      const apiMethod =
        currentFeaturePrefix === FeaturePrefix.PlatformSecurity
          ? customerDetailsApi.getAccountById
          : customerDetailsApi.getAccountByIdPipeline;

      const response = await apiMethod({
        id,
        accountId,
      });

      const isAlreadySaved = !!accounts.find(
        (account) => String(account.id) === String(accountId)
      );

      if (!isAlreadySaved) {
        dispatch(customerDetailsActions.setAccounts([...accounts, response]));
      }

      return response;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

const getResourcesStat =
  ({ id, accountId, ppToolToken, ppFeature }) =>
  async (dispatch) => {
    try {
      const response =
        ppToolToken === undefined
          ? await customerDetailsApi.getResourcesStat({
              id,
              accountId,
            })
          : await customerDetailsApi.getResourcesStatPP({
              ppToolToken:
                ppToolToken && ppFeature
                  ? `${ppToolToken}/${ppFeature}`
                  : undefined,
            });

      await dispatch(customerDetailsActions.setResourcesStat(response));
    } catch (e) {
      console.error(e);
    }
  };

export const customerDetailsService = {
  initAccounts,
  initCustomerResults,
  initScanHistory,
  enableAccount,
  getAccountById,
  disableAccount,
  getCustomerStats,
  getResourcesStat,
  getCustomerScans,
  getCustomerAccounts,
  scanSelectedAccounts,
  createOrUpdateAccount,
};
