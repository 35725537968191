import * as yup from 'yup';

export const registerFormValidationSchema = yup.object({
  name: yup.string('Enter account name').required('Name is required'),
  company_name: yup.string('Enter company name').required('Company name is required'),
  email: yup
    .string('Enter email')
    .email('Email is not valid')
    .required('Email is required'),
  password1: yup
    .string()
    .required('Password is required')
    .min(6, 'Must be at least 6 characters'),
  password2: yup
    .string()
    .oneOf([yup.ref('password1'), null], 'Passwords must match'),
});
