import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import serverStatusUrl from '../../images/server-status.svg';
import branding from '../../../../branding';

import {
  Image,
  ImageBox,
  InfoBox,
  Label,
  WelcomeLabel,
  Wrapper,
} from '../styled';

import { authSelectors } from '../../../../entities';

import { ConfirmEmailForm } from '../../../forms/confirm-email-form';

export const ConfirmEmailStep = memo(() => {
  const { registrationEmail } = useSelector(authSelectors.getAuthData);

  return (
    <Wrapper>
      <InfoBox>
        <WelcomeLabel>Verify your email</WelcomeLabel>
        <Label>
          Before you proceed to your {branding.name} account, you need to verify your
          email
          <strong>
            {registrationEmail ? `(${registrationEmail}). ` : '. '}
          </strong>
          Please, check your Inbox for our letter with 6-digits verification
          code. Copy and paste the code to the field below and hitContinue
          button. Can't find our letter? Try checking Trash or re-sendnew
          verification code. Please, check your Inbox for our letter with
          6-digits verification code. Copy and paste the code to the field below
          and hit Continue button. Can't find our letter? Try checking Trash or
          re-send new verification code.
        </Label>
        <ConfirmEmailForm />
      </InfoBox>
      <ImageBox>
        <Image src={serverStatusUrl} />
      </ImageBox>
    </Wrapper>
  );
});
