import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import HomeIcon from 'mdi-react/HomeIcon';

import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router';

import branding from '../../branding';
import { ProfileBlock } from '../ProfileBlock';

import { appActions, appSelectors, appService } from '../../entities';

import { Tabs, getCurrentFeaturePrefix, history } from '../../packages';
import {
  FeaturePrefix,
  FeaturePrefixBySlug,
  FeatureSlugs,
  PathNames,
} from '../../consts';

import {
  AdditionalLogoTitle,
  AdditionalLogoWrapper,
  CloseButton,
  HeaderRightWrapper,
  HeaderTabsWrapper,
  HeaderWithProfileWrapper,
  HomeButton,
  HomeButtonWrapper,
  MenuButton,
  MobileHomeButtonWrapper,
  MobileLogoTitle,
  MobileMenuWrapper,
} from './styled';
import { AdditionalLogo } from './AdditionalLogo';
import { useHistory } from 'react-router-dom';
import { getActions } from '../../packages/components/permissions';
import {Logo} from './Logo';

export const HeaderWithProfile = memo(({ headerRef, isHomePage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isSidebarOpen = useSelector(appSelectors.getIsSidebarOpen);
  const currentCustomer = useSelector(appSelectors.getCustomer);

  const toggleSidebar = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(appActions.setIsSidebarOpen(!isSidebarOpen));
    },
    [isSidebarOpen]
  );

  const features = useSelector(appSelectors.getFeatures);
  const { user } = useSelector(appSelectors.getAppData);

  const FeaturesImages = {
    [FeatureSlugs.PlatformSecurity]: PathNames.customerResults
      .replace(':feature', FeaturePrefix.PlatformSecurity)
      .replace(':id', currentCustomer?.id),
    [FeatureSlugs.ThreatModelling]: PathNames.threatModeling,
    [FeatureSlugs.PipelineSecurity]: PathNames.customerResults
      .replace(':feature', FeaturePrefix.PipelineSecurity)
      .replace(':id', currentCustomer?.id),
  };
  // TODO incorrect work
  const currentFeature = getCurrentFeaturePrefix();

  const pipelineActions = getActions("pipeline-security::accounts");
  const threatModelingActions = getActions("threat-modeling::diagrams");
  const platformActions = getActions("platform-security::accounts");

  const permissions = {
    "pipeline-security": pipelineActions,
    "threat-modeling": threatModelingActions,
    "platform-security": platformActions,
  };

  const tabs = useMemo(
    () =>
      features?.map((feature) => {
        let isDisabled = !user.features?.includes(feature.slug) && !user.is_superuser;
        isDisabled |= !(
          permissions[feature.slug]?.company?.["view"]?.allowed
          || permissions[feature.slug]?.team?.["view"]?.allowed
          || permissions[feature.slug]?.self?.["view"]?.allowed
        );

        return {
          title: feature.name,
          name: feature.slug,
          to: FeaturesImages[feature.slug] ?? '',
          onClick: () => dispatch(appService.reinitFeature()),
          disabled: !feature.is_active || isDisabled,
          // TODO incorrect work
          // isActive: FeaturePrefixBySlug[feature.slug] === currentFeature,
          isActive: window.location.pathname.startsWith(`/${feature.slug}`)
        };
      }),
    [pathname, user, FeaturesImages, currentFeature]
  );

  const isThreatModelingPage = history.location.pathname.includes(
    PathNames.diagrams
  );

  useEffect(() => {
    if (isHomePage && isSidebarOpen) {
      toggleSidebar({stopPropagation: () => {}});
    }
  }, [isHomePage, isSidebarOpen, toggleSidebar]);

  return (
    <HeaderWithProfileWrapper ref={headerRef}>
      <MobileMenuWrapper isSidebarOpen={isSidebarOpen}>
        <NavLink to={PathNames.home} style={{ textDecoration: "none" }}>
          <MobileLogoTitle isSidebarOpen={isSidebarOpen}>
            {branding.isBank ?
              <AdditionalLogo id="1" />
              : (isSidebarOpen ? <AdditionalLogo id="1" /> : <Logo id="1" />)
            }
            <span style={{ marginLeft: 6 }}>{branding.isBank ? "" : branding.platform}</span>
          </MobileLogoTitle>
        </NavLink>
        {!isHomePage && !isThreatModelingPage && (
          <>
            {isSidebarOpen ? (
              <CloseButton
                isSidebarOpen={isSidebarOpen}
                size={30}
                onClick={toggleSidebar}
              />
            ) : (
              <MenuButton
                isSidebarOpen={isSidebarOpen}
                size={30}
                onClick={toggleSidebar}
              />
            )}
          </>
        )}
      </MobileMenuWrapper>
      <AdditionalLogoWrapper data-logo-wrapper>
        <NavLink to={PathNames.home} style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
          <>
            <AdditionalLogo id="0" />
              {branding.isBank ? "" : <AdditionalLogoTitle>{branding.platform}</AdditionalLogoTitle>}
          </>
        </NavLink>
      </AdditionalLogoWrapper>
      <HeaderRightWrapper data-right-wrapper>
        <HeaderTabsWrapper>
          <NavLink to={PathNames.home} data-link-home>
            <HomeButton>
              <HomeIcon color={branding.colors.blue} size={24} />
            </HomeButton>
          </NavLink>
          {!isHomePage && <Tabs tabs={tabs} />}
        </HeaderTabsWrapper>
        <ProfileBlock />
      </HeaderRightWrapper>
    </HeaderWithProfileWrapper>
  );
});
