import React, { useState } from 'react';

import { ModalInline, useModalInline } from '../../packages/components/modal/ModalInline';
import { PageLayout } from '../../layouts';
import ProfileContentLayout from '../../layouts/page-layout/ProfileContentLayout';
import { Button } from '../../packages';
import { TableTeams } from './components/table-teams';
import CreateTeamForm from './components/create-team-form';
import Permission from '../../packages/components/permissions';

export const PageTeams = () => {
  const { isOpen, onClose, onOpen, modalProps } = useModalInline();
  const [timestamp, setTimestamp] = useState(0);

  return (
    <PageLayout>
      <ProfileContentLayout
        title='Teams'
        headerComponent={
          <Permission resource="company::teams">
            {(actions) => ((actions.company['create']?.allowed) ?
                <Button size='medium' type='button' style={{ width: 140 }} onClick={onOpen}>
                  Create team
                </Button>
                : null
            )}
          </Permission>

        }
      >
        <TableTeams keyMutate={timestamp} onOpenEdit={onOpen} />
      </ProfileContentLayout>
      <ModalInline isOpen={isOpen} header={modalProps?.id ? 'Rename team' : 'Create team'} onClose={onClose}>
        <CreateTeamForm
          teamId={modalProps?.id}
          name={modalProps?.name}
          onCancel={onClose}
          onSuccess={() => {
            onClose();
            setTimestamp(Date.now());
          }}
        />
      </ModalInline>
    </PageLayout>
  );
};
