import styled from 'styled-components';
import { md } from "../../../../theme";

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  font-weight: 700;

  @media screen and (max-width: ${md}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
