import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { Redirect } from 'react-router';

import { customerSelectors } from '../../entities';

import { GlobalLoaderWrapper } from '../../app';
import { FeaturePrefix, PathNames } from '../../consts';

export const NotFoundRedirect = memo(() => {
  // TODO ???
  const customers = useSelector(customerSelectors.getCustomers);

  if (!customers.length) {
    return null;
  }

  return (
    <GlobalLoaderWrapper>
      <Redirect
        to={PathNames.customerResults
          .replace(':feature', FeaturePrefix.PlatformSecurity)
          .replace(':id', customers[0]?.id)}
      />
    </GlobalLoaderWrapper>
  );
});
