import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";

import { PageLayout } from "../../layouts";

import { platformSecuritySelectors, platformSecurityService } from "../../entities";

import { OverviewContent, PolicyViolationsWrapper } from "./styled";
import { PolicyGroupsList } from "./components/policy-groups-list";
import Box from "./components/box";

export const ControlsPage = () => {
  const dispatch = useDispatch();
  const { id, accountId, resource, scanId, ppToolToken, ppFeature } = useParams();
  const currentResource = useSelector((state) => platformSecuritySelectors.getResource(state, resource));


  useEffect(() => {
    dispatch(
      platformSecurityService.getResourceControls({
        id,
        accountId,
        resourceId: resource,
        scanId,
        ppToolToken,
        ppFeature,
      })
    );
    dispatch(platformSecurityService.getResource(accountId, scanId, resource));
  }, [accountId, scanId, resource, ppToolToken, ppFeature]);

  return (
    <PageLayout
    // tabs={getAccountTabs(selectedAccount)}
    >
      <Box>Resource: {currentResource?.title || resource}</Box>
      <OverviewContent>
        <PolicyViolationsWrapper> 
        </PolicyViolationsWrapper>
        <PolicyGroupsList />
      </OverviewContent>
    </PageLayout>
  );
};
