import React, { useCallback } from 'react';
import IconArrowLeftShort from '../../../../../packages/icons/IconArrowLeftShort';
import { useDiagramResultContext } from '../../DiagramResultContext';
import branding from '../../../../../branding';

export const ComponentAssigned = (props) => {
  const { reportId, user, disabled, onSuccess: onSuccessOut } = props;
  const { onOpenAssignedUser, onCloseAssignedUser } = useDiagramResultContext();

  const onSuccess = useCallback(() => {
    onSuccessOut();
    onCloseAssignedUser();
  }, [onSuccessOut, onCloseAssignedUser]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '28px',
        minWidth: 120,
        cursor: (disabled ? 'auto' : 'pointer'),
      }}
      onClick={() =>
        (disabled ? () => {} : onOpenAssignedUser({ reportId, user, onSuccess }))
    }
    >
      <div style={{ color: branding.colors.primary, fontWeight: 600 }}>
        {!user && '<Not assigned>'}
        {user && (user?.first_name ? `${user?.first_name} ${user?.last_name || ''}` : user?.email)}
      </div>
      {
        disabled ? null : <IconArrowLeftShort style={{ transform: 'rotate(270deg)' }} />
      }
    </div>
  );
};
