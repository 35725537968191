import React, { memo, useEffect } from 'react';

import { CircularProgress } from '@mui/material';

import { useDispatch } from 'react-redux';

import { authService } from '../../entities';

import { OAuthRedirectPageWrapper } from './styled';

export const OAuthRedirectPage = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    const sp = new URLSearchParams(location.search);
    console.log('>>>', sp.get('code'));
    if (sp.has('code')) {
      dispatch(
        authService.signIn(
          {
            code: sp.get('code'),
          },
          true
        )
      );
    }
  }, [dispatch]);

  return (
    <OAuthRedirectPageWrapper>
      <CircularProgress style={{ 'margin-right': '15px' }} /> Redirecting...
    </OAuthRedirectPageWrapper>
  );
});
