import styled from 'styled-components';

import { colors, md, xsm } from '../../theme';

import arrowUrl from '../onboarding/images/arrow.png';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${md}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Box = styled.div`
  flex-grow: 1;
  min-width: 520px;
`;

export const InfoFullBox = styled(Box)`
  width: 100%;
  min-width: 400px;

  @media screen and (max-width: ${xsm}) {
    min-width: 300px;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  line-height: 72px;
  font-weight: 400;
  color: ${() => colors.textColor};
`;

export const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #535353;
`;

export const WelcomeLabel = styled(Title)`
  margin-bottom: 16px;

  @media screen and (max-width: ${xsm}) {
    font-size: 42px;
  }
`;

export const InfoBox = styled(Box)`
  max-width: 520px;
  width: 100%;
  min-width: 400px;

  @media screen and (max-width: ${xsm}) {
    min-width: 300px;
  }
`;

export const ImageBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${md}) {
    display: none;
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: 32px;
`;

export const ContinueButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

export const HelpMessage = styled.div`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  margin-top: 32px;
`;

export const Image = styled.img``;

export const ArrowRight = styled.img.attrs({
  src: arrowUrl,
})`
  margin-right: 16px;
  width: 20px;
  height: 20px;
`;