import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Form, SecondaryButton, Select } from '../../../../packages';

import { ButtonsWrapper, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';
import useApiData from '../../../../hooks/useApiData';
import { Checkmarks } from '../../../../packages/controls/checkmarks';

const AddToTeamsForm = (props) => {
  const { userIds = [], emails = [], onCancel = () => {}, onSuccess = () => {} } = props;
  const dispatch = useDispatch();

  const oneUser = userIds.length === 1;

  const { data: dataTeams } = useApiData(customerDetailsApi.getUserTeams, [userIds[0]], {
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const initialValues = useMemo(
    () => ({
      // If one user is selected, then check the teams to which he is a member
      teams: !oneUser ? [] : dataTeams.results.map((i) => i.id),
    }),
    [oneUser, userIds, dataTeams]
  );

  const validationSchema = defaultValidationSchema;

  const { data, dirty, loading, mutate } = useApiData(customerDetailsApi.getTeams, [], {
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const onSubmit = useCallback(
    (values) => {
      const { teams } = values;

      if (!oneUser) {
        customerDetailsApi
          .teamInviteUsers({ emails, team_ids: teams })
          .then(() => {
            NotificationManager.success('Invite has been successfully.');
            onSuccess(true);
          })
          .catch(() => {
            NotificationManager.error('Server error.');
            onCancel();
          });
      } else {
        customerDetailsApi
          .userChangeTeams(userIds[0], { team_ids: teams })
          .then(() => {
            NotificationManager.success('Team list has been changed.');
            onSuccess(true);
          })
          .catch(() => {
            NotificationManager.error('Server error.');
            onCancel();
          });
      }
    },
    [oneUser, userIds, dispatch, onSuccess, onCancel]
  );

  const options = useMemo(() => data.results.map((i) => ({ id: i.id, label: i.name })), [data]);

  return (
    <ProfileFormWrapper>
      <Form
        key={dataTeams.results.length}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <div style={{ marginBottom: 24, fontSize: 14, fontFamily: 'Open Sans' }}>
          {oneUser ? 'Select teams from the list for' : 'Select team(s) where you want to add the'}{' '}
          <span style={{ fontWeight: 900, color: '#000000' }}>“{emails.join(', ')}”</span>
        </div>
        <Checkmarks name='teams' label='Add to Teams' options={options} multiple />
        <ButtonsWrapper>
          <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
            {'Cancel'}
          </SecondaryButton>
          <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
            {'Add'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </ProfileFormWrapper>
  );
};

export default AddToTeamsForm;
