import React from 'react';
import branding from '../../../branding';
import DefaultAvatar from './images/DefaultAvatar';

const Avatar = (props) => {
  const { src, background = branding.colors.logoBackground, size = 32, iconSize = 16 } = props;

  return (
    <div
      style={{
        background,
        width: size,
        height: size,
        borderRadius: size / 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {src ? (
        <img src={src} height={size} width={size} style={{ objectFit: 'cover', borderRadius: size / 2 }} />
      ) : (
        <DefaultAvatar size={iconSize} />
      )}
    </div>
  );
};

export default Avatar;
