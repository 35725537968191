import React, { memo, useCallback } from 'react';

import { colors } from '../../../theme';

import {
  CheckIcon,
  PlusIcon,
  ServiceInfo,
  ServiceLogo,
  ServiceName,
  Wrapper,
} from './styled';
import awsUrl from './aws.png';
import azureUrl from './azure.png';
import githubUrl from './github.png';

const IconsByType = {
  aws: awsUrl,
  azure: azureUrl,
  azuredev: azureUrl,
  githubdev: githubUrl,
};

export const ServiceButton = memo(
  ({ onClick, name, type, isCreated, disabled }) => {
    const onClickHandler = useCallback(() => {
      if (!isCreated) {
        onClick(type);
      }
    }, [type, isCreated]);

    return (
      <Wrapper onClick={onClickHandler} disabled={disabled}>
        <ServiceInfo>
          <ServiceLogo src={IconsByType[type]} alt="service-logo" />
          <ServiceName>{name}</ServiceName>
        </ServiceInfo>
        {isCreated ? (
          <CheckIcon size={40} color={colors.darkBorderColor} />
        ) : (
          <PlusIcon size={40} color={colors.darkBorderColor} />
        )}
      </Wrapper>
    );
  }
);
