import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { authService } from '../entities';

import { ExitIcon, MenuItem, MenuWrapper } from './styled';

export const ProfileMenu = memo(() => {
  const dispatch = useDispatch();
  const onLogoutHandler = useCallback(() => {
    dispatch(authService.logout());
  }, []);

  return (
    <MenuWrapper onClick={onLogoutHandler}>
      <MenuItem>
        <ExitIcon />
        Logout
      </MenuItem>
    </MenuWrapper>
  );
});
