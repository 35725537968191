import React, { memo, useCallback } from 'react';

import { colors } from '../../../theme';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LabelWrapper,
  ServiceAccountCount,
} from './styled';
import { ServiceAccount } from './ServiceAccount';

export const ServiceAccounts = memo(
  ({
    accounts,
    serviceName,
    title,
    parentService,
    onServiceSelect,
    isServiceSelected,
    customer,
    count,
    onAccountSelect,
    selectedAccountId,
    onScroll,
    activeTabRef,
  }) => {
    const onServiceSelectHandler = useCallback(() => {
      onServiceSelect(isServiceSelected ? parentService ?? null : serviceName);
    }, [isServiceSelected, serviceName, parentService]);

    return (
      <Accordion key={serviceName} expanded={isServiceSelected}>
        <AccordionSummary
          isSelected={isServiceSelected}
          sx={{ background: colors.background }}
          onClick={onServiceSelectHandler}
        >
          <LabelWrapper isActive={isServiceSelected}>
            {title}
            <ServiceAccountCount>{count}</ServiceAccountCount>
          </LabelWrapper>
        </AccordionSummary>
        <AccordionDetails onScroll={onScroll}>
          {accounts.map((account) => (
            <ServiceAccount
              key={`${account.id}-account`}
              selectedAccountId={selectedAccountId}
              accountId={account.id}
              accountName={account.name}
              service={account.service}
              activeTabRef={activeTabRef}
              onAccountSelect={onAccountSelect}
              customerId={customer.id}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  }
);
