import React, { memo, useCallback } from 'react';

import { Link, Tooltip } from '@mui/material';

import { NavLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { FeaturePrefix, PathNames } from '../../../../consts';

import { appActions, appSelectors } from '../../../../entities';

import { AccountNameWrapper } from './styled';
import {getCurrentFeaturePrefix} from '../../../../packages';

export const AccountName = memo(({ value, scanId, id, customerId, accountId, ...rest }) => {
  const dispatch = useDispatch();
  const { selectedCustomerId } = useSelector(appSelectors.getAppData);

  const onAccountNameClick = useCallback(() => {
    dispatch(appActions.setSelectedAccount({ ...rest, component: null, id }));
  }, [rest, id]);

  return (
    <AccountNameWrapper>
      <Tooltip title={value} arrow>
        <Link
          component={NavLink}
          to={PathNames.accountOverview
            .replace(':feature', getCurrentFeaturePrefix())
            .replace(':scanId', scanId ?? 'current')
            .replace(':id', customerId ? customerId : selectedCustomerId)
            .replace(':accountId', accountId ? accountId : id)}
          onClick={onAccountNameClick}
          underline="hover"
        >
          {value}
        </Link>
      </Tooltip>
    </AccountNameWrapper>
  );
});
