import IconStatusPending from '../../../../packages/icons/IconStatusPending';
import IconStatusActive from '../../../../packages/icons/IconStatusActive';
import IconStatusBlock from '../../../../packages/icons/IconStatusBlock';
import React from 'react';

const IconStatus = (props) => {
  const { status, color, icon: Icon } = props;
  return (
    <div style={{ color, display: 'flex', alignItems: 'center' }}>
      <Icon />
      <div style={{ marginLeft: 7 }}>{status}</div>
    </div>
  );
};

export const STATUS = {
  pending: () => <IconStatus status='Pending' color='#535353' icon={IconStatusPending} />,
  active: () => <IconStatus status='Active' color='#79CD67' icon={IconStatusActive} />,
  blocked: () => <IconStatus status='Blocked' color='#FF3D3D' icon={IconStatusBlock} />,
};
