import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
`;



export const BigButton = styled.a`
  display: flex;
  flex: 0.5;
  width: 60vw;
  margin: 0 20vw 20px 20vw;
  color: #fff !important;
  font-weight: bold;
  padding: 15px 5px;
  font-size: 125%;
  text-decoration: none !important;
  justify-content: center;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.activeTextColor};
`;


export const NoStandards = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
`;
