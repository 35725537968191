import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { modalActions, usePagination } from '../../packages';

import { appActions, appSelectors, appService } from '../../entities';

import { accountModalName } from '../page-layout';

import { ProfileBlockMobile } from '../MobileProfile';

import {
  CustomerNameField,
  DescriptionBox,
  DescriptionLabel,
  DescriptionValue,
  InfoBox,
  InfoLabel,
  InfoValue,
  SidebarWrapper,
} from './styled';
import { AccountList } from './account-list';
import { CustomerList } from './customers-list';

export const Sidebar = memo(({ closeSidebar }) => {
  const dispatch = useDispatch();
  const { id, accountId } = useParams();
  const {
    menuAccounts: accounts,
    menuAccountsCount,
    expandedService,
    menuItems,
  } = useSelector(appSelectors.getAppData);
  const customer = useSelector(appSelectors.getCustomer);
  const { count: customersCount } = usePagination('customers');

  const onAccountSelect = useCallback((newAccountId) => {
    dispatch(appActions.setSelectedAccountId(newAccountId));
    closeSidebar();
  }, []);

  const onServiceSelect = useCallback((service) => {
    dispatch(appActions.setExpandedService(service));
  }, []);

  const onAccountCreate = useCallback(() => {
    dispatch(
      modalActions.setModalIsOpen({
        name: accountModalName,
        isOpen: true,
      })
    );
  }, []);

  const onScrollHandler = useCallback(
    (event) => {
      const { page, count } = usePagination('menu-accounts');

      const shouldLoadNext =
        event.target.clientHeight + event.target.scrollTop >=
        event.target.scrollHeight;

      const isNextPage = count / 25 > page + 1;

      if (shouldLoadNext && isNextPage) {
        dispatch(
          appService.getMenuAccounts(id, {
            page: page + 1,
          })
        );
      }
    },
    [id]
  );

  return (
    <SidebarWrapper>
      <ProfileBlockMobile />
      {customersCount > 1 ? (
        <CustomerList menuItems={menuItems} closeSidebar={closeSidebar} />
      ) : (
        <CustomerNameField
          name="name"
          label="Customer name"
          size="medium"
          value={customer.name}
          disabled
        />
      )}
      <InfoBox>
        <InfoLabel>Enabled</InfoLabel>
        <InfoValue>{customer?.enable ? 'Yes' : 'No'}</InfoValue>
      </InfoBox>
      <InfoBox>
        <InfoLabel>Last scan</InfoLabel>
        <InfoValue>
          {customer?.last_scan
            ? new Date(customer.last_scan * 1000).toLocaleString()
            : '-'}
        </InfoValue>
      </InfoBox>
      <InfoBox>
        <InfoLabel>Compliance</InfoLabel>
        <InfoValue>
          {customer?.last_scan ? `${customer.compliance}%` : '-'}
        </InfoValue>
      </InfoBox>
      <InfoBox>
        <InfoLabel>Policies violated</InfoLabel>
        <InfoValue>{customer?.policies_bad}</InfoValue>
      </InfoBox>
      <DescriptionBox>
        <DescriptionLabel>Description</DescriptionLabel>
        <DescriptionValue>{customer?.descr}</DescriptionValue>
      </DescriptionBox>
      <AccountList
        accounts={accounts}
        customer={customer}
        accountsCount={menuAccountsCount}
        expandedService={expandedService}
        onAccountSelect={onAccountSelect}
        onServiceSelect={onServiceSelect}
        onAccountCreate={onAccountCreate}
        closeSidebar={closeSidebar}
        selectedAccountId={accountId}
        onScroll={onScrollHandler}
      />
    </SidebarWrapper>
  );
});
