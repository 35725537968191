import React, { useCallback, useMemo, useState } from 'react';

import { customerDetailsApi } from '../../../../entities';

import headers from './headers';

import { CustomerAccountsWrapper, TableWrapper } from '../../../accounts/components/customer-accounts/styled';
import TableNew from '../../../../packages/components/table-new';
import { formatData } from './formatData';
import { ModalInline, useModalInline } from '../../../../packages/components/modal/ModalInline';
import useApiData from '../../../../hooks/useApiData';
import TeamsMemberList from '../teams-member';
import branding from '../../../../branding';

export const TableTeamUsers = (props) => {
  const { team, keyMutate, onSelect: onSelectOutside, onOpenRemoveMember, onOpenBlock, onOpenResend } = props;
  const [apiQuery, setApiQuery] = useState({});

  const { isOpen, onClose, onOpen, modalProps } = useModalInline();

  const { data, dirty, loading, mutate } = useApiData(
    customerDetailsApi.getTeamUsers,
    [team?.id, apiQuery],
    {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    keyMutate
  );

  const onChangeHandler = useCallback((query) => {
    setApiQuery(query);
  }, []);

  const preparedData = useMemo(
    () => ({
      ...data,
      results: formatData(data.results, {
        teamId: team?.id,
        teamName: team?.name,
        onOpenTeamsMember: onOpen,
        onOpenRemoveMember,
        onOpenBlock,
        onOpenResend,
      }),
    }),
    [data, team, onOpen, onOpenRemoveMember, onOpenBlock, onOpenResend]
  );

  const onSelect = useCallback(
    (ids = []) => {
      const emails = [];

      ids.forEach((id) => {
        const user = data.results.find((i) => i.id === id);
        if (user) {
          emails.push(user.email);
        }
      });

      const arr = data.results.filter(i => ids.includes(i.id));
      onSelectOutside(arr);
    },
    [onSelectOutside, data]
  );

  return (
    <CustomerAccountsWrapper>
      <TableWrapper>
        <TableNew
          key={`${keyMutate}`}
          isLoading={loading}
          onPageChange={onChangeHandler}
          onSort={onChangeHandler}
          count={preparedData.count}
          rows={preparedData.results}
          headCells={headers}
          onSelect={onSelect}
          withoutCheck
        />
      </TableWrapper>
      <ModalInline
        isOpen={isOpen}
        header={
          <div>
            <div
              style={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '28px',
                color: '#000000',
              }}
            >
              {modalProps?.full_name || modalProps?.email}’s teams
            </div>
            <div style={{ color: branding.colors.primary, fontSize: 13 }}>{modalProps.email}</div>
          </div>
        }
        onClose={onClose}
      >
        <TeamsMemberList onCancel={onClose} onSuccess={onClose} data={modalProps} />
      </ModalInline>
      {/* <ModalInline isOpen={isOpen} header='Delete team' onClose={onClose}>
        <DeleteTeamForm
          onCancel={onClose}
          onSuccess={() => {
            setApiQuery({});
            setTimestamp(Date.now());
            onClose(true);
          }}
          id={modalProps.id}
          name={modalProps.name}
        />
      </ModalInline> */}
    </CustomerAccountsWrapper>
  );
};
