import React from 'react';

const IconChainSplitted = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9 6.75V4.5' stroke='#EA0000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M15 19.5V17.25' stroke='#EA0000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M6.75 9H4.5' stroke='#EA0000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M19.5 15H17.25' stroke='#EA0000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M6.65638 12.0371L5.59701 13.0965C4.89335 13.8001 4.49805 14.7545 4.49805 15.7496C4.49805 16.2423 4.5951 16.7302 4.78366 17.1855C4.97222 17.6407 5.24859 18.0543 5.59701 18.4027C5.94542 18.7511 6.35905 19.0275 6.81427 19.2161C7.2695 19.4046 7.7574 19.5017 8.25013 19.5017C9.24525 19.5017 10.1996 19.1064 10.9033 18.4027L11.9626 17.3434'
        stroke='#EA0000'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.3438 11.9626L18.4032 10.9033C19.1069 10.1996 19.5022 9.24525 19.5022 8.25013C19.5022 7.25502 19.1069 6.30066 18.4032 5.59701C17.6996 4.89335 16.7452 4.49805 15.7501 4.49805C14.755 4.49805 13.8006 4.89335 13.097 5.59701L12.0376 6.65638'
        stroke='#EA0000'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default IconChainSplitted;
