import React, { memo, useCallback, useRef } from 'react';

import { JSONContent, JSONContentWrapper, JSONCopyIcon } from './styled';

export const JSONBlock = memo(({ value }) => {
  const codeRef = useRef(null);

  const copyCodeHandler = useCallback(() => {
    codeRef.current?.select();
    document.execCommand('Copy');
  }, [codeRef]);

  return (
    <JSONContentWrapper>
      <JSONContent ref={codeRef} value={value} readOnly />
      <JSONCopyIcon onClick={copyCodeHandler} />
    </JSONContentWrapper>
  );
});
