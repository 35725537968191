import cylinder from "./icons/cylinder.svg.js";
import hexagon from "./icons/hexagon.svg.js";
import pentagon from "./icons/pentagon.svg.js";
import triangle from "./icons/triangle.svg.js";

export const ShapeDefault = "rect";

const BasicShapes = [
  {
    shape: ShapeDefault,
    width: 70,
    height: 70,
  },
  {
    shape: "ellipse",
    width: 70,
    height: 35,
    style: { borderRadius: "50%" },
  },
  {
    shape: "cylinder",
    icon: cylinder,
    width: 70,
    height: 70,
  },
  {
    shape: "pentagon",
    icon: pentagon,
    width: 70,
    height: 64,
  },
  {
    shape: "hexagon",
    icon: hexagon,
    width: 80,
    height: 70,
  },
  {
    shape: "triangle",
    icon: triangle,
    width: 70,
    height: 60,
  },
];

export default BasicShapes;
