import { createSelector } from 'reselect';

import { appSelectors } from '../app/selectors';

import { customerDetailsReducerNamespace, customerDetailinitialState } from './store';

const getCustomerDetailsData = (state) =>
  state[customerDetailsReducerNamespace] || customerDetailinitialState;

const getAccountById = (state, id) => {
  const { accounts } = getCustomerDetailsData(state);

  return accounts.find((account) => String(account.id) === String(id));
};

const getAvailableScansCount = (state, id) => {
  const currentAccount = getAccountById(state, id);
  const { user } = appSelectors.getAppData(state);

  const isUnlimited =
    currentAccount?.service === 'aws'
      ? user.aws_scan_is_unlimited
      : user.azure_scan_is_unlimited;

  const availableScans =
    currentAccount?.service === 'aws'
      ? user.aws_scan_limit
      : user.azure_scan_limit;

  return {
    isUnlimited,
    availableScans,
  };
};

const getTotalPolicesViolated = createSelector(
  getCustomerDetailsData,
  ({ customerStats }) => customerStats.policies_bad
);

const getTotalPolicesCompliance = createSelector(
  getCustomerDetailsData,
  (state) => state.customerScans.reduce((sum, scan) => scan.compliance + sum, 0)
);

const getViolatedPolicesStats = createSelector(
  getCustomerDetailsData,
  ({ customerStats }) => {
    const { policies_bad } = customerStats ?? {};
    const { high, low, medium, unset } = customerStats.severity ?? {};
    const { aws, azure, azuredev, githubdev } =
      customerStats.policies_bad_details ?? {};

    return {
      total: policies_bad,
      highPercent: policies_bad ? (high * 100) / policies_bad : 0,
      lowPercent: policies_bad ? (low * 100) / policies_bad : 0,
      mediumPercent: policies_bad ? (medium * 100) / policies_bad : 0,
      unsetPercent: policies_bad ? (unset * 100) / policies_bad : 0,
      high: high ?? 0,
      low: low ?? 0,
      medium: medium ?? 0,
      unset: unset ?? 0,
      awsPercent: aws ?? 0,
      azurePercent: azure ?? 0,
      azuredevPercent: azuredev ?? 0,
      githubdevPercent: githubdev ?? 0,
    };
  }
);

const getTotalCompliancePercent = createSelector(
  getCustomerDetailsData,
  ({ customerStats }) => ({
    azureCompliancePercent: customerStats.policies_details?.azure
      ? customerStats.compliance_details?.azure
      : null,
    awsCompliancePercent: customerStats.policies_details?.aws
      ? customerStats.compliance_details?.aws
      : null,
    overallCompliancePercent: customerStats.policies
      ? customerStats.compliance_total
      : null,
    azureDevCompliancePercent: customerStats.policies_details?.azuredev
      ? customerStats.compliance_details?.azuredev
      : null,
    githubDevCompliancePercent: customerStats.policies_details?.githubdev
      ? customerStats.compliance_details?.githubdev
      : null,
  })
);

const getDataForTrendsChart = createSelector(
  getCustomerDetailsData,
  ({ customerTrends }) => {
    const awsTrends = customerTrends
      .filter((trend) => trend.compliance_details?.aws)
      .map((trend) => ({
        secondary: trend.compliance_details?.aws ?? 0,
        primary: new Date(trend.timestamp),
      }));

    const azureTrends = customerTrends
      .filter((trend) => trend.compliance_details?.azure)
      .map((trend) => ({
        secondary: trend.compliance_details?.azure ?? 0,
        primary: new Date(trend.timestamp),
      }));

    const totalCompliance = customerTrends
      .filter((trend) => trend.compliance_total)
      .map((trend) => ({
        secondary: trend.compliance_total ?? 0,
        primary: new Date(trend.timestamp),
      }));

    if (!azureTrends.length && !awsTrends.length) return null;

    return [
      {
        label: 'Aws',
        data: awsTrends,
      },
      {
        label: 'Azure',
        data: azureTrends,
      },
      {
        label: 'Total compliance',
        data: totalCompliance,
      },
    ];
  }
);

const getSelectedAccount = createSelector(
  getCustomerDetailsData,
  ({ selectedAccountId, accounts }) =>
    accounts.find(({ id }) => id === selectedAccountId)
);

const getIsNotEmptyScans = createSelector(
  getCustomerDetailsData,
  ({ customerScans }) => customerScans.find((scan) => scan.policies)
);

const getCustomerStat = createSelector(
  getCustomerDetailsData,
  ({ customerStats }) => customerStats
);

const getResourcesStat = createSelector(
  getCustomerDetailsData,
  ({ resourcesStat }) => resourcesStat
);

export const customerDetailsSelectors = {
  getCustomerDetailsData,
  getTotalPolicesViolated,
  getTotalPolicesCompliance,
  getTotalCompliancePercent,
  getDataForTrendsChart,
  getSelectedAccount,
  getIsNotEmptyScans,
  getViolatedPolicesStats,
  getAvailableScansCount,
  getAccountById,
  getResourcesStat,
  getCustomerStat,
};
