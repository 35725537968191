import React from "react";
import { Card } from "../card";
import { colors } from "../../../theme";
import { Tooltip } from "../tooltip";
import { FeaturePrefix } from "../../../consts";
import { NoViolations } from "./NoViolations";

import {
  Legend,
  LegendCircle,
  LegendCount,
  LegendLabel,
  LegendWrapper,
  LinesWrapper,
  ServiceLegend,
  ServiceName,
  ServicePercent,
  TotalCount,
  TotalLabel,
  TotalViolations,
  ViolationHelpMessage,
  ViolationsCount,
  ViolationsLegend,
  ViolationsLine,
  ViolationsPercent,
  ViolationsWrapper,
} from "./styled";

const TemperatureGraph = ({ title, total = 0, lines, helpMessage = null, style = {} }) => {

  return (
    <Card
      title={title || "Temperature Graph"}
      helpMessage={
        helpMessage && (
          <ViolationHelpMessage>
            {helpMessage}
          </ViolationHelpMessage>
        )
      }
      style={{ flexGrow: 2, ...style }}
    >
      {total ? (
        <ViolationsWrapper>
          <ViolationsPercent>
            <LinesWrapper>
              {lines.map(({ percent, color, stat }) => (
                <Tooltip title={stat} arrow>
                  <ViolationsLine percent={percent} color={color} />
                </Tooltip>
              ))}
            </LinesWrapper>
          </ViolationsPercent>
          <ViolationsCount>
            <TotalViolations>
              <TotalCount>{total}</TotalCount>
              <TotalLabel>Total</TotalLabel>
            </TotalViolations>
            <LegendWrapper>
              {lines.map(({ count, color, title }) => (
                <Legend>
                  <LegendCircle color={color} />
                  <LegendCount>{count}</LegendCount>
                  <LegendLabel>{title}</LegendLabel>
                </Legend>
              ))}
            </LegendWrapper>
          </ViolationsCount>
        </ViolationsWrapper>
      ) : (
        <NoViolations />
      )}
    </Card>
  );
};

export default TemperatureGraph;
