import React, { useCallback, useState } from 'react';

import { ModalInline, useModalInline } from '../../packages/components/modal/ModalInline';
import { PageLayout } from '../../layouts';
import ProfileContentLayout from '../../layouts/page-layout/ProfileContentLayout';
import { Button, SecondaryButton } from '../../packages';
import { UsersMainMenu } from '../../layouts/UsersMainMenu';
import InviteUserForm from './components/invite-user-form';
import AddToTeamsForm from './components/add-to-teams-form';
import DeleteUserForm from './components/delete-user-form';
import StatusUserForm from './components/status-user-form';
import UserResendInviteForm from './components/user-resend-invite-form';
import { TableUsers } from './components/table-users';
import IconMoreVertical from '../../packages/icons/IconMoreVertical';
import { NotificationManager } from 'react-notifications';
import Permission from '../../packages/components/permissions';

export const PageUsers = () => {
  const { isOpen: isOpenInvite, onClose: onCloseInvite, onOpen: onOpenInvite, keyUpdate } = useModalInline();
  const {
    isOpen: isOpenAddToTeam,
    onClose: onCloseAddToTeam,
    onOpen: onOpenAddToTeam,
    keyUpdate: keyUpdateAddToTeam,
    modalProps: modalPropsAddToTeam,
  } = useModalInline();
  const {
    isOpen: isOpenDelete,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
    keyUpdate: keyUpdateDelete,
  } = useModalInline();
  const {
    isOpen: isOpenBlock,
    onClose: onCloseBlock,
    onOpen: onOpenBlock,
    keyUpdate: keyUpdateBlock,
    modalProps: modalPropsBlock,
  } = useModalInline();
  const {
    isOpen: isOpenResend,
    onClose: onCloseResend,
    onOpen: onOpenResend,
    modalProps: modalPropsResend,
  } = useModalInline();

  const [selected, setSelected] = useState([]);

  const onAction = useCallback(
    (action) => {
      if (selected.length) {
        action({ userIds: selected.map((i) => i.id), emails: selected.map((i) => i.email) });
      } else {
        NotificationManager.info('Please select users');
      }
    },
    [selected]
  );

  return (
    <PageLayout>
      <ProfileContentLayout
        title='Users'
        headerComponent={
          <div style={{ display: 'flex' }}>
            <Permission resource="company::users">
              {(actions) => ((actions.company['invite']?.allowed) ?
                <Button size='medium' type='button' style={{ width: 140, marginRight: 16, padding: "20px 60px" }} onClick={onOpenInvite}>
                  Invite user
                </Button>
                : null
              )}
            </Permission>
            <UsersMainMenu
              onOpenAddToTeam={() => onAction(onOpenAddToTeam)}
              // onOpenDelete={() => onAction(onOpenDelete)} // TODO not implemented
              onOpenBlock={() => onAction(onOpenBlock)}
              onOpenResend={() => onAction(onOpenResend)}
            >
              <SecondaryButton style={{ minWidth: 'unset', width: 40, height: 40, borderWidth: 2 }}>
                <IconMoreVertical />
              </SecondaryButton>
            </UsersMainMenu>
          </div>
        }
      >
        <Permission resource="company::roles">
          {(rolesActions) => (
            <Permission resource="company::users">
              {(usersActions) => (
                <TableUsers
                  keyUpdate={`${keyUpdate}${keyUpdateDelete}${keyUpdateBlock}${keyUpdateAddToTeam}`}
                  onOpenAddToTeam={onOpenAddToTeam}
                  onOpenDelete={onOpenDelete}
                  onOpenBlock={onOpenBlock}
                  onOpenResend={onOpenResend}
                  onSelect={setSelected}
                  rolesVisible={rolesActions.company.view?.allowed}
                  rolesEditable={usersActions.company['manage-role']?.allowed}
                />
              )}
            </Permission>
          )}
        </Permission>
      </ProfileContentLayout>
      <ModalInline isOpen={isOpenInvite} header='Invite user' onClose={onCloseInvite}>
        <InviteUserForm onCancel={onCloseInvite} onSuccess={onCloseInvite} />
      </ModalInline>
      <ModalInline
        isOpen={isOpenAddToTeam}
        header={modalPropsAddToTeam?.userIds?.length === 1 ? 'Change teams list' : 'Add to teams'}
        onClose={onCloseAddToTeam}
      >
        <AddToTeamsForm
          onCancel={onCloseAddToTeam}
          onSuccess={onCloseAddToTeam}
          userIds={modalPropsAddToTeam?.userIds}
          emails={modalPropsAddToTeam?.emails}
        />
      </ModalInline>
      <ModalInline isOpen={isOpenDelete} header='Delete user' onClose={onCloseDelete}>
        <DeleteUserForm onCancel={onCloseDelete} onSuccess={onCloseDelete} />
      </ModalInline>
      {console.log('modalPropsBlock', modalPropsBlock)}
      <ModalInline
        isOpen={isOpenBlock}
        header={modalPropsBlock?.activateUser ? 'Activate user' : 'Block user'}
        onClose={onCloseBlock}
      >
        <StatusUserForm
          onCancel={onCloseBlock}
          onSuccess={onCloseBlock}
          emails={modalPropsBlock?.emails}
          activateUser={modalPropsBlock?.activateUser}
        />
      </ModalInline>
      <ModalInline isOpen={isOpenResend} header='Resend user invite' onClose={onCloseResend}>
        <UserResendInviteForm onCancel={onCloseResend} onSuccess={onCloseResend} emails={modalPropsResend?.emails} />
      </ModalInline>
    </PageLayout>
  );
};
