import React from 'react';
import { SecondaryButton } from '../../../../packages';

export const ButtonEditDiagram = ({ onClick }) => {
  return (
    <SecondaryButton variant='contained' onClick={onClick}>
      Edit
    </SecondaryButton>
  );
};
