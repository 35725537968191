import React from 'react';

const IconDownload = (props) => {
  const { style } = props;

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
         xmlns='http://www.w3.org/2000/svg' style={style}>
      <path stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2"
            d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"/>
    </svg>

  );
};

export default IconDownload;
