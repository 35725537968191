import React, { memo } from 'react';

import { Tooltip } from '@mui/material';

import { FileNameWrapper } from './styled';

export const ComponentName = memo(({ value }) => (
  <FileNameWrapper>
    <Tooltip title={value} arrow>
      <span>{value}</span>
    </Tooltip>
  </FileNameWrapper>
));
