import styled, { css } from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';

import React from 'react';

import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import RocketLaunchIcon from 'mdi-react/RocketLaunchIcon';

import { NavLink } from 'react-router-dom';

import { scrollable, scrollableObject } from '../../../theme';

export const AccountListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AccountList = styled.div`
  height: 100%;
  margin-top: 8px;
  ${scrollable};
`;

export const ServiceAccountCount = styled.div`
  min-width: 24px;
  padding: 0 5px;
  height: 24px;
  background: ${({ theme }) => theme.colors.activeBackground};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const LabelWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
`;

export const ChildWrapper = styled(NavLink)`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ isActiveTab }) => (isActiveTab ? 'bold' : 'normal')};
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.activeTextColor};
  position: relative;
`;

export const PipelineIcon = styled(RocketLaunchIcon)`
  position: absolute;
  fill: ${({ theme }) => theme.colors.activeTextColor} !important;
  left: -5px;
  width: 20px;
  top: 10px;
`;

export const Accordion = muiStyled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  background: 'inherit',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionDetails-root': {
    maxHeight: '200px',
    overflow: 'auto',
    ...scrollableObject({ theme }),
  },
}));

export const AccordionSummary = muiStyled(({ withoutExpandIcon, ...rest }) => (
  <MuiAccordionSummary
    expandIcon={withoutExpandIcon ? null : <ExpandMoreIcon />}
    {...rest}
  />
))(({ theme, withoutExpandIcon, isSelected }) => ({
  color: theme.colors.activeTextColor,
  fontWeight: 'normal',
  border: 'none',
  maxHeight: '40px',
  minHeight: '40px',
  borderRadius: '8px',
  marginBottom: '4px',
  background: theme.colors.background,
  padding: 0,
  svg: {
    fill: theme.colors.darkBorderColor,
  },
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-root': {
    background: theme.colors.background,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '16px',
    transform: isSelected ? 'rotate(180deg)' : 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: withoutExpandIcon ? theme.spacing(3) : theme.spacing(1),
  },
}));

export const AccordionDetails = muiStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(2),
  border: 'none',
}));
