import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import serverStatusUrl from '../onboarding/images/server-status.svg';

import { RegistrationLayout } from '../../layouts';

import { RegisterForm } from '../forms/register-form';
import { authService } from '../../entities';

import { history } from '../../packages';
import { PathNames } from '../../consts';
import { WelcomeTxt } from '../../pages/welcome/WelcomeTxt';

import {
  Image,
  ImageBox,
  InfoBox,
  Wrapper,
} from './styled';

export const SignUp = memo(() => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      dispatch(authService.register(values));
    },
    [dispatch]
  );

  const onBackHandler = useCallback(() => {
    history.push({ pathname: `${PathNames.welcome}` });
  }, [dispatch]);

  return (
    <RegistrationLayout>
      <Wrapper>
        <InfoBox>
          <WelcomeTxt/>
          <RegisterForm
            onSubmitHandler={onSubmit}
            onBackHandler={onBackHandler}
          />
        </InfoBox>
        <ImageBox>
          <Image src={serverStatusUrl} />
        </ImageBox>
      </Wrapper>
    </RegistrationLayout>
  );
});
