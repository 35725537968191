import styled from 'styled-components';
import branding from '../../../branding';

export const EditorModalWrapper = styled.div`
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  --editor-color-primary: ${branding.colors.primary};
`;

export const PanelDetailsWrapper = styled.div`
  position: 'absolute';
  top: 0;
  right: 0;
  bottom: 0;
  width: 320px;
  padding: 0 10px 20px 20px;
  overflow: auto;
  border: 1px solid #79aaff;
  border-color: var(--editor-color-primary);
  border-right: 0;
  border-left: 0;
  background: white;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  &> * {
    margin: 0px;
  }
`;