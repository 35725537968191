import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { useParams } from 'react-router';

import { FeaturePrefix, PathNames } from '../../consts';

import {
  customerDetailsSelectors,
  policyActions,
  policySelectors,
  policyService,
} from '../../entities';

import { LoaderWrapper } from '../../app';
import { PageLayout, getAccountTabs } from '../../layouts';

import {
  InfoCard,
  PolicyInfoCard,
  getCurrentFeaturePrefix,
} from '../../packages';

import { PolicyInfoWrapper } from './styled';
import { PolicyStat, ResourcesList } from './components';

export const PolicyDetailsPage = memo(() => {
  const dispatch = useDispatch();
  const {
    id,
    accountId,
    standardId,
    policyGroupId,
    policyId,
    ppToolToken,
    ppFeature,
    scanId,
  } = useParams();
  const { isLoading, currentPolicy } = useSelector(
    policySelectors.getPolicyData
  );
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );

  const currentFeaturePrefix = getCurrentFeaturePrefix();

  useEffect(() => {
    dispatch(
      policyService.init({
        id,
        accountId,
        standardId,
        policyGroupId,
        ppToolToken,
        ppFeature,
        policyId,
        scanId,
      })
    );

    return () => {
      dispatch(policyActions.resetState());
    };
  }, [
    accountId,
    standardId,
    policyGroupId,
    policyId,
    ppToolToken,
    ppFeature,
    scanId,
  ]);

  if (isLoading) {
    return (
      <PageLayout tabs={getAccountTabs(selectedAccount)}>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout tabs={getAccountTabs(selectedAccount)}>
      <PolicyInfoWrapper>
        {ppToolToken !== undefined ? (
          <Link
            component={NavLink}
            to={PathNames.ppPolicyGroupOverview
              .replace(':ppToolToken', ppToolToken)
              .replace(':policyGroupId', policyGroupId)
              .replace(':standardId', standardId)
              .replace(':ppFeature', ppFeature)}
          >
            &lt; BACK
          </Link>
        ) : null}
        {!!currentPolicy &&
          (currentFeaturePrefix === FeaturePrefix.PlatformSecurity ? (
            <InfoCard name={currentPolicy.name} descr={currentPolicy.descr} />
          ) : (
            <PolicyInfoCard
              name={currentPolicy.name}
              descr={currentPolicy.descr}
              rationale={currentPolicy.rationale}
              remediation={currentPolicy.remediation}
            />
          ))}
      </PolicyInfoWrapper>
      <PolicyStat />
      <ResourcesList />
    </PageLayout>
  );
});
