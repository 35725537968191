import { wretch, wretchppl } from '../app';

const getAccounts = (query) => wretch(`accounts/`).query(query).get().json();

const createAccount = (customerId, requestModel) =>
  wretch(`customers/${customerId}/accounts/`).post(requestModel).json();

const updateAccount = (customerId, accountId, requestModel) =>
  wretch(`customers/${customerId}/accounts/${accountId}/`)
    .put(requestModel)
    .json();

const patchAccount = (customerId, accountId, requestModel) =>
  wretch(`customers/${customerId}/accounts/${accountId}/`)
    .patch(requestModel)
    .json();

const getCustomerAccounts = (customerId, query) =>
  wretch(`customers/${customerId}/accounts/`).query(query).get().json();

const getCustomerAccountsPipeline = (customerId, query) =>
  wretch(`customers/${customerId}/accounts/pipeline/`).query(query).get().json();

const getCustomerAccountScans = (customerId, accountId, query) =>
  wretch(`customers/${customerId}/accounts/${accountId}/`)
    .query(query)
    .get()
    .json();

const getCustomerScans = (customerId, query) =>
  wretch(`customers/${customerId}/scans/`).query(query).get().json();

const getCustomerScansPipeline = (customerId, query) =>
  wretch(`customers/${customerId}/scans/pipeline/`).query(query).get().json();

const getCustomerTrends = (customerId) =>
  wretch(`customers/${customerId}/trends/`).get().json();

const disableAccount = (customerId, accountId) =>
  wretch(`customers/${customerId}/accounts/${accountId}/disable/`).post().res();

const enableAccount = (customerId, accountId) =>
  wretch(`customers/${customerId}/accounts/${accountId}/enable/`).post().res();

const scanAccounts = (customerId, requestModel) =>
  wretch(`customers/${customerId}/accounts/start_scan/`)
    .post(requestModel)
    .res();

const scanAccountsPipeline = (customerId, requestModel) =>
  wretch(`customers/${customerId}/accounts/start_scan/pipeline/`)
    .post(requestModel)
    .res();

const getCustomerStats = (customerId) =>
  wretch(`customers/${customerId}/stat/`).get().json();

const getCustomerStatsPipeline = (customerId) =>
  wretch(`customers/${customerId}/stat/pipeline/`).get().json();

const getAccountById = ({ id, accountId }) =>
  wretch(`customers/${id}/accounts/${accountId}/`).get().json();

const getAccountByIdPipeline = ({ id, accountId }) =>
  wretch(`customers/${id}/accounts/${accountId}/pipeline/`).get().json();

const getResourcesStat = ({ id, accountId }) =>
  wretch(`customers/${id}/accounts/${accountId}/resources_stats/`).get().json();

const getResourcesStatPP = ({ ppToolToken }) =>
  wretchppl(`results/${ppToolToken}/stat/`).get().json();

const getCurrent = (query) =>
  wretch(`companies/current/`).query(query).get().json();

const inviteUserAccept = (data = {code: ''}) => wretch(`invites/accept/`).post(data).json();

const inviteUserComplete = (
  data = { code: '', first_name: '', last_name: '', password: '' }
) => wretch(`invites/complete/`).post(data).json();

const getUsers = (query) =>
  wretch(`companies/current/users/`).query(query).get().json();

const getUsersWithoutTeam = (query) =>
  wretch(`companies/current/users/?teams__isnull=True&no_page`).query(query).get().json();

const getUser = (userId, query) =>
  wretch(`companies/current/users/${userId}/`).query(query).get().json();

const getTeams = (query) =>
  wretch(`companies/current/teams/`).query(query).get().json();

const getTeamsAndMembers = (query) =>
  wretch(`companies/current/teams/?expand=members&fields=id,name,members.first_name,members.last_name,members.id,members.email&no_page`).query(query).get().json();

const getTeam = (teamId, query) =>
  wretch(`companies/current/teams/${teamId}/`).query(query).get().json();

const removeTeamMember = (teamId, emails = []) =>
  wretch(`companies/current/teams/${teamId}/users/?emails=${emails.join(', ')}`).delete().text();

const getTeamUsers = (teamId, query) =>
  wretch(`companies/current/teams/${teamId}/users/`).query(query).get().json();

const createTeam = (data = { name: '' }, query) =>
  wretch(`companies/current/teams/`).query(query).post(data).json();

const updateTeam = (teamId, data = { name: '' }, query) =>
  wretch(`companies/current/teams/${teamId}/`).query(query).put(data).json();

const deleteTeam = (teamId) =>
  // TODO empty response
  wretch(`companies/current/teams/${teamId}/`).delete().text();

const teamInviteUsers = (data = { emails: [], team_ids: [] }) =>
  wretch('companies/current/users/invite/').post(data).json();

const userChangeTeams = (userId, data = { team_ids: [] }) =>
  wretch(`companies/current/users/${userId}/teams/`).put(data).json();

const teamAddUsers = (teamId, data = { emails: [] }) =>
  wretch(`companies/current/teams/${teamId}/users/`).put(data).json();

const teamResendInviteUsers = (data = { emails: [] }) =>
  wretch('companies/current/users/invite/resend/').post(data).json();

const companiesActivateUsers = (data = { emails: [] }) =>
  wretch('companies/current/users/activate/').put(data).json();

const companiesBlockUsers = (data = { emails: [] }) =>
  wretch('companies/current/users/block/').post(data).json();

const getUserTeams = (userId) =>
  wretch(`companies/current/users/${userId}/teams/`).get().json();

const setUserTeams = (userId, data = { team_ids: [] }) =>
  wretch(`companies/current/users/${userId}/teams/`).post(data).json();

const deleteUserFromTeam = (teamId, userId) =>
  // TODO empty response
  wretch(`companies/current/teams/${teamId}/users/${userId}/`).delete().text();

const getRoles = (query) =>
  wretch(`roles/company-roles/`).query(query).get().json();

const updateUserRole = (userId, data) => wretch(`companies/current/users/${userId}/role/`).put(data).json();

export const customerDetailsApi = {
  getAccounts,
  scanAccounts,
  patchAccount,
  createAccount,
  updateAccount,
  enableAccount,
  getAccountById,
  disableAccount,
  scanAccountsPipeline,
  getResourcesStat,
  getResourcesStatPP,
  getCustomerStats,
  getCustomerScans,
  getCustomerTrends,
  getCustomerAccounts,
  getAccountByIdPipeline,
  getCustomerAccountScans,
  getCustomerScansPipeline,
  getCustomerStatsPipeline,
  getCustomerAccountsPipeline,
  getCurrent,
  inviteUserAccept,
  inviteUserComplete,
  getUsers,
  getUsersWithoutTeam,
  getUser,
  getTeams,
  getTeamsAndMembers,
  getTeam,
  getTeamUsers,
  removeTeamMember,
  createTeam,
  updateTeam,
  deleteTeam,
  teamInviteUsers,
  userChangeTeams,
  teamAddUsers,
  teamResendInviteUsers,
  deleteUserFromTeam,
  companiesActivateUsers,
  companiesBlockUsers,
  getUserTeams,
  setUserTeams,
  getRoles,
  updateUserRole,
};
