import React, {createContext, memo, useCallback, useContext, useMemo} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getCurrentFeaturePrefix, Table } from '../../../../packages';

import {
  appSelectors,
  customerDetailsActions,
  customerDetailsSelectors,
  customerDetailsService,
} from '../../../../entities';

import { AddAccountModal, ManageTeamsModal } from '../../../../layouts';

import { CustomerAccountsWrapper, NoAccounts, TableWrapper } from './styled';
import { formatAccountsData } from './utils';
import { ModalInline, useModalInline} from '../../../../packages/components/modal/ModalInline';
import { getActions } from '../../../../packages/components/permissions';
import { FeaturePrefix } from '../../../../consts';

const Context = createContext({
  onOpenManageTeams: ({ accountId, teams, onSuccess }) => {},
  onCloseManageTeams: () => {},
  diagram: {},
});


const getAccountHeaders = (featurePrefix) => {
  const actions = featurePrefix === FeaturePrefix.PlatformSecurity ?
    getActions("platform-security::accounts")
    : getActions("pipeline-security::accounts");

  const headers = [
    {
      id: 'enable',
      numeric: false,
      disablePadding: true,
      sort: 'enable',
      label: 'Enabled',
      align: 'left',
    },
    {
      id: 'service',
      numeric: false,
      sort: 'service',
      disablePadding: true,
      label: 'Service name',
      align: 'left',
    },
    {
      id: 'name',
      numeric: false,
      sort: 'name',
      disablePadding: true,
      label: 'Account’s name',
      align: 'left',
    },
    {
      id: 'lastScan',
      numeric: false,
      sort: 'last_scan',
      disablePadding: true,
      label: 'Last scan',
      align: 'center',
    },
    {
      id: 'compliance',
      numeric: true,
      sort: 'compliance',
      disablePadding: true,
      label: 'Compliance %',
      align: 'right',
    },
    {
      id: 'policiesViolated',
      numeric: true,
      sort: 'policies_bad',
      disablePadding: true,
      label: 'Policies violated',
      align: 'right',
    },
  ];

  if (featurePrefix === FeaturePrefix.PlatformSecurity) {
    headers.push({
      id: 'generateDiagram',
      numeric: true,
      disablePadding: true,
      label: null,
      align: 'right',
    });
  }

  if (actions.company["manage-teams"]?.allowed) {
    headers.push({
      id: 'manageTeams',
      numeric: true,
      disablePadding: true,
      label: 'Teams',
      align: 'right',
    });
  }

  if (actions.company?.["start-scan"]?.allowed || actions.team?.["start-scan"]?.allowed) {
    headers.push({
      id: 'startNewScan',
      numeric: true,
      disablePadding: true,
      label: null,
      align: 'right',
    });
  }

  if (actions.company?.["update"]?.allowed) {
    headers.push({
      id: 'edit',
      numeric: true,
      disablePadding: true,
      label: null,
      align: 'right',
    });
  }

  return headers;
  // return accountsHeader;
};

export const CustomerAccounts = memo(() => {
  const dispatch = useDispatch();
  const { accounts, isAccountsLoading } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const { selectedCustomerId } = useSelector(appSelectors.getAppData);

  const currentFeaturePrefix = getCurrentFeaturePrefix();
  const accountsActions = (
    currentFeaturePrefix === FeaturePrefix.PlatformSecurity
      ? getActions('platform-security::accounts')
      : getActions('pipeline-security::accounts')
  );

  const tableData = useMemo(() => formatAccountsData(accounts, accountsActions, currentFeaturePrefix), [accounts]);

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        customerDetailsService.getCustomerAccounts(selectedCustomerId, query)
      );
    },
    [selectedCustomerId]
  );

  const onAccountsSelect = useCallback(
    (value) => {
      dispatch(customerDetailsActions.setSelectedAccountsIds(value));
    },
    [selectedCustomerId]
  );

  const {
    isOpen: isOpenManageTeams,
    onOpen: onOpenManageTeams,
    onClose: onCloseManageTeams,
    modalProps: modalPropsManageTeams,
  } = useModalInline();

  const accountsHeader = getAccountHeaders(currentFeaturePrefix);

  const valueContex = useMemo(
    () => ({
      accounts,
      onOpenManageTeams,
      onCloseManageTeams,
    }),
    [
      accounts,
      onOpenManageTeams,
      onCloseManageTeams,
    ]
  );

  return (
    <Context.Provider value={valueContex}>
      <CustomerAccountsWrapper>
      {tableData.length ? (
        <TableWrapper>
          <Table
            name="accounts"
            isLoading={isAccountsLoading}
            onPageChange={onChangeHandler}
            onSort={onChangeHandler}
            onSelect={onAccountsSelect}
            rows={tableData}
            headCells={accountsHeader}
          />
        </TableWrapper>
      ) : (
        <NoAccounts>No accounts found</NoAccounts>
      )}
      <AddAccountModal />
      <ModalInline
        header='Responsible Teams'
        isOpen={isOpenManageTeams}
        onClose={onCloseManageTeams}
      >
        <ManageTeamsModal
          accountId={modalPropsManageTeams.accountId}
          teams={modalPropsManageTeams.teams}
          onSuccess={modalPropsManageTeams.onSuccess}
        />
      </ModalInline>
    </CustomerAccountsWrapper>
    </Context.Provider>
  );
});

export const useCustomerAccountsContext = () => useContext(Context);
