import styled from 'styled-components';

export const TabsWrapper = styled.div`
  padding: 0 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AssignStandardsFormWrapper = styled.div`
  padding: 5px 24px;
`;

export const Description = styled.div`
  padding: 24px 0;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.darkBorderColor};
`;
