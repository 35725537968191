import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

import branding from '../../../../branding';
import IconPlaceholder from '../../images/IconPlaceholder';

const link = {
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  padding: '16px 20px',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  textDecoration: 'none',
};

const linkActive = {
  pointerEvents: 'none',
  color: branding.colors.primary,
};

const icon = {
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  marginRight: '8px',
};

const iconActive = {
  background: branding.colors.primary,
};

const Link = (props) => {
  const { title, path, active } = props;

  return (
    <NavLink to={path} style={active ? { ...link, ...linkActive } : link}>
      <div style={active ? { ...icon, ...iconActive } : icon}>
        <IconPlaceholder active={active} />
      </div>
      <div>{title}</div>
    </NavLink>
  );
};

export default Link;
