import React, { memo } from 'react';

import { DeleteButtonWrapper, DeleteIcon } from './styled';

export const DeleteComponentsButton = memo(() => (
  <DeleteButtonWrapper>
    <DeleteIcon />
    Delete
  </DeleteButtonWrapper>
));
