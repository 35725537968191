import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { Modal, modalSelectors } from '../../../../packages';

import { ComponentsForm } from './components-form';

import { componentsModalName } from './consts';

export const AddComponentModal = memo(() => {
  const selectedCustomerId = useSelector((state) =>
    modalSelectors.getModalData(state, componentsModalName)
  );
  return (
    <Modal
      name={componentsModalName}
      header={selectedCustomerId ? 'Edit component' : 'Add component'}
    >
      <ComponentsForm />
    </Modal>
  );
});
