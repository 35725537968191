import { useLocation, useParams } from 'react-router';

import { PathNames } from '../../consts';
import { getCurrentFeaturePrefix } from './getCurrentFeaturePrefix';

export const useCustomerPages = () => {
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    pathname ===
      PathNames.customerResults
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id) ||
    pathname ===
      PathNames.customerAccounts
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id) ||
    pathname ===
      PathNames.customerScanHistory
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id)
  );
};
