import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

import { useParams } from 'react-router';

import {
  appSelectors,
  customerDetailsSelectors,
  customerDetailsService,
} from '../../entities';

import { LoaderWrapper } from '../../app';
import { PageLayout, tabs } from '../../layouts';

import { getCurrentFeaturePrefix } from '../../packages';

import { EmptyCustomer, OverallScanResults } from './components';
import { Wrapper } from './styled';

export const CustomerResults = memo(() => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const selectedCustomer = useSelector(appSelectors.getCustomer);
  const { customerScans } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const isNotEmptyScans = useSelector(
    customerDetailsSelectors.getIsNotEmptyScans
  );
  const isAccountScanned =
    selectedCustomer?.ac_count && customerScans.length && isNotEmptyScans;

  const currentFeature = getCurrentFeaturePrefix();

  useEffect(() => {
    dispatch(customerDetailsService.initCustomerResults(id));
  }, [id, currentFeature]);

  if (isLoading) {
    return (
      <PageLayout tabs={tabs}>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout tabs={tabs}>
      <Wrapper>
        {isAccountScanned ? <OverallScanResults /> : <EmptyCustomer />}
      </Wrapper>
    </PageLayout>
  );
});
