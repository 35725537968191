import React, { memo } from 'react';

import { IconButton } from '@mui/material';

import { colors } from '../../../theme';

import { Tooltip } from '../tooltip';

import { CircleIcon } from './styled';

export const EnableIcon = memo(({ value: isEnable }) => (
  <Tooltip title={isEnable ? 'Enabled' : 'Disabled'}>
    <IconButton>
      <CircleIcon
        color={isEnable ? colors.success : colors.secondaryBorderColor}
      />
    </IconButton>
  </Tooltip>
));
