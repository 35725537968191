import styled from 'styled-components';

export const InfoCardDescription = styled.div`
  display: block;
  padding: 16px 32px 32px 32px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  background: ${({ theme }) => theme.colors.secondaryBackground};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
`;

export const PolicyInfoCardDescription = styled.div`
  display: block;
  padding: 4px 32px 8px 32px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  background: ${({ theme }) => theme.colors.secondaryBackground};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
`;

export const InfoCardTitle = styled.div`
  display: block;
  padding: 16px 32px 0px 32px;
  color: ${({ theme }) => theme.colors.secondaryTextColor};
  background: ${({ theme }) => theme.colors.secondaryBackground};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
`;

export const ContentWrapper = styled.div`
  display: block;
  padding-bottom: 24px;
`;
