import { getCurrentFeaturePrefix, setPagination, useQuery } from '../../packages';

import { accountDetailsApi } from './api';
import { accountDetailsActions } from './store';
import { customerDetailsActions, customerDetailsSelectors } from '../customer-details';
import { appSelectors, appService } from '../app';
import { FeaturePrefix } from '../../consts';
import { NotificationManager } from 'react-notifications';

const getAccountScans =
  ({ id, accountId, query }) =>
  async (dispatch) => {
    try {
      dispatch(accountDetailsActions.setIsScansLoading(true));
      const { page = 0, ...rest } = query || {};
      const otherQueryParams = useQuery('account-scan');

      const { results, ...pagination } =
        await accountDetailsApi.getAccountScans({
          id,
          accountId,
          query: {
            page: page + 1,
            ...rest,
            ...otherQueryParams,
          },
        });

      dispatch(accountDetailsActions.setScans(results));
      setPagination('account-scans', { ...pagination, page });

      dispatch(accountDetailsActions.setIsScansLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

const exportScanHistory =
  ({ id, accountId, query }) =>
  async (dispatch) => {
    try {
      dispatch(accountDetailsActions.setIsExportingScans(true));

      await accountDetailsApi.exportScanHistory({
        id,
        accountId,
        query,
      });

      dispatch(accountDetailsActions.setIsExportingScans(false));
    } catch (e) {
      console.error(e);
    }
  };

const init =
  ({ id, accountId }) =>
  async (dispatch) => {
    try {
      dispatch(accountDetailsActions.setIsLoading(true));

      await dispatch(getAccountScans({ id, accountId, query: { page: 0 } }));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(accountDetailsActions.setIsLoading(false));
    }
  };

const updateAccountsTeam = ({ accountId, teamIds }) => async (dispatch, getState) => {
  try {
    const { accounts } = customerDetailsSelectors.getCustomerDetailsData(
      getState()
    );
    const { selectedCustomerId } = appSelectors.getAppData(getState());
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiAction = currentFeaturePrefix === FeaturePrefix.PlatformSecurity
      ? accountDetailsApi.manageTeamsPlatform
      : accountDetailsApi.manageTeamsPipeline;

    apiAction({ customerId: selectedCustomerId, accountId, teamIds })
      .then(() => {
        NotificationManager.info(
          'Team have been assigned successfully'
        );
      })
      .catch(() => {
        NotificationManager.error('Server error');
      });

    const updatedAccounts = accounts.map((account) => {
      if (account.id === accountId) {
        return { ...account, teams: teamIds };
      }

      return account;
    });

    await dispatch(customerDetailsActions.setAccounts(updatedAccounts));

    await dispatch(appService.getUser());
  } catch (e) {
    console.error(e);
  }
};

export const accountDetailsService = {
  init,
  getAccountScans,
  exportScanHistory,
  updateAccountsTeam,
};
