import { threatModelingApi } from '../../../../entities';
import useApiData from '../../../../hooks/useApiData';
import ListMostUsed from './list-most-used';

const TMMostUsed = (props) => {
  const { query } = props;

  const threats = useApiData(threatModelingApi.dashboardMostCommonThreats, [query], { count: 0, threats: [] });
  const components = useApiData(threatModelingApi.dashboardMostUsedComponents, [query], { count: 0, components: [] });
  const controls = useApiData(threatModelingApi.dashboardMostNonCompliantControls, [query], []);

  return (
    <div>
      <ListMostUsed
        label={
          <>
            Most common <span style={{ fontWeight: 700 }}>threats</span> ({threats.data.count})
          </>
        }
        data={threats.data.threats.map((i) => ({ name: i.name, count: i.count, percent: i.percent }))}
        unit='%'
      />
      <ListMostUsed
        label={
          <>
            Most used <span style={{ fontWeight: 700 }}>components</span> ({components.data.count})
          </>
        }
        data={components.data.components}
      />
      <ListMostUsed
        label={
          <>
            Most <span style={{ fontWeight: 700 }}>non-compliant</span> controls
          </>
        }
        data={controls.data.map((i) => ({ name: i.code, count: i.percent }))}
        unit='%'
      />
    </div>
  );
};

export default TMMostUsed;
