import styled from 'styled-components';
import {
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@mui/material';
import ArrowCollapseIcon from 'mdi-react/ArrowCollapseIcon';
import ArrowExpandIcon from 'mdi-react/ArrowExpandIcon';

import branding from '../../../branding';
import { scrollable } from '../../../theme';

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
  background: theme.colors.background,
  height: '48px',
  fontFamily: 'Raleway, sans-serif',
  color: theme.colors.textColor,
  '& .MuiTableCell-root': {
    color: theme.colors.textColor,
    fontWeight: 'bold',
    fontSize: '12px',
    fontFamily: 'Raleway, sans-serif',
    lineHeight: 1,
    border: 'none',
    padding: '0 5px',

    '&:first-child': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },

    '&:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },

    '& .MuiButtonBase-root': {
      color: theme.colors.textColor,
      '& .MuiSvgIcon-root': {
        color: theme.colors.textColor,
      },
    },
  },
}));

export const StyledTableRow = styled(MuiTableRow)(
  ({ isCustom, theme, rowBackGround }) => ({
    marginBottom: '4px',
    height: '48px',
    fontFamily: 'Raleway, sans-serif',
    background:
      rowBackGround ||
      (isCustom && theme.colors.customBackground) ||
      'transparent',

    '& .MuiTableCell-root': {
      color: theme.colors.textColor,
      fontFamily: 'Raleway, sans-serif',
      fontSize: '12px',
      lineHeight: 1,
      padding: '0 5px',

      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      },

      '&:last-child': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
  })
);

export const TableContainer = styled(MuiTableContainer)`
  overflow-y: hidden;
  ${scrollable}
`;

export const TableLoaderWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 20px 0;
`;

export const CollapseIcon = styled(ArrowCollapseIcon)`
  width: 24px;
  height: 24px;
  fill: ${branding.colors.primary};
  cursor: pointer;
`;

export const ExpandIcon = styled(ArrowExpandIcon)`
  width: 24px;
  height: 24px;
  fill: ${branding.colors.primary};
  cursor: pointer;
`;

export const ExpandWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 12px;
  align-items: flex-start;
`;
