import styled from 'styled-components';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';

export const SuccessIcon = styled(CheckCircleIcon)`
  fill: ${({ theme }) => theme.colors.successIcon};
`;

export const WarningIcon = styled(AlertCircleIcon)`
  fill: ${({ theme }) => theme.colors.warningIcon};
`;
