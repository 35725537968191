import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { CircularProgress, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { PathNames } from '../../consts';

import { PageLayout, getAccountTabs } from '../../layouts';
import { LoaderWrapper } from '../../app';
import { PolicyViolations } from '../../packages';

import {
  customerDetailsSelectors,
  standardSelectors,
  standardService,
} from '../../entities';

import { OverviewContent, PolicyViolationsWrapper } from './styled';
import { PolicyGroupTrend, PolicyGroupsList } from './components';

export const StandardOverviewPage = () => {
  const dispatch = useDispatch();
  const { id, accountId, standardId, scanId, ppToolToken, ppFeature } = useParams();
  const { isLoading } = useSelector(standardSelectors.getStandardData);
  const violationStats = useSelector(standardSelectors.getViolatedPolicesStats);
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );

  useEffect(() => {
    dispatch(
      standardService.initOverview({
        id,
        accountId,
        standardId,
        scanId,
        ppToolToken,
        ppFeature,
      })
    );
  }, [accountId, scanId, standardId, ppToolToken, ppFeature]);

  if (isLoading) {
    return (
      <PageLayout tabs={getAccountTabs(selectedAccount)}>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout tabs={getAccountTabs(selectedAccount)}>
      <OverviewContent>
        <PolicyViolationsWrapper>
          {ppToolToken !== undefined ? (
            <Link
              component={NavLink}
              to={PathNames.ppOverview.replace(':ppToolToken', ppToolToken).replace(':ppFeature', ppFeature)}
            >
              &lt; BACK
            </Link>
          ) : null}
          <PolicyViolations violationStats={violationStats} />
        </PolicyViolationsWrapper>
        <PolicyGroupTrend />
        <PolicyGroupsList />
      </OverviewContent>
    </PageLayout>
  );
};
