import React, { memo, useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Form } from '../../../packages';

import { ContinueButtonWrapper } from '../../onboarding/steps/styled';

import { authService } from '../../../entities';

import { ConfirmEmailWrapper, InputField, ResendCode } from './styled';
import { confirmEmailValidationSchema } from './validate';
import { Timer } from './Timer';

export const ConfirmEmailForm = memo(({onSubmitHandler}) => {
  const dispatch = useDispatch();
  const [isTimerHidden, setIsTimerHidden] = useState(false);
  const initialValues = {
    code: '',
  };

  const onSubmit = useCallback(
    (values) => {
      dispatch(authService.confirmCode(values));
    },
    [dispatch]
  );

  const onTimerExpired = useCallback(() => {
    setIsTimerHidden(true);
  }, [dispatch]);

  const onResendCodeClick = useCallback(() => {
    setIsTimerHidden(false);
    dispatch(authService.resendCode());
  }, [dispatch]);

  return (
    <ConfirmEmailWrapper>
      <Form
        validationSchema={confirmEmailValidationSchema}
        initialValues={initialValues}
        onSubmit={onSubmitHandler ?? onSubmit}
        isLoading={false}
      >
        <InputField name="code" label="Code" />
        <ResendCode
          component="field"
          isDisabled={!isTimerHidden}
          onClick={onResendCodeClick}
        >
          Re-send verification code
          {!isTimerHidden && (
            <>
              (
              <Timer initialSeconds={60} onExpireAction={onTimerExpired} />
              {' sec.)'}
            </>
          )}
        </ResendCode>
        <ContinueButtonWrapper component="button">
          <Button variant="contained" type="submit">
            Continue
          </Button>
        </ContinueButtonWrapper>
      </Form>
    </ConfirmEmailWrapper>
  );
});
