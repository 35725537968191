const LibraryExample = [
  {
    id: 1222,
    label: "Batch-AI",
    icon: "00028-icon-service-Batch-AI.svg",
    width: 70,
    height: 70,
    handles: "00000100",
  },
  // {
  //   id: 4651,
  //   label: "Batch-AI",
  //   icon: "00028-icon-service-Batch-AI.svg",
  //   width: 70,
  //   height: 70,
  //   shape: "cylinder",
  //   handles: "00000100",
  // },
  {
    id: 2,
    label: "Web-Services",
    icon: "00030-icon-service-Machine-Learning-Studio-(Classic)-Web-Services.svg",
    width: 70,
    height: 70,
    // shape: "ellipse",
    handles: "01010101",
    // labelStyle: {
    //   alignSelf: "flex-end",
    //   marginBottom: -18,
    // },
  },
  {
    id: 3,
    label: "Web-Service-Plans",
    icon: "10168-icon-service-Machine-Learning-Studio-Web-Service-Plans.svg",
    width: 70,
    height: 70,
    // shape: "rect",
  },
  // {
  //   id: 11,
  //   label: "Batch-AI",
  //   icon: "00028-icon-service-Batch-AI.svg",
  //   width: 70,
  //   height: 70,
  //   // shape: "cylinder",
  // },
  // {
  //   id: 12,
  //   label: "Web-Services",
  //   icon: "00030-icon-service-Machine-Learning-Studio-(Classic)-Web-Services.svg",
  //   width: 70,
  //   height: 70,
  //   // shape: "ellipse",
  // },
  // {
  //   id: 13,
  //   label: "Web-Service-Plans",
  //   icon: "10168-icon-service-Machine-Learning-Studio-Web-Service-Plans.svg",
  //   width: 70,
  //   height: 70,
  //   // shape: "rect",
  // },
];

export default LibraryExample;
