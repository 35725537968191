import CardMostUsed from '../card-most-used';

const ListMostUsed = (props) => {
  const { label, data = [], unit = '', rightComponent } = props;

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0 16px' }}>
        <div style={{ fontSize: 24, lineHeight: '24px' }}>{label}</div>
        {rightComponent}
      </div>
      <div style={{ display: 'flex', minHeight: 150, overflowX: 'auto', paddingBottom: 10 }}>
        {data.map((item) => (
          <CardMostUsed key={item.title} {...item} unit={unit} />
        ))}
      </div>
    </div>
  );
};

export default ListMostUsed;
