import React, { memo } from 'react';

import { useParams } from 'react-router';

import { PathNames } from '../../../../consts';

import { TableEntityName, getCurrentFeaturePrefix } from '../../../../packages';

export const PolicyGroupName = memo(({ value, id, is_custom }) => {
  const {
    id: customerId,
    scanId,
    accountId,
    standardId,
    ppToolToken,
    ppFeature,
  } = useParams();

  const pathTo =
    ppToolToken === undefined
      ? PathNames.policyGroupOverview
          .replace(':feature', getCurrentFeaturePrefix())
          .replace(':id', customerId)
          .replace(':scanId', scanId ?? 'current')
          .replace(':accountId', accountId)
          .replace(':standardId', standardId)
          .replace(':policyGroupId', id)
      : PathNames.ppPolicyGroupOverview
          .replace(':standardId', standardId)
          .replace(':ppToolToken', ppToolToken)
          .replace(':ppFeature', ppFeature)
          .replace(':policyGroupId', id);

  return <TableEntityName to={pathTo} value={value} isCustom={is_custom} />;
});
