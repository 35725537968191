import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconButton } from '@mui/material';

import { Tooltip, modalActions } from '../../../../packages';
import { deleteDiagramModal } from '../delete-diagram-modal';

import { DeleteIcon } from './styled';

export const DeleteDiagramButton = memo(({ id }) => {
  const dispatch = useDispatch();
  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalIsOpen({
          name: deleteDiagramModal,
          isOpen: true,
        })
      );
      dispatch(
        modalActions.setModalData({
          name: deleteDiagramModal,
          data: [id],
        })
      );
    },
    [id]
  );
  return (
    <Tooltip title="Delete">
      <IconButton onClick={onClickHandler}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
});
