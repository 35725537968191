import styled from 'styled-components';

import { xsm } from '../../theme';

export const DiagramsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;

export const DiagramsCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;

export const NoDiagrams = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
  padding: 50px 0;
  cursor: pointer;
`;

export const DiagramsHeader = styled.div`
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;

  @media screen and (max-width: ${xsm}) {
    flex-wrap: wrap;
  }
`;

export const FileInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${xsm}) {
    margin-top: 20px;
  }
`;
