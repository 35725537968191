import React, { memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Wrapper } from '../styled';

import {
  OnboardingSteps,
  appActions,
  appSelectors,
  customerDetailsSelectors,
} from '../../../../entities';

import { FeaturePrefix, PathNames } from '../../../../consts';

import { getCurrentFeaturePrefix, history } from '../../../../packages';

import { NoAccountsMode } from './NoAccountsMode';
import { AccountCreatingMode } from './AccountCreatingMode';

const AccountFormModes = {
  NoAccounts: 'no-account',
  AccountCreating: 'account-creating',
};

export const AccountsStep = memo(() => {
  const dispatch = useDispatch();
  const [accountFormMode, setAccountFormMode] = useState(
    AccountFormModes.NoAccounts
  );
  const currentCustomer = useSelector(appSelectors.getCustomer);
  const [selectedServiceType, setSelectedServiceType] = useState();
  const { accounts } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const onCreateAccount = useCallback((type) => {
    setSelectedServiceType(type);
    setAccountFormMode(AccountFormModes.AccountCreating);
  }, []);

  const showSelectAccountTypeForm = useCallback(() => {
    setAccountFormMode(AccountFormModes.NoAccounts);
  }, []);
  const currentFeaturePrefix = getCurrentFeaturePrefix();

  useEffect(() => {
    if (accounts.length) {
      dispatch(appActions.setOnboardingStep(OnboardingSteps.assignStandards));

      if (currentFeaturePrefix === FeaturePrefix.PipelineSecurity) {
        history.push({
          pathname: PathNames.customerResults
            .replace(':feature', FeaturePrefix.PipelineSecurity)
            .replace(':id', currentCustomer.id),
        });
      }
    }
  }, [accounts, dispatch]);

  return (
    <Wrapper>
      {accountFormMode === AccountFormModes.NoAccounts && (
        <NoAccountsMode onCreateAccount={onCreateAccount} />
      )}
      {accountFormMode === AccountFormModes.AccountCreating && (
        <AccountCreatingMode
          serviceType={selectedServiceType}
          onBackClick={showSelectAccountTypeForm}
        />
      )}
    </Wrapper>
  );
});
