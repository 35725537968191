import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Form, history, SecondaryButton, Select, TextField } from '../../../packages';

import { ButtonsWrapper, checkboxStyle, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { Checkmarks } from '../../../packages/controls/checkmarks';
import {
  threatModelingActions,
  threatModelingApi,
  threatModelingSelectors,
  threatModelingService,
} from '../../../entities';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../packages/utils/parse';
import { PageLayout } from '../../../layouts';
import { FormTitle, PageWrapper, WrapTitle } from '../styled';
import Header from '../../dashboard/components/header';
import { TextareaField } from '../../../packages/components/textarea/TextField';
import { SelectLazyField } from '../../diagram-result/components/add-component-modal/components-form/styled';
import { useLazyList } from '../../../packages/utils/list';
import useSelectLazyField from '../../../packages/utils/useSelectLazyField';
import { CheckboxField } from '../../../packages/components/checkbox-field';
import { TodoList } from '../../../packages/controls/todo-list';
import { sortById } from '../../../packages/utils/sort';
import { PathNames } from '../../../consts';
import { BackIcon } from '../components/delete-button/styled';
import DateField from '../../../packages/components/date-field';

const TabComponentTypeEdit = (props) => {
  const {
    match: {
      params: { componentTypeId },
    },
  } = props;
  const isNew = componentTypeId === 'new';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { componentTypeIsLoading, componentType } = useSelector(threatModelingSelectors.getThreatModelingData);
  const [keyUpdate, setKeyUpdate] = useState(Date.now());
  useEffect(() => {
    if (!isNew) {
      dispatch(threatModelingService.getComponentType(componentTypeId));
    } else {
      dispatch(threatModelingActions.setComponentType(null));
    }
  }, [isNew, keyUpdate]);

  const initialValues = useMemo(
    () =>
      componentType
        ? {
            slug: componentType.slug,
            name: componentType.name,
            service: componentType.service,
            generic_type: componentType.generic_type
              ? { id: componentType.generic_type.id, label: componentType.generic_type.name }
              : null,
            last_threats_update: componentType.last_threats_update?.split('.')[0],
            diagrams_keywords: componentType.diagrams_keywords.map((i) => ({ text: i })),
            text_keywords: componentType.text_keywords.map((i) => ({ text: i })),
            text_direct_keywords: componentType.text_direct_keywords.map((i) => ({ text: i })),
            company: componentType.company ? { id: componentType.company.id, label: componentType.company.name } : null,
          }
        : {
            slug: '',
            name: '',
            service: '',
            generic_type: null,
            last_threats_update: '',
            diagrams_keywords: [],
            text_keywords: [],
            text_direct_keywords: [],
            company: null,
          },
    [componentType]
  );

  const validationSchema = defaultValidationSchema;

  const setErrors = useCallback((errors, helpers) => {
    Object.keys(errors).forEach((field) => {
      const val = errors[field];
      const text = Array.isArray(val) ? val.join(' ') : val;
      helpers.setFieldError(field, text);
    });
  }, []);

  const onSubmit = useCallback(
    (values, helpers) => {
      const {
        slug,
        name,
        service,
        generic_type,
        last_threats_update,
        diagrams_keywords,
        text_keywords,
        text_direct_keywords,
        company,
      } = values;
      const data = {
        slug,
        name,
        service,
        generic_type_id: generic_type?.id || null,
        last_threats_update,
        diagrams_keywords: diagrams_keywords.map((i) => i.text),
        text_keywords: text_keywords.map((i) => i.text),
        text_direct_keywords: text_direct_keywords.map((i) => i.text),
        company_id: company?.id || null,
      };

      setLoading(true);

      const request = isNew
        ? threatModelingApi.createComponentType(data)
        : threatModelingApi.updateComponentType(componentTypeId, data);

      request
        .then((resp) => {
          NotificationManager.success(
            isNew ? 'The component type was successfully created.' : 'The component type was successfully updated.'
          );
          if (isNew) {
            history.replace({
              pathname: PathNames.componentType.replace(':componentTypeId', resp.id),
            });
          } else {
            setKeyUpdate(Date.now());
          }
        })
        .catch((err) => {
          const dataErr = parseError(err);

          setErrors(dataErr, helpers);

          const text = dataErr?.detail || dataErr?.details || dataErr?.message || 'An error has occurred';
          NotificationManager.error(text);
        })
        .finally(() => setLoading(false));
    },
    [dispatch, isNew, setKeyUpdate]
  );

  const SelectGenericComponentTypesOptions = useSelectLazyField(
    {
      name: 'generic_type',
      label: 'Generic type',
      request: threatModelingApi.getModerationGenericComponentTypes,
      mapFunc: (i) => ({ id: i.id, label: i.name }),
    },
    { withSearch: true }
  );
  const SelectCompanyOptions = useSelectLazyField(
    {
      name: 'company',
      label: 'Company',
      request: threatModelingApi.getModerationCompanies,
      mapFunc: (i) => ({ id: i.id, label: i.name }),
    },
    { withSearch: true }
  );

  return (
    <PageLayout withoutSidebar>
      <PageWrapper>
        <Header></Header>
        <WrapTitle>
          <BackIcon onClick={() => history.push(PathNames.componentTypes)} />
          <FormTitle>{isNew ? 'Create component type' : 'Change component type'}</FormTitle>
        </WrapTitle>
        <ProfileFormWrapper>
          <Form
            key={`${componentTypeIsLoading}-${isNew}-${componentType?.id}`}
            validationSchema={validationSchema}
            initialValues={initialValues}
            isLoading={loading}
            onSubmit={onSubmit}
          >
            <InputField name='name' label='Name' />
            <InputField name='slug' label='Slug' disabled />
            <Select
              label='Service'
              name='service'
              options={[
                { value: '', label: '-' },
                { value: 'aws', label: 'Aws' },
                { value: 'azure', label: 'Azure' },
                { value: 'other', label: 'Other' },
              ]}
            />
            {SelectGenericComponentTypesOptions}
            {SelectCompanyOptions}
            <DateField name='last_threats_update' label='Last threats update' defaultValue='-' disabled />
            <TodoList name='diagrams_keywords' label='Diagrams keywords' />
            <TodoList name='text_keywords' label='Text keywords' />
            <TodoList name='text_direct_keywords' label='Text direct keywords' />
            <ButtonsWrapper>
              <div />
              <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
                {loading ? (componentTypeId ? 'Creating...' : 'Saving...') : componentTypeId ? 'Create' : 'Save'}
              </Button>
            </ButtonsWrapper>
          </Form>
        </ProfileFormWrapper>
      </PageWrapper>
    </PageLayout>
  );
};

export default TabComponentTypeEdit;
