import React, { memo } from 'react';

import { Button } from '../../../../packages';

export const ImportDiagramButton = memo(({ onClickHandler }) => (
  <Button
    variant="contained"
    sx={{ marginLeft: '16px', display: 'block' }}
    onClick={onClickHandler}
  >
    Import
  </Button>
));
