import React, { memo, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, SecondaryButton } from '../../../../packages';

import { appSelectors, standardService } from '../../../../entities';

import { AssignStandardsContainer } from '../../../../pages/accounts/components/assign-standards';

import {
  AssignStandardsFormWrapper,
  ButtonsWrapper,
  Description,
} from './styled';

export const AssignStandardsForm = memo(({ accountId }) => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const currentCustomer = useSelector(appSelectors.getCustomer);

  const onSaveClick = useCallback(() => {
    dispatch(
      standardService.saveAssignStandards({
        accountId,
        id: currentCustomer.id,
        regs: selectedItems,
      })
    );
  }, [selectedItems, currentCustomer, accountId]);

  const onResetClick = useCallback(() => {
    dispatch(
      standardService.getAssignStandards({
        accountId,
        id: currentCustomer.id,
      })
    );
  }, [currentCustomer, accountId]);

  return (
    <AssignStandardsFormWrapper>
      <AssignStandardsContainer
        customerId={currentCustomer.id}
        accountId={accountId}
        onItemSelect={setSelectedItems}
      />
      <Description>
        Existing scan results will be updated (recalculated) in a few minutes
        after changes applied.
      </Description>
      <ButtonsWrapper>
        <SecondaryButton onClick={onResetClick}>Reset</SecondaryButton>
        <Button variant="contained" onClick={onSaveClick}>
          Save
        </Button>
      </ButtonsWrapper>
    </AssignStandardsFormWrapper>
  );
});
