import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { SecondaryTextField } from '../../../packages';
import { md } from '../../../theme';

export const AccountFormWrapper = styled.div`
  margin-top: 40px;
  @media screen and (max-width: ${md}) {
    margin-bottom: 24px;
  }
`;

export const InputField = muiStyled(SecondaryTextField)(() => ({
  marginBottom: 32,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
