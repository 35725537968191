import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {getCurrentFeaturePrefix, ScanSelectedButton} from '../../../../packages';
import {
  customerDetailsSelectors,
  customerDetailsService,
} from '../../../../entities';

import { ScanSelectedAccountsWrapper } from './styled';
import Permission from '../../../../packages/components/permissions';
import {FeaturePrefix} from '../../../../consts';

export const ScanSelectedAccountsButton = memo(() => {
  const dispatch = useDispatch();
  const { accounts, selectedAccountsIds } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );

  const currentFeaturePrefix = getCurrentFeaturePrefix();

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      if (selectedAccountsIds.length) {
        const accountsForScan = selectedAccountsIds.filter(
          (id) => accounts.find((customer) => customer.id === id)?.enable
        );

        dispatch(customerDetailsService.scanSelectedAccounts(accountsForScan));
      }
    },
    [selectedAccountsIds, accounts]
  );

  return (
    <ScanSelectedAccountsWrapper>
      <Permission resource={
        currentFeaturePrefix === FeaturePrefix.PlatformSecurity ?
          "platform-security::accounts" : "pipeline-security::accounts"
      }>
        {(actions) => (
          (actions.company?.["start-scan"].allowed || actions.team?.["start-scan"].allowed) ?
            <ScanSelectedButton onClick={onClickHandler} />
            : null
        )}
      </Permission>
    </ScanSelectedAccountsWrapper>
  );
});
