import React, { memo, useCallback } from 'react';

import {
  BackButton,
  getCurrentFeaturePrefix,
  history,
} from '../../../../packages';

import { ServiceButton } from '../../service-button';

import {
  ContinueButtonWrapper,
  Image,
  ImageBox,
  InfoBox,
  Label,
  ServiceList,
  WelcomeLabel,
} from '../styled';
import serverUrl from '../../images/server.svg';
import { FeaturePrefix, PathNames } from '../../../../consts';

export const NoAccountsMode = memo(({ onCreateAccount }) => {
  const onBackClick = useCallback(() => {
    history.push({ pathname: PathNames.chooseFeatures });
  }, []);

  const currentFeaturePrefix = getCurrentFeaturePrefix();

  return (
    <>
      <InfoBox>
        <WelcomeLabel>Add Cloud Accounts</WelcomeLabel>
        <Label>
          {currentFeaturePrefix === FeaturePrefix.PlatformSecurity
            ? 'Add one account per subscription in Azure or per tenant in AWS. You can add multiple accounts per cloud provider if organized that way. The overview will aggregate the compliance status of AWS and Azure levels even if you have various subscriptions or tenants for scanning. Need help with adding accounts? Please refer to the help guide in the account section.'
            : 'Users can add one account per organization or project in Azure DevOps or Github. The pipeline security checks will assess the security of the supply chain as described in CIS benchmarks, including the code Repos, organization permissions, source controls, infrastructure as code, secrets and deployment security. Please refer to the guideline for onboarding here. If you want to enable additional features, please get in touch with the admin.'}
        </Label>
        <ServiceList>
          {currentFeaturePrefix === FeaturePrefix.PlatformSecurity ? (
            <>
              <ServiceButton
                key="azure"
                name="Microsoft Azure"
                type="azure"
                onClick={onCreateAccount}
              />
              <ServiceButton
                key="aws"
                name="Amazon Web Services"
                type="aws"
                onClick={onCreateAccount}
              />
            </>
          ) : (
            <>
              <ServiceButton
                key="azuredev"
                name="AzureDev"
                type="azuredev"
                onClick={onCreateAccount}
              />
              <ServiceButton
                key="githubDev"
                name="GitHubDev"
                type="githubdev"
                onClick={onCreateAccount}
              />
            </>
          )}
        </ServiceList>
        <ContinueButtonWrapper>
          <BackButton onClick={onBackClick}>Back</BackButton>
        </ContinueButtonWrapper>
      </InfoBox>
      <ImageBox>
        <Image src={serverUrl} />
      </ImageBox>
    </>
  );
});
