import React, { memo, useCallback, useEffect, useState } from 'react';

import { DumbCheckbox } from '../checkbox';

import { HeaderCheck, HeaderLabel, List, ListHeader, Wrapper } from './styled';
import { Item } from './Item';

export const CheckList = memo(
  ({ label = 'Standard name' , items, onItemClick, selectedItems = [], onSelectAllItems, disabled }) => {
    const [isAllSelected, setIsAllSelected] = useState(false);

    const onSelectAllItemsHandler = useCallback(
      (e) => {
        e.preventDefault();

        setIsAllSelected(!isAllSelected);
        onSelectAllItems(isAllSelected);
      },
      [onSelectAllItems, isAllSelected]
    );

    useEffect(() => {
      if (selectedItems.length === items.length) {
        setIsAllSelected(true);
      }
    }, [selectedItems]);

    return (
      <Wrapper>
        {!!onSelectAllItems && <ListHeader>
          <HeaderCheck>
            <DumbCheckbox
              appearance="primary"
              value={isAllSelected}
              onClick={onSelectAllItemsHandler}
              indeterminate={isAllSelected}
            />
          </HeaderCheck>
          <HeaderLabel>{label}</HeaderLabel>
        </ListHeader>}
        <List>
          {items.map(({ label, id }) => (
            <Item
              key={`${id}-check-list-item`}
              id={id}
              label={label}
              onClick={onItemClick}
              disabled={disabled}
              isChecked={selectedItems.includes(id)}
            />
          ))}
        </List>
      </Wrapper>
    );
  }
);
