import React, { memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  FileIcon,
  ScanningDescription,
  ScanningPageWrapper,
  ScanningStatus,
} from '../styled';
import { appSelectors, appService } from '../../../../entities';
import { Button, Loader, SuccessIcon, history } from '../../../../packages';
import { FeaturePrefix, PathNames } from '../../../../consts';

const ScanningModes = {
  inprogress: 'inprogress',
  complete: 'complete',
};

export const ScanningStep = memo(() => {
  const dispatch = useDispatch();
  const [scanningMode, setScanningMode] = useState(ScanningModes.inprogress);
  const { user } = useSelector(appSelectors.getAppData);
  const customer = useSelector(appSelectors.getCustomer);

  useEffect(() => {
    if (user.scan_status === 'inprogress') {
      setScanningMode(ScanningModes.inprogress);
    }

    if (user.scan_status === 'current_finished') {
      setScanningMode(ScanningModes.complete);
    }
  }, [user]);

  const showScanResultsHandler = useCallback(() => {
    dispatch(
      appService.updateCompany(user.company_id, { status: 'completed' })
    );
    history.push({
      pathname: PathNames.customerResults
        .replace(':feature', FeaturePrefix.PlatformSecurity)
        .replace(':id', customer.id),
    });
  }, [user, customer]);

  return (
    <ScanningPageWrapper>
      {scanningMode === ScanningModes.inprogress ? (
        <>
          <Loader />
          <ScanningStatus>Scanning in progress</ScanningStatus>
          <ScanningDescription>It remains approx 24 hours</ScanningDescription>
        </>
      ) : (
        <>
          <SuccessIcon />
          <ScanningStatus>Scanning Complete!</ScanningStatus>
          <ScanningDescription>
            {`Scanning end time: ${new Date(
              customer.last_scan * 1000
            ).toLocaleString()}`}
          </ScanningDescription>
          <Button
            sx={{
              maxWidth: '350px',
              minWidth: '300px',
              width: '100%',
              fontWeight: 'normal',
            }}
            onClick={showScanResultsHandler}
          >
            <FileIcon />
            Show results
          </Button>
        </>
      )}
    </ScanningPageWrapper>
  );
});
