import React from 'react';
import Link from './components/Link';
import { PathNames } from '../../consts';
import Permission from '../../packages/components/permissions';

const SidebarProfile = () => {
  const { pathname } = document.location;

  return (
    <div style={{ height: '100%', backgroundColor: 'white' }}>
      <Link title='Profile' path={PathNames.profile} active={pathname === PathNames.profile} />

      <Permission resource="company::users">
        {(actions) => (
          (actions.company['view']?.allowed || actions.team['view']?.allowed) ?
            <Link title="Users" path={PathNames.users} active={pathname === PathNames.users}/>
            : null
        )}
      </Permission>

      <Permission resource="company::teams">
        {(actions) => (
          (actions.company['view']?.allowed || actions.team['view']?.allowed) ?
            <Link title="Teams" path={PathNames.teams} active={pathname === PathNames.teams}/>
            : null
        )}
      </Permission>

      <Permission resource="company">
        {(actions) => (
          (actions.company['view']?.allowed) ?
            <Link title="Features" path={PathNames.features} active={pathname === PathNames.features}/>
            : null
        )}
      </Permission>
    </div>
  );
};

export default SidebarProfile;
