import React, { memo, useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ThirdSelect, debounce, usePagination } from '../../../packages';

import { appSelectors, appService } from '../../../entities';

import { CustomerListLabel, CustomerListWrapper } from './styled';

export const CustomerList = memo(({ menuItems, setSelectedCustomer }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { page } = usePagination('menu-items');
  const { isMenuItemsLoading } = useSelector(appSelectors.getAppData);

  const formattedOptions = useMemo(
    () =>
      menuItems.map((customer) => ({
        label: customer.name,
        value: customer.id,
      })),
    [menuItems]
  );

  const onSearch = useCallback(
    (newValue) => {
      dispatch(appService.getMenuItems({ search: newValue }));
    },
    [dispatch]
  );

  const onSearchHandlerWithDebounce = useMemo(
    () => debounce((newValue) => onSearch(newValue), 500),
    [onSearch]
  );

  const handleInputChange = useCallback(
    (newValue) => {
      const newInputValue = newValue.replace(/\W/g, '');
      setInputValue(newInputValue);

      onSearchHandlerWithDebounce(newInputValue);
    },
    [onSearchHandlerWithDebounce]
  );

  const onBlurHandler = useCallback(() => {
    handleInputChange('');
  }, []);

  const onChangeHandler = useCallback(
    ({ value }) => {
      setSelectedCustomer(value);
    },
    [menuItems, setSelectedCustomer]
  );

  const onMenuScrollToBottom = useCallback(() => {
    dispatch(appService.getMenuItems({ search: inputValue, page: page + 1 }));
  }, [inputValue, page, dispatch]);

  return (
    <CustomerListWrapper>
      <CustomerListLabel>Choose customer</CustomerListLabel>
      <ThirdSelect
        isLoading={isMenuItemsLoading}
        options={formattedOptions}
        onBlur={onBlurHandler}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onChange={onChangeHandler}
        onInputChange={handleInputChange}
      />
    </CustomerListWrapper>
  );
});
