import React, { memo, useCallback, useEffect } from 'react';

import { Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import {
    Modal,
    history,
    modalActions,
    modalSelectors, getCurrentFeaturePrefix,
} from '../../../../packages';

import {
  CUSTOMER_STEPS,
  customerDetailsActions,
  customerSelectors,
  customerService,
} from '../../../../entities';

import { FeaturePrefix, PathNames } from '../../../../consts';

import { ButtonsWrapper, StartScanContent, Title } from './styled';
import { customerStartScanModal } from './consts';

export const StartScanModal = memo(() => {
  const dispatch = useDispatch();
  const customerId = useSelector((state) =>
    modalSelectors.getModalData(state, customerStartScanModal)
  );
  const selectedCustomer = useSelector((state) =>
    customerSelectors.getCustomerById(state, customerId)
  );

  const onContinueClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(customerService.scanSelectedCustomers([customerId]));
      dispatch(
        modalActions.setModalIsOpen({
          name: customerStartScanModal,
          isOpen: false,
        })
      );
    },
    [customerId]
  );

  const onSpecifyClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalIsOpen({
          name: customerStartScanModal,
          isOpen: false,
        })
      );
      dispatch(
        customerDetailsActions.setCurrentStep(CUSTOMER_STEPS.customerAccount)
      );

      history.push({
        pathname: PathNames.customerAccounts
          .replace(':feature', getCurrentFeaturePrefix())
          .replace(':id', customerId),
      });
    },
    [customerId]
  );

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: customerStartScanModal,
          data: null,
        })
      );
    },
    []
  );

  return (
    <Modal name={customerStartScanModal} header="Start scan">
      <StartScanContent>
        <Title>
          {`${selectedCustomer?.name} has several accounts. By continuing you are launching scans for all of them.`}
        </Title>
        <ButtonsWrapper component="button">
          <Button size="medium" onClick={onSpecifyClickHandler}>
            Specify accounts
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={onContinueClickHandler}
          >
            Continue
          </Button>
        </ButtonsWrapper>
      </StartScanContent>
    </Modal>
  );
});
