import styled from 'styled-components';

import { icons } from '../../packages';

export const WarningWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const WarningMessage = styled.div`
  font-size: 32px;
  text-align: center;
  max-width: 700px;
`;

export const WarningDescription = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 700px;
`;

export const SetupIconWrapper = styled.svg`
  font-size: 32px;
  padding: 20px;
  text-align: center;
  max-height: 210px;
  max-width: 300px;
`;

export const LinkIconWrapper = styled.svg`
  font-size: 32px;
  padding: 20px;
  text-align: center;
  max-width: 200px;
  max-height: 200px;
`;