import { PathNames } from '../../consts';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { DeleteIcon } from './components/delete-button/styled';

/*
{
    "id": 5478,
    "code": "d6e832f0d9d14ea5929e98ac7e62a091",
    "name": "Container hijacking",
    "countermeasures_processing": false,
    "controls_processing": false,
    "security_checks_processing": false,
    "is_draft": false,
    "component_type": {
        "id": 29,
        "slug": "amazon-elastic-container-service-amazon-ecs",
        "name": "Amazon Elastic Container Service (Amazon ECS)",
        "service": "aws",
        "generic_type": {
            "id": 12,
            "slug": "orchestration-service",
            "name": "Orchestration Service"
        },
        "diagrams_keywords": [
            "ecs"
        ],
        "text_keywords": [
            "ecs",
            "elastic container service",
            "Elastic Container Container Service (Amazon ECS)"
        ],
        "text_direct_keywords": [
            "ECS"
        ]
    }
}
*/

export const getHeaders = (actions = {}) => {
  const headers = [
    {
      id: 'code',
      numeric: false,
      disablePadding: true,
      label: 'Code',
      align: 'left',
      sort: 'code',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      align: 'left',
      sort: 'name',
    },

    {
      id: 'component_type',
      numeric: false,
      disablePadding: false,
      label: 'Component type',
      align: 'left',
      sort: 'component_type',
    },
    {
      id: 'is_draft',
      numeric: false,
      disablePadding: false,
      label: 'Draft',
      align: 'left',
      sort: 'is_draft',
    },
    {
      id: 'delete',
      numeric: true,
      disablePadding: false,
      align: 'center',
      label: '',
    },
  ];

  return headers;
};

// export const getHeadersPlus = (actions = {}) => {
//   const diagramsHeadersPlus = getHeaders();

//   return diagramsHeadersPlus;
// };

export const formatData = (list, actions = { onDelete: (item) => {} }) =>
  list?.map((item) => {
    const { id, code, name, component_type, is_draft } = item;

    const data = {
      code: {
        value: code,
        align: 'left',
      },
      name: {
        value: name,
        align: 'left',
        component: () => <NavLink to={PathNames.threat.replace(':threatId', id)}>{name}</NavLink>,
      },
      component_type: {
        value: component_type?.name,
        align: 'left',
      },
      is_draft: {
        value: is_draft,
        align: 'left',
      },
      delete: {
        component: () => <DeleteIcon onClick={() => actions.onDelete(item)} />,
        align: 'center',
      },
      data: { ...item, enable: true },
    };

    return data;
  });
