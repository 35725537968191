import React, { memo } from 'react';

import { Card } from '../card';

import {
  ContentWrapper,
  InfoCardDescription,
  InfoCardTitle,
  PolicyInfoCardDescription,
} from './styled';

export const InfoCard = memo(({ name, descr }) => (
  <Card title={name} style={{ marginBottom: 20, marginTop: 20, width: '100%' }}>
    <InfoCardDescription dangerouslySetInnerHTML={{ __html: descr }} />
  </Card>
));

export const PolicyInfoCard = memo(
  ({ name, descr, rationale, remediation }) => (
    <Card
      title={name}
      style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
    >
      <ContentWrapper>
        {!!descr && (
          <div>
            <InfoCardTitle>Description</InfoCardTitle>
            <PolicyInfoCardDescription
              dangerouslySetInnerHTML={{ __html: descr }}
            />
          </div>
        )}
        {!!rationale && (
          <div>
            <InfoCardTitle>Rationale</InfoCardTitle>
            <PolicyInfoCardDescription
              dangerouslySetInnerHTML={{ __html: rationale }}
            />
          </div>
        )}
        {!!remediation && (
          <div>
            <InfoCardTitle>Remediation</InfoCardTitle>
            <PolicyInfoCardDescription
              dangerouslySetInnerHTML={{ __html: remediation }}
            />
          </div>
        )}
      </ContentWrapper>
    </Card>
  )
);
