import styled from 'styled-components';

import { lmd } from '../../../../theme';

export const OverallScanResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FistLine = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: ${lmd}) {
    flex-wrap: wrap;
  }
`;

export const OverallComplianceWrapper = styled.div`
  margin-right: 20px;
  flex-grow: 1;

  @media screen and (max-width: ${lmd}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const SecondLine = styled.div`
  display: flex;
  width: 100%;
`;

export const FourthLine = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
  width: 100%;
`;

export const ThirdLine = styled.div`
  display: flex;
  width: 100%;
`;
