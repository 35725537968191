import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StepsHeader } from '../headers';

import { OnboardingSteps, appSelectors, appService } from '../../entities';

import { GlobalLoaderWrapper } from '../../app';
import { Loader } from '../../packages';

import { Content, Progress, ProgressLine, Wrapper } from './styled';
import { PrivacyPolicyModal } from './modals';

const StepNumberByName = {
  [OnboardingSteps.selectRegistrationType]: 0,
  [OnboardingSteps.register]: 1,
  [OnboardingSteps.confirmEmail]: 2,
  [OnboardingSteps.assignStandards]: 3,
};

export const PipelineOnboardingLayout = memo(({ children }) => {
  const dispatch = useDispatch();
  const { onboardingStep, isOnboardingReady } = useSelector(
    appSelectors.getAppData
  );
  const stepNumber = StepNumberByName[onboardingStep];

  useEffect(() => {
    dispatch(appService.initPipelineOnboarding());
  }, []);

  if (!isOnboardingReady) {
    return (
      <GlobalLoaderWrapper>
        <Loader />
      </GlobalLoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <StepsHeader step={stepNumber} stepsCount={3} />
      <ProgressLine>
        <Progress width={`${stepNumber * 33}%`} />
      </ProgressLine>
      <Content>{children}</Content>
      <PrivacyPolicyModal />
    </Wrapper>
  );
});
