export const scanHistoryHeader = [
  {
    id: 'indicator',
    numeric: false,
    disablePadding: true,
    label: 'Compliance',
    align: 'center',
  },
  {
    id: 'service',
    numeric: false,
    disablePadding: true,
    label: 'Service name',
    sort: 'service',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    sort: 'name',
    disablePadding: false,
    label: 'Account’s name',
    align: 'left',
  },
  {
    id: 'lastScan',
    numeric: false,
    // TODO add sorting by this field
    sort: 'timestamp',
    disablePadding: false,
    label: 'Scan date',
    align: 'left',
  },
  {
    id: 'compliance',
    numeric: true,
    sort: 'compliance',
    disablePadding: false,
    label: 'Compliance %',
    align: 'center',
  },
  {
    id: 'policiesViolated',
    numeric: true,
    sort: 'policies_bad',
    disablePadding: false,
    label: 'Policies violated',
    align: 'center',
  },
];
