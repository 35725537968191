export const accountModalName = 'account';

export const AccountTabs = {
  credentials: 'credentials',
  standards: 'standards',
};

export const editAccountModalTabs = [
  { name: AccountTabs.credentials, title: 'Credentials' },
  { name: AccountTabs.standards, title: 'Standards' },
];
