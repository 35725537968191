import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

import { ModalInline, useModalInline } from '../../packages/components/modal/ModalInline';
import { PageLayout } from '../../layouts';
import ProfileContentLayout from '../../layouts/page-layout/ProfileContentLayout';
import { Button, SecondaryButton } from '../../packages';
import StatusUserForm from './components/status-user-form';
import UserResendInviteForm from './components/user-resend-invite-form';
import TeamMainMenu from '../../layouts/TeamMainMenu';
import AddMemberForm from './components/add-member-form';
import { TableTeamUsers } from './components/table-team-users';
import { NotificationManager } from 'react-notifications';
import RemoveMemberForm from './components/remove-member-form';
import useApiData from '../../hooks/useApiData';
import { customerDetailsApi } from '../../entities';
import IconArrowLeftShort from '../../packages/icons/IconArrowLeftShort';
import { PathNames } from '../../consts';
import IconEdit from '../../packages/icons/IconEdit';
import Permission from '../../packages/components/permissions';

const PageTeam = () => {
  const { params } = useRouteMatch();
  const teamId = params.teamId;

  const {
    isOpen: isOpenAddMember,
    onClose: onCloseAddMember,
    onOpen: onOpenAddMember,
    keyUpdate: keyMutateAddMember,
  } = useModalInline();
  const {
    isOpen: isOpenRemoveMember,
    onClose: onCloseRemoveMember,
    onOpen: onOpenRemoveMember,
    modalProps: modalPropsRemoveMember,
    keyUpdate: keyMutateRemoveMember,
  } = useModalInline();
  const {
    isOpen: isOpenBlock,
    onClose: onCloseBlock,
    onOpen: onOpenBlock,
    modalProps: modalPropsBlock,
    keyUpdate: keyMutateStatus,
  } = useModalInline();
  const {
    isOpen: isOpenResend,
    onClose: onCloseResend,
    onOpen: onOpenResend,
    modalProps: modalPropsResend,
  } = useModalInline();

  const [selected, setSelected] = useState([]);

  const { data: team } = useApiData(customerDetailsApi.getTeam, [teamId], null);

  const onAction = useCallback(
    (action) => {
      if (selected.length) {
        action({
          ids: selected.map((i) => i.id),
          emails: selected.map((i) => i.email),
        });
      } else {
        NotificationManager.info('Please select users');
      }
    },
    [selected]
  );

  return (
    <PageLayout>
      <ProfileContentLayout
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NavLink to={PathNames.teams}>
              <SecondaryButton variant='outlined' style={{ minWidth: 'unset', width: 40, marginRight: 16, padding: 0 }}>
                <IconArrowLeftShort />
              </SecondaryButton>
            </NavLink>
            <div>{team?.name}</div>
          </div>
        }
        headerComponent={
          <div style={{ display: 'flex' }}>
            <Permission resource="company::teams">
              {(actions) => ((actions.company['manage-members']?.allowed) ?
                  <Button size='medium' type='button' style={{ width: 140, marginRight: 16 }} onClick={onOpenAddMember}>
                    Add member
                  </Button>
                  : null
              )}
            </Permission>
            {/* <TeamMainMenu
              onOpenRemoveMember={() => onAction(onOpenRemoveMember)}
              onOpenBlock={() => onAction(onOpenBlock)}
              onOpenResend={() => onAction(onOpenResend)}
            /> */}
          </div>
        }
      >
        <TableTeamUsers
          keyMutate={`${keyMutateAddMember}${keyMutateRemoveMember}${keyMutateStatus}`}
          team={team}
          onOpenRemoveMember={onOpenRemoveMember}
          onOpenBlock={onOpenBlock}
          onOpenResend={onOpenResend}
          onSelect={setSelected}
        />
      </ProfileContentLayout>
      <ModalInline isOpen={isOpenAddMember} header='Add member' onClose={onCloseAddMember}>
        <AddMemberForm onCancel={onCloseAddMember} onSuccess={onCloseAddMember} teamId={teamId} />
      </ModalInline>
      <ModalInline isOpen={isOpenRemoveMember} header='Remove a member' onClose={onCloseRemoveMember}>
        <RemoveMemberForm
          onCancel={onCloseRemoveMember}
          onSuccess={onCloseRemoveMember}
          team={team}
          ids={modalPropsRemoveMember.ids}
          emails={modalPropsRemoveMember.emails}
        />
      </ModalInline>
      <ModalInline
        isOpen={isOpenBlock}
        header={modalPropsBlock?.activateUser ? 'Activate user' : 'Block user'}
        onClose={onCloseBlock}
      >
        <StatusUserForm
          onCancel={onCloseBlock}
          onSuccess={onCloseBlock}
          emails={modalPropsBlock.emails}
          activateUser={modalPropsBlock?.activateUser}
        />
      </ModalInline>
      <ModalInline isOpen={isOpenResend} header='Resend user invite' onClose={onCloseResend}>
        <UserResendInviteForm onCancel={onCloseResend} onSuccess={onCloseResend} emails={modalPropsResend?.emails} />
      </ModalInline>
    </PageLayout>
  );
};

export default PageTeam;
