const headers = [
  {
    id: 'name',
    sort: 'name',
    label: 'Team name',
    align: 'left',
  },
  {
    id: 'members_count',
    label: 'Members',
    align: 'left',
  },
  {
    id: '_delete',
    align: 'right',
  },
];

export default headers;
