import React, { memo } from 'react';

import { colors } from '../../../../theme';

import { Step } from './Step';
import {
  ArrowRight,
  AwsButton,
  Comment,
  DisabledAwsButton,
  HighlightedText,
  Image,
  ListItem,
  ListTopic,
  ListWrapper,
  PermissionLabel,
} from './styled';


import Image1 from './imgs/GitHub-1.png';
import Image2 from './imgs/GitHub-2.png';
import Image3 from './imgs/GitHub-3.png';
import Image4 from './imgs/GitHub-4.png';
import Image5 from './imgs/GitHub-5.png';
import Image6 from './imgs/GitHub-6.png';
import branding from '../../../../branding';



export const GitHubSteps = memo(() => (
  <>
    <Step title="Step 1">
      <ListWrapper>
        <ListTopic>
        Go to <a href="https://github.com/" target="_blank">https://github.com/</a>
        </ListTopic>
        <ListTopic>
          Click on <HighlightedText>User's menu</HighlightedText> and to go <HighlightedText>Settings</HighlightedText>
        </ListTopic>
        <ListTopic>
          <Image src={Image1}/>
        </ListTopic>
      </ListWrapper>
    </Step>

    <Step title="Step 2">
      <ListWrapper>
        <ListTopic>
        Go to the left menu and click  <HighlightedText>&lt;&gt; Developer settings</HighlightedText>
        <Comment>(at bottom of list)</Comment>
        </ListTopic>
        <ListTopic>
          Go to <HighlightedText>Personal access token</HighlightedText>, choice  <HighlightedText>Classic tokens</HighlightedText> which you want to check.
        </ListTopic>
        <ListTopic>
          Click <AwsButton>Generate new token</AwsButton>
        </ListTopic>
        <ListTopic>
        and select <HighlightedText>Generate new token (classic)</HighlightedText>
        </ListTopic>
        <ListTopic>
          <Image src={Image2}/>
        </ListTopic>
      </ListWrapper>
    </Step>
    <Step title="Step 3">
      <ListWrapper>
      <ListTopic>
        Set the <HighlightedText>Note</HighlightedText>:
        </ListTopic>
        <ListTopic>
        Set Expiration at <HighlightedText>90 days</HighlightedText>
        </ListTopic>
        <Comment>or select "Custom defined" and choice more</Comment>
        <ListTopic>
        Set the <HighlightedText>Scopes</HighlightedText>:
        </ListTopic>
        <ListItem><PermissionLabel>repo</PermissionLabel><Comment>Full access</Comment></ListItem>
        <ListItem><PermissionLabel>read:packages</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>admin.org</PermissionLabel><Comment>Full access</Comment></ListItem>
        <ListItem><PermissionLabel>read:public_key</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:repo_hook</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:user</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>user:email</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:discussion</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:enterprise</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:audit_log</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:project</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:gpg_key</PermissionLabel></ListItem>
        <ListItem><PermissionLabel>read:ssh_signing_key</PermissionLabel></ListItem>
        <ListTopic>
        <Image src={Image3}/>
        <Image src={Image4}/>
        <Image src={Image5}/>
        </ListTopic>
      </ListWrapper>
    </Step>
    <Step title="Step 4">
      <ListWrapper>
      <ListTopic>
      And click <AwsButton>Generate token</AwsButton>
      </ListTopic>
      <ListTopic>
        At next screen you need to copy your <HighlightedText>PAT</HighlightedText>
        <Comment>note you can only do this on this screen, when it closes you can't copy it again</Comment>
      </ListTopic>
      <ListTopic></ListTopic>
      <ListTopic>
      Also copy the slug of your organization. 
      </ListTopic>
      <ListTopic>The easiest way to do this is to copy it from the URL of the browser. </ListTopic>
      <ListItem>Click the User's Menu</ListItem>
      <ListItem>Click <HighlightedText>Your Organizations</HighlightedText></ListItem>
      <ListItem>Select your Organisation from list and copy the slug
      </ListItem>
      

      <ListTopic>
        <Image src={Image6}/>
      </ListTopic>
      </ListWrapper>
    </Step>
    <Step title="Step 5">
      <ListWrapper>

      <ListTopic>
        Put the <HighlightedText>Access Token</HighlightedText> and the slug of the <HighlightedText>Organization</HighlightedText> in the {branding.name}
      </ListTopic>
      </ListWrapper>
    </Step>
  </>
));





