import React, { memo } from 'react';

import { Card } from '../card';

import { Chart } from '../chart';

export const OverallComplianceTrend = memo(({ chartData }) => {
  if (!chartData || chartData.every((chart) => chart.data.length < 2))
    return null;

  return (
    <Card
      title="Overall Compliance Trend"
      style={{ flexGrow: 2 }}
      helpMessage="On this chart you may see how the overall customer's compliance has been changing during the last 6 months. The dots on trend lines display the results of scans (compliance %) collected on a specific date in three dimensions: overall compliance % on a specific date (total of all the accounts), compliance % of Azure accounts available, compliance % of AWS accounts available. "
    >
      <Chart chartData={chartData} />
    </Card>
  );
});
