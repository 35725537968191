import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { appSelectors, authService } from '../entities';

import { MobileExitIcon, MobileProfileWrapper, MobileUserName } from './styled';

export const ProfileBlockMobile = memo(() => {
  const dispatch = useDispatch();
  const { user } = useSelector(appSelectors.getAppData);

  const onLogoutHandler = useCallback(() => {
    dispatch(authService.logout());
  }, []);

  const username =
    user.first_name && user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username;

  return (
    <MobileProfileWrapper>
      <MobileUserName>{username}</MobileUserName>
      <MobileExitIcon onClick={onLogoutHandler} size={40} />
    </MobileProfileWrapper>
  );
});
