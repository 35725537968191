import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { CheckList } from '../check-list';
import { SecondarySelect } from '../select';

import { CheckListWrapper, Wrapper } from './styled';

export const AssignStandards = memo(
  ({ items, presets, selectAllByDefault, onItemSelect }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState(null);

    const presetOptions = useMemo(
      () =>
        presets
          ? presets.map((preset) => ({
              label: preset.label,
              value: preset.id,
            }))
          : [],
      [presets]
    );

    const onItemClick = useCallback(
      (currentItemId, isChecked) => {
        let newValue = [];
        if (isChecked) {
          newValue = selectedItems.filter((id) => id !== currentItemId);
        } else {
          newValue = [...selectedItems, currentItemId];
        }

        setSelectedItems(newValue);
        onItemSelect(newValue);
      },
      [selectedItems, setSelectedItems]
    );

    const selectAllItems = useCallback(
      (isChecked) => {
        if (isChecked) {
          setSelectedItems([]);
          onItemSelect([]);
        } else {
          const ids = items.map(({ id }) => id);

          setSelectedItems(ids);
          onItemSelect(ids);
        }
      },
      [selectedItems, items, setSelectedItems]
    );

    const onPresetSelect = useCallback(
      (selectedOption) => {
        setSelectedPreset(selectedOption);
      },
      [selectedItems, items, setSelectedItems]
    );

    useEffect(() => {
      if (selectedPreset) {
        const preset = presets.find(({ id }) => id === selectedPreset.value);
        setSelectedItems(preset.value);
        onItemSelect(preset.value);
      }
    }, [selectedPreset]);

    useEffect(() => {
      const activeItems = items
        .filter((item) => item.active)
        .map((item) => item.id);

      const itemsForSelect = selectAllByDefault
        ? items.map(({ id }) => id)
        : activeItems;

      setSelectedItems(itemsForSelect);
      onItemSelect(itemsForSelect);
    }, [items]);

    return (
      <Wrapper>
        <SecondarySelect
          label="Choose preset"
          value={selectedPreset}
          options={presetOptions}
          onChange={onPresetSelect}
        />
        <CheckListWrapper>
          <CheckList
            selectedItems={selectedItems}
            items={items}
            onItemClick={onItemClick}
            onSelectAllItems={selectAllItems}
          />
        </CheckListWrapper>
      </Wrapper>
    );
  }
);
