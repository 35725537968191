import React, { memo } from 'react';

import { StepContent, StepHeader, StepWrapper } from './styled';

export const Step = memo(({ title, children }) => (
  <StepWrapper>
    <StepHeader>{title}</StepHeader>
    <StepContent>{children}</StepContent>
  </StepWrapper>
));
