import { wretch } from '../app';
import { downloadFile } from '../../packages';

const getAccountScans = ({ id, accountId, query }) =>
  wretch(`customers/${id}/accounts/${accountId}/scans/`)
    .query(query)
    .get()
    .json();

const exportScanHistory = ({ id, accountId, query }) => {
  downloadFile(`customers/${id}/accounts/${accountId}/scans/export/`, query);
};

const manageTeamsPlatform = ({ customerId, accountId, teamIds }) => {
  return wretch(`customers/${customerId}/accounts/${accountId}/manage-teams/platform/`)
    .put({ team_ids: teamIds })
    .json();
};

const manageTeamsPipeline = ({ customerId, accountId, teamIds }) => {
  return wretch(`customers/${customerId}/accounts/${accountId}/manage-teams/pipeline/`)
    .put({ team_ids: teamIds })
    .json();
};

export const accountDetailsApi = {
  getAccountScans,
  exportScanHistory,
  manageTeamsPlatform,
  manageTeamsPipeline,
};
