import * as yup from 'yup';

export const createComponentValidationSchema = yup.object({
  label: yup
    .string('Enter component Description')
    .required('Component description is required')
    .max(
      256,
      'Component description should be of maximum 256 characters length'
    ),
  type: yup.object(),
});
