import React, { memo, useCallback } from 'react';

import { DumbCheckbox } from '../checkbox';

import { ItemCheck, ItemLabel, ItemWrapper, LabelWrapper } from './styled';

export const Item = memo(({ id, label, isChecked, onClick, disabled }) => {
  const onItemClick = useCallback(
    (e) => {
      e.preventDefault();

      onClick(id, isChecked);
    },
    [id, isChecked, onClick]
  );

  return (
    <ItemWrapper onClick={disabled ? () => {} : onItemClick} isChecked={isChecked}>
      <ItemCheck>
        <DumbCheckbox value={isChecked} disabled={disabled} />
      </ItemCheck>
      <LabelWrapper>
        <ItemLabel>{label}</ItemLabel>
      </LabelWrapper>
    </ItemWrapper>
  );
});
