import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';

import { useHistory, useParams, useRouteMatch } from 'react-router';

import {
  customerDetailsSelectors,
  appSelectors,
  standardActions,
  standardSelectors,
  standardService
} from '../../entities';

import { FeaturePrefix, FeatureSlugs } from '../../consts';

import { LoaderWrapper } from '../../app';
import { widgetBaseUrl } from '../../entities/app';

import { PageLayout, getAccountTabs, getPlSecAccountTabs } from '../../layouts';

import { Standards, usePagination } from '../../packages';

import { NoStandards, Wrapper, BigButton } from './styled';
import { formatAccountStandards } from './utils';
import { AccountStat, PatPopup } from './components';

export const AccountOverviewPage = memo((props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { accountId, scanId, id, ppToolToken, ppFeature } = params;
  const isPlatformSecurity = !!useRouteMatch(`/${FeaturePrefix.PlatformSecurity}/`);
  console.log(isPlatformSecurity, {params, props})
  const { count: standardsCount } = usePagination('standards');
  const { isLoading, standards } = useSelector(
    standardSelectors.getStandardData
  );
  const selectedAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );

  const pipelineData = useSelector(appSelectors.getPipelineData);

  const history = useHistory();

  useEffect(() => {
    dispatch(standardActions.setStandards([]));
    dispatch(standardService.init({ id, accountId, scanId, ppToolToken, ppFeature }));
  }, [accountId, scanId, id, ppToolToken, ppFeature]);

  const tabs = useMemo(
    () => (isPlatformSecurity ? getPlSecAccountTabs(selectedAccount) : getAccountTabs(selectedAccount)),
    [isPlatformSecurity, selectedAccount]
  );

  const formattedStandards = useMemo(
    () => formatAccountStandards(standards, params, history.push),
    [standards]
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchNextPage = useCallback(() => {
    const { page, count } = usePagination('standards');
    const isNextPage = count ? count / 25 > page + 1 : false;

    if (isNextPage) {
      dispatch(
        standardService.getStandards({
          scanId, 
          accountId, ppToolToken, ppFeature,
          query: {
            page: page + 1,
          },
        })
      );
    }
  }, [accountId, scanId, ppToolToken, ppFeature]);

  const showAddPat = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleInputDialogClose = useCallback(async (val) => {
    if (val === false) {
      setDialogOpen(false);
      return;
    } else {
      const org = window.sessionStorage['azdevorg']||window.ARIBOT_PIPELINE_ORG;
      setDialogOpen(false);
      await standardService.joinAccountByPat({pat: val, org, ppToolToken});
      window.location.reload();
    }
  }, [ppToolToken, ppFeature]);

  if (isLoading) {
    return (
      <PageLayout tabs={tabs}>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  if (ppFeature === FeatureSlugs.PipelineSecurity && pipelineData && !pipelineData.is_ppl_data) {
      const org = window.sessionStorage['azdevorg']||window.ARIBOT_PIPELINE_ORG;
      const myUrlWithParams = new URL(`${widgetBaseUrl}/pipeline-api/results/${ppToolToken}/oauth-account/${org}/`);
      // myUrlWithParams.searchParams.append('redirect_url', location.href);
      const urlOauth = myUrlWithParams.href;
      return (
      <PageLayout tabs={tabs}>
          <LoaderWrapper>
            <Wrapper>
              <BigButton href={urlOauth} target="_blank">Connect AzureDev account</BigButton>
              <BigButton onClick={showAddPat} target="_blank">Connect using PAT</BigButton>
            </Wrapper>
          </LoaderWrapper>
          <PatPopup
            open={dialogOpen}
            onClose={handleInputDialogClose}
            initialValue={""}
          />
      </PageLayout>
    );
  }

  return (
    <PageLayout tabs={tabs}>
      <Wrapper>
        <AccountStat />
        {formattedStandards.length ? (
          <>
            <Standards items={formattedStandards} />
            {standards.length !== standardsCount && (
              <Button onClick={fetchNextPage} sx={{ marginRight: '-20px' }}>
                Show more
              </Button>
            )}
          </>
        ) : (
          <NoStandards>No scan results available</NoStandards>
        )}
      </Wrapper>
    </PageLayout>
  );
});
