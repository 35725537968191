import React, { memo, useEffect, useMemo, useRef } from 'react';

import { FeaturePrefix, PathNames } from '../../../consts';

import { ListHeader } from '../list-header';

import { getCurrentFeaturePrefix } from '../../../packages';

import { AccountList, AccountListWrapper } from './styled';
import { ServiceAccounts } from './ServiceAccounts';
import { AzureAccounts } from './AzureAccounts';

import { getActions } from '../../../packages/components/permissions';

export const PureAccountList = memo(
  ({
    accounts,
    customer,
    onAccountSelect,
    onAccountCreate,
    accountsCount,
    expandedService,
    onServiceSelect,
    closeSidebar,
    ...rest
  }) => {
    const currentFeaturePrefix = getCurrentFeaturePrefix();
    const {
      awsAccounts,
      azureAccounts,
      azureJenkinsAccounts,
      pipelineAccounts,
      azuredevAccounts,
      githubdevAccounts,
    } = useMemo(
      () =>
        accounts.reduce(
          (acc, account) => {
            switch (account.service) {
              case 'aws':
              case 'jenkins_aws': {
                acc.awsAccounts.push(account);
                break;
              }
              case 'azure': {
                acc.azureAccounts.push(account);
                break;
              }
              case 'jenkins_azure': {
                acc.azureJenkinsAccounts.push(account);
                break;
              }
              case 'pipeline': {
                acc.pipelineAccounts.push(account);
                break;
              }
              case 'azuredev': {
                acc.azuredevAccounts.push(account);
                break;
              }
              case 'githubdev': {
                acc.githubdevAccounts.push(account);
                break;
              }
              default: {
                console.error('Unexpected service type -', account.service);
                break;
              }
            }

            return acc;
          },
          {
            awsAccounts: [],
            azureAccounts: [],
            azureJenkinsAccounts: [],
            pipelineAccounts: [],
            azuredevAccounts: [],
            githubdevAccounts: [],
          }
        ),
      [accounts, currentFeaturePrefix]
    );

    const platformActions = getActions("platform-security::accounts");
    const pipelineActions = getActions("pipeline-security::accounts");

    const hideAddButton = currentFeaturePrefix === FeaturePrefix.PlatformSecurity ?
      !platformActions.company?.["create"]?.allowed
      : !pipelineActions.company?.["create"]?.allowed;

    const activeTabRef = useRef(null);

    useEffect(() => {
      activeTabRef.current?.scrollIntoView();
    }, [activeTabRef]);

    // TODO update counters for pipeline-security
    return (
      <AccountListWrapper>
        <ListHeader
          onLinkClick={closeSidebar}
          count={accountsCount.count}
          label="Accounts"
          labelLink={PathNames.customerAccounts
            .replace(':feature', getCurrentFeaturePrefix())
            .replace(':id', customer.id)}
          onAddButtonClick={onAccountCreate}
          hideAddButton={hideAddButton}
        />
        <AccountList>
          {currentFeaturePrefix === FeaturePrefix.PlatformSecurity ? (
            <>
              <ServiceAccounts
                key="aws"
                title="AWS"
                serviceName="AWS"
                isServiceSelected={expandedService === 'AWS'}
                accounts={awsAccounts}
                count={accountsCount.awsCount ?? 0}
                customer={customer}
                onAccountSelect={onAccountSelect}
                onServiceSelect={onServiceSelect}
                activeTabRef={activeTabRef}
                {...rest}
              />
              <AzureAccounts
                key="Azure"
                customer={customer}
                expandedService={expandedService}
                azureAccounts={azureAccounts}
                azureJenkinsAccounts={azureJenkinsAccounts}
                pipelineAccounts={pipelineAccounts}
                accountsCount={accountsCount}
                onAccountSelect={onAccountSelect}
                onServiceSelect={onServiceSelect}
                activeTabRef={activeTabRef}
                {...rest}
              />
            </>
          ) : (
            <>
              <ServiceAccounts
                key="azuredev"
                title="AzureDev"
                serviceName="azuredev"
                isServiceSelected={expandedService === 'azuredev'}
                accounts={azuredevAccounts}
                count={azuredevAccounts.length}
                customer={customer}
                onAccountSelect={onAccountSelect}
                onServiceSelect={onServiceSelect}
                activeTabRef={activeTabRef}
                {...rest}
              />
              <ServiceAccounts
                key="githubdev"
                title="GithubDev"
                serviceName="githubdev"
                isServiceSelected={expandedService === 'githubdev'}
                accounts={githubdevAccounts}
                count={githubdevAccounts.length}
                customer={customer}
                onAccountSelect={onAccountSelect}
                onServiceSelect={onServiceSelect}
                activeTabRef={activeTabRef}
                {...rest}
              />
            </>
          )}
        </AccountList>
      </AccountListWrapper>
    );
  }
);
