import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Link } from '@mui/material';

import { Button, Modal, modalActions } from '../../../../packages';

import { colors } from '../../../../theme';

import { privacyPolicyModalName } from './consts';
import {
  ButtonsWrapper,
  Label,
  ListItem,
  ListTopic,
  ListWrapper,
  SubTopic,
  Title,
  Topic,
  TopicLastUpdated,
  Wrapper,
} from './styled';
import branding from '../../../../branding';

export const PrivacyPolicyModal = memo(() => {
  const dispatch = useDispatch();

  const closeModalHandler = useCallback(() => {
    dispatch(
      modalActions.setModalIsOpen({
        name: privacyPolicyModalName,
        isOpen: false,
      })
    );
  });

  return (
    <Modal
      name={privacyPolicyModalName}
      header="Privacy Policy"
      styles={{ width: '700px' }}
    >
      <Wrapper>
        <TopicLastUpdated>Last updated: March 01, 2022</TopicLastUpdated>
        <Label>
          Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Label>
        <Label>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </Label>
        <Title>Interpretation and Definitions</Title>
        <Topic>Interpretation</Topic>
        <Label>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Label>
        <Topic>Definitions</Topic>
        <ListTopic>For the purposes of this Privacy Policy:</ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>
              Account means a unique account created for You to access our
              Service or parts of our Service.
            </ListItem>
            <ListItem>
              Affiliate means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority.
            </ListItem>
            <ListItem>
              Application means the software program provided by the Company
              downloaded by You on any electronic device, named {branding.name}
            </ListItem>
            <ListItem>
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to {branding.to}.
            </ListItem>
            <ListItem>
              For the purpose of the GDPR, the Company is the Data Controller.
            </ListItem>
            <ListItem>Country refers to: Netherlands</ListItem>
            <ListItem>
              Data Controller, for the purposes of the GDPR (General Data
              Protection Regulation), refers to the Company as the legal person
              which alone or jointly with others determines the purposes and
              means of the processing of Personal Data.
            </ListItem>
            <ListItem>
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </ListItem>
            <ListItem>
              Personal Data is any information that relates to an identified or
              identifiable individual.
            </ListItem>
            <ListItem>
              For the purposes of GDPR, Personal Data means any information
              relating to You such as a name, an identification number, location
              data, online identifier or to one or more factors specific to the
              physical, physiological, genetic, mental, economic, cultural or
              social identity.
            </ListItem>
            <ListItem>Service refers to the Application.</ListItem>
            <ListItem>
              Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analyzing how the Service is used. For the purpose of the GDPR,
              Service Providers are considered Data Processors.
            </ListItem>
            <ListItem>
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </ListItem>
            <ListItem>
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </ListItem>
            <ListItem>
              Under GDPR (General Data Protection Regulation), You can be
              referred to as the Data Subject or as the User as you are the
              individual using the Service.
            </ListItem>
          </ul>
        </ListWrapper>
        <Title>Collecting and Using Your Personal Data</Title>
        <Topic>Types of Data Collected</Topic>
        <SubTopic>Personal Data</SubTopic>
        <ListTopic>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>Email address</ListItem>
            <ListItem>First name and last name</ListItem>
            <ListItem>Phone number</ListItem>
            <ListItem>Address, State, Province, ZIP/Postal code, City</ListItem>
            <ListItem>Usage Data</ListItem>
          </ul>
        </ListWrapper>
        <SubTopic>Usage Data</SubTopic>
        <Label>
          Usage Data is collected automatically when using the Service.
        </Label>
        <Label>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </Label>
        <Label>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </Label>
        <Label>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </Label>
        <Title>Use of Your Personal Data</Title>
        <ListTopic>
          The Company may use Personal Data for the following purposes:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>
              <b>To provide and maintain our Service</b>, including to monitor
              the usage of our Service
            </ListItem>
            <ListItem>
              <b>To manage Your Account:</b> to manage Your registration as a
              user of the Service. The Personal Data You provide can give You
              access to different functionalities of the Service that are
              available to You as a registered user.
            </ListItem>
            <ListItem>
              <b>For the performance of a contract:</b> the development,
              compliance and undertaking of the purchase contract for the
              products, items or services You have purchased or of any other
              contract with Us through the Service.
            </ListItem>
            <ListItem>
              <b>To contact You:</b> To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </ListItem>
            <ListItem>
              <b>To provide You</b> with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless You have opted not to receive such
              information.
            </ListItem>
            <ListItem>
              <b>To manage Your requests:</b> To attend and manage Your requests
              to Us.
            </ListItem>
            <ListItem>
              <b>For business transfers:</b> We may use Your information to
              evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of Our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which Personal
              Data held by Us about our Service users is among the assets
              transferred.
            </ListItem>
            <ListItem>
              <b>For other purposes:</b> We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </ListItem>
          </ul>
        </ListWrapper>
        <ListTopic>
          We may share Your personal information in the following situations:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>
              <b>With Service Providers:</b> We may share Your personal
              information with Service Providers to monitor and analyze the use
              of our Service, to contact You.
            </ListItem>
            <ListItem>
              <b>For business transfers:</b> We may share or transfer Your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </ListItem>
            <ListItem>
              <b>With Affiliates:</b> We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </ListItem>
            <ListItem>
              <b>With business partners:</b> We may share Your information with
              Our business partners to offer You certain products, services or
              promotions.
            </ListItem>
            <ListItem>
              <b>With other users:</b> when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside.
            </ListItem>
            <ListItem>
              <b>With Your consent:</b> We may disclose Your personal
              information for any other purpose with Your consent.
            </ListItem>
          </ul>
        </ListWrapper>
        <Title>Retention of Your Personal Data</Title>
        <Label>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </Label>
        <Label>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Label>
        <Title>Transfer of Your Personal Data</Title>
        <Label>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction
        </Label>
        <Label>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </Label>
        <Label>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </Label>
        <Title>Disclosure of Your Personal Data</Title>
        <Topic>Business Transactions</Topic>
        <Label>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </Label>
        <Topic>Law enforcement</Topic>
        <Label>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </Label>
        <Topic>Other legal requirements</Topic>
        <ListTopic>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>Comply with a legal obligation</ListItem>
            <ListItem>
              Protect and defend the rights or property of the Company
            </ListItem>
            <ListItem>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </ListItem>
            <ListItem>
              Protect the personal safety of Users of the Service or the public
            </ListItem>
            <ListItem>Protect against legal liability</ListItem>
          </ul>
        </ListWrapper>
        <Title>Security of Your Personal Data</Title>
        <Label>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Label>
        <Title>
          Detailed Information on the Processing of Your Personal Data
        </Title>
        <Label>
          The Service Providers We use may have access to Your Personal Data.
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies.
        </Label>
        <Topic>Analytics</Topic>
        <Label>
          We may use third-party Service providers to monitor and analyze the
          use of our Service.
        </Label>
        <SubTopic>Google Analytics</SubTopic>
        <Label>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
        </Label>
        <Label>
          You may opt-out of certain Google Analytics features through your
          mobile device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy Policy:
          <Link
            component="a"
            target="_blank"
            href="https://policies.google.com/privacy"
            underline="hover"
            sx={{
              wordBreak: 'break-word',
              marginLeft: '5px',
              color: colors.activeColor,
            }}
          >
            https://policies.google.com/privacy
          </Link>
        </Label>
        <Label>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:
          <Link
            component="a"
            target="_blank"
            href="https://policies.google.com/privacy"
            underline="hover"
            sx={{
              wordBreak: 'break-word',
              marginLeft: '5px',
              color: colors.activeColor,
            }}
          >
            https://policies.google.com/privacy
          </Link>
        </Label>
        <Topic>Email Marketing</Topic>
        <Label>
          We may use Your Personal Data to contact You with newsletters,
          marketing or promotional materials and other information that may be
          of interest to You. You may opt-out of receiving any, or all, of these
          communications from Us by following the unsubscribe link or
          instructions provided in any email We send or by contacting Us.
        </Label>
        <Label>
          We may use Email Marketing Service Providers to manage and send emails
          to You.
        </Label>
        <SubTopic>Sendgrid</SubTopic>
        <Label>
          Their Privacy Policy can be viewed at
          <Link
            component="a"
            target="_blank"
            href="https://sendgrid.com/"
            underline="hover"
            sx={{
              wordBreak: 'break-word',
              marginLeft: '5px',
              color: colors.activeColor,
            }}
          >
            https://sendgrid.com/
          </Link>
        </Label>
        <Title>GDPR Privacy</Title>
        <Topic>Legal Basis for Processing Personal Data under GDPR</Topic>
        <ListTopic>
          We may process Personal Data under the following conditions:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>
              <b>Consent:</b> You have given Your consent for processing
              Personal Data for one or more specific purposes.
            </ListItem>
            <ListItem>
              <b>Performance of a contract:</b> Provision of Personal Data is
              necessary for the performance of an agreement with You and/or for
              any pre-contractual obligations thereof.
            </ListItem>
            <ListItem>
              <b>Legal obligations:</b> Processing Personal Data is necessary
              for compliance with a legal obligation to which the Company is
              subject.
            </ListItem>
            <ListItem>
              <b>Vital interests:</b> Processing Personal Data is necessary in
              order to protect Your vital interests or of another natural
              person.
            </ListItem>
            <ListItem>
              <b>Public interests:</b> Processing Personal Data is related to a
              task that is carried out in the public interest or in the exercise
              of official authority vested in the Company.
            </ListItem>
            <ListItem>
              <b>Legitimate interests:</b> Processing Personal Data is necessary
              for the purposes of the legitimate interests pursued by the
              Company.
            </ListItem>
          </ul>
        </ListWrapper>
        <Label>
          In any case, the Company will gladly help to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Data is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </Label>
        <Topic>Your Rights under the GDPR</Topic>
        <Label>
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </Label>
        <ListTopic>
          You have the right under this Privacy Policy, and by law if You are
          within the EU, to:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>
              <b>Request access to Your Personal Data.</b> The right to access,
              update or delete the information We have on You. Whenever made
              possible, you can access, update or request deletion of Your
              Personal Data directly within Your account settings section. If
              you are unable to perform these actions yourself, please contact
              Us to assist You. This also enables You to receive a copy of the
              Personal Data We hold about You.
            </ListItem>
            <ListItem>
              <b>
                Request correction of the Personal Data that We hold about You.
              </b>
              You have the right to have any incomplete or inaccurate
              information We hold about You corrected.
            </ListItem>
            <ListItem>
              <b>Object to processing of Your Personal Data.</b> This right
              exists where We are relying on a legitimate interest as the legal
              basis for Our processing and there is something about Your
              particular situation, which makes You want to object to our
              processing of Your Personal Data on this ground. You also have the
              right to object where We are processing Your Personal Data for
              direct marketing purposes.
            </ListItem>
            <ListItem>
              <b>Request erasure of Your Personal Data.</b> You have the right
              to ask Us to delete or remove Personal Data when there is no good
              reason for Us to continue processing it.
            </ListItem>
            <ListItem>
              <b>Request the transfer of Your Personal Data.</b> We will provide
              to You, or to a third-party You have chosen, Your Personal Data in
              a structured, commonly used, machine-readable format. Please note
              that this right only applies to automated information which You
              initially provided consent for Us to use or where We used the
              information to perform a contract with You.
            </ListItem>
            <ListItem>
              <b>Withdraw Your consent.</b> You have the right to withdraw Your
              consent on using your Personal Data. If You withdraw Your consent,
              We may not be able to provide You with access to certain specific
              functionalities of the Service.
            </ListItem>
          </ul>
        </ListWrapper>
        <Topic>Exercising of Your GDPR Data Protection Rights</Topic>
        <Label>
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </Label>
        <Label>
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </Label>
        <Title>Children's Privacy</Title>
        <Label>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </Label>
        <Label>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </Label>
        <Title>Links to Other Websites</Title>
        <Label>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </Label>
        <Label>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </Label>
        <Title>Changes to this Privacy Policy</Title>
        <Label>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Label>
        <Label>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </Label>
        <Label>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Label>
        <Title>Contact Us</Title>
        <ListTopic>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </ListTopic>
        <ListWrapper>
          <ul>
            <ListItem>By email: {branding.email}</ListItem>
          </ul>
        </ListWrapper>
        <ButtonsWrapper>
          <Button onClick={closeModalHandler}>Close</Button>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
});
