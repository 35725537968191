import { policyInitialState, policyReducerNamespace } from './store';

const getPolicyData = (state) => state[policyReducerNamespace] || policyInitialState;

const getResourceById = (state, id) => {
  const { resources } = getPolicyData(state);

  return resources.find((account) => String(account.id) === String(id));
};

export const policySelectors = {
  getPolicyData,
  getResourceById,
};
