import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StepsHeader } from '../headers';

import { OnboardingSteps, appSelectors, appService } from '../../entities';

import { GlobalLoaderWrapper } from '../../app';
import { Loader } from '../../packages';

import { Content, Progress, ProgressLine, Wrapper } from './styled';
import { AccountServiceModal, PrivacyPolicyModal } from './modals';

const StepNumberByName = {
  [OnboardingSteps.accounts]: 1,
  [OnboardingSteps.assignStandards]: 2,
  [OnboardingSteps.readyToScanning]: 3,
  [OnboardingSteps.scanning]: 4,
};

export const OnboardingLayout = memo(({ children }) => {
  const dispatch = useDispatch();
  const { onboardingStep, isOnboardingReady } = useSelector(
    appSelectors.getAppData
  );
  const stepNumber = StepNumberByName[onboardingStep];

  useEffect(() => {
    dispatch(appService.initOnboarding());
  }, []);

  if (!isOnboardingReady) {
    return (
      <GlobalLoaderWrapper>
        <Loader />
      </GlobalLoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <StepsHeader step={stepNumber} stepsCount={4} />
      <ProgressLine>
        <Progress width={`${stepNumber * 25}%`} />
      </ProgressLine>
      <Content>{children}</Content>
      <PrivacyPolicyModal />
      <AccountServiceModal />
    </Wrapper>
  );
});
