import { useCallback } from 'react';

import { useParams } from 'react-router';

import { useDispatch } from 'react-redux';

import {
  accountDetailsService,
  customerService,
  policyGroupService,
} from '../../entities';
import {
  useAccountPage,
  useCustomerPages,
  usePolicyGroupPage,
  useStandardPage,
} from '../../packages';

export const useExport = (format) => {
  const dispatch = useDispatch();
  const { id, accountId, scanId, standardId, policyGroupId } = useParams();
  const isCustomersPage = useCustomerPages();
  const isAccountPage = useAccountPage();
  const isStandardPage = useStandardPage();
  const isPolicyGroupPage = usePolicyGroupPage();

  if (isCustomersPage) {
    return () => {
      dispatch(
        customerService.exportAccounts({
          id,
          query: { target_format: format },
        })
      );
    };
  }

  if (isAccountPage) {
    return () => {
      dispatch(
        accountDetailsService.exportScanHistory({
          accountId,
          id,
          query: { target_format: format },
        })
      );
    };
  }

  if (isStandardPage) {
    return () => {
      dispatch(
        policyGroupService.exportStandardScanHistory({
          id,
          scanId,
          accountId,
          standardId,
          query: { target_format: format },
        })
      );
    };
  }

  if (isPolicyGroupPage) {
    return useCallback(() => {
      dispatch(
        policyGroupService.exportPolicyGroupScanHistory({
          id,
          scanId,
          accountId,
          standardId,
          policyGroupId,
          query: { target_format: format },
        })
      );
    }, [accountId, id]);
  }

  return () => {};
};
