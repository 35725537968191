import React, { memo, useCallback, useEffect } from 'react';

import { Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { Modal, modalActions, modalSelectors } from '../../../../packages';

import { customerService } from '../../../../entities';

import { ButtonsWrapper, StartScanAllContent, Title } from './styled';
import { customerStartScanAllModal } from './consts';

export const StartScanAllModal = memo(() => {
  const dispatch = useDispatch();
  const customerIds = useSelector((state) =>
    modalSelectors.getModalData(state, customerStartScanAllModal)
  );
  const onContinueClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(customerService.scanSelectedCustomers(customerIds));
      dispatch(
        modalActions.setModalIsOpen({
          name: customerStartScanAllModal,
          isOpen: false,
        })
      );
    },
    [customerIds]
  );

  const onCancelClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        modalActions.setModalIsOpen({
          name: customerStartScanAllModal,
          isOpen: false,
        })
      );
    },
    [customerIds]
  );

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: customerStartScanAllModal,
          data: null,
        })
      );
    },
    []
  );

  return (
    <Modal name={customerStartScanAllModal} header="Start scan">
      <StartScanAllContent>
        <Title>
          {`You selected ${customerIds?.length} ${
            customerIds?.length > 1 ? 'accounts' : 'account'
          }. By continuing you are launching scans for selected customers and for all their accounts.`}
        </Title>
        <ButtonsWrapper component="button">
          <Button size="medium" onClick={onCancelClickHandler}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={onContinueClickHandler}
          >
            Continue
          </Button>
        </ButtonsWrapper>
      </StartScanAllContent>
    </Modal>
  );
});
