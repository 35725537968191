import styled from 'styled-components';

import { icons } from '../icons';

import { scrollable } from '../../../theme';

export const StandardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-right: -48px;

  & > * {
    margin-bottom: 20px;
    margin-right: 20px;
  }
`;

export const StandardWrapper = styled.div`
  background: ${({ theme, color }) => color ?? theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadows.secondaryShadow};
  border-radius: 8px;
  padding: 20px 20px 50px 20px;
  flex: 1;
  min-width: 300px;
  min-height: 150px;
  max-height: 150px;
  position: relative;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  max-height: 72px;
  color: ${({ theme }) => theme.colors.activeTextColor};

  ${scrollable}
`;

export const PercentWrapper = styled.div`
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.activeTextColor};
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const StarIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 12px;
  left: 20px;
`;

export const CustomLabel = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.customLabelColor};
`;

export const CustomIcon = styled.img.attrs({
  src: icons.customIcon,
})`
  margin-right: 8px;
  width: 20px;
`;
