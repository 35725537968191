import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { TextField } from '../../../packages';

export const ProfileFormWrapper = styled.div`
  padding: 5px 24px;
`;

export const InputField = muiStyled(TextField)(() => ({
  marginBottom: 12,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 6px;
`;

export const checkboxStyle = {
  margin: '0.5rem',
};