import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { Modal, modalSelectors } from '../../../../packages';

import { CustomerForm } from '../../../../pages/customers/components';

import { customerModalName } from './consts';

export const AddCustomerModal = memo(() => {
  const selectedCustomerId = useSelector((state) =>
    modalSelectors.getModalData(state, customerModalName)
  );
  return (
    <Modal
      name={customerModalName}
      header={selectedCustomerId ? 'Edit customer' : 'Add new customer'}
    >
      <CustomerForm />
    </Modal>
  );
});
