import React, { memo } from 'react';

import serverStatusUrl from '../../images/server-status.svg';

import {
  Image,
  ImageBox,
  InfoBox,
  Wrapper,
} from '../styled';

import { RegisterForm } from '../../../forms/register-form';
import { WelcomeTxt } from '../../../../pages/welcome/WelcomeTxt';


export const RegisterStep = memo(() => (
  <Wrapper>
    <InfoBox>
      <WelcomeTxt/>
      <RegisterForm />
    </InfoBox>
    <ImageBox>
      <Image src={serverStatusUrl} />
    </ImageBox>
  </Wrapper>
));
