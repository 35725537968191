import React, { memo } from 'react';

import { colors } from '../../../theme';

import {
  CustomIcon,
  CustomLabel,
  NameWrapper,
  PercentWrapper,
  StandardWrapper,
  StarIconWrapper,
} from './styled';

export const Standard = memo(({ name, percent, onClick, isCustom }) => (
  <StandardWrapper
    onClick={onClick}
    color={isCustom ? colors.customBackground : colors.secondaryBackground}
  >
    <NameWrapper isCustom={isCustom}>
      {isCustom && <CustomIcon />}
      {name}
    </NameWrapper>
    <StarIconWrapper>
      {isCustom && <CustomLabel>Custom</CustomLabel>}
    </StarIconWrapper>

    <PercentWrapper>{`${percent}%`}</PercentWrapper>
  </StandardWrapper>
));
