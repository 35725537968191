import styled from 'styled-components';

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
  align-items: flex-end;
  margin-left: 32px;
  padding-right: 32px;
  margin-bottom: 20px;
`;

export const HeaderLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

export const EmptyMessage = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greyText};
  margin: 20px auto;
`;
