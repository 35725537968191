import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Modal,
  modalActions,
  modalSelectors,
} from '../../../../packages';

import { accountServiceModalName } from './consts';
import { ButtonsWrapper, Content, Wrapper } from './styled';
import { AwsSteps } from './AwsSteps';
import { AzureSteps } from './AzureSteps';
import { AzureDevSteps } from './AzureDevSteps';
import { GitHubSteps } from './GitHubSteps';

export const AccountServiceModal = memo(() => {
  const dispatch = useDispatch();
  const service = useSelector((state) =>
    modalSelectors.getModalData(state, accountServiceModalName)
  );

  const closeModalHandler = useCallback(() => {
    dispatch(
      modalActions.setModalIsOpen({
        name: accountServiceModalName,
        isOpen: false,
      })
    );
  }, [dispatch]);

  let label = '';
  let body = null;
  switch(service) {
    case 'azure':
    case 'pipeline': 
      label = 'Azure account preparing';
      body = (<AzureSteps/>);
      break;
    case 'aws': 
      label = 'AWS account preparing';
      body = (<AwsSteps/>);
      break;
    case 'azuredev': 
      label = 'Azure DevOps account preparing';
      body = (<AzureDevSteps/>);
      break;
    case 'githubdev': 
      label = 'GitHub account preparing';
      body = (<GitHubSteps/>);
      break;
  }

  return (
    <Modal
      name={accountServiceModalName}
      header={(<span>{label}</span>)}
      styles={{ width: '900px' }}
    >
      <Wrapper>
        <Content>{body}</Content>
        <ButtonsWrapper>
          <Button onClick={closeModalHandler}>Close</Button>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
});
