import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Form, SecondaryButton } from '../../../../packages';

import { ButtonsWrapper, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { customerDetailsApi } from '../../../../entities';

const CreateTeamForm = (props) => {
  const { teamId, name = '', onCancel = () => {}, onSuccess = () => {} } = props;
  const dispatch = useDispatch();

  const isNew = !teamId;

  const initialValues = useMemo(
    () => ({
      name,
    }),
    [name]
  );

  const validationSchema = defaultValidationSchema;

  const onSubmit = useCallback(
    (values) => {
      if (!isNew) {
        customerDetailsApi
          .updateTeam(teamId, { name: values.name })
          .then(() => {
            onSuccess();
          })
          .then(() => onCancel());
      } else {
        customerDetailsApi
          .createTeam({ name: values.name })
          .then(() => {
            onSuccess();
          })
          .then(() => onCancel());
      }
    },
    [isNew, teamId, dispatch, onSuccess]
  );

  return (
    <ProfileFormWrapper>
      <Form validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        <InputField name='name' label='Team name' />
        <ButtonsWrapper>
          <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
            {'Cancel'}
          </SecondaryButton>
          <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
            {isNew ? 'Create' : 'Rename'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </ProfileFormWrapper>
  );
};

export default CreateTeamForm;
