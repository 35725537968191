import React from 'react';
import { WrapperList } from './styled';

const List = (props) => {
  const { data = [] } = props;

  return (
    <WrapperList>
      {data.map((item, idx) => (
        <Item key={idx} style={item.style}>
          {item.title}
        </Item>
      ))}
    </WrapperList>
  );
};

export default List;

const Item = (props) => (
  <div style={{ padding: 8, boxShadow: '0px 1px 0px #E6E6E6', ...(props.style || {}) }}>{props.children}</div>
);
