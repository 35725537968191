import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

import { useParams } from 'react-router';

import {
  customerDetailsSelectors,
  customerDetailsService,
} from '../../entities';

import { LoaderWrapper } from '../../app';
import { PageLayout, tabs } from '../../layouts';

import { CustomerAccounts } from './components';
import { Wrapper } from './styled';

export const AccountsPage = memo(() => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );

  useEffect(() => {
    dispatch(customerDetailsService.initAccounts(id));
  }, [id]);

  if (isLoading) {
    return (
      <PageLayout tabs={tabs}>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout tabs={tabs}>
      <Wrapper>
        <CustomerAccounts />
      </Wrapper>
    </PageLayout>
  );
});
