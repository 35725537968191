import React from 'react';

const IconStatusPending = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.3334 0.833336C10.7937 0.833336 11.1667 1.20643 11.1667 1.66667V5C11.1667 5.46024 10.7937 5.83334 10.3334 5.83334C9.87318 5.83334 9.50008 5.46024 9.50008 5V1.66667C9.50008 1.20643 9.87318 0.833336 10.3334 0.833336ZM3.85249 3.51908C4.17793 3.19364 4.70557 3.19364 5.031 3.51908L7.38934 5.87741C7.71477 6.20285 7.71477 6.73049 7.38934 7.05592C7.0639 7.38136 6.53626 7.38136 6.21083 7.05592L3.85249 4.69759C3.52706 4.37215 3.52706 3.84452 3.85249 3.51908ZM16.8143 3.51908C17.1398 3.84452 17.1398 4.37215 16.8143 4.69759L14.456 7.05592C14.1306 7.38136 13.6029 7.38136 13.2775 7.05592C12.9521 6.73049 12.9521 6.20285 13.2775 5.87741L15.6358 3.51908C15.9613 3.19364 16.4889 3.19364 16.8143 3.51908ZM1.16675 10C1.16675 9.53976 1.53984 9.16667 2.00008 9.16667H5.33341C5.79365 9.16667 6.16675 9.53976 6.16675 10C6.16675 10.4602 5.79365 10.8333 5.33341 10.8333H2.00008C1.53984 10.8333 1.16675 10.4602 1.16675 10ZM14.5001 10C14.5001 9.53976 14.8732 9.16667 15.3334 9.16667H18.6667C19.127 9.16667 19.5001 9.53976 19.5001 10C19.5001 10.4602 19.127 10.8333 18.6667 10.8333H15.3334C14.8732 10.8333 14.5001 10.4602 14.5001 10ZM7.38934 12.9441C7.71477 13.2695 7.71477 13.7972 7.38934 14.1226L5.031 16.4809C4.70557 16.8064 4.17793 16.8064 3.85249 16.4809C3.52706 16.1555 3.52706 15.6278 3.85249 15.3024L6.21083 12.9441C6.53626 12.6186 7.0639 12.6186 7.38934 12.9441ZM13.2775 12.9441C13.6029 12.6186 14.1306 12.6186 14.456 12.9441L16.8143 15.3024C17.1398 15.6278 17.1398 16.1555 16.8143 16.4809C16.4889 16.8064 15.9613 16.8064 15.6358 16.4809L13.2775 14.1226C12.9521 13.7972 12.9521 13.2695 13.2775 12.9441ZM10.3334 14.1667C10.7937 14.1667 11.1667 14.5398 11.1667 15V18.3333C11.1667 18.7936 10.7937 19.1667 10.3334 19.1667C9.87318 19.1667 9.50008 18.7936 9.50008 18.3333V15C9.50008 14.5398 9.87318 14.1667 10.3334 14.1667Z'
        fill='#535353'
      />
    </svg>
  );
};

export default IconStatusPending;
