import React, { useCallback } from 'react';

import DropdownMenu from '../packages/components/dropdown-menu';
import { SecondaryButton } from '../packages';
import IconMoreVertical from '../packages/icons/IconMoreVertical';
import Permission from '../packages/components/permissions';

export const UsersMainMenu = (props) => {
  const { children, onOpenAddToTeam, onOpenDelete, onOpenBlock, onOpenResend } = props;
  return (
    <Permission resource="company::users">
      {(actions) => (
        <DropdownMenu
          data={[
            // { label: 'Add to teams', onClick: onOpenAddToTeam },
            // { label: 'Re-send invite', onClick: onOpenResend },
            {
              label: 'Block user',
              onClick: onOpenBlock,
              hidden: (
                !(actions.company['block']?.allowed || actions.team['block']?.allowed)
              ),
            },
            // { label: 'Delete user', onClick: onOpenDelete },
          ]}
        >
          {children}
        </DropdownMenu>
      )}
    </Permission>
  );
};
