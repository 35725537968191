import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Image,
  ImageBox,
  InfoBox,
  Label,
  WelcomeLabel,
  Wrapper,
} from '../styled';
import assign from '../../images/assign.svg';

import { Button, history } from '../../../../packages';

import {
  OnboardingSteps,
  appActions,
  appSelectors,
  customerDetailsSelectors,
  standardActions,
  standardSelectors,
  standardService,
} from '../../../../entities';

import { AssignStandardsContainer } from '../../../accounts/components/assign-standards';

import { FeaturePrefix, PathNames } from '../../../../consts';

import { ButtonWrapper } from './styled';

export const AssignStandardStep = memo(() => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const currentCustomer = useSelector(appSelectors.getCustomer);
  const { accounts } = useSelector(
    customerDetailsSelectors.getCustomerDetailsData
  );
  const assignStandards = useSelector(standardSelectors.getAssignStandards);
  const isPipelineOnboarding = useSelector(
    appSelectors.getIsPipelineOnboarding
  );

  const currentAccount = useMemo(() => accounts[accounts.length - 1], []);

  const onContinueClick = useCallback(() => {
    dispatch(
      standardService.saveAssignStandards({
        accountId: currentAccount.id,
        id: currentCustomer.id,
        regs: selectedItems,
      })
    );
  }, [selectedItems, currentCustomer, currentAccount]);

  useEffect(() => {
    const isSavedStandards = assignStandards.find(
      (standard) => standard.active
    );
    const query = new URLSearchParams(window.location.search);
    const source = query.get('source');

    if (source === 'github') {
      if (isPipelineOnboarding) {
        // TODO maybe should change here link
        history.push({
          pathname: PathNames.customerResults
            .replace(':feature', FeaturePrefix.PipelineSecurity)
            .replace(':id', currentCustomer.id),
        });
      }
    }

    if (isSavedStandards) {
      dispatch(standardActions.setAssignStandards([]));

      if (isPipelineOnboarding) {
        // TODO maybe should change here link
        history.push({
          pathname: PathNames.customerResults
            .replace(':feature', FeaturePrefix.PlatformSecurity)
            .replace(':id', currentCustomer.id),
        });
      } else {
        dispatch(appActions.setOnboardingStep(OnboardingSteps.readyToScanning));
      }
    }
  }, [assignStandards]);

  return (
    <Wrapper>
      <InfoBox>
        <WelcomeLabel>Assign Standards to your account</WelcomeLabel>
        <Label>
          Specify the list of Standards, compliance of which you want to monitor
          during the scans. You will be able to edit assigned standards list
          later.
        </Label>
        {currentAccount ? (
          <AssignStandardsContainer
            customerId={currentCustomer.id}
            accountId={currentAccount.id}
            onItemSelect={setSelectedItems}
            isPipelineOnboarding={isPipelineOnboarding}
          />
        ) : null}
        <ButtonWrapper>
          <Button variant="contained" onClick={onContinueClick}>
            Continue
          </Button>
        </ButtonWrapper>
      </InfoBox>
      <ImageBox>
        <Image src={assign} />
      </ImageBox>
    </Wrapper>
  );
});
