import React from 'react';

import { ManuallyIcon, RecognizedIcon, StatusWrapper, UnrecognizedIcon } from './styled';

export const ComponentsStatus = {
  RECOGNIZED: 'recognized',
  MANUAL: 'manual',
  UNRECOGNIZED: 'unrecognized',
};

export const ComponentStatus = ({ value, status_verbose }) => (
  <StatusWrapper status={value}>
    {value === 'recognized' && <RecognizedIcon />}
    {value === 'manual' && <ManuallyIcon />}
    {value === 'unrecognized' && <UnrecognizedIcon />}
    {status_verbose}
  </StatusWrapper>
);
