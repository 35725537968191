import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import serverStatusUrl from '../onboarding/images/server-status.svg';

import { authService } from '../../entities';

import { RegistrationLayout } from '../../layouts';

import { ConfirmEmailForm } from '../forms/confirm-email-form';
import { history } from '../../packages';
import { PathNames } from '../../consts';
import branding from '../../branding';

import {
  Image,
  ImageBox,
  InfoBox,
  Label,
  WelcomeLabel,
  Wrapper,
} from './styled';

export const Verification = memo(() => {
  const query = new URLSearchParams(window.location.search);
  const registrationEmail = query.get('email');

  const dispatch = useDispatch();

  const onConfirmAction = useCallback(() => {
    history.push({ pathname: `${PathNames.chooseFeatures}` });
  }, [dispatch]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(authService.confirmCode(values, onConfirmAction));
    },
    [dispatch, onConfirmAction]
  );

  return (
    <RegistrationLayout>
      <Wrapper>
        <InfoBox>
          <WelcomeLabel>Verify your email</WelcomeLabel>
          <Label>
            Before you proceed to your {branding.name} account, you need to verify your
            email
            <strong>
              {registrationEmail ? `(${registrationEmail}). ` : '. '}
            </strong>
            Please, check your Inbox for our letter with 6-digits verification
            code. Copy and paste the code to the field below and hit Continue
            button. Can't find our letter? Try checking Trash or re-sendnew
            verification code. Please, check your Inbox for our letter with
            6-digits verification code. Copy and paste the code to the field
            below and hit Continue button. Can't find our letter? Try checking
            Trash or re-send new verification code.
          </Label>
          <ConfirmEmailForm onSubmitHandler={onSubmit} />
        </InfoBox>
        <ImageBox>
          <Image src={serverStatusUrl} />
        </ImageBox>
      </Wrapper>
    </RegistrationLayout>
  );
});
