import React, { useCallback, useMemo, useState } from 'react';

import { Button, Form, SecondaryButton, TextField } from '../../../../packages';

import { ButtonsWrapper, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../../packages/utils/parse';

const AddMemberForm = (props) => {
  const { teamId, onCancel = () => {}, onSuccess = () => {} } = props;
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      emails: '',
    }),
    []
  );

  const validationSchema = defaultValidationSchema;

  const onSubmit = useCallback(
    (values, helpers) => {
      const { emails } = values;

      setLoading(true);

      customerDetailsApi
        .teamAddUsers(teamId, { emails: emails.split(',').map((i) => i.trim()) })
        .then(() => {
          NotificationManager.success('Users has been added successfully.');
          onSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          const dataErr = parseError(err);

          if (dataErr?.emails?.[0]) {
            helpers.setFieldError('emails', dataErr?.emails?.[0]);
          } else {
            NotificationManager.error('Server error.');
          }
        });
    },
    [teamId, onSuccess]
  );

  return (
    <ProfileFormWrapper>
      <Form validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} isLoading={loading}>
        <TextField
          name='emails'
          label='Members email'
          placeholder='Members emails, comma separated'
          multiline
          rows={3}
        />
        <ButtonsWrapper>
          <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
            {'Cancel'}
          </SecondaryButton>
          <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
            {'Add'}
          </Button>
        </ButtonsWrapper>
      </Form>
    </ProfileFormWrapper>
  );
};

export default AddMemberForm;
