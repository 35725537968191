import React, { Fragment, memo } from 'react';

import { ProgressLine, ProgressLineWrapper, ResultLineWrapper } from './styled';
import { getLinePath } from './utils';

export const ResultLines = memo(({ data, overallPercent }) => (
  <ResultLineWrapper>
    {data.map(({ percent, radius, title }) => (
      <Fragment key={title}>
        <ProgressLineWrapper d={getLinePath(100, radius)} />
        <ProgressLine d={getLinePath(percent, radius)} />
        <text x={0} y={(radius + 20) * 2 - 5 + (100 - radius)}>
          {title}
        </text>
      </Fragment>
    ))}
    <text x={80} y={120}>
      {`${overallPercent}%`}
    </text>
    <text x={80} y={140}>
      Overall
    </text>
  </ResultLineWrapper>
));
