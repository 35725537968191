import { wretch, wretchppl } from '../app';
import { downloadFile } from '../../packages';

const getPolicies = ({
  accountId,
  standardId,
  policyGroupId,
  scanId = 'current',
  query,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/pols/`
  )
    .query(query)
    .get()
    .json();

const getPoliciesPP = ({ ppToolToken, standardId, policyGroupId, query }) =>
  wretchppl(
    `results/${ppToolToken}/regs/${standardId}/grps/${policyGroupId}/pols/`
  )
    .query(query)
    .get()
    .json();

const getPoliciesById = ({
  accountId,
  standardId,
  policyGroupId,
  scanId = 'current',
  policyId,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/pols/${policyId}/`
  )
    .get()
    .json();

const getPolicyGroupChartModel = ({
  accountId,
  standardId,
  scanId = 'current',
  policyGroupId,
  query,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/pols/chart/`
  )
    .query(query)
    .get()
    .json();

const getPolicyGroupChartModelPP = ({
  ppToolToken,
  standardId,
  policyGroupId,
  query,
}) =>
  wretchppl(
    `results/${ppToolToken}/regs/${standardId}/grps/${policyGroupId}/pols/chart/`
  )
    .query(query)
    .get()
    .json();

const getPolicyGroupStats = ({
  accountId,
  scanId = 'current',
  standardId,
  policyGroupId,
}) =>
  wretch(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/stat/`
  )
    .get()
    .json();

const getPolicyGroupStatsPP = ({ ppToolToken, standardId, policyGroupId }) =>
  wretchppl(
    `results/${ppToolToken}/regs/${standardId}/grps/${policyGroupId}/stat/`
  )
    .get()
    .json();

const exportStandardScanHistory = ({
  accountId,
  scanId = 'current',
  standardId,
  query,
}) =>
  downloadFile(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/export/`,
    query
  );

const exportPolicyGroupScanHistory = ({
  accountId,
  scanId = 'current',
  standardId,
  policyGroupId,
  query,
}) =>
  downloadFile(
    `results/account/${accountId}/${scanId}/regs/${standardId}/grps/${policyGroupId}/pols/export/`,
    query
  );

export const policyGroupApi = {
  getPolicies,
  getPoliciesPP,
  getPoliciesById,
  getPolicyGroupStats,
  getPolicyGroupStatsPP,
  getPolicyGroupChartModel,
  getPolicyGroupChartModelPP,
  exportStandardScanHistory,
  exportPolicyGroupScanHistory,
};
