import styled from 'styled-components';

import { lmd, md, sm } from '../../theme';
import branding from '../../branding';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  justify-content: center;
  word-break: break-word;

  span {
    font-weight: 700;
    margin-left: 7px;
    margin-right: 7px;
  }
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  margin-top: 8px;
`;

export const CardsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;

  svg {
    color: ${branding.colors.primary};
  }

  @media screen and (max-width: ${lmd}) {
    margin-right: 0px;
    margin-left: auto;

    a {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: ${md}) {
    flex-wrap: wrap;

    a {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: ${sm}) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    a {
      margin-right: 0px;
    }
  }
`;
