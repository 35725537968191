export const customerModalName = 'customer';

export const customersHeader = [
  {
    id: 'enable',
    numeric: false,
    disablePadding: true,
    sort: 'enable',
    label: 'Enabled',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    sort: 'name',
    label: 'Customer’s name',
    align: 'left',
  },

  {
    id: 'lastScan',
    numeric: false,
    sort: 'last_scan',
    disablePadding: false,
    label: 'Last scan',
    align: 'center',
  },
  {
    id: 'compliance',
    numeric: false,
    disablePadding: false,
    label: 'Compliance %',
    sort: 'compliance',
    align: 'center',
  },
  {
    id: 'policiesViolated',
    numeric: true,
    disablePadding: false,
    label: 'Policies violated',
    sort: 'policies_bad',
    align: 'center',
  },
  {
    id: 'startNewScan',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: '',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: '',
  },
];
