import React from 'react';
import branding from '../../branding';

const ProfileContentLayout = (props) => {
  const { title = 'Page_title', headerComponent, children, styleContent = {} } = props;

  return (
    <div style={{}}>
      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
        <div
          style={{
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '34px',
            lineHeight: '40px',
            color: branding.colors.primary,
          }}
        >
          {title}
        </div>
        <div>{headerComponent}</div>
      </div>
      <div style={{ padding: '64px 0', marginTop: 16, backgroundColor: 'white', ...styleContent }}>{children}</div>
    </div>
  );
};

export default ProfileContentLayout;
