import React from 'react';
import { SecondaryButton } from '../../../../packages';
import IconDownload from '../../../../packages/icons/IconDownload';

const ButtonExportPdf = ({ onClick }) => {

  return (
    <div style={{ marginRight: 10 }}>
      <SecondaryButton onClick={onClick}>
        <span>Export PDF</span>
        <IconDownload style={{ marginLeft: 16 }} />
      </SecondaryButton>
    </div>
  );
};

export default ButtonExportPdf;
