import { useLocation, useParams } from 'react-router';

import {FeaturePrefix, PathNames} from '../../consts';
import {getCurrentFeaturePrefix} from './getCurrentFeaturePrefix';

export const usePolicyGroupPage = () => {
  const { pathname } = useLocation();
  const { id, accountId, policyGroupId, standardId, scanId } = useParams();

  return (
    pathname ===
    PathNames.policyGroupOverview
      .replace(':feature', getCurrentFeaturePrefix())
      .replace(':id', id)
      .replace(':scanId', scanId)
      .replace(':accountId', accountId)
      .replace(':standardId', standardId)
      .replace(':policyGroupId', policyGroupId)
  );
};
