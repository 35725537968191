import React, { memo, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import { Button } from '@mui/material';

import {
  OverallCompliance,
  OverallComplianceTrend,
  PolicyViolations,
  Standards,
  getCurrentFeaturePrefix,
  usePagination,
} from '../../../../packages';

import {
  customerDetailsSelectors,
  standardSelectors,
  standardService,
} from '../../../../entities';

import { CustomerStat } from '../customer-stat';

import {
  FistLine,
  FourthLine,
  OverallComplianceWrapper,
  OverallScanResultsWrapper,
  SecondLine,
  ThirdLine,
} from './styled';
import Permission from '../../../../packages/components/permissions';
import {FeaturePrefix} from '../../../../consts';

export const OverallScanResults = memo(() => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { customerStandards } = useSelector(standardSelectors.getStandardData);
  const { count: standardsCount } = usePagination('customer-account-overview');

  const {
    awsCompliancePercent,
    azureCompliancePercent,
    azureDevCompliancePercent,
    githubDevCompliancePercent,
    overallCompliancePercent,
  } = useSelector(customerDetailsSelectors.getTotalCompliancePercent);
  const violationStats = useSelector(
    customerDetailsSelectors.getViolatedPolicesStats
  );

  const chartData = useSelector(customerDetailsSelectors.getDataForTrendsChart);

  const formattedCustomerStandards = useMemo(() =>
    customerStandards.map(
      (standard) => ({
        onClick: () => {},
        name: standard.name,
        description: standard.descr,
        percent: standard.compliance,
        isCustom: standard.is_custom,
      }),
      [customerStandards]
    )
  );
  const currentFeaturePrefix = getCurrentFeaturePrefix();
  const fetchNextPage = useCallback(() => {
    const { page, count } = usePagination('customer-standards');
    const isNextPage = count ? count / 25 > page + 1 : false;

    if (isNextPage) {
      dispatch(
        standardService.getCustomerStandards({
          customerId: id,
          query: {
            page: page + 1,
          },
        })
      );
    }
  }, [id]);

  return (

    <OverallScanResultsWrapper>
      <Permission resource="platform-security::accounts::statistics">
        {(platformActions) => (
          <Permission resource="pipeline-security::accounts::statistics">
            {(pipelineActions) => (
              (
                platformActions.company?.['view']?.allowed && currentFeaturePrefix === FeaturePrefix.PlatformSecurity ||
                pipelineActions.company?.['view']?.allowed && currentFeaturePrefix === FeaturePrefix.PipelineSecurity
              ) ?
                <div>
                  <FistLine>
                    <OverallComplianceWrapper>
                      <OverallCompliance
                        awsCompliancePercent={awsCompliancePercent}
                        azureCompliancePercent={azureCompliancePercent}
                        featureName={currentFeaturePrefix}
                        azureDevCompliancePercent={azureDevCompliancePercent}
                        githubDevCompliancePercent={githubDevCompliancePercent}
                        overallCompliancePercent={overallCompliancePercent}
                      />
                    </OverallComplianceWrapper>
                    <PolicyViolations
                      violationStats={violationStats}
                      featureName={currentFeaturePrefix}
                    />
                  </FistLine>
                  <SecondLine>
                    <CustomerStat/>
                  </SecondLine>
                </div>
              : null
            )}
          </Permission>
        )}
      </Permission>
      <ThirdLine>
        <OverallComplianceTrend chartData={chartData}/>
      </ThirdLine>
      {formattedCustomerStandards.length ? (
        <FourthLine>
          <Standards items={formattedCustomerStandards}/>
          {customerStandards.length !== standardsCount && (
            <Button onClick={fetchNextPage}>Show more</Button>
          )}
        </FourthLine>
      ) : null}
    </OverallScanResultsWrapper>
  );
});
