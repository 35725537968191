import { createSlice } from '@reduxjs/toolkit';

import { FeatureSlugs } from '../../consts';

import { OnboardingSteps } from './consts';

const FEATURES_SORT_ORDER = [
  FeatureSlugs.PipelineSecurity,
  FeatureSlugs.ThreatModelling,
  FeatureSlugs.PlatformSecurity,
];

export const appInitialState = {
  isReady: false,
  isLayoutReady: false,
  isOnboardingReady: false,
  isReadyDataByParams: false,
  isSidebarOpen: false,
  user: {},
  profile: {},
  menuItems: [],
  menuAccounts: [],
  menuAccountsCount: 0,
  onboardingStep: OnboardingSteps.info,
  isPolicyAgree: false,
  userRole: '',
  selectedMenuItemId: null,
  selectedCustomer: null,
  selectedAccount: null,
  pipelineData: null,
  activeTab: null,
  expandedService: null,
  selectedCustomerId: null,
  selectedAccountId: null,
  selectedPolicyId: null,
  isMenuItemsLoading: false,
  isMenuItemsSearching: false,
  lastCreatedAccount: false,
  selected: null,
};

const STORE_NAME = '@app';

export const appStore = createSlice({
  name: STORE_NAME,
  initialState: appInitialState,
  reducers: {
    setIsReady(state, action) {
      state.isReady = action.payload;
    },
    setIsLayoutReady(state, action) {
      state.isLayoutReady = action.payload;
    },
    setIsOnboardingReady(state, action) {
      state.isOnboardingReady = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setMenuItems: (state, action) => {
      state.menuItems = action.payload;
    },
    setPipelineData: (state, action) => {
      state.pipelineData = action.payload;
      if (action.payload && action.payload.profile) {
        state.profile = action.payload.profile;
      }
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    setExpandedService: (state, action) => {
      state.expandedService = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setMenuAccounts: (state, action) => {
      state.menuAccounts = action.payload;
    },
    setMenuAccountsCount: (state, action) => {
      state.menuAccountsCount = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedMenuItemId(state, action) {
      state.selectedMenuItemId = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setSelectedCustomerId(state, action) {
      state.selectedCustomerId = action.payload;
    },
    setIsPolicyAgree(state, action) {
      state.isPolicyAgree = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setOnboardingStep: (state, action) => {
      state.onboardingStep = action.payload;
    },
    setIsReadyDataByParams(state, action) {
      state.isReadyDataByParams = action.payload;
    },
    setIsMenuItemsLoading: (state, action) => {
      state.isMenuItemsLoading = action.payload;
    },
    setIsMenuItemsSearching: (state, action) => {
      state.isMenuItemsSearching = action.payload;
    },
    setSelectedAccountId(state, action) {
      state.selectedAccountId = action.payload;
    },
    setSelectedPolicyId(state, action) {
      state.selectedPolicyId = action.payload;
    },
    setSelectedStandardId(state, action) {
      state.selectedStandardId = action.payload;
    },
    setLastCreatedAccount(state, action) {
      state.lastCreatedAccount = action.payload;
    },
    setFeatures(state, action) {
      const ordering = {};
      for (let i = 0; i < FEATURES_SORT_ORDER.length; i++)
        ordering[FEATURES_SORT_ORDER[i]] = i;

      const { payload } = action;
      payload.sort(
        (a, b) =>
          ordering[a.id] - ordering[b.id] || a.name.localeCompare(b.name)
      );

      state.features = payload;
    },
    resetState: () => appInitialState,
  },
});

export const appReducer = appStore.reducer;
export const appActions = appStore.actions;
