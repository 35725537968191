import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Search,
  Table,
  setPagination,
  setQuery,
  usePagination,
} from '../../packages';

import {
  appSelectors,
  customerActions,
  customerSelectors,
  customerService,
} from '../../entities';

import { PageLayout } from '../../layouts';

import { colors } from '../../theme';

import { customersHeader } from './consts';

import {
  ButtonsWrapper,
  CustomersCard,
  CustomersHeader,
  CustomersPageWrapper,
  NoCustomers,
} from './styled';
import {
  AddCustomerButton,
  ScanSelectedCustomersButton,
  StartScanAllModal,
  StartScanModal,
} from './components';
import { formatCustomersData } from './utils';

export const CustomersPage = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const { user } = useSelector(appSelectors.getAppData);

  const { customers, isCustomersByPageLoading } = useSelector(
    customerSelectors.getCustomerData
  );
  const tableData = useMemo(() => formatCustomersData(customers), [customers]);

  const onChangeHandler = useCallback(
    (query) => {
      dispatch(
        customerService.getCustomers({
          ...query,
          search: searchValue,
        })
      );
    },
    [searchValue]
  );

  const onSearchHandler = useCallback(
    (value) => {
      setSearchValue(value);
      dispatch(customerService.getCustomers({ search: value }));
    },
    [setSearchValue]
  );

  const onCustomersSelect = useCallback((value) => {
    dispatch(customerActions.setSelectedCustomerIds(value));
  }, []);

  useEffect(() => {
    dispatch(customerService.getCustomers({ ordering: 'name' }));

    return () => {
      const { count } = usePagination('customers');

      setPagination('customers', { count, page: 0 });
      setQuery('customers', {});
    };
  }, []);

  return (
    <PageLayout>
      <CustomersPageWrapper>
        <CustomersHeader>
          <Search
            onSearch={onSearchHandler}
            styles={{
              color: colors.textColor,
            }}
            wrapperStyles={{
              width: 'auto',
              borderColor: colors.textColor,
            }}
          />
          <ButtonsWrapper>
            {user.is_superuser && <AddCustomerButton />}
            <ScanSelectedCustomersButton />
          </ButtonsWrapper>
        </CustomersHeader>
        <CustomersCard>
          {tableData.length ? (
            <Table
              isLoading={isCustomersByPageLoading}
              name="customers"
              rows={tableData}
              headCells={customersHeader}
              onSelect={onCustomersSelect}
              onPageChange={onChangeHandler}
              onSort={onChangeHandler}
            />
          ) : (
            <NoCustomers>No customers found</NoCustomers>
          )}
        </CustomersCard>
      </CustomersPageWrapper>
      <StartScanModal />
      <StartScanAllModal />
    </PageLayout>
  );
};
