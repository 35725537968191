import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material/styles';

import { Button } from '@mui/material';

import { Select, TextField } from '../../../../packages';
import { sm } from '../../../../theme';

export const ProfileFormWrapper = styled.div`
  padding: 0 25%;

  @media screen and (max-width: ${sm}) {
    padding: 0 5%;
  }
`;

export const ServiceInfo = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #535353;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const InputField = muiStyled(TextField)(() => ({
  marginBottom: 12,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const SelectField = styled(Select)``;

export const OAuthButton = muiStyled(Button)();

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
