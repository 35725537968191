import React, { memo, useCallback, useEffect } from 'react';

import { Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  SecondaryButton,
  modalActions,
  modalSelectors,
} from '../../../../packages';

import { threatModelingApi, threatModelingService } from '../../../../entities';

import { ButtonsWrapper, StartScanContent, Title } from './styled';
import { deleteComponentsModal } from './consts';

export const DeleteComponentsModal = memo(() => {
  const dispatch = useDispatch();
  const componentsForDelete = useSelector((state) =>
    modalSelectors.getModalData(state, deleteComponentsModal)
  );

  const { ids = [], diagramId, node_id, onUpdate = () => {} } = componentsForDelete || {};

  const onContinueClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(threatModelingService.deleteComponents({ ids })).then(async () => {
        if (node_id) {
          const manual = await threatModelingApi.getManualDiagram(diagramId).catch((err) => {
            console.log('threatModelingApi.getManualDiagram', diagramId, err);
          });

          if (manual) {
            manual.manual_data.nodes = manual.manual_data.nodes.filter((n) => n.id !== node_id);
            manual.manual_data.edges = manual.manual_data.edges.filter(
              (n) => n.source !== node_id && n.target !== node_id
            );

            const result = await threatModelingApi
              .updateManualDiagram(diagramId, { manual_data: manual.manual_data })
              .catch((err) => {
                console.log('threatModelingApi.updateManualDiagram()', diagramId, err);
              });
          }
        }

        onUpdate();
      });
      dispatch(
        modalActions.setModalIsOpen({
          name: deleteComponentsModal,
          isOpen: false,
        })
      );
    },
    [ids, diagramId, node_id, onUpdate]
  );

  const onCancelClick = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      modalActions.setModalIsOpen({
        name: deleteComponentsModal,
        isOpen: false,
      })
    );
  }, []);

  useEffect(
    () => () => {
      dispatch(
        modalActions.setModalData({
          name: deleteComponentsModal,
          data: null,
        })
      );
    },
    []
  );

  return (
    <Modal name={deleteComponentsModal} header="Delete components">
      <StartScanContent>
        <Title>
          {`You selected ${ids?.length} ${
            ids?.length > 1 ? 'components' : 'component'
          }. By continuing you are deleting selected ${
            ids?.length > 1 ? 'components' : 'component'
          }.`}
        </Title>
        <ButtonsWrapper component="button">
          <Button size="medium" onClick={onCancelClick}>
            Cancel
          </Button>
          <SecondaryButton size="medium" onClick={onContinueClickHandler}>
            Delete
          </SecondaryButton>
        </ButtonsWrapper>
      </StartScanContent>
    </Modal>
  );
});
