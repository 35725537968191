import React, { memo } from 'react';

import {
  AddButton,
  AddIcon,
  Counter,
  CounterLabel,
  CounterWrapper,
  ListHeaderWrapper,
} from './styled';

export const ListHeader = memo(
  ({
    count,
    labelLink,
    onAddButtonClick,
    label,
    hideAddButton = false,
    onLinkClick,
  }) => (
    <ListHeaderWrapper>
      <CounterWrapper>
        <CounterLabel to={labelLink} onClick={onLinkClick}>
          {label}
        </CounterLabel>
        <Counter>{count}</Counter>
      </CounterWrapper>
      {hideAddButton ? null : (
        <AddButton onClick={onAddButtonClick}>
          <AddIcon />
        </AddButton>
      )}
    </ListHeaderWrapper>
  )
);
