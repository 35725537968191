import React, { memo } from 'react';

import { HeaderWrapper, StepStatus } from './styled';
import { Logo } from './Logo';

export const StepsHeader = memo(({ step, stepsCount }) => (
  <HeaderWrapper>
    <Logo />
    <StepStatus>{`Step ${step} of ${stepsCount}`}</StepStatus>
  </HeaderWrapper>
));
