import React, { useCallback } from 'react';

import { Button, SecondaryButton } from '../../../../packages';

import { ButtonsWrapper, ProfileFormWrapper } from './styled';
import { customerDetailsApi } from '../../../../entities';
import { NotificationManager } from 'react-notifications';

const RemoveMemberForm = (props) => {
  const { team, emails = [], onCancel = () => {}, onSuccess = () => {} } = props;

  const onDelete = useCallback(() => {
    customerDetailsApi
      .removeTeamMember(team.id, emails)
      .then(() => {
        NotificationManager.success('Member successfully deleted.');
        onSuccess(true);
      })
      .catch(() => {
        NotificationManager.error('Server error.');
        onCancel();
      });
  }, [team, emails, onSuccess]);

  return (
    <ProfileFormWrapper>
      <div style={{ marginBottom: 24, fontSize: 14, fontFamily: 'Open Sans' }}>
        Do you really want to Remove a member{' '}
        <span style={{ fontWeight: 900, color: '#000000' }}>“{emails.join(', ')}”</span> from the{' '}
        <span style={{ fontWeight: 900, color: '#000000' }}>“{team.name}”</span> team?
      </div>
      <ButtonsWrapper>
        <SecondaryButton size='medium' type='button' style={{ width: 140, height: 40 }} onClick={onCancel}>
          {'Cancel'}
        </SecondaryButton>
        <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }} onClick={onDelete}>
          {'Yes'}
        </Button>
      </ButtonsWrapper>
    </ProfileFormWrapper>
  );
};

export default RemoveMemberForm;
