import React from 'react';

const DefaultAvatar = (props) => {
  const { size = 32 } = props;

  return (
    <svg width={size} height={size} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.8281 23.8625C26.2386 22.4662 25.3831 21.1978 24.3093 20.1281C23.2388 19.0553 21.9707 18.2 20.575 17.6094C20.5625 17.6031 20.55 17.6 20.5375 17.5938C22.4843 16.1875 23.75 13.8969 23.75 11.3125C23.75 7.03125 20.2812 3.5625 16 3.5625C11.7187 3.5625 8.24996 7.03125 8.24996 11.3125C8.24996 13.8969 9.51558 16.1875 11.4625 17.5969C11.45 17.6031 11.4375 17.6062 11.425 17.6125C10.025 18.2031 8.76871 19.05 7.69058 20.1313C6.6178 21.2018 5.76247 22.4699 5.17183 23.8656C4.59159 25.232 4.27865 26.697 4.24996 28.1812C4.24912 28.2146 4.25497 28.2478 4.26716 28.2788C4.27935 28.3099 4.29763 28.3382 4.32093 28.3621C4.34423 28.386 4.37207 28.4049 4.40282 28.4179C4.43357 28.4308 4.46659 28.4375 4.49996 28.4375H6.37496C6.51246 28.4375 6.62183 28.3281 6.62496 28.1938C6.68746 25.7812 7.65621 23.5219 9.36871 21.8094C11.1406 20.0375 13.4937 19.0625 16 19.0625C18.5062 19.0625 20.8593 20.0375 22.6312 21.8094C24.3437 23.5219 25.3125 25.7812 25.375 28.1938C25.3781 28.3313 25.4875 28.4375 25.625 28.4375H27.5C27.5333 28.4375 27.5663 28.4308 27.5971 28.4179C27.6278 28.4049 27.6557 28.386 27.679 28.3621C27.7023 28.3382 27.7206 28.3099 27.7327 28.2788C27.7449 28.2478 27.7508 28.2146 27.75 28.1812C27.7187 26.6875 27.4093 25.2344 26.8281 23.8625ZM16 16.6875C14.5656 16.6875 13.2156 16.1281 12.2 15.1125C11.1843 14.0969 10.625 12.7469 10.625 11.3125C10.625 9.87813 11.1843 8.52812 12.2 7.5125C13.2156 6.49687 14.5656 5.9375 16 5.9375C17.4343 5.9375 18.7843 6.49687 19.8 7.5125C20.8156 8.52812 21.375 9.87813 21.375 11.3125C21.375 12.7469 20.8156 14.0969 19.8 15.1125C18.7843 16.1281 17.4343 16.6875 16 16.6875Z'
        fill='white'
      />
    </svg>
  );
};

export default DefaultAvatar;
