import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Tooltip } from '@mui/material';

import { threatModelingSelectors } from '../../../../entities';

import {
  CurrentZoomValue,
  HeaderText,
  ComponentBorder,
  ComponentNumber,
  HeaderWrapper,
  Image,
  ToolsWrapper,
  ZoomButton,
} from './styled';
import DiagramEditor, { EDITOR_MODE } from '../../../../packages/components/diagram-editor';
import { Loader } from '../../../../packages';

export const DiagramView = memo(({ manual, onElementSettings, loading }) => {
  const { currentDiagram, components } = useSelector(
    threatModelingSelectors.getThreatModelingData
  );
  const imageRef = useRef(null);
  const isManual = currentDiagram?.is_manual;

  const highlights = components?.map((component) => ({
    id: component.number,
    status: component.status,
    ...component.position,
  }));

  const calculateCoordinates = useCallback(
    (position) => {
      if (imageRef?.current && position) {
        const clientWidth = imageRef?.current.clientWidth;
        const clientHeight = imageRef?.current.clientHeight;
        const naturalWidth = imageRef?.current.naturalWidth;
        const naturalHeight = imageRef?.current.naturalHeight;

        return {
          ...position,
          x: (position.x * clientWidth) / naturalWidth - 2,
          y: (position.y * clientHeight) / naturalHeight - 2,
          width: (position.width * clientWidth) / naturalWidth + 4,
          height: (position.height * clientHeight) / naturalHeight + 4,
        };
      }

      return {};
    },
    [imageRef]
  );

  const transformComponentRef = useRef(null);

  useEffect(() => {
    window.transformComponentRef = transformComponentRef.current;
  }, [transformComponentRef.current]);

  const MAX_SCALE = 9;
  const MIN_SCALE = 1;

  if (!currentDiagram) {
    return <span>...</span>;
  }

  if (isManual) {
    return manual && currentDiagram?.id === manual?.id && !loading && manual?.manual_data ? (
      <DiagramEditor
        mode={EDITOR_MODE.PREVIEW}
        width='100%'
        height='100%'
        initial={manual?.manual_data}
        onElementSettings={onElementSettings}
      />
    ) : (
      <div style={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: "center"}}>
        <Loader />
      </div>
    );
  }

  return (
    <TransformWrapper
      maxScale={MAX_SCALE}
      minScale={MIN_SCALE}
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      ref={transformComponentRef}
    >
      {({ zoomIn, zoomOut, state }) => (
        <>
          <HeaderWrapper>
            <Tooltip title={currentDiagram?.filename} arrow>
              <HeaderText>{`Source: ${currentDiagram?.filename}`}</HeaderText>
            </Tooltip>
          </HeaderWrapper>
          <TransformComponent
            wrapperStyle={{ width: '100%', height: 'calc(100% - 120px)' }}
          >
            {highlights?.map((position) => {
              const { id, x, y, width, height, status } =
                calculateCoordinates(position);

              if (!id || !width || !height) return;

              return (
                <ComponentBorder
                  status={status}
                  key={`${id}-element-highlight`}
                  id={`${id}-element-highlight`}
                  {...{ x, y, width, height }}
                >
                  <ComponentNumber status={status}>{id}</ComponentNumber>
                </ComponentBorder>
              );
            })}
            <Image
              ref={imageRef}
              src={currentDiagram?.preview}
              alt="diagram-view"
              id="imgggg"
            />
          </TransformComponent>
          <ToolsWrapper>
            <ZoomButton onClick={() => zoomOut()}>-</ZoomButton>
            <CurrentZoomValue>
              {state.scale <= 1
                ? 100
                : parseInt(100 + (state.scale * 100) / MAX_SCALE)}
              %
            </CurrentZoomValue>
            <ZoomButton onClick={() => zoomIn()}>+</ZoomButton>
          </ToolsWrapper>
        </>
      )}
    </TransformWrapper>
  );
});
