import React from "react";
import { HelpMessage } from "../../../../packages";
import { colors } from "../../../../theme";

export const PolicyName = (props) => {
  const { value, descr } = props;

  return (
    <div>
      <span>{value}</span>
      &nbsp;
      {descr && <HelpMessage color={colors.activeBackground} title={descr} iconStyle={{ width: 20 }} />}
    </div>
  );
};
