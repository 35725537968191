import styled from 'styled-components';

import { scrollable } from '../../../theme';

export const OverallStandardWrapper = styled.div`
  background: ${({ theme }) => theme.colors.activeBackground};
  padding: 40px 10px;
  width: 250px;
  max-height: 700px;
  position: relative;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  padding: 20px 10px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const NameWrapper = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 0 10px;
  max-height: 540px;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.background};
  ${scrollable}
`;

export const StandardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const PercentWrapper = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;
