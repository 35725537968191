import * as yup from 'yup';

const defaultSchema = {
  service: yup
    .string('Select service name')
    .required('Service name is required'),
  name: yup.string('Enter account name').required('Name is required'),
};

export const defaultValidationSchema = yup.object(defaultSchema);

export const generateAccountValidationSchema = (fields) =>
  yup.object(
    fields.reduce(
      (schema, field) => ({
        ...schema,
        [field.name]: yup.string().required(`${field.label} is required`),
      }),
      defaultSchema
    )
  );
