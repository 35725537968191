import React, { memo } from 'react';

import { HelpMessage } from '../help-message';

import { colors } from '../../../theme';

import { CardWrapper, Content, Header } from './styled';

export const Card = memo(
  ({ title, children, style, header, helpMessage, headerStyle, disabled }) => (
    <CardWrapper style={style} disabled={disabled}>
      {header || (
        <Header style={headerStyle}>
          {title}
          {helpMessage && (
            <HelpMessage title={helpMessage} color={colors.darkBorderColor} />
          )}
        </Header>
      )}
      <Content>{children}</Content>
    </CardWrapper>
  )
);
