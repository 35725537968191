const isBank = false;

const brandingAirbot = {
  isBank,
  platform: 'Aristiun',
  name: 'Aribot',
  poweredBy: 'Ayurak AI',
  email: 'info@aristiun.com',
  to: 'Aristiun B.V., Amsterdam',
  colors: {
    logoBackground: '#2F6CF6',
    primary: '#2170F6',
    lightBlue: '#EBF2FE',
    blue: '#2F6CF6',
    mediumBlue: '#CFD9FA',
    deepBlue: '#052BA0',
    loader: '#1117a2',
  },
};

const brandingBank = {
  isBank,
  platform: 'The Bank',
  name: 'Dashboard',
  poweredBy: 'The Bank',
  // TODO
  email: 'info@aristiun.com',
  // TODO
  to: 'Aristiun B.V., Amsterdam',
  colors: {
    logoBackground: 'transparent',
    primary: '#009286',
    lightBlue: '#F2FEF2',
    blue: '#009286',
    mediumBlue: '#D9FAD9',
    deepBlue: '#004c4c',
    loader: '#009286',
  },
};

const branding = isBank ? brandingBank : brandingAirbot;

export default branding;
