import React from "react";

const IconTriangle = (props) => {
  const { backgroundColor } = props;

  return (
    <svg
      width="56"
      height="48"
      strokeWidth="1"
      stroke="currentColor"
      viewBox="0 0 56 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path d="M28 0L55.7128 48H0.287188L28 0Z" strokeWidth={1} fill={backgroundColor} />
    </svg>
  );
};

export default IconTriangle;
