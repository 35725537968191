import { wretch, wretchInstallationAPI } from '../app';

const signIn = (requestModel) =>
  wretch('auth/login/').post(requestModel).json();

const applyOAuthCode = (requestCode) =>
  wretch('auth/oauth_code/').post(requestCode).json();

const linkAuth = (token) => wretch(`token_auth/${token}/`).get().json();

const getGithubRegistrationData = (installation_id) =>
  wretchInstallationAPI(`github-app/?installation_id=${installation_id}`)
    .get()
    .json();

const registerByToken = (token, payload) =>
  wretch(`pipeline_token_auth/${token}/`).post(payload).json();

const registerByTokenGithub = (installation_id, payload) =>
  wretchInstallationAPI(`github-app/?installation_id=${installation_id}`)
    .post(payload)
    .json();

const register = (payload) => wretch('register/').post(payload).json();

const generateCompanyName = (email) => wretch(`generate-company-name/?prompt=${email}`).get().json();

const resendCode = (payload) =>
  wretch('verify_email/resend/').post(payload).json();

const verifyEmail = (payload) => wretch('verify_email/').post(payload).json();

const getRegistrationData = (token) =>
  wretch(`pipeline_token_auth/${token}/`).get().json();

export const authApi = {
  signIn,
  resendCode,
  register,
  generateCompanyName,
  registerByToken,
  verifyEmail,
  linkAuth,
  applyOAuthCode,
  getRegistrationData,
  registerByTokenGithub,
  getGithubRegistrationData,
};
