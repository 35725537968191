import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';

import {
  Card,
  HelpMessage,
  Search,
  getCurrentFeaturePrefix,
} from '../../../../packages';

import { platformSecuritySelectors, platformSecurityService, standardSelectors, standardService } from '../../../../entities';

import { colors } from '../../../../theme';

import { PolicyGroups } from '../policy-groups';

import { FeaturePrefix } from '../../../../consts';

import { CardHeader, EmptyMessage, HeaderLabel } from './styled';

export const PolicyGroupsList = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  const { accountId, resource, scanId, ppToolToken, ppFeature } = useParams();
  const { data, isLoading } = useSelector((state) => platformSecuritySelectors.getResourceControls(state, resource));

  const onSearchHandler = useCallback(
    (value) => {
      setSearchValue(value);
      dispatch(
        platformSecurityService.getResourceControls({
          scanId,
          accountId,
          ppFeature,
          resourceId: resource,
          ppToolToken,
          search: value,
        })
      );
    },
    [accountId, resource, scanId, ppToolToken, ppFeature]
  );

  return (
    <Card
      header={
        <CardHeader>
          <HeaderLabel>
            {'List of Controls'}
          </HeaderLabel>
          <Search
            styles={{
              color: colors.textColor,
              borderBottom: `1px solid ${colors.borderColor}`,
            }}
            wrapperStyles={{
              width: 'auto',
              marginLeft: 'auto',
              marginRight: '10px',
            }}
            searchValue={searchValue}
            onSearch={onSearchHandler}
          />
          <HelpMessage
            color={colors.helpMessageColor}
            title="This is the list of Controls (policy groups) of a chosen Standard. Each control has its compliance % according to a specific scan results. Click on the Control’s name to view the list of policies in it and its compliance status."
          />
        </CardHeader>
      }
      style={{ marginBottom: 20 }}
    >
      <PolicyGroups searchValue={searchValue} />
    </Card>
  );
};
