import React, { memo } from 'react';

import { colors } from '../../../theme';
import { Card } from '../card';

import { CellCounter, CellLabel, StatCell, StatWrapper } from './styled';

export const StatTable = memo(
  ({
    styles = {},
    compliant,
    compliantSymbol = '%',
    passed,
    scanned,
    failed,
    compliantLabel,
    scannedLabel,
    passedLabel,
    failedLabel,
  }) => (
    <Card
      header={<div />}
      style={{ marginBottom: 20, width: '100%', ...styles }}
    >
      <StatWrapper>
        <StatCell>
          <CellCounter color={colors.successIcon}>{`${
            compliant ?? 0
          }${compliantSymbol}`}</CellCounter>
          <CellLabel>{compliantLabel ?? 'Compliance'}</CellLabel>
        </StatCell>
        <StatCell>
          <CellCounter>{scanned ?? 0}</CellCounter>
          <CellLabel>{scannedLabel ?? 'Assets scanned'}</CellLabel>
        </StatCell>
        <StatCell>
          <CellCounter color={colors.successIcon}>{passed ?? 0}</CellCounter>
          <CellLabel>{passedLabel ?? 'Compliant'}</CellLabel>
        </StatCell>
        <StatCell>
          <CellCounter color="#EEA54E">{failed ?? 0}</CellCounter>
          <CellLabel>{failedLabel ?? 'Violated'}</CellLabel>
        </StatCell>
      </StatWrapper>
    </Card>
  )
);
