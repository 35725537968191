import { NotificationManager } from 'react-notifications';

import {
  getCurrentFeaturePrefix,
  modalActions,
  setPagination,
  usePagination,
  useQuery,
} from '../../packages';

import { appService } from '../app';

import { customerModalName } from '../../pages/customers/consts';

import { FeaturePrefix } from '../../consts';

import { customerActions } from './store';
import { customerApi } from './api';
import { customerSelectors } from './selectors';

const getCustomers = (query) => async (dispatch) => {
  try {
    dispatch(customerActions.setIsCustomersByPageLoading(true));
    const { page = 0, ...rest } = query || {};
    const otherQueryParams = useQuery('customers');
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerApi.getCustomers
        : customerApi.getCustomersPipeline;

    const { results, ...pagination } = await apiMethod({
      page: page + 1,
      ...rest,
      ...otherQueryParams,
    });

    setPagination('customers', { ...pagination, page });

    dispatch(customerActions.setCustomers(results));
    dispatch(customerActions.setIsCustomersByPageLoading(false));
  } catch (e) {
    console.error(e);
  }
};

const getCustomerById =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      const { customers } = await customerSelectors.getCustomerData(getState);
      const currentFeaturePrefix = getCurrentFeaturePrefix();

      const apiMethod =
        currentFeaturePrefix === FeaturePrefix.PlatformSecurity
          ? customerApi.getCustomerById
          : customerApi.getCustomerByIdPipeline;

      const response = await apiMethod(id);

      const isAlreadySaved = !!customers.find(
        (customer) => String(customer.id) === String(id)
      );

      if (!isAlreadySaved) {
        dispatch(customerActions.setCustomers([...customers, response]));
      }

      return response;
    } catch (e) {
      console.error(e);

      return null;
    }
  };

const disableCustomer = (id) => async (dispatch) => {
  try {
    await customerApi.disableCustomer(id);
    const { page } = usePagination('customers');

    dispatch(appService.getMenuItems());
    await dispatch(getCustomers({ ordering: 'name', page }));
  } catch (e) {
    console.error(e);
  }
};

const enableCustomer = (id) => async (dispatch) => {
  try {
    await customerApi.enableCustomer(id);
    const { page } = usePagination('customers');

    dispatch(appService.getMenuItems());
    await dispatch(getCustomers({ ordering: 'name', page }));
  } catch (e) {
    console.error(e);
  }
};

const createOrUpdateCustomer =
  ({ name, description, options }, customerId) =>
  async (dispatch) => {
    try {
      const { withNotify = true } = options ?? {};

      dispatch(customerActions.setIsCustomerCreating(true));

      const requestModel = {
        name,
        descr: description,
      };
      let message;

      if (customerId) {
        await customerApi.updateCustomer(customerId, requestModel);
        message = 'Successfully updated';
      } else {
        await customerApi.createCustomer(requestModel);
        message = 'Successfully created';
      }

      if (withNotify) {
        NotificationManager.success(message);
      }

      dispatch(
        modalActions.setModalIsOpen({
          name: customerModalName,
          isOpen: false,
        })
      );

      dispatch(getCustomers());
    } catch (e) {
      const errorModel = JSON.parse(e.message);
      const errors = Object.values(errorModel);

      errors.forEach((error) => {
        NotificationManager.error(error);
      });

      console.error(e);
    } finally {
      dispatch(customerActions.setIsCustomerCreating(false));
    }
  };

const scanSelectedCustomers = (ids) => async (dispatch, getState) => {
  try {
    const { customers } = customerSelectors.getCustomerData(getState());

    const updatedCustomer = customers.map((customer) => {
      if (ids.includes(customer.id)) {
        return { ...customer, status: 'inprogress' };
      }

      return customer;
    });

    await dispatch(customerActions.setCustomers(updatedCustomer));
    const currentFeaturePrefix = getCurrentFeaturePrefix();

    const apiMethod =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? customerApi.scanCustomers
        : customerApi.scanCustomersPipeline;

    await apiMethod({ items: ids });
    NotificationManager.success('Successfully started');
  } catch (e) {
    console.error(e);
  }
};

const getAccountTypes = () => async (dispatch) => {
  try {
    const currentFeaturePrefix = getCurrentFeaturePrefix();
    const accountTypes =
      currentFeaturePrefix === FeaturePrefix.PlatformSecurity
        ? await customerApi.getAccountTypes()
        : await customerApi.getPipelineAccountTypes();

    await dispatch(customerActions.setAccountTypes(accountTypes));
  } catch (e) {
    console.error(e);
  }
};

const getResourcesStat =
  ({ id }) =>
  async (dispatch) => {
    try {
      const currentFeaturePrefix = getCurrentFeaturePrefix();
      const response =
        currentFeaturePrefix === FeaturePrefix.PlatformSecurity
          ? await customerApi.getResourcesStat({ id })
          : await customerApi.getResourcesStatPipeline({ id });

      await dispatch(customerActions.setResourcesStat(response));
    } catch (e) {
      console.error(e);
    }
  };

const exportAccounts =
  ({ id, query }) =>
  async () => {
    try {
      await customerApi.exportAccounts({
        id,
        query,
      });
    } catch (e) {
      console.error(e);
    }
  };

export const customerService = {
  getCustomerById,
  enableCustomer,
  disableCustomer,
  getAccountTypes,
  getCustomers,
  exportAccounts,
  getResourcesStat,
  createOrUpdateCustomer,
  scanSelectedCustomers,
};
