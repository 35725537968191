import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form } from '../../../../packages';
import { appSelectors, appService, customerDetailsSelectors } from '../../../../entities';
import { InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import Avatar from '../../../../packages/components/avatar';
import { NotificationManager } from 'react-notifications';
import branding from '../../../../branding';

export const ProfileForm = (props) => {
  const { onChangePassword = () => {} } = props;
  const dispatch = useDispatch();
  const { isAccountCreating } = useSelector(customerDetailsSelectors.getCustomerDetailsData);
  const [updating, setUpdating] = useState(false);

  const profile = useSelector(appSelectors.getProfile);

  const initialValues = useMemo(
    () => ({
      avatar: profile.avatar,
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
    }),
    [profile]
  );

  const validationSchema = defaultValidationSchema;

  const [file, setFile] = useState(null);

  const onSubmit = useCallback(
    (values) => {
      setUpdating(true);

      appService
        .updateProfile(dispatch, {
          avatar: file || profile.avatar,
          email: profile.email,
          first_name: values.first_name,
          last_name: values.last_name,
        })
        .then(() => {
          NotificationManager.success('Profile has been updated');
          setUpdating(false);
        })
        .catch(() => {
          NotificationManager.error('Server error');
          setUpdating(false);
        });
    },
    [profile, file, dispatch]
  );

  return (
    <ProfileFormWrapper>
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        isLoading={isAccountCreating}
        fromWrapperStyle={{ maxWidth: 460 }}
      >
        <InputField name='avatar' type='file' style={{ cursor: 'pointer' }} onSelectFile={setFile}>
          {({ value }) => (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
              <Avatar size={128} iconSize={64} src={value} />
              <div style={{ marginLeft: '24px', textDecoration: 'underline', color: branding.colors.primary }}>Upload image</div>
            </div>
          )}
        </InputField>
        <InputField name='first_name' label='First Name' />
        <InputField name='last_name' label='Last Name' />
        <InputField name='email' label='Email' style={{pointerEvents: 'none', opacity: 0.6}}/>
        <div
          style={{
            color: branding.colors.primary,
            textDecorationLine: 'underline',
            margin: '12px 0 24px',
            cursor: 'pointer',
          }}
          onClick={onChangePassword}
        >
          Change password
        </div>
        <Button variant='contained' size='medium' type='submit' style={{ width: 128, height: 40 }}>
          {updating ? 'Updating...' : 'Save'}
        </Button>
      </Form>
    </ProfileFormWrapper>
  );
};

export default ProfileForm;
