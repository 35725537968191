export const xs = '320px';
export const xsm = '540px';
export const sm = '768px';
export const ms = '960px';
export const md = '1024px';
export const lmd = '1200px';
export const lg = '1440px';
export const xl = '1920px';

export const mobile = '768px';
