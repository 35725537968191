import React, { useCallback, useMemo, useState } from 'react';
import { NotificationManager } from 'react-notifications';

import { customerDetailsApi, threatModelingApi } from '../../../entities';
import { Button, TextField } from '../../../packages';

import { List, listFilter, Scrollable } from './modalAssignedUtils';
import useDataFullList from '../../../hooks/useDataFullList';

const ModalAssignedUser = (props) => {
  const { reportId, user = null, onSuccess } = props;

  const [ selectedUser, setSelectedUser ] = useState(user?.id);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const onSearch = useCallback((e) => setSearch(e.target.value), []);

  const users = useDataUsers();

  const listUsers = useMemo(
    () =>
      users
        .map((i) => ({
          value: i.id,
          title: i.first_name ? `${i.first_name} ${i.last_name || ''}` : i.email,
        }))
        .filter((i) => listFilter(i.title, search)),
    [users, search]
  );

  const onSelect = useCallback((value) => {
    if (value === selectedUser) {
      setSelectedUser(null);
    } else {
      setSelectedUser(value);
    }
  }, []);

  const updateAssigned = useCallback(() => {
    if (!selectedUser) {
      setSelectedUser(null);
    }

    setLoading(true);

    threatModelingApi
      .patchReport(reportId, { user: selectedUser })
      .then(() => {
        setLoading(false);
        NotificationManager.info(
          'User has been assigned successful'
        );
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
        NotificationManager.error('Server error');
      });
  }, [reportId, selectedUser, onSuccess]);

  return (
    <div style={{ width: 416, margin: '0 26px' }}>
      <TextField
        label="Users"
        placeholder='Start typing'
        style={{ width: '100%', marginTop: 5 }}
        autoFocus
        onChange={onSearch}
      />
      <div style={{ position: 'relative', height: 290, margin: '16px 0' }}>
        <Scrollable key="users">
          <List data={listUsers} active={selectedUser} onSelect={(val) => onSelect(val)} />
        </Scrollable>
      </div>
      <div>
        <Button
          variant='contained'
          size='medium'
          type='submit'
          style={{ width: '100%', height: 40 }}
          onClick={updateAssigned}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default ModalAssignedUser;

const useDataUsers = () => {
  const { data } = useDataFullList(customerDetailsApi.getUsers);
  return data;
};
