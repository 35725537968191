import React, { memo } from 'react';

import { Button } from '../button';

import { ArrowRight } from './styled';

export const ScanSelectedButton = memo(({ ...rest }) => (
  <Button variant="contained" {...rest}>
    <ArrowRight />
    Scan selected
  </Button>
));
