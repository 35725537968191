import styled from 'styled-components';

export const ResultLineWrapper = styled.svg`
  display: flex;
  height: 240px;
  width: 220px;
  align-items: center;
  justify-content: center;
`;

export const ProgressLine = styled.path`
  fill: ${({ theme }) => theme.colors.activeBackground};
  stroke: ${({ theme }) => theme.colors.activeBackground};
`;

export const ProgressLineWrapper = styled.path`
  fill: ${({ theme }) => theme.colors.background};
  stroke: ${({ theme }) => theme.colors.background};
`;
