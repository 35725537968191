import { getCurrentFeaturePrefix } from '../../../../packages';
import { FeaturePrefix } from '../../../../consts';

export const scanHistoryHeader = [
  {
    id: 'indicator',
    numeric: false,
    disablePadding: true,
    label: 'Compliance',
    align: 'center',
  },
  {
    id: 'name',
    numeric: false,
    sort: 'name',
    disablePadding: false,
    label:
      getCurrentFeaturePrefix() === FeaturePrefix.PlatformSecurity
        ? 'Control name'
        : 'Subgroup',
    align: 'left',
  },
  {
    id: 'compliance',
    numeric: false,
    sort: 'compliance',
    disablePadding: false,
    label: 'Compliance %',
    align: 'center',
  },
  {
    id: 'policies',
    numeric: false,
    disablePadding: false,
    label:
      getCurrentFeaturePrefix() === FeaturePrefix.PlatformSecurity
        ? 'Policies count'
        : 'Checks count',
    align: 'center',
  },
  {
    id: 'policesViolated',
    numeric: false,
    disablePadding: false,
    label:
      getCurrentFeaturePrefix() === FeaturePrefix.PlatformSecurity
        ? 'Polices violated'
        : 'Checks violated',
    align: 'center',
  },
  {
    id: 'high',
    numeric: false,
    disablePadding: false,
    label: 'High/Critical',
    align: 'center',
  },
  {
    id: 'resources',
    numeric: false,
    disablePadding: false,
    label: 'Resources',
    align: 'center',
  },
  {
    id: 'resourcesViolated',
    numeric: false,
    disablePadding: false,
    label: 'Resources violated',
    align: 'center',
  },
  {
    id: 'lastScan',
    numeric: false,
    sort: 'last_scan',
    disablePadding: false,
    label: 'Last scan',
    align: 'center',
  },
];
