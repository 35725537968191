import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { SecondaryButton, modalActions } from '../../../../packages';
import { componentsModalName } from '../add-component-modal';

export const AddComponentButton = memo(() => {
  const dispatch = useDispatch();

  const onClickHandler = useCallback((e) => {
    e.stopPropagation();
    dispatch(
      modalActions.setModalIsOpen({
        name: componentsModalName,
        isOpen: true,
      })
    );
  }, []);

  return (
    <SecondaryButton
      variant="contained"
      sx={{ marginRight: '16px', display: 'block' }}
      onClick={onClickHandler}
    >
      Add Component
    </SecondaryButton>
  );
});
